import React, { useEffect, useState } from "react";

/*import style*/
import styles from '../../styles/components/RecomendedDomains.module.scss';

import SearchTable from "../dataTable/searchTable";
import SubscriptionModal from "../modals/subscriptionModal";
import { getServices } from "../../services/apis/services";



const RecomendedDomains=(props)=>{
    const [selectedDomain, setSelectedDomain] = useState(null);
    return(
        <>
        <div className={styles.e_card_border_wrapper}>
            <div className={styles.e_card_wrapper}>
                <div className="d-flex align-items-center pb-3">
                    <h3 className="mb-0 pe-3">Domains Recommended For You</h3>
                    <div className={`ms-1 ${styles.e_badge_wrapper} d-flex`}>
                        <div className={styles.e_recomended_badge}>
                            <span className="d-flex">Recommended</span>
                        </div>
                    </div>
                </div>
                <SearchTable className="pt-3" data={props.data} setDomain={setSelectedDomain}/>
                {/* <SearchTable className="pt-3" data={props.web3? sample3:sample} setDomain={setSelectedDomain}/> */}
            </div>
        </div>
        <SubscriptionModal domain={selectedDomain}/>
        </>
    )
}

export default RecomendedDomains;