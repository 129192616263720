/*Package importing*/
import React from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input'


/*import style*/
import styles from '../../styles/components/InputBox.module.scss';

export default class InputBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value,
            error: null,
        } 
    }

    componentDidMount() {
        if (this.props.defaultValue) {
            if (this.props.defaultValue) {
                this.setState({ value: this.props.defaultValue });
            }
        }
		 
    }
    validate() {
		
        if (this.props.type === "email") {
            if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.props.value)) {
                this.setState({ error: "Enter a valid email ID." })
            }
        }
		else if (this.props.type === "tel") { 
            if (!/^[0-9]{6,14}$/.test(this.props.value)) {
                this.setState({ error: "Enter a valid phone no." })
            }
        }
        else if (this.props.type === "number" && (this.props.placeholder === "Phone Number" || this.props.placeholder === "Phone number" || this.props.placeholder === "Mobile Number")) {
            if (!/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(this.state.value) || this.state.value === "0000000000") {
                this.setState({ error: "Enter a valid phone number." })
            }
        }
        else if(this.props.type === "number" && this.props.validatePhone){
            let valid = isValidPhoneNumber(`${this.props.countryCode}${this.state.value}`);
            if(!valid){
                this.setState({ error: "Enter a valid phone number." });
            }
        }
    }
    valueChange(e) {
        if (!this.props.isDisabled) {
            this.setState({ value: e.target.value, error: null });
            this.props.valueChange(e.target.value);
        }
    }

    keyPress(e) {
        if (e.keyCode == 13 && this.props.searchBox) {
            this.props.onSearch();
        }
    }

    render() {
        return (
            <div className={
                this.props.isDisabled && this.props.suffix ?
                    `${styles.e_input_wrap} ${styles.e_input_disabled} ${styles.e_suffix_present}`
                    : this.props.isDisabled ?
                        `${styles.e_input_wrap} ${styles.e_input_disabled}`
                        : this.props.suffix ?
                            `${styles.e_input_wrap} ${styles.e_suffix_present}`
                            : styles.e_input_wrap
            }>
                <input id={this.props.id}
                    value={this.props.value ? this.props.value : this.state.value}
                    type={this.props.type}
                    className={
                        this.state.error ?
                            `${styles.e_input_error} ${styles.e_input_box} ${this.props.className}`
                            : `${styles.e_input_box} ${this.props.className}`
                    }
                    onChange={(e) => { this.valueChange(e) }}
                    placeholder={this.props.placeholder}
                    required={this.props.required}
                    onBlur={() => { this.validate() }}
                    prefix={this.props.prefix}
                    onKeyDown={e => this.keyPress(e)}
                    readOnly={this.props.readOnly}
                    onFocus={() => this.setState({ error: null })}
                    disabled={this.props.isDisabled ? true : false}
                />
                {
                    this.props.suffix ?
                        <span className={
                            this.state.error ?
                                `${styles.e_suffix} ${styles.e_suffix_error}`
                                :
                                styles.e_suffix
                        }>{this.props.suffix}</span>
                        :
                        null
                }
                {this.props.label ? <label htmlFor={this.props.id} className="label">{this.props.label}</label> : null}

                <p className={
                    this.state.error || this.props.error ?
                        `${styles.e_error_indicator} ${styles.e_indicator} mb-0`
                        : this.props.tip ?
                            `${styles.e_tip_indicator} ${styles.e_indicator} mb-0`
                            :
                            `${styles.e_indicator} mb-0`
                }>
                    {
                        this.state.error ?
                            this.state.error
                            : this.props.error ?
                                this.props.error
                                : this.props.tip ?
                                    this.props.tip
                                    :
                                    ""
                    }
                </p>
            </div>
        );
    }
}

