import React, { useState } from 'react';

/*import style*/
import styles from '../../styles/components/SearchBox.module.scss';

const SearchBox = (props) => {
    const [value, setValue] = useState('');

    const handleChange = (e) => {
        let query = e.target.value;
        setValue(query);
        props.onChange(query);
    }

    const keyDownHandler = (e) => {
        if (e.keyCode == 13) {
            props.onSearch();
        }
    }

    return (
        <div className={`position-relative`}>
            <div className="input-group border rounded-pill shadow p-2 mb-1 bg-white w-100" >
                <input autoComplete="off" className="form-control  border-0 w3searchBox" maxLength={62} 
				id={props.id}
                    value={props.value}
                    onChange={handleChange}
                    onKeyDown={keyDownHandler}
                    placeholder="Domain name,Twitter account"
                    required />
				<span className="input-group-append">
                    <button className="btn btn-primary   border rounded-pill ms-n5" type="button" onClick={() => props.onSearch()}>
                        Generate Report
                    </button>
                </span>
            </div>
        </div>
    )
}

export default SearchBox;