/*packages*/
import React, { useEffect, useState } from "react";

/*components*/
import { Toast } from "../../components/alerts";
import { KycCard } from "../../components/cards";
import AddKycModal from "../../components/modals/addKycModal";
import Loader from "../../components/others/loader";

/*services*/
import services from "../../services/apis/services";

const KycContainer = () => {

    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(null);
    const [availableDocuments, setAvailableDocuments] = useState([]);

    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null)
            }, 4000);
        }
    }, [error]);

    useEffect(() => {
        getAvailableDocuments();
    }, []);


    /*---- get available documents api ---*/
    const getAvailableDocuments = () => {
        setLoader(true);
        let url = "action/get/kyclabels";

        services(true, url).then((response) => {
            let res = response.data;

            if (res.status === 1) {
              
                setAvailableDocuments(res.resData);
                setLoader(false);

                setAvailableDocuments(res.resData);
            } else if (res.status === 0 || res.status === -1 || res.status === -9) {
                setError(res.msg);
                setLoader(false);
            } else {
                setError(res.msg);
                setLoader(false);
            }
        });
    }

    return (
        <>
            <div className="w-100">
                {
                    loader ?
                        <Loader />
                        :
                        <div className="row">
                            <div className="col-lg-6 col-md-8 col-sm-10">
                                <KycCard type={2} />
                            </div>
                        </div>
                }
            </div>
            <AddKycModal updated={() => getAvailableDocuments()} options={availableDocuments}/>
            {
                error !== null ?
                    <Toast data={error} />
                    :
                    null
            }
        </>
    )
}

export default KycContainer;