/* Packages */
import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";

/* Components */
import { Primary, TextButton } from '../cta';
import { InputBox } from '../form';
import { Toast } from '../alerts/index'

/* import services */
import services from '../../services/apis/services'
const CreatePassword = (props) => {
    const router = useHistory();
    const [error, setError] = useState(null);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null);
            }, 3000);
        }
    }, [error]);

    /* reset password api*/
    const setPassword = () => {
        setLoader(true);
        let url = 'action/reset/login';

        let body = {
            password: props.password,
            cpassword: props.confirmPassword,
            otpToken: router.location.state.token
        }

        services(true, url, body).then((response) => {
            let res = response.data
            if (res.status === 1) {
                localStorage.setItem("web23_token", res.resData.token);
                setLoader(false);
                router.push('/auth/login')
            } else {
                setError(res.resData[0].msg);
                setLoader(false);
            }
        });
    }

    return (
        <div className="container-fluid p-0">
            <div className="e-auth-container w-100">
                <div className="e-auth-max-width-container mx-auto position-relative">
                    <h3 className="text-center mb-3">Change Password</h3>
                    <span className="position-absolute e-fp-back-btn">
                        <TextButton
                            name="Back"
                            back={true}
                            small={true}
                            handleClick={() => {
                                router.push('/auth/verify-otp', {
                                    token: router.location.state.token
                                })
                            }}
                        />
                    </span>
                </div>
                <div className="w-100 d-flex justify-content-center pt-3">
                    <div className="e-auth-form-wrapper">
                        <div className="row">
                            <div className="col-lg-12">
                                <InputBox
                                    label="New Password"
                                    value={props.password}
                                    type="password"
                                    valueChange={(value) => props.setPassword(value)}
                                    placeholder="New Password"
                                    required={true} />
                            </div>
                            <div className="col-lg-12 pt-2">
                                <InputBox
                                    label="Re-enter New Password"
                                    value={props.confirmPassword}
                                    type="password"
                                    valueChange={(value) => props.setConfirmPassword(value)}
                                    placeholder="Re-enter New Password"
                                    required={true} />
                            </div>
                            <div className="col-lg-12 pt-3">
                                {
                                    props.password && props.confirmPassword ?
                                        <Primary
                                            name="Save New Password"
                                            full_width={true}
                                            handleClick={() => {
                                                setPassword()
                                            }}
                                            className={loader ? "e-btn-loader" : ""}
                                        />
                                        :
                                        <Primary
                                            name="Save New Password"
                                            full_width={true}
                                            disabled={true}
                                        />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                error !== null ?
                    <Toast data={error} />
                    :
                    null
            }
        </div>
    )
}

export default CreatePassword;