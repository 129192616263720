import React, { useState } from 'react';

/*import style*/
import styles from '../../styles/components/SubscriptionTable.module.scss';

const SubscriptionTable = (props) => {

    return (
        <div className={`${styles.e_table_wrapper}`}>
            {
                props.data && props.data.length ?
                    <div className={styles.e_table_container}>
                        {
                            props.data.map((item, i) => {
                                return (
                                    <div className={props.selected === item.PackageId.value ? `${styles.e_cell} ${styles.e_selected_cell} position-relative` : `${styles.e_cell} position-relative`} key={`table-item-${i}`} onClick={() => {props.setSelected(item.PackageId.value); props.selectedPackageName(item.PackageName.value)}}>
                                        <div className={`${styles.e_gradiant_card_bg} position-absolute`}></div>
                                        <div className={`${styles.e_cell_content} position-relative`}>
                                            <h2 className="mb- pb-3 d-flex align-items-center">
                                                <span className={styles.e_gradiant}>₹{item.Price.value}</span><span className={`${styles.e_regular} ps-1`}>/year</span>
                                            </h2>
                                            <div className="d-flex align-items-center pt-1 pb-3">
                                                <h3 className="mb-0">{item.PackageName.value}</h3>
                                                {
                                                    item.SubscriptionBadge ?
                                                        <div className={`${styles.e_badge} text-capitalize d-flex ms-2`}>
                                                            <span>{item.SubscriptionBadge.value}</span>
                                                        </div>
                                                        :
                                                        null
                                                }
                                            </div>
                                            <div className="e-border-bottom w-100 pt-1"></div>
                                            {/* {
                                                item.specs && item.specs.length ? */}
                                                    <div className={`${styles.e_specs_container} pt-3`}>
                                                        {/* {
                                                            item.specs.map((spec, j) => ( */}
                                                                <div className={`${styles.e_specs} d-flex`} key={`spec-${i}`}>
                                                                    <div className="d-flex">
                                                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.e_tick_icon}>
                                                                            <path d="M9 16.5C4.85975 16.4955 1.50455 13.1403 1.5 9.00001V8.85001C1.58245 4.72841 4.97594 1.44598 9.098 1.50067C13.2201 1.55537 16.5253 4.92668 16.4983 9.04902C16.4714 13.1714 13.1224 16.4992 9 16.5ZM5.5575 8.69251L4.5 9.75001L7.5 12.75L13.5 6.75001L12.4425 5.68501L7.5 10.6275L5.5575 8.69251Z" fill="#9EA3AA" />
                                                                        </svg>
                                                                    </div>
                                                                    <p className="mb-0 ps-1">{item.SupportType.value} chat support</p>
                                                                </div>
                                                            {/* ))
                                                        } */}
                                                    </div>
                                                    <div className={`${styles.e_specs_container} pt-3`}>
                                                        {/* {
                                                            item.specs.map((spec, j) => ( */}
                                                                <div className={`${styles.e_specs} d-flex`} key={`spec-${i}`}>
                                                                    <div className="d-flex">
                                                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.e_tick_icon}>
                                                                            <path d="M9 16.5C4.85975 16.4955 1.50455 13.1403 1.5 9.00001V8.85001C1.58245 4.72841 4.97594 1.44598 9.098 1.50067C13.2201 1.55537 16.5253 4.92668 16.4983 9.04902C16.4714 13.1714 13.1224 16.4992 9 16.5ZM5.5575 8.69251L4.5 9.75001L7.5 12.75L13.5 6.75001L12.4425 5.68501L7.5 10.6275L5.5575 8.69251Z" fill="#9EA3AA" />
                                                                        </svg>
                                                                    </div>
                                                                    <p className="mb-0 ps-1">Available {item.NoOfDomain.value} domains</p>
                                                                </div>
                                                            {/* ))
                                                        } */}
                                                    </div>
                                                    <div className={`${styles.e_specs_container} pt-3`}>
                                                        {/* {
                                                            item.specs.map((spec, j) => ( */}
                                                                <div className={`${styles.e_specs} d-flex`} key={`spec-${i}`}>
                                                                    <div className="d-flex">
                                                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.e_tick_icon}>
                                                                            <path d="M9 16.5C4.85975 16.4955 1.50455 13.1403 1.5 9.00001V8.85001C1.58245 4.72841 4.97594 1.44598 9.098 1.50067C13.2201 1.55537 16.5253 4.92668 16.4983 9.04902C16.4714 13.1714 13.1224 16.4992 9 16.5ZM5.5575 8.69251L4.5 9.75001L7.5 12.75L13.5 6.75001L12.4425 5.68501L7.5 10.6275L5.5575 8.69251Z" fill="#9EA3AA" />
                                                                        </svg>
                                                                    </div>
                                                                    <p className="mb-0 ps-1">Available {item.NoOfTemplates.value} templates</p>
                                                                </div>
                                                            {/* ))
                                                        } */}
                                                    </div>
                                                    <div className={`${styles.e_specs_container} pt-3`}>
                                                        {/* {
                                                            item.specs.map((spec, j) => ( */}
                                                                <div className={`${styles.e_specs} d-flex`} key={`spec-${i}`}>
                                                                    <div className="d-flex">
                                                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.e_tick_icon}>
                                                                            <path d="M9 16.5C4.85975 16.4955 1.50455 13.1403 1.5 9.00001V8.85001C1.58245 4.72841 4.97594 1.44598 9.098 1.50067C13.2201 1.55537 16.5253 4.92668 16.4983 9.04902C16.4714 13.1714 13.1224 16.4992 9 16.5ZM5.5575 8.69251L4.5 9.75001L7.5 12.75L13.5 6.75001L12.4425 5.68501L7.5 10.6275L5.5575 8.69251Z" fill="#9EA3AA" />
                                                                        </svg>
                                                                    </div>
                                                                    <p className="mb-0 ps-1">Available {item.NoOfSocialMedia.value} social media</p>
                                                                </div>
                                                            {/* ))
                                                        } */}
                                                    </div>
                                                    <div className={`${styles.e_specs_container} pt-3`}>
                                                        {/* {
                                                            item.specs.map((spec, j) => ( */}
                                                                <div className={`${styles.e_specs} d-flex`} key={`spec-${i}`}>
                                                                    <div className="d-flex">
                                                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.e_tick_icon}>
                                                                            <path d="M9 16.5C4.85975 16.4955 1.50455 13.1403 1.5 9.00001V8.85001C1.58245 4.72841 4.97594 1.44598 9.098 1.50067C13.2201 1.55537 16.5253 4.92668 16.4983 9.04902C16.4714 13.1714 13.1224 16.4992 9 16.5ZM5.5575 8.69251L4.5 9.75001L7.5 12.75L13.5 6.75001L12.4425 5.68501L7.5 10.6275L5.5575 8.69251Z" fill="#9EA3AA" />
                                                                        </svg>
                                                                    </div>
                                                                    <p className="mb-0 ps-1"> {item.isWeb3Enabled.value === true ? "web3" : "web2"} </p>
                                                                </div>
                                                            {/* ))
                                                        } */}
                                                    </div>

                                                    {/* :
                                                    null
                                            } */}
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    :
                    null
            }
        </div>
    )
}

export default SubscriptionTable;