import React, { useState, useEffect } from 'react';
import { getServices } from "../../services/apis/services";
import { LoginSocialFacebook, LoginSocialInstagram, LoginSocialLinkedin, LoginSocialTwitter } from "reactjs-social-login";
import { FacebookLoginButton, InstagramLoginButton, LinkedInLoginButton, TwitterLoginButton  } from "react-social-login-buttons";
// import '@fortawesome/fontawesome-free/css/all.min.css';
import Facebook from '../../assets/images/icons/facebook-32.svg';
import Linkedin from '../../assets/images/icons/linkedin-32.svg';
import Twitter from '../../assets/images/icons/twitter-32.svg';
import Instagram from '../../assets/images/icons/Instagram-32.svg';
import Success from '../../assets/images/icons/tick-icon-18.svg';
import Warning from '../../assets/images/icons/warning-icon-18.svg';


import { Primary, TextButton } from '../cta';

import { HeathIndicatorCard, SocialMediaIndicator } from ".";
// import { TextButton } from "../cta";

/*import style*/
import styles from '../../styles/components/SocialMediaOverview.module.scss';

const sampleData = [
    { issue: "Look Alike", platform: "LinkedIn", resolved: false, id: 2 },
    { issue: "Data Theft", platform: "Instagram", resolved: false, id: 4 }
]

const socialMediaCards = [
    { name: "Facebook", issues: 0, connected: false },
    { name: "Instagram", issues: 0, connected: false },
    { name: "LinkedIn", issues: 0, connected: false },
    { name: "Twitter", issues: 0, connected: false },
    { name: "Youtube", issues: 0, connected: false },
];


const SocialMediaOverview = (props) => {

    const facebookAppId = process.env.REACT_APP_FACEBOOK_APP_ID;
    const twitterAppId = process.env.REACT_APP_LINKEDIN_APP_ID;
    const linkedinAppId = process.env.REACT_APP_LINKEDIN_APP_ID;
    const instagramAppId = process.env.REACT_APP_INSTAGRAM_APP_ID;

    const [profileFacebook, setProfileFacebook] = useState();
    const [profileFacebookAPIData, setProfileFacebookAPIData] = useState();
    const [profileInstagram, setProfileInstagram] = useState();
    const [profileInstagramAPIData, setProfileInstagramAPIData] = useState();

    
    const [profileTwitter, setProfileTwitter] = useState(); 
    const [profileTwitterAPIData, setprofileTwitterAPIData] = useState();

    const [profileLinkedIn, setProfileLinkedIn] = useState();
    const [profileLinkedInAPIData, setProfileLinkedInAPIData] = useState();

    const [modalTitle, setModalTitle] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [formErrors, setFormErrors] = useState({});


    const openModal = async (modalTitle) => {
        let social_account_name_front= '';
        if(modalTitle == 'Facebook Login') {
            social_account_name_front = 'facebook';
        } else if(modalTitle == 'Instagram Login') {
            social_account_name_front = 'instagram';
        } else if(modalTitle == 'LinkedIn Login') {
            social_account_name_front = 'linkedin';
        } else if(modalTitle == 'Twitter Login') {
            social_account_name_front = 'twitter';
        }
        
        let dataToBeSubmit = {
            social_account_name:social_account_name_front,
        }
        
        const url = "socialaccounts/delete";
        const token = localStorage.getItem("web23_token");
        const response = await getServices(url, token, dataToBeSubmit);
        if (response.data.status === 1) {
            if(modalTitle == 'Facebook Login') {
                setProfileFacebook()
            } else if(modalTitle == 'Instagram Login') {
                setProfileInstagram()
            } else if(modalTitle == 'LinkedIn Login') {
                setProfileLinkedIn()
            } else if(modalTitle == 'Twitter Login') {
                setProfileTwitter()
            }

            
        }

    };


    useEffect(() => {
        const updateSocialMediaData = async () => {
            try {
                console.log()
                const url = "socialaccounts/update";
                const token = localStorage.getItem("web23_token");
                let dataToBeSubmit= {}
                if(profileFacebookAPIData) {
                    console.log("profileFacebookAPIData")                    
                    console.log(profileFacebookAPIData.data.first_name)

                    dataToBeSubmit = {
                        social_account_name: 'facebook',
                        first_name:profileFacebookAPIData.data.first_name,
                        last_name:profileFacebookAPIData.data.last_name,
                        full_name:profileFacebookAPIData.data.name,
                        userID:profileFacebookAPIData.data.userID,
                        email:profileFacebookAPIData.data.email,
                        
                    }
                } else if(profileInstagramAPIData) {
                    dataToBeSubmit = {
                        social_account_name: 'instagram',
                        first_name:profileInstagramAPIData.data.first_name,
                        last_name:profileInstagramAPIData.data.last_name,
                        full_name:profileInstagramAPIData.data.name,
                        userID:profileInstagramAPIData.data.userID,
                        email:profileInstagramAPIData.data.email,
                        
                    }
                } else if(profileLinkedInAPIData) {
                    dataToBeSubmit = {
                        social_account_name: 'linkedin',
                        first_name:profileLinkedInAPIData.data.first_name,
                        last_name:profileLinkedInAPIData.data.last_name,
                        full_name:profileLinkedInAPIData.data.name,
                        userID:profileLinkedInAPIData.data.userID,
                        email:profileLinkedInAPIData.data.email,
                        
                    }
                } else if(profileTwitterAPIData) {
                    dataToBeSubmit = {
                        social_account_name: 'twitter',
                        first_name:profileTwitterAPIData.data.first_name,
                        last_name:profileTwitterAPIData.data.last_name,
                        full_name:profileTwitterAPIData.data.name,
                        userID:profileTwitterAPIData.data.userID,
                        email:profileTwitterAPIData.data.email,
                        
                    }
                }
               
                const response = await getServices(url, token, dataToBeSubmit);
                if(response.data.status ==1) {
                    if(profileFacebookAPIData) {
                        setProfileFacebook(dataToBeSubmit)
                    } else if(profileInstagramAPIData) {
                        setProfileInstagram(dataToBeSubmit)
                    }  else if(profileLinkedInAPIData) {
                        setProfileLinkedIn(dataToBeSubmit)
                    }  else if(profileTwitterAPIData) {
                        setProfileTwitter(dataToBeSubmit)
                    }
                }
               

            } catch (error) {
                console.error("Error fetching domains", error);
            }
        };
        updateSocialMediaData();
    }, [profileFacebookAPIData, profileInstagramAPIData, profileTwitterAPIData, profileLinkedInAPIData]);
  

      useEffect(() => {

        const fetchSocialMediaData = async () => {
            try {
                const url = "socialaccounts/get";
                const token = localStorage.getItem("web23_token");
                const response = await getServices(url, token);
       
                const structuredData = response.data.resData.map(socialMediaData => {
                    if(socialMediaData) {
                        if(socialMediaData.social_account_name =='facebook') {
                            let dataToBeSubmit = {
                                social_account_name:socialMediaData.social_account_name,
                                user_name:socialMediaData.username,
                                password:socialMediaData.password,
                                account_api_response:socialMediaData.account_api_response
                                
                            }
                            setProfileFacebook(dataToBeSubmit)
                        } else  if(socialMediaData.social_account_name =='instagram') {
                            let dataToBeSubmit = {
                                social_account_name:socialMediaData.social_account_name,
                                user_name:socialMediaData.username,
                                password:socialMediaData.password,
                                account_api_response:socialMediaData.account_api_response
                                
                            }
                            setProfileInstagram(dataToBeSubmit)
                        } else  if(socialMediaData.social_account_name =='twitter'){ 
                            let dataToBeSubmit = {
                                social_account_name:socialMediaData.social_account_name,
                                user_name:socialMediaData.username,
                                password:socialMediaData.password,
                                account_api_response:socialMediaData.account_api_response
                                
                            }
                            setProfileTwitter(dataToBeSubmit)
                        } else if(socialMediaData.social_account_name =='linkedin') {
                            let dataToBeSubmit = {
                                social_account_name:socialMediaData.social_account_name,
                                user_name:socialMediaData.username,
                                password:socialMediaData.password,
                                account_api_response:socialMediaData.account_api_response
                                
                            }
                            setProfileLinkedIn(dataToBeSubmit)
                        }
                    }
                    
                }
                    
                );

            } catch (error) {
                console.error("Error fetching domains", error);
            }
        };

        fetchSocialMediaData();
    }, []);
  

    return (
        <>
        <div className={styles.e_card_wrapper}>
            <div className="w-100 e-px-32 e-py-32 e-border-bottom">
                <div className="row">
                    <div className="col-lg-6">
                        <h3 className="mb-0 text-nowrap">Health Summary</h3>
                    </div>
                    <div className="col-lg-6 pt-3 pt-lg-0">
                        <div className="w-100 h-100 d-flex justify-content-end align-items-center">
                            <TextButton
                                name="Recheck"
                                recheck={true}
                                handleClick={props.handleRefresh}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${styles.e_report_body} w-100 e-px-32 e-py-32`}>
                <div className="w-100 d-flex flex-column flex-lg-row">
                    <div>
                        <div className="e-max-content-width">
                            <div className="pe-lg-3">
                                <div className="e-max-content-width">
                                    <span className={`${styles.e_indicator_title} pb-3 text-uppercase d-flex justify-content-center`}>Health indicator</span>
                                    <span>
                                        <HeathIndicatorCard value={2} />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-100 ps-lg-3 pt-4 pt-lg-0">
                        <div className="row gy-3">
                            {
                                // socialMediaCards.map((item, i) => (
                                    <>
                                    <div className="col-lg-5 col-md-6" >
                                        <a className="w-100 e-social-media-card" >
                                            {/* <SocialMediaIndicator
                                                issues={item.issues}
                                                type={item.name}
                                                not_connected={!item.connected}
                                            /> */}

                                            <div className='setincard'>
                                                <div className="d-flex align-items-center justify-content-between" style={{ border: '1px solid #e5e5e5', borderRadius: '10px', padding: '24px 10px 24px 11px' }}>
                                                    <div className="d-flex align-items-center">
                                                    <img src={Facebook} style={{
                                                        // backgroundColor: '#E8F0FE',
                                                        padding: '10px',
                                                        borderRadius: '50%',
                                                    }}/>
                                                    {/* <Facebook style={{
                                                        backgroundColor: '#E8F0FE',
                                                        padding: '10px',
                                                        borderRadius: '50%',
                                                        width: '32px',
                                                        height: '32px',
                                                    }} /> */}
                                                    <h4 className="fw-normal mb-0 ms-3" style={{ color: '#000', fontSize: '16px' }}>Facebook</h4>
                                                    </div>
                                                    
                                                    <div className="d-flex align-items-center">
                                                    { profileFacebook ? (
                                                                <Primary
                                                                    name="Disconnect"
                                                                    handleClick={() => openModal("Facebook Login")}
                                                                />

                                                                ) : (

                                                                <LoginSocialFacebook
                                                                    appId={facebookAppId}
                                                                    onResolve={(response) => {
                                                                        setProfileFacebookAPIData(response);
                                                                    }}
                                                                    onReject={(error) => {
                                                                        // console.log(error);
                                                                    }}
                                                                >
                                                                <Primary name="Connect" />
                                                                </LoginSocialFacebook>
                                                                   
                                                        )
                                                    } 
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>


                                    <div className="col-lg-5 col-md-6" >
                                    <a className="w-100 e-social-media-card" >
                                        {/* <SocialMediaIndicator
                                            issues={item.issues}
                                            type={item.name}
                                            not_connected={!item.connected}
                                        /> */}

                                            <div className='setincard'>
                                                <div className="d-flex align-items-center justify-content-between" style={{ border: '1px solid #e5e5e5', borderRadius: '10px', padding: '24px 10px 24px 11px' }}>
                                                    <div className="d-flex align-items-center">
                                                    {/* <i className="fab fa-instagram" style={{
                                                         background: 'linear-gradient(to right, #f09433, #e6683c, #dc2743, #cc2366, #bc1888)',
                                                         color: 'white',
                                                         padding: '10px 14px',
                                                         borderRadius: '50%',
                                                         fontSize: '20px',
                                                        
                                                    }}></i> */}
                                                    <img src={Instagram} style={{
                                                        // background: 'linear-gradient(to right, #f09433, #e6683c, #dc2743, #cc2366, #bc1888)',
                                                        color: 'white',
                                                        padding: '10px 14px',
                                                        borderRadius: '50%',
                                                        fontSize: '20px',
                                                    }}/>
                                                    <h4 className="fw-normal mb-0 ms-3" style={{ color: '#000', fontSize: '16px' }}>Instagram</h4>
                                                    </div>
                                                    
                                                    <div className="d-flex align-items-center">
                                                        {profileInstagram ? (

                                                                <Primary
                                                                    name="Disconnect"
                                                                    handleClick={() => openModal("Instagram Login")}
                                                                />

                                                                ) : (

                                                                    <LoginSocialInstagram
                                                                        appId={instagramAppId}
                                                                        onResolve={(response) => {
                                                                            setProfileInstagramAPIData(response);
                                                                        }}
                                                                        onReject={(error) => {
                                                                            // console.log(error);
                                                                        }}
                                                                    >
                                                                    <Primary name="Connect" />
                                                                    </LoginSocialInstagram>
                                                               
                                                            )
                                                        } 
                                                    </div>
                                                </div>
                                            </div>
                                    </a>
                                    </div>


                                    <div className="col-lg-5 col-md-6" >
                                    <a className="w-100 e-social-media-card" >
                                        {/* <SocialMediaIndicator
                                            issues={item.issues}
                                            type={item.name}
                                            not_connected={!item.connected}
                                        /> */}

                                            <div className='setincard'>
                                                <div className="d-flex align-items-center justify-content-between" style={{ border: '1px solid #e5e5e5', borderRadius: '10px', padding: '24px 10px 24px 11px' }}>
                                                    <div className="d-flex align-items-center">
                                                    {/* <i className="fab fab fa-twitter" style={{
                                                         background: 'linear-gradient(to right, #1DA1F2, #0d95e8)', 
                                                         color: 'white',
                                                         padding: '10px 14px',
                                                         borderRadius: '50%',
                                                         fontSize: '20px',
                                                        
                                                    }}></i> */}
                                                    <img src={Twitter} style={{
                                                        // background: 'linear-gradient(to right, #1DA1F2, #0d95e8)', 
                                                        color: 'white',
                                                        padding: '10px 14px',
                                                        borderRadius: '50%',
                                                        fontSize: '20px',
                                                    }}/>
                                                    <h4 className="fw-normal mb-0 ms-3" style={{ color: '#000', fontSize: '16px' }}>Twitter</h4>
                                                    </div>
                                                    
                                                    <div className="d-flex align-items-center">
                                                        {profileTwitter ? (

                                                            <Primary
                                                                name="Disconnect"
                                                                handleClick={() => openModal("Twitter Login")}
                                                            />

                                                            ) : (

                                                                <LoginSocialTwitter
                                                                    appId={twitterAppId}
                                                                    onResolve={(response) => {
                                                                        setprofileTwitterAPIData(response);
                                                                    }}
                                                                    onReject={(error) => {
                                                                        // console.log(error);
                                                                    }}
                                                                >
                                                                <Primary name="Connect" />
                                                                </LoginSocialTwitter>
                                                            
                                                        )
                                                    } 
                                                    </div>
                                                </div>
                                            </div>
                                    </a>
                                    </div>


                                    <div className="col-lg-5 col-md-6" >
                                    <a className="w-100 e-social-media-card" >
                                        {/* <SocialMediaIndicator
                                            issues={item.issues}
                                            type={item.name}
                                            not_connected={!item.connected}
                                        /> */}

                                            <div className='setincard'>
                                                <div className="d-flex align-items-center justify-content-between" style={{ border: '1px solid #e5e5e5', borderRadius: '10px', padding: '24px 10px 24px 11px' }}>
                                                    <div className="d-flex align-items-center">
                                                    {/* <i className="fab fa-linkedin-in" style={{
                                                         background: 'linear-gradient(to right, #0077B5, #005582)',
                                                         color: 'white',
                                                         padding: '10px 14px',
                                                         borderRadius: '50%',
                                                         fontSize: '20px',
                                                        
                                                    }}></i> */}
                                                    <img src={Linkedin} style={{
                                                        // background: 'linear-gradient(to right, #1DA1F2, #0d95e8)', 
                                                        color: 'white',
                                                        padding: '10px 14px',
                                                        borderRadius: '50%',
                                                        fontSize: '20px',
                                                    }}/>
                                                    <h4 className="fw-normal mb-0 ms-3" style={{ color: '#000', fontSize: '16px' }}>LinkedIn</h4>
                                                    </div>
                                                    
                                                    <div className="d-flex align-items-center">
                                                        {profileLinkedIn ? (
                                                            <Primary
                                                                name="Disconnect"
                                                                handleClick={() => openModal("LinkedIn Login")}
                                                            />

                                                            ) : (

                                                                <LoginSocialLinkedin
                                                                    appId={linkedinAppId}
                                                                    onResolve={(response) => {
                                                                        setProfileLinkedInAPIData(response);
                                                                    }}
                                                                    onReject={(error) => {
                                                                        // console.log(error);
                                                                    }}
                                                                >
                                                                <Primary name="Connect" />
                                                                </LoginSocialLinkedin>
                                                           
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                    </a>
                                    </div>
                                    </>
                                // ))
                            }
                        </div>
                    </div>
                </div>
                <div className="row pt-4">
                    <div className="col-12">

                        {/* <div className={`${styles.e_list_wrapper} py-3`}>
                            <h4 className="mb-4 px-3">Critical Issues</h4>
                            <div className={styles.e_list_container + " px-3"}>
                                <div className={`${styles.e_table_head} row gx-1 mb-2`}>
                                    <div className="col-3"><h6>Issue</h6></div>
                                    <div className="col-2"><h6>Platform</h6></div>
                                    <div className="col-2"><h6>Status</h6></div>
                                    <div className="col-2"><h6>Solved Date</h6></div>
                                    <div className="col-3"><h6>Actions</h6></div>
                                </div>
                                <div className={`${styles.e_table_body} row gx-1 gy-4`}>
                                    {
                                        sampleData.map((item, i) => (
                                            <React.Fragment key={`issue-${i}`}>
                                                <div className="col-3">{item.issue}</div>
                                                <div className="col-2">{item.platform}</div>
                                                <div className="col-2">
                                                    <div className="d-flex align-items-center">
                                                        {
                                                            item.resolved ?
                                                                <>
                                                                    <span className={styles.e_status}>Auto Resolved</span>
                                                                    <div className={`${styles.e_status_icon} ${styles.e_status_resolved} ms-1`}></div>
                                                                </>
                                                                :
                                                                <>
                                                                    <span className={styles.e_status}>Not Resolved</span>
                                                                    <div className={`${styles.e_status_icon} ${styles.e_status_not_resolved} ms-1`}></div>
                                                                </>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-2">{item.solved_date ? item.solved_date : "--"}</div>
                                                <div className="col-3">
                                                    <TextButton
                                                        name="Recheck & verify"
                                                        recheck={true}
                                                        small={true}
                                                        handleClick={() => props.handleResolve(item.id)}
                                                    />
                                                </div>
                                            </React.Fragment>
                                        ))
                                    }
                                </div>
                            </div>
                        </div> */}

                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default SocialMediaOverview;