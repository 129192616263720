import React from 'react';

import SearchLoader from '../../assets/images/search-loader.gif';
import DefaultLoader from '../../assets/images/loader.gif';

/*import style*/
import styles from '../../styles/components/Loader.module.scss';

const Loader=(props)=>{

    return(
        <div className="w-100 d-flex justify-content-center my-auto">
            {
                props.type==='search'?
                <img src={SearchLoader} alt="Search Loader" className={styles.e_loader_img}/>
                :
                <img src={DefaultLoader} alt="" className={styles.e_loader_img}/>
            }
        </div>
    )
}

export default Loader;