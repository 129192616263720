/*
 *   File : index.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : index file for badge components 
 *   Integrations : null
 *   Version : 1.0
 *   Created : 24-06-2021
 */

import HeathIndicatorBadge from "./heathIndicator";

export {HeathIndicatorBadge};