import React, { useState,useEffect } from 'react';
import { Link } from 'react-router-dom';

//import Icon from '../../assets/images/sample-person-icon.svg';
import Icon from '../../assets/images/profile/sample-girl.png';

/*import style*/
import styles from '../../styles/components/ProfileDropdown.module.scss';
import services from '../../services/apis/services';
import Cookies from 'js-cookie';

const ProfileDropdown = (props) => {
    const [isActive, setmenuActive] = useState(false);
    
    const logout = () => {
        Cookies.remove("web23_token");
        localStorage.removeItem("name");
        localStorage.removeItem("email");
        window.location.href = "/auth/login";
    }
	/*
	useEffect(() => {
		getProfileDetails();
	  }, []);
    const getProfileDetails = () => {
	let SESSION_TOKEN=localStorage.getItem("web23_token");
	let url = 'action/profile/get';
	let data = {
      token:SESSION_TOKEN ,
    };
	services(true, url, data).then((response) => {
      let res = response.data
      if (res.status === 1) {
        setProfileData(res.resData);
      } else if (res.status === 0 || res.status === -1) {
      } else {
      }
    });
	}
	*/
    return (
        <div>
            <div className={`${styles.e_wrapper} position-relative`} onClick={() => setmenuActive(!isActive)}>
                <div className={`${styles.e_container} d-flex align-items-center`}>
                    <img src={Icon} alt="User Image" className={styles.e_user_img} />
                    <span className="px-1 d-none d-md-block">
                        {props.profile.fullName}
                    </span>
                    <span className={`${styles.e_indicator} d-none d-md-block`}></span>
                </div>
                <div className={`${isActive ? `${styles.e_menu_wrapper} ${styles.e_menu_active}` : styles.e_menu_wrapper} position-absolute`}>
                    <div className={`${styles.e_menu_container} mt-1 d-flex flex-column`}>
                        <div className={styles.e_personal_details + " mb-3"}>
                            <h4 className="mb-1">{props.profile.fullName}</h4>
                            <span>{props.profile.email}</span>
                        </div>
                        <Link
                            className={props.activePage === "profile" && props.path === "orders"
                                ?
                                `${styles.e_dropdown_link_item} ${styles.e_dropdown_link_active}  py-2`
                                :
                                `${styles.e_dropdown_link_item} py-2`}
                            to="/profile/orders">
                            <span>Orders & Profile</span>
                        </Link>
                        <Link
                            className={props.activePage === "profile" && props.path === "billing"
                                ?
                                `${styles.e_dropdown_link_item} ${styles.e_dropdown_link_active}  py-2`
                                :
                                `${styles.e_dropdown_link_item} py-2`}
                            to="/profile/billing">
                            <span>Billing & Subscriptions</span>
                        </Link>
                        <Link
                            className={props.activePage === "profile" && props.path === "kyc"
                                ?
                                `${styles.e_dropdown_link_item} ${styles.e_dropdown_link_active}  py-2`
                                :
                                `${styles.e_dropdown_link_item} py-2`}
                            to="/profile/kyc">
                            <span>KYC Details</span>
                        </Link>
                        <Link
                            className={props.activePage === "profile" && props.path === "settings"
                                ?
                                `${styles.e_dropdown_link_item} ${styles.e_dropdown_link_active}  py-2`
                                :
                                `${styles.e_dropdown_link_item} py-2`}
                            to="/profile/settings">
                            <span>Account Settings</span>
                        </Link>
                        <span className={`${styles.e_logout} pt-2`} onClick={() => logout()}>Logout</span>
                    </div>
                </div>
            </div>
            {
                isActive ?
                    <div className={styles.e_backdrop} onClick={() => setmenuActive(false)}></div>
                    :
                    null
            }
        </div>
    )
}

export default ProfileDropdown;