import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Toast } from "../../components/alerts";

import { HeathIndicatorBadge } from "../../components/badges";
import { ContactCard, DomainDetailCard, DomainHealthSummary, ManageDomains, UpgradePlanCard } from "../../components/cards";
import { TextButton } from "../../components/cta";
import { ToggleButton } from "../../components/form";
import ScanHealthModal from "../../components/modals/scanHealthModal";
import Loader from "../../components/others/loader";

import { getServices } from "../../services/apis/services";

import { useParams, useLocation } from 'react-router-dom';

/*services*/
import services from "../../services/apis/services";
import GetURLVar from "../../services/getParams/getUrl";
import moment from 'moment';
const sampleContactInfo = { name: "Sarah James", address: "2972 Westheimer Rd. Santa Ana, Illinois 85486", email: "sarahj@outlook.com", number: "(208) 555-0112" }

const PACKAGE_INFO={
		"000":"Start Up",
		"333":"Silver",
		"444":"Gold",
		"555":"Diamond"
};
const DomainDetail = (props) => {
    // console.log(" domain details");
    // console.log(props);

    const { type } = useParams();
    
    // Extract query parameters
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const urldomain = queryParams.get('domain');
    // console.log("domain nameeeeeee");
    // console.log(domain);

    const [domainName, setDomainName] = useState("");
    const [profileData, setProfileData] = useState({});
    const [error, setError] = useState(null);
    const [loader, setLoader] = useState(false);
	const [domainData, setDomainData] = useState([]);
    const router = useHistory();

    useEffect(() => {
        setTimeout(() => {
            setError(null)
        }, 3000)
    }, [error]);

    const getQuery = async () => {
        let search = await GetURLVar();
        if (search && search.domain) {
			setDomainName(search.domain);
			getDomainDetails(search.domain);
		}
    }


    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        getQuery();
        getProfileDetails();
		
    }, []);



    const showHealthScanModal = () => {
        let bootstrap = window.bootstrap;
        let healthModal = new bootstrap.Modal(document.querySelector('#scanHealthModal'));
        healthModal.show();
    }

    /*------------resend otp api--------*/
    const getProfileDetails = () => {
		let SESSION_TOKEN=localStorage.getItem("web23_token");
        setLoader(true);
        let url = 'action/profile/get';

        let data = {
            token: SESSION_TOKEN,
        };
		services(true, url, data).then((response) => {
            let res = response.data
			console.log(res);
            if (res.status === 1) {
                setProfileData(res.resData);
                setLoader(false);
            } else if (response.data.status === 0){ 
                setError("Your Profile is not detailed, kindly submit your profile.");
                setLoader(false);
            } else if (response.data.status === -1 || res.status === -9) {
                //setError(res.data[0]?.msg);
                setError(res.msg);
                setLoader(false);
            }
        });
    }
	
	/* fetching domains */
    const getDomainDetails = (_domainName) => {
        setLoader(true);
        let url = "action/details/w2domain";
		let data = {
            token: localStorage.getItem("web23_token"),
			domain:_domainName
        }
		services(true, url, data).then((response) => {
            let res = response.data;
			if (res.status === 1) {
                setDomainData(res.resData)
            } 
        });
    }

    const [activedomainsData, setActiveDomainsData] = useState([]);
    const [isDomainActive, setIsDomainActive] = useState(null);

    useEffect(() => {
        fetchMainDomains();
      }, []);

    const fetchMainDomains = async () => {
        try {
            let url = "";
            if (props.type === 'web3') {
                url = "domains/getmaindomain3";
            } else {
                url = "domains/getmaindomain2";
            }
            
            const token = localStorage.getItem("web23_token");
            const response = await getServices(url, token);
    
            const structuredData = response.data.resData.data.map(domain => ({
                name: domain.domain_name,
                itemName: domain.domain_name,
                purchaseDate: domain.purchase_date,
                expireOn: domain.domain_exp ? domain.domain_exp.split('T')[0].split('-').reverse().join('-') : "",
                auto_renewal: domain.autorenwel,  // Replace with actual data or default value
                expiry: domain.domain_exp ? domain.domain_exp.split('T')[0].split('-').reverse().join('-') : "",
                domain_exp: domain.domain_exp // Actual expiry date
            }));
    
            // Use find instead of filter to get a single object
            const activeDomain = structuredData.find(domain => {
                // Check if urldomain matches domain_name
                if (urldomain !== domain.itemName) {
                    setIsDomainActive(false);
                    return false;
                }
    
                // Parse the domain expiration date
                const targetDate = new Date(domain.domain_exp);
                const currentDate = new Date();
                
                // Check if the target date is valid
                if (isNaN(targetDate.getTime())) {
                    console.error("Invalid target date:", domain.domain_exp);
                    return false;
                }
                
                // Normalize dates to remove time differences (hours, minutes, seconds)
                const targetDateWithoutTime = new Date(targetDate.toDateString());
                const currentDateWithoutTime = new Date(currentDate.toDateString());
                
                // Calculate the difference in time (in milliseconds)
                const differenceInTime = targetDateWithoutTime - currentDateWithoutTime;
                
                // If the difference in days is greater than 0, the domain is still active (not expired)
                return differenceInTime > 0;
            });
    
            // Set the active domain data
            setActiveDomainsData(activeDomain);
            // console.log("Active domain:");
            // console.log(activeDomain);
            
        } catch (error) {
            console.error("Error fetching main domains:", error);
        }
        };

    return (
        <>
            <div className="container-fluid position-relative e-domain-detail-page-container pt-3">
                <div className="e-gradient-bg position-absolute"></div>
                <div className="container mt-3 position-relative">
                    <TextButton white={true} name="Back to Domains" back={true} handleClick={() => router.goBack()} />
                    <div className="row pt-5 pb-3">
                        <div className="col-lg-6">
                            <div className="d-flex align-items-center">
                                <h2 className="mb-0 e-domain-name pe-3 text-lowercase">{activedomainsData.itemName}</h2>
                                <HeathIndicatorBadge healthy={true} />
                            </div>
                        </div>
                        <div className="col-lg-6 pt-3 pt-lg-0">
                            <div className="d-flex w-100 justify-content-end h-100 align-items-end e-estimated-value-wrapper">
                                <h5 className="mb-0">Value Estimated:</h5>
                                <h3 className="mb-0 ps-1">₹7845</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row pt-3 gy-3 pb-3">

                        <div className="col-md-6 col-lg-3">
                            <div className="e-summary-card w-100">
                                <h6 className="mb-0 pb-3 text-uppercase">Status</h6>
                                <div className="d-flex align-items-center">
                                    <h3 className="mb-0 pe-3">{isDomainActive ? "Deactive" : "Active"}</h3>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 22C6.47967 21.994 2.00606 17.5204 2 12V11.8C2.10993 6.30455 6.63459 1.92797 12.1307 2.0009C17.6268 2.07382 22.0337 6.5689 21.9978 12.0654C21.9619 17.5618 17.4966 21.9989 12 22ZM7.41 11.59L6 13L10 17L18 9.00002L16.59 7.58002L10 14.17L7.41 11.59Z" fill="#00D248" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="e-summary-card w-100">
                                <h6 className="mb-0 pb-3 text-uppercase">Protection</h6>
                                <div className="d-flex align-items-center">
                                    <h3 className="mb-0 pe-3">{PACKAGE_INFO[domainData.packageId]}</h3>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10 0C7.00356 2.38833 5.12533 2.5 2 2.5V12.1125C2 15.9483 4.84711 16.9492 10 20C15.1529 16.9492 18 15.9483 18 12.1125V2.5C14.8747 2.5 12.9964 2.38833 10 0ZM9.51289 12.5L6.44444 9.69833L7.544 8.66583L9.51289 10.435L13.408 6.70583L14.5084 7.7375L9.51289 12.5Z" fill="#00D248" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="e-summary-card w-100">
                                <h6 className="mb-0 pb-3 text-uppercase">expires on</h6>
                                <h3 className="mb-0 pe-3">{moment(activedomainsData.expireOn).format('DD-MMM-YYYY')}</h3>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="e-summary-card w-100 e-toggle-wrapper">
                                <h6 className="mb-0 pb-3 text-uppercase">auto renew</h6>
                                {/* <ToggleButton onChange={() => { }} hideInfo={true} /> */}
                                <ToggleButton
                                    defaultActive={activedomainsData.auto_renewal} 
                                    onChange={(newState) => {
                                        activedomainsData.auto_renewal = newState;
                                        console.log("Auto-renewal is now:", newState ? "On" : "Off");
                                    }}
                                    hideInfo={true} 
                                />

                            </div>
                        </div>

                    </div>
                    <div className="row pb-5">
                        {
                            props.type === "web3" ?
                                <div className="col-12 pt-4">
                                    <DomainDetailCard />
                                </div>
                                :
                                null
                        }
                        <div className="col-12 pt-4">
                            <DomainHealthSummary handleRefresh={showHealthScanModal} />
                        </div>
                        <div className="col-12 pt-4">
                            <ManageDomains type={props.type} domain={domainName} />
                        </div>
                        <div className="col-lg-6 col-md-8 pt-4">
                            {
                                loader ?
                                    <Loader />
                                    :
                                    <ContactCard data={profileData} />
                            }
                        </div>
                        <div className="col-lg-6 col-md-8 pt-4">
                            <UpgradePlanCard />
                        </div>
                    </div>
                </div>

            </div>
            <ScanHealthModal
                title={<h5 className="fw-bold text-lowercase d-flex align-items center mb-0 pb-2">
                    <svg className="me-1" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.67">
                            <path fillRule="evenodd" clipRule="evenodd" d="M1.66602 10.0001C1.66602 14.6025 5.39698 18.3334 9.99935 18.3334C14.5996 18.3284 18.3276 14.6004 18.3327 10.0001C18.3327 5.39771 14.6017 1.66675 9.99935 1.66675C5.39698 1.66675 1.66602 5.39771 1.66602 10.0001ZM3.38426 9.16675C3.64787 7.05277 4.90124 5.24616 6.66602 4.2253V9.16675H3.38426ZM8.33268 9.16675V3.54344C8.86538 3.40634 9.42385 3.33341 9.99935 3.33341C10.5749 3.33406 11.1333 3.40754 11.666 3.54514V9.16675H8.33268ZM11.666 10.8334H8.33268V16.4567C8.86538 16.5938 9.42385 16.6667 9.99935 16.6667C10.5748 16.6667 11.1333 16.5938 11.666 16.4567V10.8334ZM13.3327 15.7749V10.8334H16.6144C16.3508 12.9474 15.0975 14.754 13.3327 15.7749ZM13.3327 9.16675V4.22847C15.0956 5.2498 16.348 7.05466 16.6135 9.16675H13.3327ZM3.38426 10.8334H6.66602V15.7749C4.90124 14.754 3.64787 12.9474 3.38426 10.8334ZM16.666 10.0063L16.666 10.0001L16.666 9.99786V10.0063Z" fill="#11142F" />
                        </g>
                    </svg>
                    Sarahjohnjames.xyz
                </h5>} />
            {
                error !== null ?
                    <Toast data={error} />
                    :
                    null
            }
        </>
    )
}

export default DomainDetail;