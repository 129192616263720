/*
 *   File : index.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : index file for alert components 
 *   Integrations : null
 *   Version : 1.0
 *   Created : 27-06-2021
 */

 import Toast from './toast';
 import Tooltip from './tooltip';


 export { Toast, Tooltip }