import React, { useEffect, useState } from 'react';

import { ReportOverviewCard } from '../../components/cards';
import { TabHeadingTwo } from '../../components/tabHeadings';

import SocialMediaReportContainer from './socialMediaReport';
import WebThreeReport from './webThreeReport';
import WebTwoReport from './webTwoReport';

const Reports = () => {
    const [activeTab, setActiveTab] = useState(1);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    const switchTabContent=()=>{
        let content = <WebTwoReport/>;
        let key = activeTab;
        switch (key) {
            case 1:{
                content = <WebTwoReport/>;
                break;
            }
            case 2:{
                content = <WebThreeReport/>;
                break;
            }
            case 3:{
                content = <SocialMediaReportContainer/>;
                break;
            }
        }
        return content;
    }

    return (
        <div className="container-fluid position-relative e-reports-page-container pt-3">
            <div className="e-gradient-bg position-absolute"> </div>
            <div className="container position-relative mt-5">
                <ReportOverviewCard/>
                <div className="pt-5 w-100 d-flex justify-content-center pb-3">
                    <TabHeadingTwo className="e-max-content-width" tabs={["Web 2.0", "Web 3.0", "Social Media Accounts"]} activeTab={activeTab} setActive={setActiveTab}/>
                </div>
                {switchTabContent()}
            </div>
        </div>
    )
}

export default Reports;