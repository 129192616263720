import React, { useContext, useEffect, useState } from 'react';

import { message } from 'antd';

import { CartContext } from '../../context/cartContext';

/*--- import services---*/
import services from '../../services/apis/services';
/*import style*/
import styles from '../../styles/components/SearchTable.module.scss';
import { Primary } from '../cta';
import { Toast } from '../alerts';



const SocailTWTable = (props) => {
    const [cartData, setCartData] = useContext(CartContext);
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(null);
    const [cartItem,setCartItem] = useState("");


    useEffect(() => {
        setTimeout(() => {
            setError(null);
        },3000);
    },[error]);
   
    

    return (
        <div className='mt-4 py-4'>
			
            <div className="">
                <div className="">
					<table className="table table-hover table-striped table-bordered table-box-sha">
                    <thead>
                        <tr>
                            <td nowrap><h6 className="mb-0 text-nowrap">Twitter Account</h6></td>
                            <td><h6 className="mb-0">Screen Name</h6></td>
                            <td><h6 className="mb-0">Description</h6></td>
                        </tr>
                    </thead>
                    <tbody >
                        {
                            props.data ?
                                <>
                                    {
                                        props.data.map((item, i) => {
                                            return (
											(item.ips.name!=undefined) ? 
											
                                                <tr  key={`search-result-row-${i}`}>
                                                    
                                                    
                                                        <td className="" nowrap>
                                                            <div className="w-100 d-flex flex-column">
                                                                <div className="fs-6 mb-1 text-lowercase">
																<a href={'https://twitter.com/'+item.domain} target='_blank' className=''>{item.domain}</a>
																</div>
																
                                                            </div>
                                                        </td>
                                                        <td className="">
                                                            <span className="d-flex h-100 fs-6">{item.ips.name && <>{item.ips.sne}</>}</span>
                                                        </td>
                                                        <td className="y788">
                                                            <div className="d-flex" style={{fontSize:"12px"}}>
															<img src={item.ips.pimg} style={{width:'32px',height:'32px'}} className='me-2 rounded-circle' />
															{item.ips.name && <>{item.ips.dsc}</>}
                                                            </div>
															<div className="mb-2 mt-2 ">
															{item.ips.name && <>
																<div className='d-flex'>
																	<div className="px-2 flex-grow-1 bd-highlight">
																		<span className='px-2 capitalize' style={{color:'#a2a2a2',fontSize:"12px"}}>{item.ips.cdate}</span> 
																		<span className='capitalize' style={{color:'#a2a2a2',fontSize:"12px"}}>{"Follwers: "+item.ips.flrc}</span>  
																	</div>
																	<div>
																		<select className='form-select eform-select-sm'>
																			<option value=''>Mark as</option>
																			<option value=''>Inappropriate</option>
																			<option value=''>I own this</option>
																			<option value=''>Impersonation</option>
																		</select>
																	</div>
																</div>	
																</>
															}
                                                            </div>
                                                        </td>
                                                    
                                                </tr>
												:null
											
                                            )
                                        })
                                    }
                                </>
                                :
                                null
                        }
                    </tbody>
                </table>
			</div>	
            </div>
            {
                error !== null ?
                    <Toast data={error} type="fail" />
                    :
                    null
            }
        </div>
    )
}

export default SocailTWTable;