import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

import { DomainCard } from "../../components/cards";
import { getServices } from "../../services/apis/services";



const ExpiredDomains = (props) => {

    const router = useHistory();

    // const [domainsDataActive, setDomainsDataActive] =  useState([]); 

    // useEffect(() => {
    //     const fetchDomains = async () => {
    //       try {
    //         let url = ''
    //         if(props.type == 'web2') {
    //              url = "domains/getmaindomain2";
    //         } else if(props.type == 'web3') {
    //             url = "domains/getmaindomain3";
    //         }
    //         const token = localStorage.getItem("web23_token");
    //         const response = await getServices(url, token);
      
    //         // Transform the fetched data to match the structure of expiringSample
    //         const structuredData = response.data.resData.data.map(domain => ({
    //           itemName: domain.domain_name,
    //           name: domain.domain_name,
    //           itemId: domain._id,
    //           _id: domain._id,
    //           orderType: "D2",
    //           auto_renew:domain.autorenwel,
    //           purchaseDate: domain.purchase_date,
    //           expireOn: domain.domain_exp ? domain.domain_exp.split('T')[0].split('-').reverse().join('-') : "",
    //           domain_exp: domain.domain_exp,// ? domain.domain_exp.split('T')[0].split('-').reverse().join('-') : ""  // Replace with actual expiry date
    
    //         }));
    
    
    //         let domainExpSoon = structuredData.filter(domain => {
    //             const targetDate = new Date(domain.domain_exp);
    //             const currentDate = new Date();
    //             // Check if dates are valid
    //             if (isNaN(targetDate.getTime())) {
    //                 console.error("Invalid target date:", domain.domain_exp);
    //                 return false;
    //             }
    //             // Normalize dates to remove time differences
    //             const targetDateWithoutTime = new Date(targetDate.toDateString());
    //             const currentDateWithoutTime = new Date(currentDate.toDateString());
            
    //             const differenceInTime = targetDateWithoutTime - currentDateWithoutTime;
    //             const differenceInDays = differenceInTime / (1000 * 3600 * 24);
    //             // Return domains expiring within 30 days
    //             return differenceInDays <= 0;
    //         });
            
    //         setDomainsDataActive(domainExpSoon)

    //         console.log(domainsDataActive);
    //       } catch (error) {
    //         console.error("Error fetching domains", error);
    //       }
    //     };
    //     fetchDomains();
    //   }, []);
    

    return (
        <div className="container-fluid px-0 e-active-domain pt-4">
            <div className="e-domain-gradiant-bg">
                <div className="container">
                    <div className="row gy-4">
                        {
                            props.data.map((item, i) => {
                                return (
                                    <div className="col-md-6 col-lg-4 col-xl-3" key={`domain-${i}`}>
                                        <DomainCard data={item} expact={'exp'} redirect={() => { router.push(`/domain/${props.web3 ? 'web3' : 'web2'}/detail?domain=${item.itemName.toLowerCase()}`) }} />
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ExpiredDomains;