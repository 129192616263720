import React, { useRef, useState, useEffect } from 'react';
import { useLocation, useHistory } from "react-router";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { Primary, TextButton } from '../../../src/components/cta';

import { Secondary } from "../../components/cta";
import { TabHeadingOne, TabHeadingTwo } from "../../components/tabHeadings";
import SecondaryDomainTable from "../../components/dataTable/secondaryDomainTable";
import SecondaryXDomainTable from "../../components/dataTable/secondaryXDomainTable";
import ActiveDomains from "./activeDomain";
import ExpiredDomains from "./expiredDomains";
import { ExpiringSoon, RecomendedDomains } from "../../components/cards";
import services from "../../services/apis/services";
import Loader from "../../components/others/loader";
import { getServices } from "../../services/apis/services";

import { Toast } from '../../components/alerts';

const secondaryDomainSample = [
    { name: "shop.sarahjames.com", active: true, expiry: "25 Jun 2020", redirections: 20 },
    { name: "shop.sarahjames.in", active: true, expiry: "25 Jun 2020", redirections: 20 },
    { name: "store.sarahjames.com", active: true, expiry: "25 Jun 2020", redirections: 20 }
]

const secondaryDomainSampleExpired = [
    { name: "shop.sarahjames.com", active: false, expiry: "25 Jun 2019", redirections: 20 },
    { name: "shop.sarahjames.in", active: false, expiry: "25 Jun 2019", redirections: 20 },
    { name: "store.sarahjames.com", active: false, expiry: "25 Jun 2019", redirections: 20 }
]

const expiringSample = [
    { name: "Sarah.xyz", auto_renewal: false, expiry: "25 Jun 2020" },
    { name: "sarah.co.in", auto_renewal: true, expiry: "25 Jun 2020" },
    { name: "sarah.com", auto_renewal: false, expiry: "25 Jun 2020" }
]

const secondaryDomainSample3 = [
    { name: "shop.sarahjames.eth", active: true, expiry: "25 Jun 2020", redirections: 20 },
    { name: "shop.sarahjames.nft", active: true, expiry: "25 Jun 2020", redirections: 20 },
    { name: "store.sarahjames.eth", active: true, expiry: "25 Jun 2020", redirections: 20 }
]

const secondaryDomainSampleExpired3 = [
    { name: "shop.sarahjames.eth", active: false, expiry: "25 Jun 2019", redirections: 20 },
    { name: "shop.sarahjames.nft", active: false, expiry: "25 Jun 2019", redirections: 20 },
    { name: "store.sarahjames.eth", active: false, expiry: "25 Jun 2019", redirections: 20 }
]

const expiringSample3 = [
    { name: "Sarah.xyz", auto_renewal: false, expiry: "25 Jun 2020" },
    { name: "sarah.coin", auto_renewal: true, expiry: "25 Jun 2020" },
    { name: "sarah.eth", auto_renewal: false, expiry: "25 Jun 2020" }
]

const ManageDomain = (props) => {

    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        if (location.state && location.state.toastMessage) {
            setToastMessage(location.state.toastMessage);

            history.replace({ ...location, state: null });
        }

        const timer = setTimeout(() => {
            setToastMessage(null);
        }, 5000); 

        return () => clearTimeout(timer);

    }, [location, history]);

    const [activeTab, setActive] = useState(1);
    const [domainStatus, setDomainStatus] = useState(1);
    const [loader, setLoader] = useState(false);
    const [domainData, setDomainData] = useState([]);
    const [error, setError] = useState(null);
    const router = useHistory();
    const [domainsData, setDomainsData] = useState([]); // To store domains data  
    const [domainsSubDataWeb2, setSubDomainsDataWeb2] = useState([]); // To store domains data  
    const [totalCount, setTotalCount] = useState(0);

    
    const [activedomainsData, setActiveDomainsData] = useState([]);
    const [domainsDataExp, setDomainsDataExp] = useState([]); // To store domains data  
    const [domainsDataRecomanded, setDomainsDataRecomanded] = useState(); // To store domains data

    const textAreaRef = useRef(null);
    const textAreaRefCnameValue = useRef(null);
    const [generatedString, setGeneratedString] = useState(''); // Store only the random string
    const [generatedStringCnameValue, setGeneratedStringCnameValue] = useState(''); // Store only the random string
    const [generatedText, setGeneratedText] = useState(''); // Store the full text including domain
    const [formData, setFormData] = useState({}); // State object to store all form inputs
    
    // Function to generate a random string between 10 and 60 characters
    const generateRandomString = (minLength, maxLength) => {
        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const length = Math.floor(Math.random() * (maxLength - minLength + 1)) + minLength;
        let result = '';
        for (let i = 0; i < length; i++) {
            result += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return result;
    };

    const handleShowModal = (e) => {
        e.stopPropagation();
        const modal = new window.bootstrap.Modal(document.getElementById('exampleModal'));
        modal.show();
    };

    // Function to handle the modal's show event and update the textarea
    const handleModalShow = () => {
        const randomString = generateRandomString(10, 60);
        const randomStringCNameValue = generateRandomString(10, 60);
        setGeneratedString(randomString);
        setGeneratedStringCnameValue(randomStringCNameValue); // Initially set generatedText to only the random string
    };

    const [toastMessage, setToastMessage] = useState(null); // State for toast message


    useEffect(() => {
        // Attach event listener for Bootstrap modal show event
        const modalElement = document.getElementById('exampleModal');
        modalElement.addEventListener('show.bs.modal', handleModalShow);

        // Cleanup event listener on component unmount
        return () => {
            modalElement.removeEventListener('show.bs.modal', handleModalShow);
        };
    }, []);

    const addDomain = async () => {
        try {

            let url = "";
            if (props.update == 'web2') {
                url = "domains/add2";
                
            } else if (props.update == 'web3') {
                 url = "domains/add3";
                 
            }
            // const url = "domains/add2";
            const token = localStorage.getItem("web23_token");
            const response = await getServices(url, token, formData);
            if (response.data.status === 1) {
                // alert("Domain added to the list");
                setToastMessage({ message: 'Domain added to the list!', type: 'success' });
                const modal = new window.bootstrap.Modal(document.getElementById('exampleModal'));
                modal.hide();
            }
            // console.log("Domains fetched:", response.data);
        } catch (error) {
            console.error("Error fetching domains", error);
        }
    };

    const handleDomainAdd = async (e) => {
        e.preventDefault();
        addDomain();
    };

    // Generic handler to update form fields in state
    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));


       

        setFormData(prevState => ({
            ...prevState,
            ['cname_value']: generatedStringCnameValue
        }));

        // Update the generated text when the domain name changes
        if (name === 'domain_name') {
            setGeneratedText(`${generatedString}.${value}`);

            setFormData(prevState => ({
                ...prevState,
                ['cname_key']: `${generatedString}.${value}`
            }));
        }
    };


    // useEffect(() => {
    //     const fetchDomains = async () => {
    //       try {
    //         const url = "domains/getmaindomain2";
    //         const token = localStorage.getItem("web23_token");
    //         const response = await getServices(url, token);
    //         setDomainsData(response.data.resData.data);
    //         console.log("Domains fetched:", response.data);
    //       } catch (error) {
    //         console.error("Error fetching domains", error);
    //       }
    //     };
    
    //     fetchDomains();
    //   }, []);

    const fetchMainDomains = async () => {
        try {
        //   console.log("props.type")
        //   console.log(props)

          let url = "";
          if (props.type == 'web3') {
              url = "domains/getmaindomain3";
          } else {
              url = "domains/getmaindomain2";
          }
          // const url = "domains/getmaindomain2";
          const token = localStorage.getItem("web23_token");
          const response = await getServices(url, token);
    
        //   console.log("response get domainnnn")
        //   console.log(response.data.resData.data)

        const domain_count_data = response.data.resData.data;

        // Get today's date in the format to compare with domain_exp (assuming domain_exp is a date string)
        const today = new Date();

        // Filter data where domain_exp is greater than today's date
        const filteredData = domain_count_data.filter(item => {
            const domainExpDate = new Date(item.domain_exp); // Convert domain_exp to Date object
            return domainExpDate > today; // Check if domain_exp is greater than today
        });

        setTotalCount(filteredData.length);
        const totalCount = filteredData.length;

        // console.log("Total Count:", totalCount);

          // Transform the fetched data to match the structure of expiringSample
          const structuredData = response.data.resData.data.map(domain => ({
            name: domain.domain_name,
            itemName:domain.domain_name,
            purchaseDate: domain.purchase_date,
            expireOn: domain.domain_exp ? domain.domain_exp.split('T')[0].split('-').reverse().join('-') : "",
            auto_renewal: domain.autorenwel,  // Replace with actual data or default value
            expiry: domain.domain_exp ? domain.domain_exp.split('T')[0].split('-').reverse().join('-') : "",
            domain_exp: domain.domain_exp,// ? domain.domain_exp.split('T')[0].split('-').reverse().join('-') : ""  // Replace with actual expiry date
          }));

          let domainExpSoon = structuredData.filter(domain => {
              // console.log("Raw domain expiration date string:", domain.domain_exp);
          
              const targetDate = new Date(domain.domain_exp);
              const currentDate = new Date();
          
              // Check if dates are valid
              if (isNaN(targetDate.getTime())) {
                  console.error("Invalid target date:", domain.domain_exp);
                  return false;
              }
          
          
              // Normalize dates to remove time differences
              const targetDateWithoutTime = new Date(targetDate.toDateString());
              const currentDateWithoutTime = new Date(currentDate.toDateString());
          
              const differenceInTime = targetDateWithoutTime - currentDateWithoutTime;
              const differenceInDays = differenceInTime / (1000 * 3600 * 24);
          
          
              // Return domains expiring within 30 days
              return differenceInDays <= 30 && differenceInDays >= 0;
          });
          
          setDomainsData(domainExpSoon)



          let domainExp = structuredData.filter(domain => {
            const targetDate = new Date(domain.domain_exp);
            const currentDate = new Date();
            // Check if dates are valid
            if (isNaN(targetDate.getTime())) {
                console.error("Invalid target date:", domain.domain_exp);
                return false;
            }
            // Normalize dates to remove time differences
            const targetDateWithoutTime = new Date(targetDate.toDateString());
            const currentDateWithoutTime = new Date(currentDate.toDateString());
        
            const differenceInTime = targetDateWithoutTime - currentDateWithoutTime;
            const differenceInDays = differenceInTime / (1000 * 3600 * 24);
            // Return domains expiring within 30 days
            return differenceInDays <= 0;
        });

        //   console.log("domainExp")
        //   console.log(domainExp);
          setDomainsDataExp(domainExp)

          let activeDomains = structuredData.filter(domain => {
            // Parse the domain expiration date
            const targetDate = new Date(domain.domain_exp);
            const currentDate = new Date();
        
            // Check if the target date is valid
            if (isNaN(targetDate.getTime())) {
                console.error("Invalid target date:", domain.domain_exp);
                return false;
            }
        
            // Normalize dates to remove time differences (hours, minutes, seconds)
            const targetDateWithoutTime = new Date(targetDate.toDateString());
            const currentDateWithoutTime = new Date(currentDate.toDateString());
        
            // Calculate the difference in time (in milliseconds)
            const differenceInTime = targetDateWithoutTime - currentDateWithoutTime;
            
            // If the difference in days is greater than 0, the domain is still active (not expired)
            return differenceInTime > 0;
        });
        
        // Set the active domains data
        setActiveDomainsData(activeDomains);
        

    
          // setDomainsData(structuredData); // Update state with the structured data
    
          // Use `for...of` loop for async handling
          let accumulatedRecommendedData = [];
          for (const domain of structuredData) {
            const urlnew = "gd/domain/search";
            try {
              const responseNewAvlDomain = await getServices(urlnew, token, { domainName: domain.name });
            //   console.log("responseNewAvlDomain.data.resData.domains");
            //   console.log(responseNewAvlDomain.data.resData.domains);


              var dataForProcess = responseNewAvlDomain.data.resData.domains
              const filterRecomndedData = dataForProcess.filter(dataForProcessNewDomains => dataForProcessNewDomains.available == true);
              accumulatedRecommendedData = [...accumulatedRecommendedData, ...filterRecomndedData];


            } catch (error) {
              console.error(`Error fetching data for domain ${domain.name}:`, error);
            }
          }

          setDomainsDataRecomanded(accumulatedRecommendedData);

    
          // console.log("Structured data fetched:");
        } catch (error) {
          console.error("Error fetching domains", error);
        }
      };
    
      

      const fetchSubDomains = async () => {
          try {
             let url = "";
             if (props.type == 'web3') {
                  url = "domains/getsubdomain3";
              } else {
                url = "domains/getsubdomain2";
              }

              setSubDomainsDataWeb2([])
          //   const url = "domains/getsubdomain2";
            const token = localStorage.getItem("web23_token");
            const response = await getServices(url, token);
            //   console.log("response recomm");
            //   console.log(response);

            // Transform the fetched data to match the structure of expiringSample
            const structuredData = response.data.resData.data.map(domain => ({
              name: domain.sub_domain_name,
              auto_renewal: domain.autorenwel,  // Replace with actual data or default value
              expiry: domain.domain_exp ? domain.domain_exp.split('T')[0].split('-').reverse().join('-') : "",
              domain_exp: domain.domain_exp,// ? domain.domain_exp.split('T')[0].split('-').reverse().join('-') : ""  // Replace with actual expiry date
            }));
            setSubDomainsDataWeb2(structuredData)
          } catch (error) {
            console.error("Error fetching domains", error);
          }
        };

        useEffect(() => {
     
        
          fetchMainDomains();
          fetchSubDomains();
        }, []);
      

    // useEffect(() => {
    //     window.scrollTo({
    //         top: 0,
    //         behavior: 'smooth'
    //     });

    //     getDomains();
    // }, []);

    useEffect(() => {
        setDomainStatus(1);
    }, [activeTab]);

    useEffect(() => {
        if (props.type) handleRouteChange();
    }, [props.type]);

    const handleRouteChange = () => {
        if (props.type === "web2")  {
            setActive(1);
            fetchMainDomains();
            fetchSubDomains();
        }
        else if (props.type === "web3") {

            setActive(2);
            fetchMainDomains();
            fetchSubDomains();
        }
        else { router.replace('/domains/web2');
            fetchMainDomains();
        }
    }

    const handleTabSwitch = (val) => {
        if (val === 1) {
            router.push('/domains/web2');
        }
        else if (val === 2) {
            router.push('/domains/web3');
        }
    }

    /* fetching domains */
    // const getDomains = () => {
    //     setLoader(true);
    //     let url = "action/my/w2domains";

    //     let data = {
    //         token: localStorage.getItem("web23_token")
    //     }

    //     services(true, url, data).then((response) => {
    //         let res = response.data;

    //         if (res.status === 1) {
    //             setLoader(false);
    //             setDomainData(res.resData)
    //         } else if (res.status === 0 || res.status === -1 || res.status === -9) {
    //             setError(res.msg);
    //             setLoader(false);
    //         } else {
    //             setError(res.msg);
    //             setLoader(false);
    //         }
    //     });
    // }
    return (
        <div className="e-manage-domains-page container-fluid px-0 pt-4 pb-5">
            <div className="container">
                <div className="row">
                    <div className="col-12 pt-2 pb-3">
                        <TabHeadingOne tabs={["Web 2.0", "Web 3.0"]} activeTab={activeTab} setActive={val => handleTabSwitch(val)} />
                    </div>
                </div>
            </div>
            {
                activeTab === 1 ?
                    <>
                        <div className="container">
                            <h3 className="mb-0 pb-4">Your Web 2.0 Domains</h3>
                            <div className="row">
                                <div className="col-lg-10">
                                    <div className="d-flex h-100 align-items-center">
                                        <TabHeadingTwo tabs={[`Active Domains (${totalCount})`, "Expired Domains"]} activeTab={domainStatus} setActive={setDomainStatus} />
                                    </div>
                                </div>
                                <div className="col-lg-2 pt-3 pt-lg-0">
                                    <div className="d-flex w-100 justify-content-end" onClick={handleShowModal}>
                                        <Secondary name="Link Outside Domains" globe_icon={true} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            domainStatus === 1 ?
                                <>
                                    {
                                        loader ?
                                            <Loader />
                                            :
                                            <ActiveDomains data={activedomainsData} web23={'web2'} />
                                    }
                                    <div className="container pt-4">
                                        <h3 className="pt-3 mb-0 pb-3">Domains</h3>
                                        {/*<SecondaryDomainTable data={secondaryDomainSample} />*/}
										<SecondaryXDomainTable data={domainData} />
                                    </div>
                                </>
                                :
                                domainStatus === 2 ?
                                    <>
                                        <ExpiredDomains type ={'web2'} data ={domainsDataExp}/>
                                        <div className="container pt-4">
                                            <h3 className="pt-3 mb-0 pb-3">Secondary Domains</h3>
                                            <SecondaryDomainTable data={domainsSubDataWeb2} />
                                        </div>
                                    </>
                                    :
                                    null
                        }
                        <div className="container pt-5">
                            <RecomendedDomains  data={domainsDataRecomanded} />
                        </div>
                        {
                            domainStatus === 1 ?
                                <div className="container pt-4">
                                    <ExpiringSoon data={domainsData} />
                                    {/* <ExpiringSoon data={expiringSample} /> */}
                                </div>
                                :
                                null
                        }
                    </>
                    :
                    activeTab === 2 ?
                        <>
                            <div className="container">
                                <h3 className="mb-0 pb-4">Your Web 3.0 Domains</h3>
                                <div className="row">
                                    <div className="col-lg-10">
                                        <div className="d-flex h-100 align-items-center">
                                            <TabHeadingTwo tabs={[`Active Domains (${totalCount})`, "Expired Domains"]} activeTab={domainStatus} setActive={setDomainStatus} />
                                        </div>
                                    </div>
                                    <div className="col-lg-2 pt-3 pt-lg-0">
                                        <div className="d-flex w-100 justify-content-end" onClick={handleShowModal}>
                                            <Secondary name="Link Outside Domains" globe_icon={true} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                domainStatus === 1 ?
                                    <>
                                        {
                                            loader ?
                                                <Loader />
                                                :
                                                <ActiveDomains data={activedomainsData} web23={'web3'} />
                                        }
                                        <div className="container pt-4">
                                            <h3 className="pt-3 mb-0 pb-3">Domains</h3>
                                            {/*<SecondaryDomainTable data={secondaryDomainSample} />*/}
                                            <SecondaryXDomainTable data={domainData} />
                                        </div>
                                        
                                    </>
                                    :
                                    domainStatus === 2 ?
                                        <>
                                            {/* <ExpiredDomains web3={true} /> */}
                                            <ExpiredDomains type ={'web3'} data ={domainsDataExp} />
                                            <div className="container pt-4">
                                                <h3 className="pt-3 mb-0 pb-3">Secondary Domains</h3>
                                                {/* <SecondaryDomainTable data={secondaryDomainSampleExpired3} /> */}
                                                <SecondaryDomainTable data={domainsSubDataWeb2} />
                                            </div>
                                        </>
                                        :
                                        null
                            }
                            <div className="container pt-5">
                                <RecomendedDomains data={domainsDataRecomanded} />
                            </div>
                            {
                                domainStatus === 1 ?
                                    <div className="container pt-4">
                                        {/* <ExpiringSoon data={expiringSample3} /> */}
                                        <ExpiringSoon data={domainsData} />
                                    </div>
                                    :
                                    null
                            }


                                    
                        </>
                        :
                        null
            }

            {toastMessage && <Toast message={toastMessage} type="success" />}
            
        </div>
    )
}

export default ManageDomain;