import React, { useEffect, useState } from 'react';
/*import style*/
import styles from '../../styles/components/OverviewCard.module.scss';
import $ from 'jquery';
import services from '../../services/apis/services';
import w3services from '../../services/apis/w3services';
const OverviewW3BookDomain = (props) => {
	let _UI_ID="gsjds3773_";
	let [domainsInfo,setDomainsInfo]=useState({});
	let [bookedStatus,setBookedStatus]=useState("-");
	useEffect(() => {
		if(props.domain!=null && props.domain.length>0){
		setTimeout(() => {
			avlDomainX(props.domain);
			
        }, 500)	
		}
        
    }, [props.domain]);
	
	const avlDomainX=(_DOMAINNAME)=>{
		let url = "/api/0x8764894720194876485732958";
		let data = {
		  token: process.env.REACT_APP_W3LocalToken,
		  domainName:_DOMAINNAME
		}
		w3services(true, url, data).then((response) => {
            let res = response.data
			// console.log(res);
            if (res.status === 1) {
                let rec=res.resData;
				console.log(rec);
				setBookedStatus("Available");
				setDomainsInfo(rec);
            }
			else if (res.status === 0) {
                setBookedStatus("Booked");
            }else {
               setBookedStatus("-"); 
			   setDomainsInfo({});
            }
        });
	}
	return (
        <div className={`${styles.e_overview_wrapper} ${styles.e_web_3}`}>
			{
				/*
				<a  className="d-flex text-decoration-none" style={{"cursor":"pointer"}} role="button" data-bs-toggle="offcanvas" aria-controls={_UI_ID+"offcanvasJList"} href={"#"+_UI_ID+"offcanvasJList"} aria-controls="offcanvasRight" id={_UI_ID+"tooltip"} > 
				*/
			}
			<a  className="d-flex text-decoration-none" style={{"cursor":"pointer"}} role="button" onClick={()=> props.setITab(31)} >
                <div className={styles.e_icon}></div>
                <div className="ps-2 d-flex flex-column pt-1">
                    <h4 className="mb-2 text-decoration-none">
                        {props.domain}
                    </h4>
                    <div className="d-flex align-items-end text-decoration-none">
                        <span className={styles.e_value}>{bookedStatus} 
							{ bookedStatus.price && 
								<span className='fs-5'>
								{' ℏ '+domainsInfo.price}
								</span>
							}
						</span>
					</div>
                </div>
            </a>
			
        </div>
    )
}

export default OverviewW3BookDomain;