/*--------------
Properties      default    desc

healthy         false      set to true for healthy card
value           0          value to show on card
---------------*/

/*Package importing*/
import React from 'react';

/*import style*/
import styles from '../../styles/components/HeathIndicatorBadge.module.scss';

const HeathIndicatorBadge = (props) => {
    return (
        <div>
            <div className={styles.e_indicator_wrapper}>
                <div className="d-flex align-items-center">
                    <div className={props.healthy? `${styles.e_icon} ${styles.e_healthy}`: `${styles.e_icon} ${styles.e_unhealthy}`}></div>
                    <p className="mb-0">
                        {
                            props.healthy?
                            `100% Safe`
                            :
                            `${props.value} Issue${props.value>1? 's': ''}`
                        }
                    </p>
                </div>
            </div>
        </div>
    )
}

export default HeathIndicatorBadge;