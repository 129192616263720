
/*Package importing*/
import React, { Component } from 'react';
import Chart from 'react-apexcharts';

export default class DonutChart extends React.Component {
  constructor(props) {
    super(props);
      this.state = {
        series: this.props.data,
        options: {
          chart: {
            type: 'donut',
          },
          dataLabels: {
            enabled: false,

          },
          plotOptions: {
            pie: {
              donut: {
                size: (this.props.isThin===undefined || this.props.isThin===false)? "95%": "90%"
              }
            }
          },
          legend: {
            fontWeight: 500,
            fontSize: "12px",
            lineHeight: "15px",
            fontFamily: "'Lexend', sans-serif",
            fontWeight: 500,
            position: this.props.legendOnSide? "right": 'bottom'
        },
          colors: this.props.colors,

          labels: this.props.labels,
          tooltip: {
            style: {
              fontFamily: "'Lexend', sans-serif",
              fontWeight: 700,
            },
            fillSeriesColor: false,

            y: {
              formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
                return value+"%" ;
              }
            }
          },
          stroke: {
            width: 0,
          },
          // fill: {
          //   type: 'gradient',
          // }
        },
      };
    }
  render() {
    return (
      <div className="donut">
        <Chart options={this.state.options} series={this.state.series}  type="donut" width="100%" height="500"/>
      </div>
    );
  }
}

