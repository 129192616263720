import React, { useEffect, useState } from 'react';
import $ from 'jquery';

/* components */
import { Primary, Secondary } from '../cta';
import { FileUpload, InputBox, NumberInput, SingleSelect } from '../form';
import { Toast } from '../alerts';

/* assets */
import SampleDp from '../../assets/images/profile/sample-girl.png';
import LoaderGif from '../../assets/images/loader/loader1.gif'

/* services */
import services from '../../services/apis/services';

import axios from 'axios';
import ImagePreview from './imagePreview';
import { Modal } from 'bootstrap';
const EditProfileModal = (props) => {
    
    const [error, setError] = useState(null);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [addressOne, setAddressOne] = useState("");
    const [addressTwo, setAddressTwo] = useState("");
    const [state, setState] = useState(null);
    const [city, setCity] = useState("");
    const [pin, setPin] = useState("");
    const [profilePic, setProfilePic] = useState(SampleDp);
    const [countryCode, setCountryCode] = useState("");
    const [loader, setLoader] = useState(false);
    const [country, setCountry] = useState(null);
    const [fileName, setFileName] = useState("");
    const [uploadStatus, setUploadStatus] = useState(false);
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [file, setFile] = useState("");
    const [croppedImage, setCroppedImage] = useState("");
    const [type, setType] = useState("");

    const handleInputChange = (e) => {
        console.log('input change')
        console.log(e.target)
        const { name, value } = e.target;

       
    };

    useEffect(() => {
        // var urlencoded = new URLSearchParams();
        // urlencoded.append("token", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Imxvd2FoYXA2MjBAYmVwdXJlbWUuY29tIiwiZnVsbE5hbWUiOiJKb2huIERvZSIsImNvdW50cnlDb2RlIjoiOTEiLCJsb2dpbklkIjoxMDg4NjQxMSwiY3VzdG9tZXJJZCI6MTA4ODY0MTEsIm1vYmlsZU5vIjoiOTk5OTk5OTkxMiIsInJvbGVJZCI6IjEiLCJzaWF0IjoxNjQ2MDQ2Njk0OTUzLCJzZXhwIjoxNjQ2MTE4Njk0OTUzLCJpYXQiOjE2NDYwNDY2OTQsImV4cCI6MTY0NjA4MjY5NH0.SFxT2RpgIqc-o2VUZagm9G0nqmtT5qxeEqASKLlzxmY");

        // var requestOptions = {
        //     method: 'GET',
        //     headers: {
        //         "Content-Type": "application/x-www-form-urlencoded"
        //     },
        //     body: urlencoded,
        // };

        // fetch("http://147.182.216.70:5001/action/image/profile/get", requestOptions)
        //     .then(response => console.log(response))
        //     .catch(error => console.log('error', error));
    }, [])

    useEffect(() => {
        if (croppedImage) {
            var modal = new Modal(document.getElementById("editProfileModal"), {});
            modal.show();
            var file = new File([croppedImage], fileName, { lastModified: new Date().getTime(), type: type })
            console.log(file, croppedImage.type)
            setUploadStatus(true);
            const formdata = new FormData();
            formdata.append("image", file, file.name);


            axios.post("http://147.182.216.70:5001/image/profile/set", formdata, {
                headers: {
                    token: `${localStorage.getItem("web23_token")}`
                }
            }).then((response) => {
                if (response.data.status === 1) {
                    setUploadStatus(false);
                    setProfilePic(croppedImage);
                } else if (response.data.status === 0 || response.data.status === -1) {
                    setError(response.data.msg);
                    setUploadStatus(false);
                } else {
                    setError(response.data.msg);
                    setUploadStatus(false);
                }

            });
        }
    }, [croppedImage])

    useEffect(() => {
        getCountryList();
    }, [])

    useEffect(() => {
        setTimeout(() => {
            setError(null)
        }, 3000)
    }, [error]);



    useEffect(() => {
        if (country !== null) {
            getStateList();
        }
    }, [country]);

    /*-----------upload profile picture ----*/
    const handleProfilePicUpload = (fileInput) => {
        

        if (fileInput) {
			setFileName(fileInput.name);
			setType(fileInput.type)
			const reader = new FileReader();
            var modal = new Modal(document.getElementById("imagePreview"), {});
            modal.show();
            $('#editProfileModal').trigger('click');
            reader.addEventListener("load", () => {
                const image = reader.result;
				setFile(image);
            });
            reader.readAsDataURL(fileInput);
        }
    }

    useEffect(() => {
        if (props.profile) {
            if (!props.billing) {
                setName(props.profile.fullName);
                setEmail(props.profile.email || props.profile.contactEmail);
                setCity(props.profile.city);
                setPhone(props.profile.mobileNo1);
                setAddressOne(props.profile.adrLine1);
                setAddressTwo(props.profile.adrLine2);
                setState({ label: props.profile.state, value: props.profile.state });
                setPin(props.profile.pincode);
                setCountryCode("+" + props.profile.countryCode);
                setCountry({ label: props.profile.country, value: props.profile.country });
            } else  if (props.billing ==true) {
                setCountryCode("+91");
                setEmail(localStorage.getItem("email"));
                
            }
        }
    }, [props.profile]);

    const updatedData = () => {
        setTimeout(() => {
            if (props.profile) {
                setName(props.profile.fullName);
                setEmail(props.profile.email || props.profile.contactEmail);
                setCity(props.profile.city);
                setPhone(props.profile.mobileNo);
                setAddressOne(props.profile.adrLine1);
                setAddressTwo(props.profile.adrLine2);
                setState({ label: props.profile.state, value: props.profile.state });
                setPin(props.profile.pincode);
                setCountryCode("+" + props.profile.countryCode);
                setCountry({ label: props.profile.country, value: props.profile.country });
                setProfilePic(SampleDp);
            }
        }, 200);
    }

    /* --------- update profile ----------*/
    const updateProfile = () => {
        setLoader(true);
        let url = 'action/profile/set';

        let data = {
            token: localStorage.getItem("web23_token"),
            fullName: name,
            adrLine1: addressOne,
            adrLine2: addressTwo,
            country: country?.label,
            state: state?.value,
            city: city,
            pincode: pin,
            countryCode: countryCode,
            mobileNo: phone
        }

        services(true, url, data).then((response) => {
            const res = response.data;

            if (res.status === 1) {
                setLoader(false);
                $('#editProfileModal').trigger('click');
                props.update(true);
            } else if (res.status === 0 || res.status === -1 || res.status === -9) {
                setError(res.msg);
                setLoader(false);
                setError(res?.resData[0]?.msg)
            } else {
                setError(res.msg);
                setLoader(false);
            }
        });
    }

    const addBillingAddress = () => {
        setLoader(true);

        let url = 'action/bill/setcontact';

        let data = {
            token: localStorage.getItem("web23_token"),
            fullName: name,
            country: country?.label,
            adrLine1: addressOne,
            adrLine2: addressTwo,
            pincode: pin,
            city: city,
            state: state?.value,
            countryCode: countryCode?.split("+")[1],
            phoneNo: phone,
            contactEmail: email,
            receiveMsg: 0
        }

        services(true, url, data).then((response) => {
            let res = response.data;

            if (res.status === 1) {
                setLoader(false);
                $('#editProfileModal').trigger('click');
                props.update(true);
            } else if (res.status === 0 || res.status === -1 || res.status === -9) {
                setError(res.msg);
                setLoader(false);
                setError(res?.resData[0]?.msg)
            } else {
                setError(res.msg);
                setLoader(false);
            }
        });
    }

    /*-------country list api ----*/
    const getCountryList = () => {
        let temp_country_list = [];
        let url = 'action/get/countrylist';

        services(true, url).then((response) => {
            let res = response.data;

            if (res.status === 1) {
                let countryObj = res.resData

                Object.values(countryObj).map((item) => (
                    temp_country_list.push({
                        value: item.cd,
                        label: item.name
                    })
                ));
                setCountryList(temp_country_list);
                var data = Object.values(countryObj).filter((item) => item.cd === "91")
                data.map((value) => (
                    setCountry({
                        value: value.cd,
                        label: value.name
                    })
                ));
            } else {
                setError(res.msg);
            }
        })
    }

    /*-------country list api ----*/
    const getStateList = () => {
        let temp_state_list = [];
        let url = 'action/get/statelist';

        let data = {
            countryName: country?.label
        }

        services(true, url, data).then((response) => {
            let res = response.data;


            if (res.status === 1) {
                let countryObj = res.resData

                Object.values(countryObj).map((item) => (
                    temp_state_list.push({
                        value: item,
                        label: item
                    })
                ));
                setStateList(temp_state_list);
            } else {
                setError(res.msg);
            }
        });
    }

    return (
        <>
            <div className="modal e-edit-profile-modal" id="editProfileModal" tabIndex="-1" aria-labelledby="editProfileModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content position-relative">
                        <div className="modal-header">
                            <h5 className="modal-title mb-0" id="editProfileModalLabel">{!props.billing ? "Edit Profile Details" : "Edit Billing Address"}</h5>
                            <div className="e-close-btn-wrapper d-flex align-items-center justify-content-center position-absolute" data-bs-dismiss="modal" onClick={() => updatedData()}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41L17.59 5Z" fill="#11142F" />
                                </svg>
                            </div>
                        </div>
                        <div className="modal-body pt-4 pb-2">
                            <div className="row">
                                <div className="col-md-6">
                                    <InputBox
                                        label={<>Name <span className="e-warning-color">*</span></>}
                                        value={name}
                                        type="text"
                                        valueChange={(value) => {
                                            setName(value);
                                            setError(null);
                                        }}
                                         
                                        placeholder="Name"
                                        required={true} />
                                </div>
                                <div className="col-md-6">
                                    <div className="e-input-wrap mb-2">
                                        <label>Profile Image <span className="e-warning-color">*</span></label>
                                        <div className="w-100 d-flex align-items-center">
                                            <img src={uploadStatus ? LoaderGif : profilePic} alt="display-pic" className="e-edit-profile-pic" />
                                            <div className="ps-2 d-flex">
                                                <FileUpload
                                                    name="Change"
                                                    tempKey="/profile/image"
                                                    id="kyc"
                                                    className="e-file-upload"
                                                    uploadStatus={(val) => { }}
                                                    uploadSucess={handleProfilePicUpload} />
                                                {/* <input type="file" onChange={(e) => handleProfilePicUpload(e)} id="kyc" /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <InputBox
                                        label={<>Email <span className="e-warning-color">*</span></>}
                                        value={email}
                                        type="email"
                                        valueChange={(value) => {
                                            setEmail(value);
                                            setError(null);
                                        }}
                                         
                                        placeholder="Email"
                                        required={true}
										isDisabled={true}
										/>
                                </div>
                                <div className="col-md-6">
                                    <NumberInput
                                        label={<>Phone Number <span className="e-warning-color">*</span></>}
                                        value={phone}
										type="tel"
                                        valueChange={(value) => {
                                            console.log("Phone Number Changed: ", value);  
                                            setPhone(value);
                                            setError(null);
                                        }}
                                         
                                        placeholder="00000 00000"
                                        defaultCode={countryCode ? countryCode : "+91"}
                                        onCodeChange={(val) => { setCountryCode(val); console.log(val) }}
                                        required={true} />
								</div>
                                <div className="col-md-6">
                                    <InputBox
                                        label={<>Address Line 1 <span className="e-warning-color">*</span></>}
                                        value={addressOne}
                                        type="text"
                                        valueChange={(value) => {
                                            setAddressOne(value);
                                            setError(null);
                                        }}
                                         
                                        placeholder="Address Line 1"
                                        required={true} />
                                </div>
                                <div className="col-md-6">
                                    <InputBox
                                        label={<>Address Line 2 <span className="e-warning-color">*</span></>}
                                        value={addressTwo}
                                        type="text"
                                        valueChange={(value) => {
                                            setAddressTwo(value);
                                            setError(null);
                                        }}
                                         
                                        placeholder="Address Line 2"
                                        required={true} />
                                </div>
                                <div className="col-md-6">
                                    <div className="e-input-wrap">
                                        <label>Country <span className="e-warning-color">*</span></label>
                                        <SingleSelect
                                            isSearchable={true}
                                            placeholder="Choose Country"
                                            value={country}
                                            options={countryList}
                                            selectchange={val => setCountry(val)}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="e-input-wrap">
                                        <label>State <span className="e-warning-color">*</span></label>
                                        <SingleSelect
                                            isSearchable={true}
                                            options={stateList}
                                            placeholder="Choose State"
                                            value={state}
                                            selectchange={val => setState(val)}
                                             
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <InputBox
                                        label={<>City <span className="e-warning-color">*</span></>}
                                        value={city}
                                        type="text"
                                        valueChange={(value) => {
                                            setCity(value);
                                            setError(null);
                                        }}
                                         
                                        placeholder="Your City"
                                        required={true} />
                                </div>
                                <div className="col-md-6">
                                    <InputBox
                                        label={<>Pincode <span className="e-warning-color">*</span></>}
                                        value={pin}
                                        type="number"
                                        valueChange={(value) => {
                                            setPin(value);
                                            setError(null);
                                        }}
                                         
                                        placeholder="Your Pincode"
                                        required={true} />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer pt-3 justify-content-end align-items-center d-flex">
                            <div className="d-flex align-items-center">
                                <Secondary large={true} className="me-3" name="Cancel" cancel="modal" handleClick={() => updatedData()} />
                                {
                                    name && email &&
                                        phone && state &&
                                        addressOne && addressTwo &&
                                        city && pin ?
                                        <Primary large={true} name="Save" className={loader ? "e-btn-loader" : ""} handleClick={() => { !props.billing ? updateProfile() : addBillingAddress() }} />
                                        :
                                        <Primary large={true} name="Save" disabled={true} />
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {
                    error !== null ?
                        <Toast data={error} />
                        :
                        null
                }

            </div>
            <ImagePreview imageToCrop={file} onImageCropped={(croppedImage) => setCroppedImage(croppedImage)} />
        </>
    )
}

export default EditProfileModal;