import React, { useEffect, useState } from "react";


/*import style*/
import styles from '../../styles/components/OrdersCard.module.scss';

/* services */
// import services from '../../services/apis/services'
import { getServices } from "../../services/apis/services";
// import Loader from "../others/loader";

import Icon from '../../assets/images/profile/calendar-icon.png';
import { TextButton } from "../cta";
import { SingleSelect, ToggleButton } from "../form";

// const sampleData = [
//     { orderId: "wo12356", desc: "sarahjames.com", renewal_date: "Aug 15 2020", auto_renewal: true, payment_type: "CCard - Federal Bank" },
//     { orderId: "wo12355", desc: "sarahjames.xyz", renewal_date: "Aug 15 2020", auto_renewal: false, payment_type: "CCard - State Bank of India" },
//     { orderId: "wo12354", desc: "Medium Pack Subs.", renewal_date: "Aug 15 2020", auto_renewal: false, payment_type: "CCard - Federal Bank" },
// ];

const BillingCard = (props) => {
    const [showDropdown, setDropdown] = useState(false);
    // const [error, setError] = useState(null);
    // const [loader, setLoader] = useState(false);
    const [orderSubscriptions, setOrderSubscriptions] = useState([]);

    useEffect(() => {
        getOrderList();
    }, []);

    const getOrderList = async () => {
        // setLoader(true);
        let url = "reports/handleGetAllActiveSubscriptions";

          
        const token = localStorage.getItem("web23_token");
        const response = await getServices(url, token);
        setOrderSubscriptions(response.data.data);
    
    }

    const options = [
        {
            value: 1, label: "Date Asc"
        },
        {
            value: 2, label: "Date Desc"
        }
    ]
    return (
        <>
            <div className={`${styles.e_card_wrapper} e-py-32 e-px-32`}>
                <div className="w-100 d-flex align-items-center justify-content-between pb-3">
                    <div className="d-flex align-items-center">
                        <div className={styles.e_card_icon}>
                            <img src={Icon} alt="orders icon" />
                        </div>
                        <h3 className="mb-0 ps-1">Subscriptions</h3>
                    </div>
                    <div className="position-relative d-flex">
                        <div className={styles.e_dotted_burger} onClick={() => setDropdown(true)}></div>
                        <div className={showDropdown ? `${styles.e_dropdown} ${styles.e_dropdown_active} position-absolute py-2` : `${styles.e_dropdown} position-absolute py-2`}>
                            <div className="px-3 py-2 text-end">
                                <TextButton refresh={true} name="Refresh Orders" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-100 pt-3">
                    <div className={styles.e_reports_body}>
                        <div className="container-fluid p-3">
                            <div className="row">
                                <div className="col-lg-3 col-md-6">
                                    <div className="w-100 d-flex align-items-center">
                                        <span className={styles.e_sort_label}>Sort by:</span>
                                        <div className="w-100 ps-2">
                                            <SingleSelect options={options} placeholder="Date Asc" selectchange={() => { }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`${styles.e_table_wrapper} mt-2`}>
                            <div className={`${styles.e_table_container}`}>
                                <div className={`${styles.e_table_head} w-100 py-3`}>
                                    <div className="w-100 px-3">
                                        <div className="row">
                                            <div className="col-3">
                                                <h6>Domain / Subscription</h6>
                                            </div>
                                            <div className="col-2">
                                                <h6>Renewal Date</h6>
                                            </div>
                                            <div className="col-2">
                                                <h6>Cancel Date</h6>
                                            </div>
                                            <div className="col-2">
                                                <h6>Auto Renewal</h6>
                                            </div>
                                            <div className="col-3">
                                                <h6>Payment Type</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`${styles.e_table_body} w-100`}>
                                    {
                                        orderSubscriptions.map((item, i) => {
                                            return (
                                                <div className={i < orderSubscriptions.length - 1 ? "w-100 px-3 e-border-bottom" : "w-100 px-3"} key={`order-${i}`}>
                                                    <div className="row py-3">
                                                        <div className="col-3">{item.itemName}</div>
                                                        <div className="col-2">{item.auto_renewal ? item.expireOn : "--"}</div>
                                                        <div className="col-2">{!item.auto_renewal ? item.expireOn : "--"}</div>
                                                        <div className="col-2 fw-bold">
                                                            <ToggleButton hideInfo={true} defaultActive={item.auto_renewal} />
                                                        </div>
                                                        <div className="col-3">{item.payment_type}</div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center pt-4">
                        <h5 className={`${styles.e_load_more_btn} mb-0`}>Load More</h5>
                    </div>
                </div>
            </div>
            {
                showDropdown ?
                    <div className={`${styles.e_backdrop} position-fixed`} onClick={() => setDropdown(false)}></div>
                    :
                    null
            }
        </>
    )
}

export default BillingCard;