import * as web3 from '@solana/web3.js';
import * as borsh from 'borsh';
//const fs=require("fs");
let jsonRec={
		"input_type":0,		
		"media_uri":"",
		"base_uri":"",
		"metadata_uri":"",
		"is_transferable":1,
		"is_burnable":1,
		"parent_nft":"",   // Public key of brand nft
		"start_time":0,
		"expiry_time":0,     // datetime in millisecond
		"accumulative_fund":0,    //  derive staking from compound
		"stake_type":"",       // coin type ( cETH, cBNS)
		"stake_address":""     // Public Key
		 };
class TAccount {
  
  media_uri="";
  metadata_uri="";
  base_uri="";
  is_transferable=1;
  is_burnable=1;
  parent_nft="";
  start_time=0;
  expiry_time=0;
  accumulative_fund=0;
  stake_type="";
  stake_address="";
  constructor(fields={media_uri:'',base_uri:'',metadata_uri:'',is_transferable:1,is_burnable:1,
						parent_nft:'',
						start_time:0,
						expiry_time:0,
						accumulative_fund:0,
						stake_type:'',
						stake_address:''
					 }) {
    if (fields) {
      this.media_uri =fields.media_uri;
      this.metadata_uri=fields.metadata_uri; 
      this.base_uri=fields.base_uri;
	  this.is_transferable=fields.is_transferable;
	  this.is_burnable=fields.is_burnable;	 
	  this.parent_nft=fields.parent_nft;
	  this.start_time=fields.start_time;
	  this.expiry_time=fields.expiry_time;
	  this.accumulative_fund=fields.accumulative_fund;
	  this.stake_type=fields.stake_type;
	  this.stake_address=fields.stake_address;
    }
  }
}

const TSchema = new Map([
	[TAccount, {kind: 'struct', 
			 fields: [
				  ['media_uri','string'],
				  ['base_uri','string'],
				  ['metadata_uri','string'],
				  ['is_transferable','u32'],
			      ['is_burnable','u32'],
				  ['parent_nft','string'],
				  ['start_time','u32'],
				  ['expiry_time','u32'],
				  ['accumulative_fund','u32'],
				  ['stake_type','string'],
				  ['stake_address','string']
				 ]
			}
  ],
	]);



const metadataInput0=(async(_connection,_programId,_accountMint,_payer,_jsonRec)=>{
	
	let recData=new TAccount();
	
	console.dir(_jsonRec);
	recData.media_uri=_jsonRec.media_uri;
	recData.base_uri=_jsonRec.base_uri;
	recData.metadata_uri=_jsonRec.metadata_uri;
	recData.is_transferable=_jsonRec.is_transferable;
	recData.is_burnable=_jsonRec.is_burnable;
	recData.parent_nft=_jsonRec.parent_nft;
	recData.start_time=_jsonRec.start_time;
	recData.expiry_time=_jsonRec.expiry_time;
	recData.accumulative_fund=_jsonRec.accumulative_fund;
	recData.stake_type=_jsonRec.stake_type;
	recData.stake_address=_jsonRec.stake_address;
	console.dir(recData);
	const T_DATA = borsh.serialize(TSchema,recData);
	console.log("INitaialized Borsh Dada... ");	
	console.dir(T_DATA);
	const T_SIZE=T_DATA.length;
	console.log("INitaialized Borsh Dada... Length "+T_SIZE);	
	/*const desrObj = borsh.deserialize(
						TSchema,
						TAccount,
						T_DATA,
					  );
	console.dir(T_DATA);
	console.dir(desrObj);
	console.log(" data_uri="+desrObj.media_uri);
        console.log(" meta data_uri="+desrObj.metadata_uri);
	*/
	const connection=_connection;
	const programId=new web3.PublicKey(_programId);
	
	console.log("CHECKING PROGRAM ID="+programId.toBase58());
	
	const programInfo=await connection.getAccountInfo(programId);
	if(programInfo!=null)
	{	  
		var PAYER_SEED='';
		console.log("META DATA ASC-ACCOUNT :"+_accountMint.publicKey);
		//var payerPubkeyX= web3.Keypair.generate();
		//var payerPubkey=payerPubkeyX.publicKey;
		/*var payerPubkey = await web3.PublicKey.createWithSeed(
			_payer.publicKey,
			PAYER_SEED,
			programId,
		  	);*/

			const lamports = await connection.getMinimumBalanceForRentExemption(T_SIZE);
		  	console.log("LAMPORT="+lamports);
			// create  payer account
			const transaction = new web3.Transaction().add(
				web3.SystemProgram.createAccount({
				  fromPubkey: _payer.publicKey,
				  newAccountPubkey:_accountMint.publicKey,
				  lamports,
				  space: T_SIZE,
				  programId,
				}),
			  );
			  _jsonRec.input_type=1; // write data to account in program	
			  const instruction = new web3.TransactionInstruction({
				keys: [{pubkey:_accountMint.publicKey, isSigner: false, isWritable: true}],
				programId,
				data: Buffer.from(JSON.stringify(_jsonRec)),
				});	
			  transaction.add(instruction);


			  var _phantomContext=_payer;
			  if(_phantomContext==null){console.log(" Phnatum Null");}
			  else {
					console.log("MINTER "+_payer.publicKey+" == "+_phantomContext.publicKey);
					transaction.feePayer =_phantomContext.publicKey;
					
					let blockhashObj = await connection.getRecentBlockhash("max");
					transaction.recentBlockhash = await blockhashObj.blockhash;
					var signers=[_accountMint];
						transaction.sign(...signers);
					console.log("Txn created successfully");
					let signed =    await _phantomContext.signTransaction(transaction); //signAllTransactions
					  console.log("Signature :"+signed);
					  console.log(signed);
					let signature = await connection.sendRawTransaction(signed.serialize(), {
												skipPreflight: false,
											  preflightCommitment: "singleGossip",
											  });
					console.log("INit Signatured:"+signature);
					await connection.confirmTransaction(signature,"singleGossip");
					console.log("Signature: ", signature);
					const accountInfo = await connection.getAccountInfo(_accountMint.publicKey);
						if (accountInfo === null) {
						throw 'Error: cannot find the greeted account';
						}
						const accountObject= borsh.deserialize(
						TSchema,
						TAccount,
						accountInfo.data,
						);
						console.log(
						_accountMint.publicKey.toBase58(),
						'MEDIA URI :',accountObject.media_uri,
						'METADATA URI:',accountObject.metadata_uri
						); 
					return(signature);			
				}  
			  
			return(null);  
			
			
		 
		
	} 
});

const getMetadata0=async(_connection,_associatedAccountMintPubKey)=>{
	const connection=_connection;
	const accountInfo = await connection.getAccountInfo(_associatedAccountMintPubKey);
	console.log("META DATA ACCOUNT INFO--");
	console.dir(accountInfo);
						if (accountInfo === null) {
						throw 'This SPL TOKEN HAS NO META DATA';
						}
				try{
						const accountObject= borsh.deserialize(
						TSchema,
						TAccount,
						accountInfo.data,
						);
						console.log(
							_associatedAccountMintPubKey.toBase58(),
						'MEDIA URI :',accountObject.media_uri,
						'METADATA URI:',accountObject.metadata_uri
						); 
						let jsonRec={
							
							"media_uri":accountObject.media_uri,
							"base_uri":accountObject.base_uri,
							"metadata_uri":accountObject.metadata_uri,
							"is_transferable":accountObject.is_transferable,
							"is_burnable":accountObject.is_burnable,
							"parent_nft":accountObject.parent_nft,   
							"start_time":accountObject.start_time,
							"expiry_time":accountObject.expiry_time,    
							"accumulative_fund":accountObject.accumulative_fund,    
							"stake_type":accountObject.stake_type,       
							"stake_address":accountObject.stake_address 

						 };			
						return(jsonRec);
				}catch(e)
				{
					return(null);
				}	
}
export const metadataInput=metadataInput0;
export const getMetadata=getMetadata0;