import React, { useEffect, useState } from "react";
import moment from 'moment';

/*import style*/
import styles from '../../styles/components/OrdersCard.module.scss';

import Icon from '../../assets/images/profile/file-icon.png';
import { TextButton } from "../cta";
import { SingleSelect } from "../form";

/* services */
import services from '../../services/apis/services'
import Loader from "../others/loader";
import { Modal } from "bootstrap";
import InvoiceModal from "../modals/invoiceModal";
import { Toast } from "../alerts";

const sampleData = [
    { orderId: "wo12356", desc: "sarahjames.com", purchase_date: "Aug 15 2020", purchase_time: "5.00 PM", expiry_date: "Aug 15 2023", expiry_time: "5.00 PM", amount: "₹1500" },
    { orderId: "wo12355", desc: "sarahjames.xyz", purchase_date: "Aug 15 2020", purchase_time: "5.00 PM", expiry_date: "Aug 15 2023", expiry_time: "5.00 PM", amount: "₹1500" },
    { orderId: "wo12354", desc: "Medium Pack Subs.", purchase_date: "Aug 15 2020", purchase_time: "5.00 PM", expiry_date: "Aug 15 2023", expiry_time: "5.00 PM", amount: "₹1500" },
];

const OrdersCard = (props) => {
    const [showDropdown, setDropdown] = useState(false);
    const [error, setError] = useState(null);
    const [loader, setLoader] = useState(false);
    const [orderData, setOrderData] = useState([]);
    const [invoiceData, setInvoiceData] = useState([]);
    const [billId, setBillId] = useState("");
    const [invoiceLoader, setInvoiceLoader] = useState(false);
    const [rowId, setRowId] = useState("");
    const [billingData, setBillingData] = useState({});

    useEffect(() => {
        getOrderList();
        getBilling();
    }, []);

    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null);
            })
        }
    }, [error])
    const options = [
        {
            value: 1, label: "Date Asc"
        },
        {
            value: 2, label: "Date Desc"
        }
    ]

    /* orders list */
    const getOrderList = () => {
        setLoader(true);
        let url = "action/my/purchaseddetaillist";

        let data = {
            token: localStorage.getItem("web23_token")
        }

        services(true, url, data).then((response) => {
            let res = response.data

            if (res.status === 1) {
                setOrderData(res.resData);
                setLoader(false);
            } else if (res.status === 0 || res.status === -9 || res.status === -1) {
                setError(res?.resData[0]?.msg);
                setError(res.msg);
                setLoader(false);
            } else {
                setError(res?.resData[0]?.msg);
                setError(res.msg);
                setLoader(false);
            }
        });
    }

    /* download */
    const downloadInvoice = (billId) => {
        setBillId(billId)
        setInvoiceLoader(true);
        let url = "action/my/purchasedbill";

        let data = {
            token: localStorage.getItem("web23_token"),
            billId: billId
        }

        services(true, url, data).then((response) => {
            let res = response.data

            if (res.status === 1) {
                var modal = new Modal(document.getElementById("invoice"), {});
                modal.show();
                setInvoiceData(res.resData);
                setInvoiceLoader(false);
            } else if (res.status === 0 || res.status === -9 || res.status === -1) {
                setError(res?.resData[0]?.msg);
                setError(res.msg);
                setInvoiceLoader(false);
            } else {
                setError(res?.resData[0]?.msg);
                setError(res.msg);
                setInvoiceLoader(false);
            }
        });
    }


    /* billing address */
    const getBilling = () => {
        let url = "action/bill/getcontact";

        let data = {
            token: localStorage.getItem("web23_token"),
        }

        services(true, url, data).then((response) => {
            let res = response.data

            if (res.status === 1) {
                setBillingData(res.resData);
            } else if (res.status === 0 || res.status === -9 || res.status === -1) {
                setError(res.msg);
            } else {
                setError(res?.resData[0]?.msg);
                setError(res.msg);
            }
        });
    }
    return (
        <>
            <div className={`${styles.e_card_wrapper} e-py-32 e-px-32`}>
                <div className="w-100 d-flex align-items-center justify-content-between pb-3">
                    <div className="d-flex align-items-center">
                        <div className={styles.e_card_icon}>
                            <img src={Icon} alt="orders icon" />
                        </div>
                        <h3 className="mb-0 ps-1">Order History</h3>
                    </div>
                    <div className="position-relative d-flex">
                        <div className={styles.e_dotted_burger} onClick={() => setDropdown(true)}></div>
                        <div className={showDropdown ? `${styles.e_dropdown} ${styles.e_dropdown_active} position-absolute py-2` : `${styles.e_dropdown} position-absolute py-2`}>
                            <div className="px-3 py-2 text-end">
                                <TextButton
                                    refresh={true}
                                    name="Refresh Orders"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-100 pt-3">
                    <div className={styles.e_reports_body}>
                        <div className="container-fluid p-3">
                            <div className="row">
                                <div className="col-lg-3 col-md-6">
                                    <div className="w-100 d-flex align-items-center">
                                        <span className={styles.e_sort_label}>Sort by:</span>
                                        <div className="w-100 ps-2">
                                            <SingleSelect
                                                options={options}
                                                placeholder="Date Asc"
                                                selectchange={() => { }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            loader ?
                                <Loader />
                                :
                                <div className={`${styles.e_table_wrapper} mt-2`}>
                                    <div className={`${styles.e_table_container}`}>
                                        <div className={`${styles.e_table_head} w-100 py-3`}>
                                            <div className="w-100 px-3">
                                                <div className="row">
                                                    <div className="col-2">
                                                        <h6>Order ID</h6>
                                                    </div>
                                                    <div className="col-3">
                                                        <h6>Domain / Subscription</h6>
                                                    </div>
                                                    <div className="col-2">
                                                        <h6>Purchase Date</h6>
                                                    </div>
                                                    <div className="col-2">
                                                        <h6>Expires On</h6>
                                                    </div>
                                                    <div className="col-1">
                                                        <h6>Amount</h6>
                                                    </div>
                                                    <div className="col-2">
                                                        <h6>Invoice</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`${styles.e_table_body} w-100`}>
                                            {
                                                orderData.map((issue, i) => {
                                                    return (
                                                        <div className={i < sampleData.length - 1 ? "w-100 px-3 e-border-bottom" : "w-100 px-3"} key={`order-${i}`}>
                                                            <div className="row py-3">
                                                                <div className="col-2">{issue.billId}</div>
                                                                <div className="col-3">{issue.itemName}</div>
                                                                <div className="col-2">
                                                                    <div className="d-flex flex-column">
                                                                        <span>{moment(issue?.purchaseDate?.split('T')[0], "YYYY-MM-DD").format("DD MMM YYYY")}</span>
                                                                        <span className={styles.e_fw_small}>{(moment(issue.purchaseDate)).format('hh:mm a')}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-2">
                                                                    <div className="d-flex flex-column">
                                                                        <span>{moment(issue?.expireOn?.split('T')[0], "YYYY-MM-DD").format("DD MMM YYYY")}</span>
                                                                        <span className={styles.e_fw_small}>{(moment(issue.expireOn)).format('hh:mm a')}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-1 fw-bold">₹{issue.finalPrice}</div>
                                                                <div className="col-2">
                                                                    <TextButton
                                                                        name="Download"
                                                                        className={issue._id === rowId && invoiceLoader ? "e-btn-loader" : ""}
                                                                        blue={true}
                                                                        download={true}
                                                                        handleClick={() => {
                                                                            downloadInvoice(issue.billId);
                                                                            setRowId(issue._id)
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>
            {
                showDropdown ?
                    <div className={`${styles.e_backdrop} position-fixed`} onClick={() => setDropdown(false)}></div>
                    :
                    null
            }
            <InvoiceModal
                invoice={invoiceData}
                billId={billId}
                billingAddress={billingData}
            />
            {
                error !== null ?
                    <Toast data={error} />
                    :
                    null
            }
        </>
    )
}

export default OrdersCard;