import React from 'react';
import { HeathIndicatorBadge } from '../../components/badges';
import { OverviewCard, HeathIndicatorCard, SocialMediaIndicator, SocialMediaReport, ReportCards, HealthSummaryCard } from '../../components/cards';
import DomainCard from '../../components/cards/domainCard';
import { Primary, Secondary, TextButton } from '../../components/cta';
import SubscriptionTable from '../../components/dataTable/subscriptionTable';
import { CheckBox, InputBox, NumberInput, SingleSelect, ToggleButton } from '../../components/form';
import CartDropdown from '../../components/others/cartDropdown';
import { useToolTip } from '../../services/tooltipHandler/tooltipHandler';

const overviewTypes = ["web_2", "web_3", "look_alike", "enroachments", "redirections", "resolved", "social", "templates"];
const socialMediaCards = ["facebook", "instagram", "linkedin", "twitter", "youtube"];

const Test = () => {

    const tooltip = useToolTip();

    return (
        <div className="container py-5" >
            <h3>Primary Buttons</h3>
            <div className="d-flex align-items-center w-100 p-4" style={{ background: "#eee" }}>
                <Primary name="Primary Button search" className="mx-3" search={true} />
                <Primary name="Primary Button small grey" className="mx-3" small={true} grey={true} />
                <Primary name="Primary Button large" className="mx-3" large={true} />
            </div>
            <div className="d-flex align-items-center w-100 p-4">
                <Primary name="Full-width large grey primary Button" className="mx-3" large={true} full_width={true} grey={true} />
            </div>
            <h3 className="mt-3">Secondary Buttons</h3>
            <div className="d-flex align-items-center w-100 p-4" style={{ background: "#eee" }}>
                <Secondary name="Secondary Button" className="mx-3" />
                <Secondary name="Secondary Button with globe" className="mx-3" globe_icon={true} />
                <Secondary name="Secondary Button small red" className="mx-3" small={true} red={true} />
                <Secondary name="Secondary Button large white" className="mx-3" large={true} white={true} />
            </div>
            <div className="d-flex align-items-center w-100 p-4" style={{ background: "#eee" }}>
                <Secondary name="Full-width large blue secondary Button" className="mx-3" full_width={true} large={true} blue={true} />
            </div>
            <h3 className="mt-3">Text Buttons</h3>
            <div className="d-flex align-items-center w-100 p-4" style={{ background: "#eee" }}>
                <TextButton name="TextButton " className="mx-3" />
                <TextButton name="TextButton back" className="mx-3" back={true} />
                <TextButton name="TextButton forward" className="mx-3" forward={true} />
                <TextButton name="TextButton download" className="mx-3" download={true} />
            </div>
            <div className="d-flex align-items-center w-100 p-4" style={{ background: "#eee" }}>
                <TextButton name="TextButton recheck" className="mx-3" recheck={true} />
                <TextButton name="TextButton recheck small" className="mx-3" recheck={true} small={true} />
                <TextButton name="TextButton back white" className="mx-3" back={true} white={true} />
            </div>
            <h3 className="mt-3">OverView Card</h3>
            <div className="row gy-3 mt-5">
                {
                    overviewTypes.map(item => (
                        <div className="col-lg-3">
                            <OverviewCard type={item} value="8" />
                        </div>
                    ))
                }
            </div>
            <h3 className="mt-3">Social Media</h3>
            <div className="row gy-3 mt-5">
                {
                    socialMediaCards.map((item, i) => (
                        <div className="col-lg-3">
                            <SocialMediaIndicator issues={i%2==0? 0: i} type={item} not_connected={i==0? true: false}/>
                        </div>
                    ))
                }
            </div>
            <div className="row gy-3 mt-5">
                {
                    socialMediaCards.map((item, i) => (
                        <div className="col-lg-12">
                            <SocialMediaReport issues={i%2==0? 0: i} type={item} not_connected={i==0? true: false}/>
                        </div>
                    ))
                }
            </div>
            <h3 className="mt-3">Report Cards</h3>
            <div className="row mt-3 py-5" style={{backgroundColor: '#E5E5E5'}}>
                <div className="col-lg-12">
                    <ReportCards/>
                </div>

                <div className="col-lg-4 py-4">
                    <DomainCard/>
                </div>
            </div>
            <div className="row mt-3 py-5" style={{backgroundColor: '#E5E5E5'}}>
                <div className="col-lg-12">
                    <HealthSummaryCard/>
                </div>
            </div>
            <h3 className="mt-3">Inputs</h3>
            <div className="row mt-3 py-5" style={{backgroundColor: '#E5E5E5'}}>
                <div className="col-lg-6">
                    <InputBox 
                    valueChange={()=>{}}
                    placeholder="00000 00000"
                    label="Label"
                    type="number"/>
                </div>
                <div className="col-lg-6">
                    <ToggleButton onChange={()=>{}}/>
                </div>
                <div className="col-lg-12 py-4" style={{background: "#fff"}}>
                   <SubscriptionTable/>
                </div>
            </div>
            <h3 className="mt-3" style={{cursor: "pointer"}} onMouseEnter={e=>tooltip.showTooltip(e, "Tool tip test dialogue")} onMouseLeave={tooltip.hideTooltip}>Heath Indicator Card and Badge</h3>
            <div className="container p-4">
                <div className="row gy-3">
                    <div className="col-lg-3">
                        <HeathIndicatorCard value={2} />
                    </div>
                    <div className="col-lg-3">
                        <HeathIndicatorCard healthy={true} />
                    </div>
                    <div className="col-lg-3">
                        <HeathIndicatorBadge value={2} />
                    </div>
                    <div className="col-lg-3">
                        <HeathIndicatorBadge healthy={true} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Test;