import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

import { DomainCard } from "../../components/cards";

const sampleData3 = [
    {
        "expireOn": "2023-02-28T12:02:42.384Z",
        "_id": "621f58a4463defb2f48e5b77",
        "itemId": "250222130013BMND",
        "itemName": "googllab.com",
        "orderType": "W2",
        "purchaseDate":
            "2022-03-02T11:44:36.758Z"
    },
    {
        "expireOn": "2023-03-04T07:59:20.696Z",
        "_id": "6221fe8c328cf5d7b832fa5a",
        "itemId": "040322041520L47Q",
        "itemName": "snrnh.com",
        "orderType": "D2",
        "purchaseDate": "2022-03-04T11:57:00.333Z"
    },
]

const ActiveDomains = (props) => {
// console.log("propers dataaaaaaa")
// console.log(props)
    const [data, setData] = useState([]);

    useEffect(() => {
        if (props.web3) {
            setData(sampleData3)
        }
        else {
            setData(props.data);
        }
    }, [props.web3,props.data]);

    const router = useHistory();

    return (
        <div className="container-fluid px-0 e-active-domain pt-4">
            <div className="e-domain-gradiant-bg">
                <div className="container">
                    <div className="row gy-4">
                        {
                            data.map((item, i) => {
                                return (
                                    <div className="col-md-6 col-lg-4 col-xl-3" key={`domain-${i}`}>
                                        <DomainCard data={item} expact={'act'} redirect={() => { router.push(`/domain/${props.web3 ? 'web3' : 'web2'}/detail?domain=${item.itemName.toLowerCase()}`) }} />
                                    </div>
                                )
                            })
                        }
                        <div className="col-md-6 col-lg-4 col-xl-3">
                            <DomainCard redirect={() => router.push(`/search/${props.web3 ? 'web3' : 'web2'}?query=`)} update= {props.web23}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ActiveDomains;