import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

import SampleDP from '../../assets/images/profile/sample-girl.png';
import EditProfileModal from "../../components/modals/editProfileModal";
import { TabHeadingTwo } from "../../components/tabHeadings";

import BillingContainer from "./billing";
import KycContainer from "./kyc";
import OrdersContainer from "./orders";
import AccountSettings from "./settings";

const PROFILE_PATHS = {
    1: "orders",
    2: "billing",
    3: "kyc",
    4: "settings"
}

const ProfileContainer = (props) => {
    console.log("props")
    console.log(props.location.pathname)  
    const [activeTab, setActive] = useState(1);
    const router = useHistory();

    const handlePathChange = (path) => {
        let tab = 1;
        if (path === "/profile/orders") {
            tab = 1;
        }
        else if (path === "/profile/billing") {
            tab = 2;
        }
        else if (path === "/profile/kyc") {
            tab = 3;
        }
        else if (path === "/profile/settings") {
            tab = 4;
        }
        else {
            router.push('/profile/orders');
        }
        setActive(tab);
    }

    const handleTabChange = (tab) => {
        let paths = PROFILE_PATHS;
        if (paths[tab]) {
            router.push(`${paths[tab]}`)
        };
    }

    useEffect(() => {
        if (props.location.pathname) {
            handlePathChange(props.location.pathname)
        }
    }, [props.location.pathname]);

    const switchTabContent = () => {
        let key = activeTab;
        let content;
        switch (key) {
            case 1: {
                content = <OrdersContainer update={(data) => props.update(data)}/>
                break;
            }
            case 2: {
                content = <BillingContainer update={(data) => props.update(data)}/>
                break;
            }
            case 3: {
                content = <KycContainer />
                break;
            }
            case 4: {
                content = <AccountSettings />
                break;
            }
        }
        return content;
    }

    return (
        <>
            <div className="w-100 e-profile-page-container">
                <div className="container-fluid e-profile-page-header-wrapper pt-3">
                    <div className="container">
                        <div className="d-flex align-items-center e-profile-header py-4">
                            <img src={SampleDP} alt="Profile Picture" />
                            <h2 className="mb-0 ps-1">{localStorage.getItem("name")}</h2>
                        </div>
                        <div className="w-100 pt-3">
                            <TabHeadingTwo
                                tabs={[
                                    "Orders & Profile",
                                    "Billing & Subscriptions",
                                    "KYC Details",
                                    "Account Settings"
                                ]}
                                activeTab={activeTab}
                                setActive={tab => {
                                    handleTabChange(tab)
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="container-fluid pt-3">
                    <div className="container pt-4 pb-5">
                        {switchTabContent()}
                    </div>
                </div>
            </div>
            <EditProfileModal />
        </>
    )
}

export default ProfileContainer;