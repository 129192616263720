import React from "react";

import { OrdersCard, ProfileDetailCard } from "../../components/cards";

const OrdersContainer=(props)=>{

    return(
        <>
        <div className="w-100 pb-3">
            <OrdersCard/>
        </div>
        <div className="w-100 pt-4">
            <ProfileDetailCard  update={(data) => props.update(data)}/>
        </div>
        </>
    )
}

export default OrdersContainer;