import React from "react";
import $ from 'jquery';
import { Primary } from "../cta";
import SocialButtonChild from './socialLogin';

/*import style*/
import styles from '../../styles/components/SocialButton.module.scss';

const SocialButton=(props)=>{

    const triggerLogin=()=>{
        $(`#${props.id}-btn`).trigger('click');
    }

    return(
        <div className={`w-100 position-relative ${styles.e_social_button_wrapper}`}>
            <Primary handleClick={triggerLogin} name={props.name} grey={true} type="button" full_width={true}/>
            <div className={`${styles.e_social_btn} position-absolute`}>
                <SocialButtonChild {...props} id={`${props.id}-btn`}>
                </SocialButtonChild>
            </div>
        </div>
    )
}

export {SocialButton} ;
