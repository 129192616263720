import React from 'react';

/*import style*/
import styles from '../../styles/components/ProgressBar.module.scss';

const ProgressBar=(props)=>{

    return(
        <div className={`${styles.e_wrapper} d-flex`}>
            <div className={`${styles.e_progress_bar}`} style={{width:`${props.width? props.width: 0}%`}}></div>
        </div>
    )
}

export default ProgressBar;