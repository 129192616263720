import React from "react";
import SocialLogin from "react-social-login";
import { Primary } from "../cta";

class SocialButtonChild extends React.Component {
  render() {
    const { children, triggerLogin, ...props } = this.props;
    return (
      <button onClick={triggerLogin} {...props} id={props.id}>
        {children}
      </button>
    );
  }
}

export default SocialLogin(SocialButtonChild);