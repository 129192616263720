import axios from 'axios';

export default async function w3services(type, url, data,content) {
  if (type === true) {
    let API_URL = process.env.REACT_APP_W3_MODE + url;
    return await axios.post(
      API_URL,
      data,
      {
        'headers': {
          'method': 'post',
          'Accept': 'application/json',
          'Content-Type':  content ? 'multipart/form-data' : 'application/json',
        }
      }

    ).then(function (response) {
      return response;
    })
      .catch(function (response) {
		//  console.log("---response---");
		//  console.log({status:response.status,response:response});
		//  console.log("---response---");
        return {status:(response.status===undefined?-9:response.status),msg:'Network Issue',response:response};
      })
  } else {
    let API_URL = process.env.REACT_APP_W3_MODE + url;
    return await axios.get(
      API_URL,
      data,
      {
        headers: {
          'method': 'get',
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      }
    ).then(function (response) {
          return response;
        })
      .catch(function (response) {
         return {status:(response.status===undefined?-9:response.status),msg:'Network Issue',response:response};
      })
  }
} 