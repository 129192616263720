/*--------------
Properties    default    desc

name           --             Button Name
type           Submit         Button type
disabled       false          set to true to disable button
large          false          set to true for large button
medium         false          set to true for medium button
small          false          set to true for small button
grey           false          set to true for grey button
search         false          set to true for search button
full_width     false          button takes full width when set to true
className      --             classname for button wrapper
cancel         --             data-bs-dismiss attribute for bootstrap
toggle         --             data-bs-toggle attribute for bootstrap
target         --             data-bs-target attribute for bootstrap
handleClick    --             onClick function
handleSubmit   --             onKeyPress function
---------------*/

/*Package importing*/
import React from 'react';
import * as solanaWeb3 from '@solana/web3.js';

/*import style*/
import styles from '../../styles/components/Primary.module.scss';
import jconstant from '../../config/xutils/constant';
import {metadataInput,getMetadata} from "../../config/xutils/customermetadata.js"
import * as jtoken from '../../config/xclient/token';

export default function Primary(props) {
  return (
    <div className={
      props.disabled && props.full_width? 
      `${styles.e_btn_wrapper} ${styles.e_btn_disabled} ${styles.e_btn_full_width} ${props.className}` 
      :props.full_width? 
      `${styles.e_btn_wrapper} ${styles.e_btn_full_width} ${props.className}` 
      :props.disabled ? 
      `${styles.e_btn_wrapper} ${styles.e_btn_disabled} ${props.className}` 
      : `${styles.e_btn_wrapper} ${props.className}`}>
      <button
        id={props.id}
        className={
          props.large && props.grey ?
            `${styles.e_p_btn} ${styles.e_p_btn_large} ${styles.e_p_btn_grey}`
            : props.small && props.grey ?
              `${styles.e_p_btn} ${styles.e_p_btn_small} ${styles.e_p_btn_grey}`
              : props.medium && props.grey ?
              `${styles.e_p_btn} ${styles.e_p_btn_medium} ${styles.e_p_btn_grey}`
              : props.grey ?
                `${styles.e_p_btn} ${styles.e_p_btn_grey}`
                : props.large ?
                  `${styles.e_p_btn} ${styles.e_p_btn_large}`
                  : props.medium ?
                  `${styles.e_p_btn} ${styles.e_p_btn_medium}`
                  : props.small ?
                    `${styles.e_p_btn} ${styles.e_p_btn_small}`
                    : props.search ?
                      `${styles.e_p_btn} ${styles.e_search_btn}`
                      : `${styles.e_p_btn}`} 
        data-bs-dismiss={props.cancel}
        data-bs-toggle={props.toggle}
        data-bs-target={props.target}
        onClick={props.handleClick}
        onKeyPress={props.handleSubmit}
        type={props.type ? props.type : "submit"} >
        <span className={props.search ? 'd-flex align-items-center' : ''}>
          {
            props.search ?
              <>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.8032 11.2587C12.6864 11.1419 12.6704 10.9403 12.76 10.8011C13.464 9.71791 13.8768 8.42832 13.8768 7.04272C13.8768 3.21552 10.7632 0.103516 6.9392 0.103516C3.112 0.103516 0 3.21552 0 7.04272C0 10.8683 3.112 13.9803 6.9392 13.9803C8.4288 13.9803 9.808 13.5019 10.9408 12.6987C11.0736 12.6027 11.2736 12.6155 11.3904 12.7307L13.8704 15.2107C13.8864 15.2267 13.904 15.2427 13.9216 15.2555C13.9504 15.2795 14.0704 15.3915 14.1856 15.5083L14.48 15.8011C14.5968 15.9163 14.7856 15.9163 14.9008 15.8011L15.912 14.7899C16.0288 14.6731 16.0288 14.4843 15.9136 14.3675L12.8032 11.2587ZM11.136 10.2747C10.9072 10.5723 10.6496 10.8443 10.3648 11.0859C9.44 11.8715 8.2448 12.3483 6.9392 12.3483C4.0128 12.3483 1.632 9.96751 1.632 7.04272C1.632 4.11792 4.0128 1.73552 6.9392 1.73552C9.864 1.73552 12.2448 4.11632 12.2448 7.04272C12.2448 8.25872 11.8272 9.37872 11.136 10.2747Z" fill="#4660F1" />
                </svg>
                <span className="ps-1">
                  {props.name}
                </span>
              </>
              :
              <>{props.name}</>
          }
        </span>
      </button>
    </div>
  );
}