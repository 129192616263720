import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Logo from '../../assets/images/logo-white.svg';
import Logofooter from '../../assets/logo/logoSky.svg';

const Footer = ()=>{
    const [year, setYear] = useState(2021);

    useEffect(()=>{
        let date = new Date();
        let year = date.getFullYear();
        setYear(year);
    },[]);

    return(
        <footer className="e-footer">
            <div className="container py-3 e-footer-container">
                <div className="e-footer-logo-container pb-3 d-flex">
                <Link to="/" className="d-flex align-items-center ">
				<img src={Logofooter} alt="LOGO" className="e-footer-logo"style={{ width: '10%' }} />
                    {/* <h4 className="mb-0 ps-2">Web 23</h4> */}
                </Link>
                </div>
                <div className="row gx-lg-3 gx-2">
                    <div className="col-md-4">
                        <span>{`Copyright © ${year}. Web23 Ltd.`}</span>
                    </div>
                    <div className="col-lg-5 col-sm-8">
                        <div className="d-flex justify-content-lg-start justify-content-md-end">
                            <a href="" className="pe-lg-4">Contact</a>
                            <a href="" className="ps-lg-3 ps-2 ps-sm-3 pe-lg-4">Privacy Policy</a>
                            <a href="" className="ps-lg-3 ps-2 ps-sm-3 pe-lg-4">Terms & Conditions</a>
                            <a href="" className="ps-lg-3 ps-2 ps-sm-3">FAQs</a>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="w-100 d-flex justify-content-end">
                            <span>Powered by <a  href="https://evoqueinnovativelab.com/" target="_blank">web23.io</a></span>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;