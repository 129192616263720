import React, { createContext, useState } from 'react';

export const CartContext = createContext();

export const CartDataProvider = props =>{
    const [cartData, setCartData] = useState(null);

    return(
        <CartContext.Provider value={[cartData, setCartData]}>
            {props.children}
        </CartContext.Provider>
    )
}