import React, { useState } from "react";

/*import style*/
import styles from '../../styles/components/DomainDetailCard.module.scss';
import { Secondary } from "../cta";

const address = "0xe7f5be6cb3bf6f95f79f850947b5a656ca22a29";
const parent = "eth";

const DomainDetailCard = () => {
    
    const [copied, setCopied] = useState(false);

    const copyData = (data) => {
        if (!window.navigator.clipboard) {
            // document.execCommand('copy');
        }
        else {
            window.navigator.clipboard.writeText(data).then(() => {
                setCopied(true);
                setTimeout(() => {
                    setCopied(false);
                }, 500);
            }).catch(err => {
                console.log(err);
            });
        }
    }

    return (
        <div className={`${styles.e_wrapper}`}>
            <div className="e-px-32 e-py-32 w-100 e-border-bottom">
                <h3 className="mb-0">Domain Details</h3>
            </div>
            <div className="e-px-32 e-py-32 w-100 position-relative">
                <div className="row gy-4">
                    <div className="col-12">
                        <div className="d-flex align-items-center pb-2">
                            <h5 className="mb-0">Owner</h5>
                            <svg className={`${styles.e_copy_icon} ms-1`}
                                width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"
                                onClick={() => copyData(address)}>
                                <path d="M11.6666 18.3333H3.33329C2.88668 18.3488 2.45368 18.1783 2.1377 17.8623C1.82171 17.5463 1.6511 17.1133 1.66662 16.6667V8.33334C1.6511 7.88674 1.82171 7.45374 2.1377 7.13776C2.45368 6.82177 2.88668 6.65116 3.33329 6.66668H6.66662V3.33335C6.6511 2.88674 6.82171 2.45374 7.1377 2.13776C7.45368 1.82177 7.88668 1.65116 8.33329 1.66668H16.6666C17.1132 1.65116 17.5462 1.82177 17.8622 2.13776C18.1782 2.45374 18.3488 2.88674 18.3332 3.33335V11.6667C18.3486 12.1132 18.1778 12.5461 17.8619 12.862C17.546 13.1779 17.1132 13.3486 16.6666 13.3333H13.3332V16.6667C13.3486 17.1132 13.1778 17.5461 12.8619 17.862C12.546 18.1779 12.1132 18.3486 11.6666 18.3333ZM3.33329 8.33334V16.6667H11.6666V13.3333H8.33329C7.88676 13.3486 7.4539 13.1779 7.13797 12.862C6.82204 12.5461 6.65135 12.1132 6.66662 11.6667V8.33334H3.33329ZM8.33329 3.33335V11.6667H16.6666V3.33335H8.33329Z" fill="#11142F" />
                            </svg>
                        </div>
                        <span className={styles.e_detail_grey}>
                            {address}
                        </span>
                    </div>
                    <div className="col-md-6">
                        <div className="d-flex align-items-center pb-2">
                            <h5 className="mb-0">Parent</h5>
                            <svg className={`${styles.e_copy_icon} ms-1`}
                                width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"
                                onClick={() => copyData(parent)}>
                                <path d="M11.6666 18.3333H3.33329C2.88668 18.3488 2.45368 18.1783 2.1377 17.8623C1.82171 17.5463 1.6511 17.1133 1.66662 16.6667V8.33334C1.6511 7.88674 1.82171 7.45374 2.1377 7.13776C2.45368 6.82177 2.88668 6.65116 3.33329 6.66668H6.66662V3.33335C6.6511 2.88674 6.82171 2.45374 7.1377 2.13776C7.45368 1.82177 7.88668 1.65116 8.33329 1.66668H16.6666C17.1132 1.65116 17.5462 1.82177 17.8622 2.13776C18.1782 2.45374 18.3488 2.88674 18.3332 3.33335V11.6667C18.3486 12.1132 18.1778 12.5461 17.8619 12.862C17.546 13.1779 17.1132 13.3486 16.6666 13.3333H13.3332V16.6667C13.3486 17.1132 13.1778 17.5461 12.8619 17.862C12.546 18.1779 12.1132 18.3486 11.6666 18.3333ZM3.33329 8.33334V16.6667H11.6666V13.3333H8.33329C7.88676 13.3486 7.4539 13.1779 7.13797 12.862C6.82204 12.5461 6.65135 12.1132 6.66662 11.6667V8.33334H3.33329ZM8.33329 3.33335V11.6667H16.6666V3.33335H8.33329Z" fill="#11142F" />
                            </svg>
                        </div>
                        <span className={styles.e_detail_grey}>
                            {parent}
                        </span>
                    </div>
                    <div className="col-md-6">
                        <div className="d-flex align-items-center pb-2">
                            <h5 className="mb-0">Registrant</h5>
                            <svg className={`${styles.e_copy_icon} ms-1`}
                                width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"
                                onClick={() => copyData(address)}>
                                <path d="M11.6666 18.3333H3.33329C2.88668 18.3488 2.45368 18.1783 2.1377 17.8623C1.82171 17.5463 1.6511 17.1133 1.66662 16.6667V8.33334C1.6511 7.88674 1.82171 7.45374 2.1377 7.13776C2.45368 6.82177 2.88668 6.65116 3.33329 6.66668H6.66662V3.33335C6.6511 2.88674 6.82171 2.45374 7.1377 2.13776C7.45368 1.82177 7.88668 1.65116 8.33329 1.66668H16.6666C17.1132 1.65116 17.5462 1.82177 17.8622 2.13776C18.1782 2.45374 18.3488 2.88674 18.3332 3.33335V11.6667C18.3486 12.1132 18.1778 12.5461 17.8619 12.862C17.546 13.1779 17.1132 13.3486 16.6666 13.3333H13.3332V16.6667C13.3486 17.1132 13.1778 17.5461 12.8619 17.862C12.546 18.1779 12.1132 18.3486 11.6666 18.3333ZM3.33329 8.33334V16.6667H11.6666V13.3333H8.33329C7.88676 13.3486 7.4539 13.1779 7.13797 12.862C6.82204 12.5461 6.65135 12.1132 6.66662 11.6667V8.33334H3.33329ZM8.33329 3.33335V11.6667H16.6666V3.33335H8.33329Z" fill="#11142F" />
                            </svg>
                        </div>
                        <span className={styles.e_detail_grey}>
                            {address}
                        </span>
                    </div>
                    <div className="col-md-6">
                        <div className="d-flex align-items-center pb-2">
                            <h5 className="mb-0">Controller</h5>
                            <svg className={`${styles.e_copy_icon} ms-1`}
                                width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"
                                onClick={() => copyData(address)}>
                                <path d="M11.6666 18.3333H3.33329C2.88668 18.3488 2.45368 18.1783 2.1377 17.8623C1.82171 17.5463 1.6511 17.1133 1.66662 16.6667V8.33334C1.6511 7.88674 1.82171 7.45374 2.1377 7.13776C2.45368 6.82177 2.88668 6.65116 3.33329 6.66668H6.66662V3.33335C6.6511 2.88674 6.82171 2.45374 7.1377 2.13776C7.45368 1.82177 7.88668 1.65116 8.33329 1.66668H16.6666C17.1132 1.65116 17.5462 1.82177 17.8622 2.13776C18.1782 2.45374 18.3488 2.88674 18.3332 3.33335V11.6667C18.3486 12.1132 18.1778 12.5461 17.8619 12.862C17.546 13.1779 17.1132 13.3486 16.6666 13.3333H13.3332V16.6667C13.3486 17.1132 13.1778 17.5461 12.8619 17.862C12.546 18.1779 12.1132 18.3486 11.6666 18.3333ZM3.33329 8.33334V16.6667H11.6666V13.3333H8.33329C7.88676 13.3486 7.4539 13.1779 7.13797 12.862C6.82204 12.5461 6.65135 12.1132 6.66662 11.6667V8.33334H3.33329ZM8.33329 3.33335V11.6667H16.6666V3.33335H8.33329Z" fill="#11142F" />
                            </svg>
                        </div>
                        <span className={styles.e_detail_grey}>
                            {address}
                        </span>
                    </div>
                    <div className="col-md-6">
                        <div className="d-flex align-items-start justify-content-between">
                            <div>
                                <div className="d-flex align-items-center justify-content-between pb-2">
                                    <div className="d-flex align-items-center">
                                        <h5 className="mb-0">Resolver</h5>
                                        <svg className={`${styles.e_copy_icon} ms-1`}
                                            width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"
                                            onClick={() => copyData(address)}>
                                            <path d="M11.6666 18.3333H3.33329C2.88668 18.3488 2.45368 18.1783 2.1377 17.8623C1.82171 17.5463 1.6511 17.1133 1.66662 16.6667V8.33334C1.6511 7.88674 1.82171 7.45374 2.1377 7.13776C2.45368 6.82177 2.88668 6.65116 3.33329 6.66668H6.66662V3.33335C6.6511 2.88674 6.82171 2.45374 7.1377 2.13776C7.45368 1.82177 7.88668 1.65116 8.33329 1.66668H16.6666C17.1132 1.65116 17.5462 1.82177 17.8622 2.13776C18.1782 2.45374 18.3488 2.88674 18.3332 3.33335V11.6667C18.3486 12.1132 18.1778 12.5461 17.8619 12.862C17.546 13.1779 17.1132 13.3486 16.6666 13.3333H13.3332V16.6667C13.3486 17.1132 13.1778 17.5461 12.8619 17.862C12.546 18.1779 12.1132 18.3486 11.6666 18.3333ZM3.33329 8.33334V16.6667H11.6666V13.3333H8.33329C7.88676 13.3486 7.4539 13.1779 7.13797 12.862C6.82204 12.5461 6.65135 12.1132 6.66662 11.6667V8.33334H3.33329ZM8.33329 3.33335V11.6667H16.6666V3.33335H8.33329Z" fill="#11142F" />
                                        </svg>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <span className={`${styles.e_info}`}>(Using public resolver)</span>
                                    </div>
                                </div>
                                <span className={styles.e_detail_grey}>
                                    {address}
                                </span>
                            </div>
                            <div className="ps-3">
                                <Secondary small={true} name="Set" blue={true} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={copied? `${styles.e_copied_toast} ${styles.e_copied_active} position-absolute`: `${styles.e_copied_toast} position-absolute`}>
                    Copied to clipboard.
                </div>
            </div>
        </div>
    )
}

export default DomainDetailCard;