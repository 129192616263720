/*--------------
Properties    default    desc


---------------*/


/* Package Inport */
import React, { useEffect, useState } from 'react';
import { getCountryCallingCode, getCountries } from 'react-phone-number-input';
import en from 'react-phone-number-input/locale/en.json';

import { InputBox } from '.';

/*import style*/
import styles from '../../styles/components/NumberInput.module.scss';

export default function NumberInput(props) {

  const [value, setValue] = useState("");
  const [options, setOptions] = useState([]);

  const handleChange=(val)=>{
    setValue(val.target.value !== null ? val.target.value : value);
    props.onCodeChange(val.target.value !== null ? val.target.value : props.defaultCode);
  }

  useEffect(()=>{
    getList();
    if(props.defaultCode){
        setValue(props.defaultCode);
    }
  },[props.defaultCode]);

  const getList=async()=>{
    const codes = await getCountries();
    const list = en;
    let options=[];
    codes.forEach(code=>{
        let callingCode = getCountryCallingCode(code);
        let option = {value: `+${callingCode}`, label: `${list[code]}(+${callingCode})`};
        options.push(option);
    });
    options.sort((a, b) => {
        let labelA = a.label.toLowerCase(),
            labelB = b.label.toLowerCase();
    
        if (labelA < labelB) {
            return -1;
        }
        if (labelA > labelB) {
            return 1;
        }
        return 0;
    });
    setOptions(options);
  }

  const countrySelector=()=>{
    return(
        <div className={`${styles.e_country_selector} d-flex position-relative`}>
            <span className="d-flex align-items-center">{value}<span className={`d-flex ${styles.e_indicator} ms-1`}></span></span>
            <select className="" id="e-country-select" onChange={handleChange}>
                {
                    options.map((option, i)=>(
                        <option key={`${i}`} value={option.value}>{option.label}</option>
                    ))
                }
            </select>
        </div>
    )
  }

  return (
    <div>
        <InputBox
            suffix={countrySelector()}
            placeholder={props.placeholder}
            label={props.label}
            value={props.value} 
			type={props.type}
            valueChange={(value) =>props.valueChange(value)}
            required={props.required}
            validatePhone={true}
            countryCode={value}
        />
    </div>
  )
}