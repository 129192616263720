/*Package importing*/
import React, { useState, useEffect } from 'react'

/*import style*/
import styles from '../../styles/components/DashboardReport.module.scss'

/* components */
import { HeathIndicatorBadge } from '../badges'
import { Primary, TextButton } from '../cta'
import { SingleSelect } from '../form'
import DonutChart from '../charts/pie'
import LineChart from '../charts/line'
import Loader from '../others/loader'

/* assests */
import Facebook from '../../assets/images/icons/facebook-32.svg'
import Linkedin from '../../assets/images/icons/linkedin-32.svg'
import Twitter from '../../assets/images/icons/twitter-32.svg'
import Instagram from '../../assets/images/icons/Instagram-32.svg'
import Success from '../../assets/images/icons/tick-icon-18.svg'
import Warning from '../../assets/images/icons/warning-icon-18.svg'

/* services */
import services, { getServices } from '../../services/apis/services'
import { Toast } from '../alerts'
import { Router } from 'react-router-dom'
import { withRouter } from 'react-router-dom'

const DashboardW2Report = props => {

    const [selectedWeb2, setSelectedWeb2] = useState({
        value: 1,
        label: 'Last 7 days'
    })
    const [loader, setLoader] = useState(false)
    const [loaderDonut, setloaderDonut] = useState(false)
    
    const [web2Redirectiondata, setWeb2Redirectiondata] = useState([])
    const [web2RedirectionLabel, setWeb2RedirectionLabel] = useState([])
    const [web2Threatdata, setWeb2Threatdata] = useState([])
    const [web2ThreatLabel, setWeb2ThreatLabel] = useState([])

    const [web2Alikedata, setWeb2Alikedata] = useState([])
    const [web2AlikeLabel, setWeb2AlikeLabel] = useState([])

    const [web2TotalEncroachmentsdata, setWeb2TotalEncroachmentsdata] = useState(
        []
    )
    const [web2TotalEncroachmentsLabel, setWeb2TotalEncroachmentsLabel] =
        useState([])

    const [dounutData, setDonutData] = useState([])
    const [error, setError] = useState(null)

    useEffect(() => {
        getWeb2Likes()
        // getWeb2Redirection();
        getWeb2Threats()
        getWeb2TotalEncroachments()
    }, [selectedWeb2])

    /*--- web2 donut data ----*/
    // useEffect(() => {
    //     // if (
    //     //     web2Alikedata.length > 0 &&
    //     //     web2TotalEncroachmentsdata.length > 0 &&
    //     //     web2Redirectiondata.length > 0 &&
    //     //     web2Threatdata.length > 0
    //     // ) {
    //     //     const web2DonutArr = []
    //     //     const web2DataSum = parseInt(
    //     //         web2Alikedata.reduce((a, b) => a + b, 0) +
    //     //         web2TotalEncroachmentsdata.reduce((a, b) => a + b, 0) +
    //     //         web2Redirectiondata.reduce((a, b) => a + b, 0) +
    //     //         web2Threatdata.reduce((a, b) => a + b, 0)
    //     //     )
    //     //     const web2Alikedata =
    //     //         (web2Alikedata.reduce((a, b) => a + b, 0) / web2DataSum) * 100
    //     //     const web2TotalEncroachmentsdata =
    //     //         (web2TotalEncroachmentsdata.reduce((a, b) => a + b, 0) / web2DataSum) * 100
    //     //     const web2RedirectionDonutTempData =
    //     //         (web2Redirectiondata.reduce((a, b) => a + b, 0) / web2DataSum) * 100
    //     //     const web2ThreatDonutTempData =
    //     //         (web2Threatdata.reduce((a, b) => a + b, 0) / web2DataSum) * 100
    //     //     web2DonutArr.push(
    //     //         Math.round((web2Alikedata + Number.EPSILON) * 100) / 100,
    //     //         Math.round((web2TotalEncroachmentsdata + Number.EPSILON) * 100) / 100,
    //     //         Math.round((web2RedirectionDonutTempData + Number.EPSILON) * 100) / 100,
    //     //         Math.round((web2ThreatDonutTempData + Number.EPSILON) * 100) / 100
    //     //     )
    //     //     setDonutData(web2DonutArr)
    //     // }

    //     // setDonutData([web2Alikedata, web2TotalEncroachmentsdata, web2Redirectiondata, web2Threatdata])

    //         let web2DataSum = 0
    //         web2DataSum = parseInt(
    //             web2Alikedata.reduce((a, b) => a + b, 0) +
    //             web2TotalEncroachmentsdata.reduce((a, b) => a + b, 0) +
    //             web2Redirectiondata.reduce((a, b) => a + b, 0) +
    //             web2Threatdata.reduce((a, b) => a + b, 0)
    //         )

    //     // ["Look Alikes", "Total Encroachments", "Provided Redirections", "Resolved Threats"]

    //     let tempData = []
    //     let  web2AlikedataNew = 0
    //     web2AlikedataNew =    (web2Alikedata.reduce((a, b) => a + b, 0) / web2DataSum) * 100
    //     tempData.push(parseInt(Math.floor(web2AlikedataNew)))

    //     let web2TotalEncroachmentsdataNew = 0
    //     web2TotalEncroachmentsdataNew =   (web2TotalEncroachmentsdata.reduce((a, b) => a + b, 0) / web2DataSum) * 100
    //     tempData.push(parseInt(Math.floor(web2TotalEncroachmentsdataNew)))

    //     let web2RedirectionDonutTempDataNew = 0
    //     web2RedirectionDonutTempDataNew = 0
    //     //  (web2Redirectiondata.reduce((a, b) => a + b, 0) / web2DataSum) * 100
    //      tempData.push(parseInt(Math.floor(web2RedirectionDonutTempDataNew)))

    //     let web2ThreatDonutTempDataNew = parseInt(0);
    //     web2ThreatDonutTempDataNew = (web2Threatdata.reduce((a, b) => a + b, 0) / web2DataSum) * 100
    //     tempData.push(parseInt(Math.floor(web2ThreatDonutTempDataNew)))
        
    //     let integerArray = tempData.map(Number);
    //     console.log("tempData")
    //     console.log(tempData)
    //     setDonutData(integerArray);
    //     setloaderDonut(true)
    // }, [web2Alikedata, web2TotalEncroachmentsdata, web2Redirectiondata, web2Threatdata])


    useEffect(() => {
        let web2DataSum = web2Alikedata.reduce((a, b) => a + b, 0) +
            web2TotalEncroachmentsdata.reduce((a, b) => a + b, 0) +
            web2Redirectiondata.reduce((a, b) => a + b, 0) +
            web2Threatdata.reduce((a, b) => a + b, 0);
    
        // Avoid division by 0
        if (web2DataSum === 0) {
            setDonutData([62,37,0,0]);
            return;
        }
    
        let tempData = [];
    
        let web2AlikedataNew = (web2Alikedata.reduce((a, b) => a + b, 0) / web2DataSum) * 100;
        tempData.push(Math.floor(web2AlikedataNew));
    
        let web2TotalEncroachmentsdataNew = (web2TotalEncroachmentsdata.reduce((a, b) => a + b, 0) / web2DataSum) * 100;
        tempData.push(Math.floor(web2TotalEncroachmentsdataNew));
    
        let web2RedirectionDonutTempDataNew = (web2Redirectiondata.reduce((a, b) => a + b, 0) / web2DataSum) * 100;
        tempData.push(Math.floor(web2RedirectionDonutTempDataNew));
    
        let web2ThreatDonutTempDataNew = (web2Threatdata.reduce((a, b) => a + b, 0) / web2DataSum) * 100;
        tempData.push(Math.floor(web2ThreatDonutTempDataNew));
    
    
        let integerArray = tempData.map(Number);
        // setDonutData(integerArray);
        setDonutData([62,37,0,0]);
        setloaderDonut(true);
    }, [web2Alikedata, web2TotalEncroachmentsdata, web2Redirectiondata, web2Threatdata]);
    
    const options = [
        {
            value: 1,
            label: 'Last 7 days'
        },
        {
            value: 2,
            label: 'Last 5 weeks'
        },
        {
            value: 3,
            label: 'Last year'
        }
    ]

    /*-----------------------------------------------------------
         ------------------------web2 card details-----------------
         ---------------------------------------------------------*/

    /*--------- get Web2 Likes ---------------*/
    const getWeb2Likes = () => {
        setLoader(true)
        setWeb2Alikedata([])
        setWeb2AlikeLabel([])
        var url = 'reports/web2/likes/bylast7days'

        if (selectedWeb2.value === 2) {
            var url = 'reports/web2/likes/bylast5weeks'
        } else if (selectedWeb2.value === 3) {
            var url = 'reports/web2/likes/bylast12months'
        }

        let token = localStorage.getItem('web23_token')

        getServices(url, token).then(response => {
            let res = response.data
            if (res.status === 1) {
                var temp_data = []
                var temp_label = []
                res.resData.map(item => {
                    temp_data.push(item.key_value)
                })
                res.resData.map(item => {
                    temp_label.push(item.key_name)
                })
                setWeb2Alikedata(temp_data)
                setWeb2AlikeLabel(temp_label)
                setLoader(false)
            } else {
                setError(res.resData[0].msg)
                setLoader(false)
            }
        })
    }

    /* --------- get web2 redirections -------------- */
    const getWeb2Redirection = () => {
        setLoader(true)
        setWeb2RedirectionLabel([])
        setWeb2Redirectiondata([])
        var url = 'rptdata/web2/redirection/bylast7days'

        if (selectedWeb2.value === 2) {
            var url = 'rptdata/web2/redirection/bylast5weeks'
        } else if (selectedWeb2.value === 3) {
            var url = 'rptdata/web2/redirection/bylast12months'
        }

        var data = {
            token: localStorage.getItem('web23_token')
        }

        services(true, url, data).then(response => {
            let res = response.data

            if (res.status === 1) {
                if (selectedWeb2.value === 1) {
                    var temp_redirection_data = []
                    var temp_redirection_label = []
                    res.resData.map(item => {
                        temp_redirection_data.push(item.value)
                    })
                    res.resData.map(item => {
                        temp_redirection_label.push(item.dayName)
                    })
                    setWeb2Redirectiondata(temp_redirection_data)
                    setWeb2RedirectionLabel(temp_redirection_label)
                } else if (selectedWeb2.value === 3) {
                    var temp_redirection_data = []
                    var temp_redirection_label = []
                    res.resData.map(item => {
                        temp_redirection_data.push(item.value)
                    })
                    res.resData.map(item => {
                        temp_redirection_label.push(item.monthName)
                    })
                    setWeb2Redirectiondata(temp_redirection_data)
                    setWeb2RedirectionLabel(temp_redirection_label)
                } else {
                    var temp_redirection_data = []
                    var temp_redirection_label = []
                    res.resData.map(item => {
                        temp_redirection_data.push(item.value)
                    })
                    res.resData.map(item => {
                        temp_redirection_label.push(item.week)
                    })
                    setWeb2Redirectiondata(temp_redirection_data)
                    setWeb2RedirectionLabel(temp_redirection_label)
                }
                setLoader(false)
            } else {
                setError(res.resData[0].msg)
                setLoader(false)
            }
        })
    }

    /* --------- get web2 threats ---------------- */
    const getWeb2Threats = () => {
        setLoader(true)
        setWeb2ThreatLabel([])
        setWeb2Threatdata([])
        var url = 'reports/web2/threats/bylast7days'

        if (selectedWeb2.value === 2) {
            var url = 'reports/web2/threats/bylast5weeks'
        } else if (selectedWeb2.value === 3) {
            var url = 'reports/web2/threats/bylast12months'
        }

        let token = localStorage.getItem('web23_token')
        getServices(url, token).then(response => {
            let res = response.data

            if (res.status === 1) {
                var temp_threat_data = []
                var temp_threat_label = []
                res.resData.map(item => {
                    temp_threat_data.push(item.key_value)
                })
                res.resData.map(item => {
                    temp_threat_label.push(item.key_name)
                })
                setWeb2Threatdata(temp_threat_data)
                // console.log('web2Threatdata')
                // console.log(web2Threatdata)
                setWeb2ThreatLabel(temp_threat_label)

                setLoader(false)
            } else {
                setError(res.resData[0].msg)
                setLoader(false)
            }
        })
    }

    // TotalEncroachments

    const getWeb2TotalEncroachments = () => {
        setLoader(true)
        setWeb2TotalEncroachmentsLabel([])
        setWeb2TotalEncroachmentsdata([])
        var url = 'reports/web2/encroachements/bylast7days'

        if (selectedWeb2.value === 2) {
            var url = 'reports/web2/encroachements/bylast5weeks'
        } else if (selectedWeb2.value === 3) {
            var url = 'reports/web2/encroachements/bylast12months'
        }

        let token = localStorage.getItem('web23_token')
        getServices(url, token).then(response => {
            let res = response.data

            if (res.status === 1) {
                var temp_TotalEncroachments_data = []
                var temp_TotalEncroachments_label = []
                res.resData.map(item => {
                    temp_TotalEncroachments_data.push(item.key_value)
                })
                res.resData.map(item => {
                    temp_TotalEncroachments_label.push(item.key_name)
                })
                setWeb2TotalEncroachmentsdata(temp_TotalEncroachments_data)
                setWeb2TotalEncroachmentsLabel(temp_TotalEncroachments_label)
                setLoader(false)
            } else {
                setError(res.resData[0].msg)
                setLoader(false)
            }
        })
    }

    return (
        <div>
            <div className={`${styles.e_report_wrapper}`}>
                <>
                    <div className='container-fluid e-px-32 e-py-32 e-border-bottom'>
                        <div className='row'>
                            <div className='col-md-7'>
                                <div className='d-md-flex d-none align-items-center'>
                                    <h3 className='pe-2 mb-0'>{props.title}</h3>
                                    <div
                                        className={`${styles.e_select_wrapper} d-flex align-items-center ms-4`}
                                    >
                                        <span className={styles.e_select_indicator}>Show:</span>
                                        <div className={`${styles.e_select_container} ms-1`}>
                                            <SingleSelect
                                                defaultValue={selectedWeb2}
                                                options={options}
                                                placeholder='This month'
                                                selectchange={temp => setSelectedWeb2(temp)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-5 pt-md-0 pt-2'>
                                <div className='w-100 h-100 d-flex justify-content-end align-items-center'>
                                    <TextButton
                                        name={`Manage ${props.title} Domains`}
                                        className='mx-3'
                                        forward={true}
                                        handleClick={props.redirect}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='container-fluid e-px-32 e-py-32'>
                        <div className='row gy-3'>

                            <div className="col-lg-3">
                                <div className={`${styles.e_charts_container} e-donut-chart`}>
                                    <span className={`${styles.e_indicator_title} pb-3 text-uppercase d-flex justify-content-center`}>{props.title} summary</span>
                                    <>
                                    { 
                                                
                                               loaderDonut &&  dounutData.length >0 ?
                                                    <DonutChart
                                                        data={dounutData}
                                                        labels={["Look Alikes", "Total Encroachments", "Provided Redirections", "Resolved Threats"]}
                                                        colors={['#FF5322', '#1874FF', '#ECE200', '#19BFE3']}
                                                   
                                            
                                                />
                                                :
                                                <></>
                                    }
                                    </>
                                </div>
                            </div>
                            <div className='col-lg-9'>
                                <div className='row gy-4'>
                                    <div className='col-lg-6'>
                                        <div
                                            className={`${styles.e_charts_container} e-line-chart`}
                                        >
                                            {props.type === 1 ? (
                                                loader ? (
                                                    <Loader />
                                                ) : web2TotalEncroachmentsdata.length > 0 &&
                                                    web2TotalEncroachmentsLabel.length > 0 ? (
                                                    <>
                                                        <p>Total Encroachments</p>
                                                        <span className={styles.e_count_indicator}>
                                                            {web2TotalEncroachmentsdata.reduce(
                                                                (prev, last) => prev + last,
                                                                0
                                                            )}
                                                        </span>
                                                        <LineChart
                                                            color='#1874FF'
                                                            data={[
                                                                {
                                                                    name: 'Encroachments',
                                                                    data: web2TotalEncroachmentsdata
                                                                }
                                                            ]}
                                                            width='400'
                                                            categories={web2TotalEncroachmentsLabel}
                                                        />
                                                    </>
                                                ) : null
                                            ) : null}
                                        </div>
                                    </div>

                                    <div className='col-lg-6'>
                                        <div
                                            className={`${styles.e_charts_container} e-line-chart`}
                                        >
                                            {props.type === 1 ? (
                                                loader ? (
                                                    <Loader />
                                                ) : web2Alikedata.length > 0 &&
                                                    web2AlikeLabel.length > 0 ? (
                                                    <>
                                                        <p>Look Alikes</p>
                                                        <span className={styles.e_count_indicator}>
                                                            {web2Alikedata.reduce(
                                                                (prev, last) => prev + last,
                                                                0
                                                            )}
                                                        </span>
                                                        <LineChart
                                                            color='#1874FF'
                                                            data={[
                                                                {
                                                                    name: 'Alikes',
                                                                    data: web2Alikedata
                                                                }
                                                            ]}
                                                            width='400'
                                                            categories={web2AlikeLabel}
                                                        />
                                                    </>
                                                ) : null
                                            ) : null}
                                        </div>
                                    </div>

                                    <div className='col-lg-6'>
                                        <div
                                            className={`${styles.e_charts_container} e-line-chart`}
                                        >
                                            {props.type === 1 ? (
                                                loader ? (
                                                    <Loader />
                                                ) : web2Threatdata.length > 0 &&
                                                    web2ThreatLabel.length > 0 ? (
                                                    <>
                                                        <p>Resolved Threats</p>
                                                        <span className={styles.e_count_indicator}>
                                                            {web2Threatdata.reduce(
                                                                (prev, last) => prev + last,
                                                                0
                                                            )}
                                                        </span>
                                                        <LineChart
                                                            color='#1874FF'
                                                            data={[
                                                                {
                                                                    name: 'Resolved Threats',
                                                                    data: web2Threatdata
                                                                }
                                                            ]}
                                                            width='400'
                                                            categories={web2ThreatLabel}
                                                        />
                                                    </>
                                                ) : null
                                            ) : null}
                                        </div>
                                    </div>

                                    
                                    <div className='col-lg-6'>
                                        <div
                                            className={`${styles.e_charts_container} e-line-chart`}
                                        >
                                            {props.type === 1 ? (
                                                loader ? (
                                                    <Loader />
                                                ) : web2RedirectionLabel.length > 0 &&
                                                    web2Redirectiondata.length > 0 ? (
                                                    <>
                                                        <p>Provided Redirections</p>
                                                        <span className={styles.e_count_indicator}>
                                                            {web2Redirectiondata.reduce(
                                                                (prev, last) => prev + last,
                                                                0
                                                            )}
                                                        </span>
                                                        <LineChart
                                                            color='#19BFE3'
                                                            data={[
                                                                {
                                                                    name: 'Provided Redirections',
                                                                    data: web2Redirectiondata
                                                                }
                                                            ]}
                                                            width='400'
                                                            categories={web2RedirectionLabel}
                                                        />
                                                    </>
                                                ) : null
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            </div>
        </div>
    )
}

export default withRouter(DashboardW2Report)
