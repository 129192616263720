/*
 *   File : index.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : index file for form components 
 *   Integrations : null
 *   Version : 1.0
 *   Created : 24-06-2021
 */
import SingleSelect from "./select";
import InputBox from "./input";
import CheckBox from "./checkbox";
import NumberInput from "./phoneInput";
import ToggleButton from "./toggleButton";
import FileUpload from "./uploader";

export {SingleSelect, InputBox, CheckBox, NumberInput, ToggleButton, FileUpload} ; 