import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router';

/*import style*/
import styles from '../../styles/components/ManageDomains.module.scss';

import { Secondary } from '../cta';

import { getServices } from "../../services/apis/services";
import { Toast } from "../../components/alerts";

const ManageDomains =(props)=>{
    // console.log("propsssssssssssssss");
    // console.log(props.domain);

    const history = useHistory();
    const [toastMessage, setToastMessage] = useState(null);

    const router =useHistory();

    const navigateToManagement=()=>{
        let link = `/domain/${props.type}/manage?domain=${props.domain}`;
        router.push(link);
    }

    const handleDeleteClick = async () => {
        const isConfirmed = window.confirm(`Are you sure you want to delete the domain "${props.domain}"?`);
    
        if (isConfirmed) {
            try {
                const fetchUrl = "domains/getmaindomain2";
                const token = localStorage.getItem("web23_token");
                const response = await getServices(fetchUrl, token);
                // console.log("Response Data:", response.data.resData.data);
    
                if (response && Array.isArray(response.data.resData.data)) {
                   
                    const domainNames = response.data.resData.data.map(item => item.domain_name);
    
                    // console.log("All Domain Names:", domainNames); 
    
                    const matchingDomain = response.data.resData.data.find(domain => domain.domain_name === props.domain);
                    console.log("matchingDomainnnnnnnn:", matchingDomain);

                    if (matchingDomain) {
                        const deleteUrl = "domains/delete2";
                        const token = localStorage.getItem("web23_token");
                        const deletePayload = {
                            domain_name: matchingDomain.domain_name,  
                            is_active: 0,
                        };
    
                        const deleteResponse = await getServices(deleteUrl, token, deletePayload);
                        console.log("deleteResponsee",deleteResponse);
                        
    
                        if (deleteResponse.data.status === 1 && deleteResponse.data.resData.nModified > 0) {
                            console.log(`Domain ${props.domain} marked as deleted.`);

                            history.push('/domains', { toastMessage: 'Domain successfully deleted!' });

                            //  setToastMessage({ message: 'Domain successfully deleted!', type: 'success' });

                        } else {
                            console.error('Failed to delete domain:', deleteResponse.message);
                        }
                    } else {
                        console.error(`No matching domain found for: ${props.domain}`);
                    }
                } else {
                    console.error('Invalid response or no data found');
                }
            } catch (error) {
                console.error('Error deleting domain:', error);
            }
        }
    };


    return(
        <div className={`${styles.e_wrapper} e-px-32 e-py-32 w-100`}>
            <h3 className="mb-0 pb-4">Manage Domain</h3>
            <div className="row pt-2 gy-3">
                <div className="col-lg-3 col-md-6">
                    <Secondary full_width="true" blue="true" large="true" name={props.type==="web3"? "Manage Domain": "Manage DNS"} handleClick={navigateToManagement}/>
                </div>
                <div className="col-lg-3 col-md-6">
                    <Secondary handleClick={handleDeleteClick}  full_width="true" red="true" large="true" name="Delete Domain"/>
                </div>
            </div>

            {toastMessage && <Toast message={toastMessage.message} type={toastMessage.type} />}
        </div>
    )
}

export default ManageDomains;