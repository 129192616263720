import React, { useEffect, useState } from "react";
import { getServices } from "../../services/apis/services";

import { ReportCards } from '../../components/cards';

import FacebookIcon from '../../assets/images/icons/facebook-30.png';
import InstagramIcon from '../../assets/images/icons/instagram-30.png';
import TwitterIcon from '../../assets/images/icons/twitter-30.png';
// import YoutubeIcon from '../../assets/images/icons/youtube-30.png';
import LinkedinIcon from '../../assets/images/icons/linkedin-30.png';

const SocialMediaReportContainer = () => {
    const [expandedDomainId, setExpandedDomainId] = useState(null); // To track the expanded domain

    const [domainDatasocialMediaReportsData, setDomainDatasocialMediaReportsData] = useState(
        [
            { issues: [], domain_name: "facebook.com", domain: "Facebook", icon: FacebookIcon },
            { issues: [], domain_name: "twitter.com", domain: "Twitter", icon: TwitterIcon },
            { issues: [], domain_name: "instagram.com", domain: "Instagram", icon: InstagramIcon },
            { issues: [], domain_name: "linkedln.com", domain: "LinkedIn", icon: LinkedinIcon },
        ]
    );

    const handleExpand = (domain_name) => {
        setExpandedDomainId((prevDomainId) =>
            prevDomainId === domain_name ? null : domain_name
        );
        fetchReportDetails(domain_name);
    };

    const fetchReportDetails = async (domain_name) => {
        try {
            const url = "reports/getIssueDetailsAllDashboardViewReports2";
            const token = localStorage.getItem("web23_token");

            const response = await getServices(url, token, { domain_name });

            let issuesDataForDomain = response.data.resData;

            let finalIssueData = domainDatasocialMediaReportsData.map(item => {
                if (item.domain_name === domain_name) {
                    return { ...item, issues: issuesDataForDomain };
                }
                return item;
            });

            setDomainDatasocialMediaReportsData(finalIssueData);
        } catch (error) {
            console.error("Error fetching report details:", error);
        }
    };

    return (
        <div className="w-100 e-web-2-report pt-3 pb-5">
            <h3 className="mb-0 pb-3">Social Media Account Reports</h3>
            {
                domainDatasocialMediaReportsData.map((item, i) => {
                    return (
                        <div className="pt-3" key={`web-2-report-${i}`}>
                            <ReportCards
                                data={item}
                                isExpanded={expandedDomainId === item.domain_name}
                                icon={item.icon}
                                socialMedia={true}
                                onExpand={() => handleExpand(item.domain_name)} // Handle expand click
                            />
                        </div>
                    )
                })
            }
        </div>
    );
}

export default SocialMediaReportContainer;
