import { Modal } from 'bootstrap';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import $ from 'jquery';

import { DashboardReportCard, HealthSummaryCard } from '../../components/cards';
import DashboardW2Report from '../../components/cards/dashboardW2Report';
import EditProfileModal from '../../components/modals/editProfileModal';
import services, { getServices } from "../../services/apis/services";

const Dashboard = () => {
    const router = useHistory();
    const [profileData, setProfileData] = useState({});
    const [error, setError] = useState(null);
    const [loader, setLoader] = useState(false);
    const [totals, setTotals] = useState({
        domain2count: 0,
        domain3count: 0,
        social_accounts_count: 0,
        threats_resolved_count: 0,
      });
      const [isLoaded, setIsLoaded] = useState(false);
    useEffect(() => {
        let url = "reports/getAllDashboardViewReports";
        let token = localStorage.getItem("web23_token");
        getServices(url, token).then((response) => {
          try {
            let res = response;
            // console.log("res", res.data.resData);
            if (response.data.status !== 1) {
              console.error("Error from server:", response.data.msg);
              setError(new Error(response.data.msg));
            } else {
              const aggregatedTotals = response.data.resData.data.reduce(
                (acc, item) => {
                  acc.domain2count += item.domain2count || 0;
                  acc.domain3count += item.domain3count || 0;
                  acc.social_accounts_count += item.social_accounts_count || 0;
                  acc.threats_resolved_count += item.threats_resolved_count || 0;
                  return acc;
                },
                {
                  domain2count: 0,
                  domain3count: 0,
                  social_accounts_count: 0,
                  threats_resolved_count: 0,
                }
              );
    
              setTotals(aggregatedTotals);
              setIsLoaded(true);
            }
          } catch (error) {
            console.log("error", error);
          }
        });
      }, []);
    return (
        <div className="container e-dashboard-page pt-4 pb-5">
            <div className="row pt-3">
				
                <div className="col-lg-12">
                    <HealthSummaryCard redirect={() => router.push('/reports')} />
                </div>
                <div className="col-lg-12 pt-5">
                    <DashboardW2Report title="Web 2.0" type={1} redirect={() => router.push('/domains/web2')} />
                </div>
                {/* <div className="col-lg-12 pt-5">
                    <DashboardReportCard title="Web 3.0" type={2} redirect={() => router.push('/domains/web3')} />
                </div> */}
                <div className="col-lg-12 pt-5">
                    <DashboardReportCard title="Social Media Accounts" type={3} redirect={() => router.push('/social-media')} />
                </div>
                <div className="col-lg-12 pt-5">
                    <DashboardReportCard title="
                    " type={4} redirect={() => router.push('/domains')} />
                </div>
            </div>
            <EditProfileModal />
        </div>
    )
}

export default Dashboard;