import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import EmptyIllustration from '../../assets/images/empty-search-illustration.svg'
import Wolfalpha from '../../nftimages/wolfalpha.png'
import Tldalpha from '../../nftimages/tldalpha.png'


import SearchTable from '../../components/dataTable/searchTable';
import EncroachmentTable from '../../components/dataTable/encroachmentTable';
import SocailTWTable from '../../components/dataTable/socailTWTable';
import SearchW3Table from '../../components/dataTable/searchW3Table';
import SubscriptionModal from '../../components/modals/subscriptionModal';
import { TextButton } from '../../components/cta';
import SearchBox from '../../components/serachBox/searchBox';
import { TabHeadingOne } from '../../components/tabHeadings';
import { OverviewCard } from '../../components/cards';
import  OverviewLookLikesDomain  from '../../components/cards/overviewLookLikesDomain';
import  OverviewBookCard  from '../../components/cards/overviewBookDomain';
import  OverviewDomainStatus  from '../../components/cards/overviewDomainStatus';
import  OverviewEnroachmentsCard  from '../../components/cards/overviewEnroachmentsDomain';
import  OverviewSocialDomain  from '../../components/cards/overviewSocialDomain';
import  OverviewW3BookCard  from '../../components/cards/overviewW3BookDomain';
/* Components */
import Loader from '../../components/others/loader';
import { Toast } from '../../components/alerts';
/* Services */
import GetURLVar from '../../services/getParams/getUrl';
import services from '../../services/apis/services';
import w3services from '../../services/apis/w3services';
// import jrequest from '../../services/apis/jservices';


import axiosResolver from "../../axiosResolver";
import w2tldList from "../../w2tldList.json";
import blueCircle from '../../assets/images/loader/blueCircle.gif';

const SearchDomain = (props) => {
    const jrequest = require('../../services/apis/jservices');
    const [qdomain, setQDomain] = useState("");
    const [qallinOne, setQallinOne] = useState({});
    const [qtld, setQTld] = useState("");
    const [domains, setDomains] = useState([]);
    const [bookedStatus, setBookedStatus] = useState("-");	// Unknown
    const [bookedDomains, setBookedDomains] = useState([]);
    const [bookedIPDomains, setBookedIPDomains] = useState([]);
    const [bookedTWDomains, setBookedTWDomains] = useState([]);
	const [lookLikes,setLookLikes]=useState("-");
	const [enroachments,setEnroachments]=useState("-");
    const [web3Domains, setWeb3Domains] = useState([]);
    const [searchLoading, setSearchLoading] = useState(false);
    const [query, setQuery] = useState("");
    const [selectedDomain, setSelectedDomain] = useState(null);
    const [selectedPackage, setSelectedPackage] = useState({packageId:"-",packageName:"-"});
    const [error, setError] = useState(null);
    const router = useHistory();
    const [index, setIndex] = useState(1);
	const [selectediTab,setSelectediTab]=useState(2);	// Look alike
	
	const [w3TLD,setW3TLD]=useState("hbar");
	const [availableNFTInfo,setAvailableNFTInfo]=useState({});
	const [subdomain,setSubdomain]=useState("");	// abc.com =>abc
	const [bookedTLDStatus,setBookedTLDStatus]=useState(9);
	const [isRequestForSCEncroachment,setIsRequestForSCEncroachment]=useState(false);

    const [domainstatus, setDomainStatus] = useState("-");

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setError(null);
        }, 3000);
    }, [error]);

    // useEffect(() => {
    //     if (router.location.state.query !== "") {
    //         SearchDomain(router?.location?.state?.query)
    //     }
    // }, [router?.location?.state?.query])

    const getQuery = async () => {
        let search = await GetURLVar();
        if (search && search.query) {
			let tquery=checkSearchingDomain(search.query);
            setQuery(search.query);
            if (props.type === "web2") {
				SearchDomain(tquery.domain);
				SearchDomainWebTLD3(tquery.sld);
            }
        }
    }
    const handleRouteChange = () => {
        if (props.type === "web2") {
            setIndex(1)
        }
        else if (props.type === "web3") {
            setIndex(2)
        }
    }

    useEffect(() => {
        if (props.type)
            handleRouteChange();
        getQuery();
    }, [props.type]);

    const handleTabSwitch = (val) => {

        setIndex(val);
		let tquery=query;
		let tmpq=checkSearchingDomain(tquery);
		//alert(tmpq.domain);
        if (val === 1) {
			
            router.push('/search/web2?query=' + tquery);
            if (tquery !== "") {
                SearchDomain(tmpq.domain);
				SearchDomainWebTLD3(tmpq.sld);
            }
        }
        else if (val === 2) {
			router.push('/search/web3?query=' + tquery)
            if (tquery !== "") {
                SearchDomainWeb3(tquery.w3domain);
				SearchDomainWebTLD3(tmpq.w3sld);
            }
        }

        // clearQuery();
    }

    const searchHandler = () => {
        if (query && query.length) {
			let tquery=checkSearchingDomain(query);
			
			if (index === 1 && query !== "") {
				
                router.push({
                    pathname: '/search/web2',
                    search: `?query=${query}`
                });
                SearchDomain(tquery.domain); // API search web2
				SearchDomainWebTLD3(tquery.w3sld);
            }
            else if (index === 2 && query !== "") {
				
                router.push({
                    pathname: '/search/web3',
                    search: `?query=${query}`
                });
                SearchDomainWeb3(tquery.w3domain); // API search web3
				SearchDomainWebTLD3(tquery.w3sld);
            }
        }
    }
	const checkSearchingDomain=(query)=>{
		let FQ={domain:'',sld:'',tld:'com',twq:'',w3domain:'',w3sld:'',w3tld:w3TLD};
		let tmpquery=query;
		let twq=query;
		let dts=query.split(",");
		if(dts.length>=2){
				tmpquery=dts[0];
				twq=dts[1];
		}
		let tlds=tmpquery.split(".");
		if(tlds.length===3){
			FQ.domain=tmpquery;
			FQ.sld=tlds[0];
			FQ.tld=tlds[1]+"."+tlds[2];
			FQ.w3domain=tmpquery;
			FQ.w3sld=tlds[0];
			FQ.w3tld=tlds[1]+"."+tlds[2];
			FQ.twq=tlds[0];
			
		}
		else if(tlds.length===2){
			FQ.domain=tmpquery;
			FQ.sld=tlds[0];
			FQ.tld=tlds[1];
			FQ.w3domain=tmpquery;
			FQ.w3sld=tlds[0];
			FQ.w3tld=tlds[1];
			FQ.twq=tlds[0];
			
		}
		else if(tlds.length===1){
			FQ.domain=tmpquery+".com";
			FQ.sld=tmpquery;
			FQ.tld="com";
			FQ.w3domain=tmpquery+"."+w3TLD;
			FQ.w3sld=tmpquery;
			FQ.w3tld=w3TLD;
			FQ.twq=tmpquery;
		}
		// console.log(FQ);
		setQDomain(FQ.domain);
		setQallinOne(FQ);
		return(FQ);
		
		/*let type=1;
		if(tmpquery===undefined || tmpquery===null ) return;
		let tlds=tmpquery.split(".");
		let l=tlds.length;  
		if(l===1) {tmpquery=tmpquery+".com";l++;tlds=tmpquery.split(".");}
		let TLD=tlds[l-1]; 
		let SLD=tlds[l-2];
		if(dts.length<=1){
				twq=SLD;
		}
		if(w2tldList.indexOf(TLD)>=0){
			// W2 Domains Confirmed
			type=1;
		}
		else {
			TLD=w3TLD;
			tmpquery=SLD+"."+TLD;
			type=2;
		}
		//setQuery(tmpquery);
		setQDomain(tmpquery);
		setQTld(TLD);
		setQallinOne({type:type,domain:tmpquery,sld:SLD,tld:TLD,twq:twq});
		//console.log({type:type,domain:tmpquery,sld:SLD,tld:TLD,twq:twq})
		
		return({type:type,domain:tmpquery,sld:SLD,tld:TLD,twq:twq});*/
	}
    /*--- search domain api integration ---*/
    const SearchDomain = (query) => {
		setBookedTWDomains([]);
		let sdl=query.split(".");
		setSubdomain(sdl[0]);
        setDomains([]);
        setBookedDomains([]);
		setLookLikes("-");
		setEnroachments("-");
		setBookedStatus("-");
        setSearchLoading(true);

        setDomainStatus("-");

        let url = 'gd/domain/search';
        let data = {
            domainName: query
        };
		// avlDomainNFT(sdl[0]+"."+w3TLD);
        services(true, url, data).then((response) => {
            let res = response.data
            // console.log("res search domain");
            // console.log(res);
            // console.log(query);


            if (res.status === 1) {
                
                setBookedStatus(res.resData.available === true ? "Available" : "Booked");
                setSearchLoading(false);
				if(res.resData===null || res.resData.domains===null || res.resData.domains.length===0) return;
				// setBookedStatus(res.resData.domains[0].available===false?"Booked":"Available");
                
                // setDomainStatus(res.resData.domain_availableOrNot===true?"Booked":"Available");

                // var temp_domain = [...res.resData.domains];
                var tempAvailable = res.resData.domains.filter((item) => item.available === true);
                var tempBooked = res.resData.domains.filter((item) => item.available === false);
                setDomains(tempAvailable);
                setBookedDomains(tempBooked);
                setLookLikes(tempAvailable.length);
                setEnroachments(tempBooked.length);
                setTimeout(() => {
                    setSearchLoading(false);
                }, 1000);
            } else if (res.status === -1 || res.status === 0 || res.status === -9) {
                setQuery("");
                setWeb3Domains([]);
                setError(res?.msg);
                setSearchLoading(false);
               
            } else {
                setQuery("");
                setWeb3Domains([]);
                setError(res?.resData[0]?.msg);
                setError(res?.msg);
                setSearchLoading(false);
                
            }
        });
    }

    /*--- search domain web3 api integration ---*/
    const SearchDomainWeb3 = async(query) => {
		let _DOMAINNAME=query;
		let sdl=query.split(".");
		setQDomain(query);
        setDomains([]);
        setSearchLoading(true);
		let jparam ="token="+process.env.REACT_APP_W3LocalToken+"&itemName="+sdl[1];
		let jres=await jrequest.getPostService(process.env.REACT_APP_W3_MODE+"/api/0x3876129856327956185215896",jparam);
		if(jres!=null && jres.status===1) { 
			alert("No TLD Exist");
			setSearchLoading(false);
			return;
		}
		else if(jres!=null && jres.status===0) { 
			
		}
		else {
			alert("TLD Exception");
			setSearchLoading(false);
			return;
		}
		
		
		let url = "/api/0x9874832u3dfj38942jnxjh9872380"; 
		let data = {
		  token: process.env.REACT_APP_W3LocalToken,
		  domainName:_DOMAINNAME
		};
        w3services(true, url, data).then((response) => {
			if(response.status===200){
				let res = response.data 
				if (res.status === 1) {
					setSearchLoading(false);
					var temp = res.resData;
					setWeb3Domains(temp);
					setTimeout(() => {
						setSearchLoading(false);
					}, 3000);
				} else {
					setDomains([]);
					setError("No data found");
					setSearchLoading(false);
					setQuery("")
				}
			}
        })
    }
	// search TLD
	const SearchDomainWebTLD3 = (query) => {
		let _DOMAINNAME=query;
		if(query===undefined || query===null ) return;
		let url = "/api/0x3876129856327956185215896"; 
		let data = {
		  token: process.env.REACT_APP_W3LocalToken,
		  itemName:_DOMAINNAME
		}
        w3services(true, url, data).then((response) => {
			if(response.status===200){
				let res = response.data 
				if (res.status === 1) {
				   setBookedTLDStatus(0); // available
				} else {
				   setBookedTLDStatus(1); // Booked
				}
			}
        })
    }
	
    const clearQuery = () => {
        setDomains([]);
        setQuery("");
    }
	const mintTLD=()=>{
		paymentTLD();
	}
	// PAYMENT Tld
	const loadScript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }
	 const paymentTLD = async () => {
        let url = 'action/tbds834723048ndsjfh38203/tdnjkd8347238438hsdu7985732';

        const src = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!src) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
        }

        let data = {
            token: localStorage.getItem("web23_token"),
            key: process.env.REACT_APP_KEY,
            td: subdomain,
        }

        services(true, url, data).then((response) => {
            let res = response.data;

            if (res.status === 1) {
                
                let options = res.resData;
                options.handler = function (response) {
                    confirmPaymentTLD(response.razorpay_payment_id);
                }
                const paymentObject = new window.Razorpay(options);
                paymentObject.open();
                paymentObject.on('payment.failed', function (response) {
                setError(response.error.description);
                });

            } else {
                setError(res.msg);
                
            }
        });
    }
	const confirmPaymentTLD=(paymentId)=>{
		let url = "action/dfnabskjfshnkjfndskfhdkjfnskjns"; 
		let data = {
		  token: process.env.REACT_APP_W3LocalToken,
		  tld:subdomain,
		  txn: paymentId
		};
		services(true, url, data).then((response) => {
			if(response.status===200){
				let res = response.data
				if(res.status===1){
					alert("Purchased");
					setBookedTLDStatus(1);
				}else{
					alert("Something went wrong");
				}
			}
		});
	}
	// Check w3 domain.bcom  exist or not
	// const avlDomainNFT=(_DOMAINNAME)=>{
	// 	let url = "/api/0x8764894720194876485732958";
	// 	let data = {
	// 	  token: process.env.REACT_APP_W3LocalToken,
	// 	  domainName:_DOMAINNAME
	// 	}
	// 	w3services(true, url, data).then((response) => {
	// 		if(response.status===200){
	// 			let res = response.data
	// 			if (res.status === 1) {
	// 				let rec=res.resData;
	// 				setAvailableNFTInfo(rec);
	// 			}
	// 			else if (res.status === 0) {
	// 			   setAvailableNFTInfo({});
	// 			}else {
	// 			   setAvailableNFTInfo({});
	// 			}
	// 		}
    //     });
	// }
	// Mint W3 Domain
	// const mintW3Domain=()=>{
	// 	 paymentMintW3Domain();
	// }
	//  const paymentMintW3Domain = async () => {
    //     let url = 'action/ntbds834723048ndsjfh38203/tdnjkd8347238438hsdu7985732';

    //     const src = await loadScript(
    //         "https://checkout.razorpay.com/v1/checkout.js"
    //     );

    //     if (!src) {
    //         alert("Razorpay SDK failed to load. Are you online?");
    //         return;
    //     }

    //     let data = {
    //         token: localStorage.getItem("web23_token"),
    //         key: process.env.REACT_APP_KEY,
    //         td: subdomain+"."+w3TLD,
    //     }

    //     services(true, url, data).then((response) => {
    //         let res = response.data;

    //         if (res.status === 1) {
                
    //             let options = res.resData;
    //             options.handler = function (response) {
    //                 confirmPaymentMintW3Domain(response.razorpay_payment_id);
    //             }
    //             const paymentObject = new window.Razorpay(options);
    //             paymentObject.open();
    //             paymentObject.on('payment.failed', function (response) {
    //             setError(response.error.description);
    //             });

    //         } else {
    //             setError(res.msg);
                
    //         }
    //     });
    // }
	// const confirmPaymentMintW3Domain=(paymentId)=>{
	// 	let url = "action/ntdfnabskjfshnkjfndskfhdkjfnskjns"; 
	// 	let data = {
	// 	  token: process.env.REACT_APP_W3LocalToken,
	// 	  item:subdomain+"."+w3TLD,
	// 	  txn: paymentId
	// 	};
	// 	services(true, url, data).then((response) => {
    //         let res = response.data
	// 		if(res.status===1){
	// 			alert("Purchased");
	// 			setAvailableNFTInfo({});
	// 		}else{
	// 			alert("Something went wrong");
	// 		}
	// 	});
	// }
	
	const setSelectedPackageData=(data)=>{
		// console.log("-----------------------");
		// console.log(data);
		setSelectedPackage(data);
	};
    return (
        <>
            <div className="container-fluid position-relative e-search-page-container pt-3">
                <div className="e-gradient-bg position-absolute"> </div>
                <div className="container position-relative mt-3">
                    <h3 className="mb-0 text-center text-light">Search Domain</h3>
                    <div className="position-absolute e-back-btn-wrapper">
                        <TextButton
                            name="Back"
                            back={true}
                            handleClick={() => {
                                router.goBack()
                            }}
							white={true}
                        />
                    </div>
                    <div className="row pt-4 pb-3 justify-content-center">
                        <div className="col-lg-7 col-md-10">
							<p className='text-white'>
							Eg: domain name , twitter account name
							</p>
                            <SearchBox
                                onChange={(val) => {
                                    setQuery(val)
                                }}
                                value={query}
                                onSearch={() => {
                                    searchHandler()
                                }}
                            />
                        </div>
                    </div>

                    {/* tab header */}
                    {/* <TabHeadingOne className="py-3"
                        tabs={["Web 2.0", "Web 3.0"]}
                        activeTab={index}
                        setActive={(index) => handleTabSwitch(index)} /> */}

                    {
                        index === 1 ?
                            <div className={domains && domains.length ? "pt-3 e-pb-248" : "pt-3 pb-5"}>
                                <div className="e-white-bg-wrapper">
                                    {
                                        searchLoading || domains?.length < 0 ?
                                            <div className="w-100 e-py-60 d-flex flex-column align-items-center position-relative">
                                                <Loader type="search" />
                                                <h5 className="pe-4 mt-3">Searching<span className="e-append-dots"></span></h5>
                                                <div className={searchLoading ? "w-100 e-py-60 d-flex flex-column align-items-center position-absolute e-empty-search-illustration-wrapper" : "w-100 e-py-60 d-flex flex-column align-items-center position-absolute e-empty-search-illustration-wrapper e-illustration-active"}>
                                                    <img src={EmptyIllustration} alt="" className="mb-4 e-empty-illustration" />
                                                    <h5>Search your favorite domains</h5>
                                                </div>
                                            </div>
                                            :
                                            <>
                                                {
                                                    domains?.length >= 0 ?
													<div className='row'>
                                                        <div className='col-md-8'>
                                                            <h4 className="mb-0 pb-3">Web2 Results</h4>
															<div className="col-md-12 e-border-right pe-lg-5">
																<div className="row gy-3">
																	<div className="col-md-4">
																		<OverviewBookCard domain={qdomain} type={bookedStatus} value={bookedStatus} setITab={setSelectediTab} DomainStatus={setDomainStatus} />
																	</div>
																	<div className="col-md-4">
																		<OverviewEnroachmentsCard value={enroachments} domains={bookedDomains} setITab={setSelectediTab} setBookedIPs={setBookedIPDomains} />
																	</div>
																	<div className="col-md-4">
																		<OverviewLookLikesDomain type="look_alike" value={lookLikes} setITab={setSelectediTab}  />
																	</div>
																	
																</div>
																<div className='rounded-3 px-0 pt-3'>
																
																  <button type="button" className="btn btn-warning text-light" data-bs-toggle="modal" data-bs-target="#subscriptionModal">
																	Selected Plan [ {selectedPackage.packageName} ]
																  </button>
																
																</div>
															</div>
															{selectediTab===2 && domains?.length > 0 ?
                                                            <><SearchTable
                                                                className="pt-3"
                                                                data={domains}
                                                                setDomain={setSelectedDomain}
                                                                type={props.type}
                                                            /></>
															:null
															}
															{selectediTab===3 && bookedDomains?.length > 0 ?
                                                            <>
																<EncroachmentTable
                                                                className="pt-3"
                                                                data={bookedIPDomains}
                                                               />
															
															</>
															:null
															}
															{selectediTab===4 && bookedTWDomains?.length > 0 ?
                                                            <>
															{isRequestForSCEncroachment===false &&
																<>
																<SocailTWTable
                                                                className="pt-3"
                                                                data={bookedTWDomains}
                                                               />
															   </>
															}
															{isRequestForSCEncroachment===true && 
																<div className='text-center py-4'>  
																	<img src={blueCircle} style={{width:"48px"}}/>
																	<div className='h5 text-center'>Wait....</div>
																</div>
															}
															</>
															:null
															}
                                                        </div>
														<div className='col-md-4'>
															<h4 className="mb-0 pb-3">Social Media</h4>
															<div className='row'>
																<div className="col-md-12">
																	<OverviewSocialDomain value={enroachments} domain={qallinOne.twq} setITab={setSelectediTab} setBookedTws={setBookedTWDomains} setTableReqStatus={setIsRequestForSCEncroachment} />
																</div> 
                                                                {/* <div className="col-md-6">
                                                                    <OverviewDomainStatus domain={qdomain} type={bookedStatus} value={bookedStatus} setITab={setSelectediTab} DomainStatus={domainstatus} />
                                                                </div> */}
															</div>
															{bookedTWDomains && bookedTWDomains.length>=1 && 
															<a href={process.env.REACT_APP_PRO_MODE + "/action/w2report?domain="+qallinOne.domain} target="_blank" className='btn btn-primary mt-4 text-light'>
															<i className="bi bi-download"></i> Download Report
															</a>
															}
															{/* <h4 className="mt-4 py-4 mb-0 pb-3">Web3 Results</h4> */}
															
																<>
																	<div className='card mt-4'>
																	  <img src={Tldalpha} className="w-100" />
																	  <div className="position-absolute bottom-0 start-0 w-100 text-success fs-5 text-center py-2">
																		{"."+subdomain}
																	  </div>
																	</div>
																	<div className='card-body'>
																		<div className='fs-4 text-center' >{"."+subdomain}</div>
																			{  bookedTLDStatus===0 && 
																				<div className='fs-6 text-center mt-2'><span className='btn btn-primary' role='button' onClick={(e)=>mintTLD()} >Mint TLD Hedera NFT</span></div>
																			}
																			{  bookedTLDStatus===1 && 
																				<div className='fs-6 text-center mt-2'><span className='h4 text-secondary'>Web3 TLD not available</span></div>
																			}
																	</div>
																</>
															 
															 <div className='mb-4'>&nbsp;</div>
															 
																<>
																	<div className='card'>
																	  <img src={Wolfalpha} className="w-100" />
																	  <div className="position-absolute bottom-0 start-0 w-100 text-light fs-5 text-center py-2">
																		{subdomain+"."+w3TLD}
																	  </div>
																	</div>
																	{(availableNFTInfo.price!=null && parseFloat(availableNFTInfo.price)>0) &&
																	<div className='card-body'>
																		<div className='fs-4 text-center' >{subdomain+"."+w3TLD}</div>
																		{/* <div className='fs-6 text-center mt-2'><span className='btn btn-primary' role='button' onClick={(e)=>mintW3Domain()}>Mint Domain Hedera NFT</span></div> */}
																	</div>
																	}
																	{ (availableNFTInfo.price===null || availableNFTInfo.price===undefined ) &&
																		<>
																		{/* <div className='fs-6 text-center py-4 mt-2'><span className='h4 text-secondary'>Web3 Domain not available</span></div> */}
																		</>
																	}
																</>
															 
															 
														 </div>
													</div>	
                                                        :
                                                        <div className="w-100 e-py-60 d-flex flex-column align-items-center position-relative">
                                                            <img src={EmptyIllustration} alt="" className="mb-4 e-empty-illustration" />
                                                            <h5>Search your favorite domains</h5>
                                                        </div>
                                                }
                                            </>
                                    }
                                </div>
                            </div>
                            :
							<div className={web3Domains && web3Domains?.length ? "pt-3 e-pb-248" : "pt-3 pb-5"}>
                                <div className="e-white-bg-wrapper">
                                    {
                                        searchLoading || web3Domains?.length < 0 ?
                                            <div className="w-100 e-py-60 d-flex flex-column align-items-center position-relative">
                                                <Loader type="search" />
                                                <h5 className="pe-4 mt-3">Searching<span className="e-append-dots"></span></h5>
                                                <div className={searchLoading ? "w-100 e-py-60 d-flex flex-column align-items-center position-absolute e-empty-search-illustration-wrapper" : "w-100 e-py-60 d-flex flex-column align-items-center position-absolute e-empty-search-illustration-wrapper e-illustration-active"}>
                                                    <img src={EmptyIllustration} alt="" className="mb-4 e-empty-illustration" />
                                                    <h5>Search your favorite domains</h5>

                                                </div>
                                            </div>
                                            :
                                            <>
                                                {
                                                    web3Domains?.length > 0 ?
                                                        <>
                                                            <h4 className="mb-0 pb-3">Web3 Search Results</h4>
															<div className="col-md-12 e-border-right pe-lg-5">
																<div className="row gy-3">
																	<div className="col-md-4">
																		<OverviewW3BookCard domain={qdomain} type={bookedStatus} value={bookedStatus} setITab={setSelectediTab} />
																	</div>
																	
																	
																</div>
															</div>
                                                            <SearchW3Table
                                                                className="pt-3"
                                                                data={web3Domains}
                                                                setDomain={setSelectedDomain}
                                                            />
                                                        </>
                                                        :
                                                        <div className="w-100 e-py-60 d-flex flex-column align-items-center position-relative">
                                                            <img src={EmptyIllustration} alt="" className="mb-4 e-empty-illustration" />
                                                            <h5>Search your favorite domains</h5>
                                                        </div>
                                                }
                                            </>
                                    }
                                </div>
                            </div>
                    }

                </div>
                {
                    error !== null ?
                        <Toast data={error} type="fail" />
                        :
                        null
                }
            </div>
            <SubscriptionModal
				updatePackage={(data)=>setSelectedPackageData(data)} 
                domain={selectedDomain}
                type={props.type}
            />
        </>
    )
}

export default SearchDomain;
