/*--------------
Properties      default     desc

type            --          possible type: ["facebook", "instagram", "linkedin", "twitter", "youtube"]
issues          0           0: healthy, >0: issues present will be displayed
not_connected   false       set to true to show disconnected cards
---------------*/

/*Package importing*/
import React from 'react';

/*Component Import*/
import { Primary } from '../cta';

/*import style*/
import styles from '../../styles/components/SocialMediaIndicator.module.scss';

const SocialMediaIndicator = (props) => {
    return (
        <div>
            <div className={props.not_connected ? `${styles.e_card_wrapper} ${styles.e_not_connected} w-100` : `${styles.e_card_wrapper} w-100`}>
                <div className="d-flex">
                    <div className={
                        props.type == "Facebook" ?
                            `${styles.e_fb_icon} ${styles.e_media_icon}`
                            : props.type == "Instagram" ?
                                `${styles.e_ig_icon} ${styles.e_media_icon}`
                                : props.type == "Twitter" ?
                                    `${styles.e_tw_icon} ${styles.e_media_icon}`
                                    : props.type == "LinkedIn" ?
                                        `${styles.e_ln_icon} ${styles.e_media_icon}`
                                        : props.type == "Youtube" ?
                                            `${styles.e_yt_icon} ${styles.e_media_icon}`
                                            :
                                            `${styles.e_media_icon}`
                    }></div>
                    <div className="d-flex flex-column ps-2">
                        <h5 className="mb-2 d-flex align-items-center">
                            {
                                props.type == "Facebook" ?
                                    `Facebook`
                                    : props.type == "Instagram" ?
                                        `Instagram`
                                        : props.type == "Twitter" ?
                                            `Twitter`
                                            : props.type == "LinkedIn" ?
                                                `LinkedIn`
                                                : props.type == "Youtube" ?
                                                    `Youtube`
                                                    :
                                                    props.type
                            }
                            {
                                props.not_connected ?
                                    <></>
                                    :
                                    <span className={props.issues ? `${styles.e_icon} ${styles.e_unhealthy} ms-1` : `${styles.e_icon} ${styles.e_healthy} ms-1`}></span>
                            }
                        </h5>
                        {
                            props.not_connected ?
                                <Primary name="Connect" small={true} />
                                :
                                <span className={styles.e_value}>{props.issues ? props.issues : 0}</span>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SocialMediaIndicator;