import React, { useContext } from 'react';

import { TooltipHandlerContext } from '../../context/tooltipHandlerContext';

/*import style*/
import styles from '../../styles/components/Tooltip.module.scss';

const Tooltip = ()=>{
    // const [setShowTooltip, setTitle, setPosition, tooltipLoading, showTooltip, title, position] = useContext(TooltipHandlerContext)

    const [showTooltip, title, position] = useContext(TooltipHandlerContext)

    return(
        <div 
        style={{
            top: position.top?  position.top : "unset",
            bottom: position.bottom?  position.bottom : "unset",
            left: position.left?  position.left : "unset",
            right: position.right?  position.right : "unset"
        }}
        className={showTooltip? 
        `${styles.e_tooltip_wrapper} ${styles.e_tooltip_show} position-fixed`
        : `${styles.e_tooltip_wrapper} position-fixed`}>
            {title}
        </div>
    )
}

export default Tooltip;