/*Package importing*/
import React from 'react';
import Select from 'react-select';

export default class SingleSelect extends React.Component {
  constructor(props){
    super(props);
    this.state = {

    }
  }

  componentDidMount(){
    this.setState({ selectedValue: this.props.defaultValue});
   }

  selectChange = selectedValue => {
    this.setState({ selectedValue });
    this.props.selectchange(selectedValue)
  };


  render() {
    return (
      <Select
        isSearchable={this.props.isSearchable? true: false}
        isDisabled={this.props.isDisabled}
        options={this.props.options}
        value={this.props.value? this.props.value: this.state.selectedValue}
        onChange={this.selectChange}
        isMulti={false}
        placeholder={this.props.placeholder}
        defaultValue={this.props.defaultValue}
        isSearchable={this.props.isSearchable ? this.props.isSearchable : false}
        {...this.props}
        styles={{
          singleValue: (provided) => ({
            ...provided,
            color: '#00000',
          }),
          menu: (provided) => ({
            ...provided,
            backgroundColor: '#fff',
            color: '#000',
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? '#D3D3D3' : '#fff',
            color: '#00000',
          }),
        }}
        // defaultMenuIsOpen={true}
      />
    );
  }
}

