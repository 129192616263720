import React, { useEffect, useState } from 'react';

import { TextButton } from '../cta';

import EmptyIllustration from '../../assets/images/notifications-empty-illustration.svg';

/*import style*/
import styles from '../../styles/components/NotificationDropdown.module.scss';

const sample = {
    "unread": [
        {
            "title": "Upgrade Offer",
            "message": "Upgrade to Pro Subscription and get 2nd year free"
        },
        {
            "title": "Renewal Reminder",
            "message": "sarahjames.com needs to be renewed. Turn on auto renew to renew it automatically."
        }
    ],
    "past": [
        {
            "title": "Renew Successfull",
            "message": "sarahjames.com needs to be renewed. Turn on auto renew to renew it automatically."
        },
        {
            "title": "Renew Reminder",
            "message": "sarahjames.com will expire. Renew it now to avoid domain cancellation."
        }
    ]
}

const NotificationDropdown = (props) => {
    const [isActive, setmenuActive] = useState(false);
    const [notifications, setNotifications] = useState(null);

    useEffect(() => {
        let temp = { ...sample };
        setNotifications(temp);
    }, []);

    return (
        <div>
            <div className={`${styles.e_wrapper} position-relative e-notification-dropdown-wrapper`}>
                <div className={`${styles.e_container} position-relative me-1`} onClick={() => setmenuActive(!isActive)}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 22C10.8954 22 10 21.1046 10 20H14C14 21.1046 13.1046 22 12 22ZM20 19H4V17L6 16V10.5C6 7.038 7.421 4.793 10 4.18V2H14V4.18C16.579 4.792 18 7.036 18 10.5V16L20 17V19ZM12 5.75C10.7797 5.6712 9.60278 6.21728 8.875 7.2C8.25255 8.18456 7.94714 9.33638 8 10.5V17H16V10.5C16.0528 9.33639 15.7474 8.18458 15.125 7.2C14.3972 6.21728 13.2203 5.6712 12 5.75Z" fill="#11142F" />
                    </svg>
                    {/* <span className={notifications && notifications.unread && notifications.unread.length ? 'd-flex e-icon-indicator-bubble e-indicator-bubble-active position-absolute' : 'd-flex  e-icon-indicator-bubble position-absolute'}></span> */}
                </div>
                <div className={`${isActive ? `${styles.e_menu_wrapper} ${styles.e_menu_active}` : styles.e_menu_wrapper} position-absolute`}>
                    <div className={`${styles.e_menu_container} mt-1 d-flex flex-column`}>
                        <div className="px-3 pt-3">
                            <div className={`${styles.e_header} pb-3 w-100 d-flex align-items-start justify-content-between`}>
                                <h4 className="mb-0 pt-2">Notifications</h4>
                                {notifications ? <TextButton name="Clear" blue={true} small={true} handleClick={() => setNotifications(null)} /> : null}
                            </div>
                        </div>
                        {
                            notifications ?
                                <>
                                    {
                                        notifications.unread ?
                                            <div className="px-3 pt-3">
                                                <div className={`${styles.e_unread}`}>
                                                    <h5 className="mb-3">UNREAD</h5>
                                                    {
                                                        notifications.unread.map((item, i) => (
                                                            <div key={`notification-unread-title-${i}`}>
                                                                <h4 className="mb-1">{item.title}</h4>
                                                                <p className="mb-3">{item.message}</p>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                            :
                                            null
                                    }
                                    {
                                        notifications.past ?
                                            <div className={`${styles.e_past} p-3`}>
                                                <h5 className="mb-3">PAST</h5>
                                                {
                                                    notifications.past.map((item, i) => (
                                                        <div key={`notification-past-title-${i}`}>
                                                            <h4 className="mb-1">{item.title}</h4>
                                                            <p className="mb-3">{item.message}</p>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                            :
                                            null
                                    }
                                </>
                                :
                                <div className="p-3 d-flex flex-column align-items-center justify-content-center">
                                    <img src={EmptyIllustration} alt="Empty Illustration" className={styles.e_empty_illustration} />
                                    <h4 className="mb-1 text-center">No notifications yet</h4>
                                    <p className="mb-0 text-center">Your notifications will show up here when you have a new one.</p>
                                </div>
                        }
                    </div>
                </div>
            </div>
            {
                isActive ?
                    <div className={styles.e_backdrop} onClick={() => setmenuActive(false)}></div>
                    :
                    null
            }
        </div>
    )
}

export default NotificationDropdown;