import React, { createContext, useEffect, useState } from 'react';

export const TooltipHandlerContext = createContext();

const defaultPosition = {top: "unset", left: "unset", right: '0px', bottom: '0px'};

export const HandlerDataProvider = props =>{
    const [showTooltip, setShowTooltip] = useState(false);
    const [title, setTitle] = useState("");
    const [position, setPosition] = useState({...defaultPosition});
    const [tooltipLoading, setTooltipLoading] = useState(false);

    useEffect(()=>{
        if(!showTooltip){
            setTooltipLoading(true);
            setTimeout(() => {
                setPosition({...defaultPosition});
                setTooltipLoading(false);
            }, 350);
        }
    },[showTooltip]);

    return(
        <TooltipHandlerContext.Provider value={[setShowTooltip, setTitle, setPosition, tooltipLoading, showTooltip, title, position]}>
            {props.children}
        </TooltipHandlerContext.Provider>
    )
}