import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

import { TabHeadingOne } from "../../components/tabHeadings";

import WebThreeTemplates from "./webThreeTemplates";
import WebTwoTemplates from "./webTwoTemplates";

const sampleWeb2 = ["coupons.sarahjames.com", "coupons.sarah.in", "coupons.sarahjames.page", "coupons.sarahjames.xyz", "coupons.sarahjames.co.in"];
const sampleWeb3 = ["coupons.sarahjames.eth", "coupons.sarah.coin", "coupons.sarahjames.x", "coupons.sarahjames.wallet"]

const TemplatesContainer=(props)=>{
    const [activeTab, setActiveTab] = useState(1);
    const [data, setData] = useState(null);

    const router = useHistory();

    const handleRouteChange=()=>{
        if(props.type==="web2") setActiveTab(1);
        else if(props.type==="web3") setActiveTab(2);
        else router.replace('/templates/web2');
    }

    const handleTabSwitch=(val)=>{
        if(val===1) router.push('/templates/web2');
        else if(val===2) router.push('/templates/web3');
    }

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    useEffect(()=>{
        if(props.type) handleRouteChange();
    },[props.type]);

    useEffect(()=>{
        if(activeTab===1) setData(sampleWeb2);
        else setData(sampleWeb3);
    },[activeTab]);

    const renderSelectedTab=()=>{
        let selectedTab = activeTab;
        let out = null;
        switch (selectedTab) {
            case 1:{
                out = <WebTwoTemplates/>;
                break;
            }
            case 2:{
                out = <WebThreeTemplates/>;
                break;
            }
        }
        return out;
    }

    return(
        <div className="container-fluid position-relative e-templates-page-container pt-3">
            <div className="e-gradient-bg position-absolute"> </div>
            <div className="container position-relative py-3">
                <TabHeadingOne tabs={["Web 2.0", "Web 3.0"]} activeTab={activeTab} setActive={handleTabSwitch}/>
                <div className="py-5">
                    <h3 className="mb-0 pb-4">Owned Domains & Sub Domains</h3>
                    {renderSelectedTab()}
                </div>
            </div>
        </div>
    )
}

export default TemplatesContainer;