import React, { useContext, useEffect, useState } from 'react';
import { Pagination } from 'antd';

import { CartContext } from '../../context/cartContext';
import services from '../../services/apis/services';
import Loader from '../others/loader';

import { Primary } from '../cta';
import { Toast } from '../alerts';

const SearchTable = (props) => {
    const [cartData, setCartData] = useContext(CartContext);
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(null);
    const [cartItem, setCartItem] = useState("");

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);

    const data = props.data || [];

    useEffect(() => {
        setTimeout(() => {
            setError(null);
        }, 3000);
    }, [error]);

    const addItemToCart = (item) => {
        setCartItem(item.domain);
        setLoader(true);

        let url = 'action/order/add/item';

        var params = {
            itemName: item.domain,
            orderType: props.type === "web2" ? "Web2" : "Web3",
            token: localStorage.getItem("web23_token")
        };

        services(true, url, params).then((response) => {
            let res = response.data;

            if (res.status === 1) {
                let items = [];
                items.push(res.resData);
                setCartData(items);
                setLoader(false);
            } else {
                setError(res.resData[0].msg);
                setLoader(false);
            }
        });
    }

    const handlePageChange = (page, pageSize) => {
        setCurrentPage(page);
        setPageSize(pageSize);
    };

    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const paginatedData = data.slice(startIndex, endIndex);

    return (
        <div className='mt-4 py-4' style={styles.pageContainer}>
            <div style={styles.tableWrapper}>
                <div className="">
                    <table className="table table-hover table-striped table-bordered table-box-sha">
                        <thead>
                            <tr>
                                <td width='60px'><h6>Sr. No.</h6></td>
                                <td width='320px'><h6>Domain Name</h6></td>
                                <td><h6 className="mb-0">Price</h6></td>
                                <td><h6 className="mb-0"></h6></td>
                            </tr>
                        </thead>
                        <tbody>
                            {loader ? (
                                <tr>
                                    <td colSpan="4" className="text-center">
                                        <Loader />
                                    </td>
                                </tr>
                            ) : paginatedData.length > 0 ? (
                                paginatedData.map((item, i) => (
                                    <tr key={`search-result-row-${i}`}>
                                        <td>{startIndex + i + 1}</td>
                                        <td className="text-break">
                                            <div className="w-100 d-flex flex-column">
                                                <div className="fs-4 mb-2 text-lowercase text-break">{item.domain}</div>
                                                {item.badge ? (
                                                    <span style={styles.badge} className="text-capitalize">
                                                        {item.badge}
                                                    </span>
                                                ) : null}
                                            </div>
                                        </td>
                                        <td className="r899">
                                            <span className="d-flex h-100">
                                                <span className="fs-6 mb-0 ps-1">{`at ${item.currency} ${item.price}`}</span>
                                            </span>
                                        </td>
                                        <td className="r899">
                                            <span className="d-flex h-100">
                                                <a role='button' className="badge bg-primary text-light" onClick={() => addItemToCart({ ...item })}>Add to Cart</a>
                                            </span>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <Loader />
                                    <td colSpan="4" className="text-center">No Data Available</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
            <div style={styles.paginationWrapper}>
                {data.length > pageSize && (
                    <div style={styles.customPagination}>
                        <button 
                            disabled={currentPage === 1}
                            onClick={() => handlePageChange(currentPage - 1, pageSize)}
                            style={styles.paginationButton}
                        >
                            Previous
                        </button>
                        <span style={styles.paginationInfo}>
                            Page {currentPage} of {Math.ceil(data.length / pageSize)}
                        </span>
                        <button
                            disabled={currentPage === Math.ceil(data.length / pageSize)}
                            onClick={() => handlePageChange(currentPage + 1, pageSize)}
                            style={styles.paginationButton}
                        >
                            Next
                        </button>
                    </div>
                )}
            </div>
            {error !== null ? <Toast data={error} type="fail" /> : null}
        </div>
    );
}

// Inline CSS styles
const styles = {
    pageContainer: {
        padding: '16px',
        backgroundColor: '#f9f9f9'
    },
    tableWrapper: {
        overflowX: 'auto',
        backgroundColor: '#fff',
        padding: '16px',
        borderRadius: '8px',
        // boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    },
    paginationWrapper: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '20px',
    },
    customPagination: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '20px',
    },
    paginationButton: {
        padding: '6px 12px',
        border: '1px solid #1890ff',
        backgroundColor: '#fff',
        color: '#1890ff',
        borderRadius: '4px',
        cursor: 'pointer',
        outline: 'none',
    },
    paginationInfo: {
        fontSize: '16px',
    },
    badge: {
        display: 'inline-block',
        padding: '2px 8px',
        backgroundColor: '#ff4d4f',
        color: '#fff',
        borderRadius: '4px',
        fontSize: '12px',
        fontWeight: '500',
    }
};

export default SearchTable;
