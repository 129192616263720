import React, { useEffect, useState } from "react";


/*import style*/
import styles from '../../styles/components/ProfileDetailCard.module.scss';

/* assets */
import Icon from '../../assets/images/profile/user-icon.png';

/* components */
import { TextButton } from "../cta";
import EditProfileModal from "../modals/editProfileModal";
import Loader from "../others/loader";
import { Toast } from "../alerts";
/* services */
import services from "../../services/apis/services";
const ProfileDetailCard = (props) => {
    const [showDropdown, setDropdown] = useState(false);
    const [profileData, setProfileData] = useState({});
    const [error, setError] = useState(null);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        if (!props.billing) {
            getProfileDetails();
        } else {
            getBilling();
        }
    }, []);

    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null);
            });
        }
    }, [error]);

    /*------------resend otp api--------*/
    const getProfileDetails = () => {
        setLoader(true);
        let url = 'action/profile/get';

        let data = {
            token: localStorage.getItem("web23_token"),
        };

        services(true, url, data).then((response) => {
            let res = response.data
            if (res.status === 1) {
                setProfileData(res.resData);
                setLoader(false);
            } else {
                setError(res.msg);
                setLoader(false);
            }
        });
    }

    const getBilling = () => {
        setLoader(true);
        let url = 'action/bill/getcontact';

        let data = {
            token: localStorage.getItem("web23_token"),
        };

        services(true, url, data).then((response) => {
            let res = response.data
            if (res.status === 1) {
                setProfileData(res.resData);
                setLoader(false);
            } else {
                setError(res.msg);
                setLoader(false);
            }
        });
    }
    return (
        <>
            {
                loader ?
                    <Loader />
                    :
                    <div className={`${styles.e_card_wrapper} e-py-32 e-px-32`}>
                        <div className="w-100 d-flex align-items-center justify-content-between pb-3">
                            <div className="d-flex align-items-center">
                                <div className={styles.e_card_icon}>
                                    <img src={Icon} alt="orders icon" />
                                </div>
                                <h3 className="mb-0 ps-1">{props.billing ? "Billing Info" : "Profile Details"}</h3>
                            </div>
                            <div className="position-relative d-flex">
                                <div className={styles.e_dotted_burger} onClick={() => setDropdown(true)}></div>
                                <div className={showDropdown ? `${styles.e_dropdown} ${styles.e_dropdown_active} position-absolute py-2` : `${styles.e_dropdown} position-absolute py-2`}>
                                    <div className="px-3 py-2 text-end">
                                        <TextButton name={
                                            <div className="d-flex align-items-center">
                                                <svg className="me-1" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M3.68333 17.1492C3.44956 17.1488 3.22672 17.0502 3.06916 16.8775C2.9087 16.7062 2.82896 16.4746 2.84999 16.2408L3.05416 13.9958L12.4858 4.5675L15.4333 7.51417L6.00416 16.9417L3.75916 17.1458C3.73333 17.1483 3.70749 17.1492 3.68333 17.1492ZM16.0217 6.925L13.075 3.97834L14.8425 2.21084C14.9988 2.05436 15.2109 1.96643 15.4321 1.96643C15.6533 1.96643 15.8654 2.05436 16.0217 2.21084L17.7892 3.97834C17.9456 4.13464 18.0336 4.34675 18.0336 4.56792C18.0336 4.78909 17.9456 5.0012 17.7892 5.1575L16.0225 6.92417L16.0217 6.925Z" fill="#4660F1" />
                                                </svg>
                                                {
                                                    props.billing ?
                                                        "Edit Address"
                                                        :
                                                        "Edit Details"
                                                }
                                            </div>
                                        } target="#editProfileModal"
                                            toggle="modal"
                                            handleClick={() => setDropdown(false)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-100 pt-3">
                            <div className={`${styles.e_card_body} p-3 w-100`}>
                                {
                                    Object.values(profileData).length > 0 && !props.billing ?
                                        <div className="row gy-5">
                                            <div className="col-lg-4 col-md-6">
                                                <h4>Name</h4>
                                                <h5 className="fw-normal mb-0">{profileData.fullName}</h5>
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <h4>Email</h4>
                                                <h5 className="fw-normal text-lowercase mb-0">{profileData.email}</h5>
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <h4>Phone</h4>
                                                <h5 className="fw-normal mb-0">{"+" + profileData.countryCode} {profileData.mobileNo}</h5>
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <h4>Billing Address</h4>
                                                <h5 className="fw-normal mb-0">{profileData.adrLine1} {profileData.adrLine2}<br />
                                                    {profileData.city},<br />
                                                    {profileData.state}, {profileData.pincode}
                                                </h5>
                                            </div>
                                        </div>
                                        :
                                        <>
                                            {
                                                !props.billing ?
                                                    <h6 className="text-center">No data found</h6>
                                                    :
                                                    null
                                            }
                                        </>
                                }
                                {
                                    Object.values(profileData).length > 0 && props.billing ?
                                        <div className="row gy-5">
                                            <div className="col-lg-4 col-md-6">
                                                <h4>Name</h4>
                                                <h5 className="fw-normal mb-0">{profileData.fullName}</h5>
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <h4>Email</h4>
                                                <h5 className="fw-normal text-lowercase mb-0">{profileData.contactEmail}</h5>
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <h4>Phone</h4>
                                                <h5 className="fw-normal mb-0">{"+" + profileData.countryCode} {profileData.mobileNo}</h5>
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <h4>Billing Address</h4>
                                                <h5 className="fw-normal mb-0">{profileData.adrLine1} {profileData.adrLine2}<br />
                                                    {profileData.city},<br />
                                                    {profileData.state}, {profileData.pincode}
                                                </h5>
                                            </div>
                                        </div>
                                        :
                                        <>
                                            {
                                                props.billing ?
                                                    <h6 className="text-center">No data found</h6>
                                                    :
                                                    null
                                            }
                                        </>
                                }
                            </div>
                        </div>
                    </div>
            }
            {
                showDropdown ?
                    <div className={`${styles.e_backdrop} position-fixed`} onClick={() => setDropdown(false)}></div>
                    :
                    null
            }
            <EditProfileModal
                profile={profileData}
                update={(data) => {
                    getProfileDetails();
                    getBilling();
                    props.update(data)
                }}
                cancel={() =>  {
                    getProfileDetails();
                    getBilling();
                }}
                billing={props.billing}
            />
            {
                error !== null ?
                    <Toast data={error} />
                    :
                    null
            }
        </>
    )
}

export default ProfileDetailCard;