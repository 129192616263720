import React, { useState } from "react";

import Icon from '../../assets/images/profile/settings-icon.png'
import { Toast } from "../../components/alerts";
import { Primary } from "../../components/cta";
import { InputBox } from "../../components/form";
import services from "../../services/apis/services";
import Cookies from 'js-cookie';


const AccountSettings = () => {
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState(null);
    const [loader, setLoader] = useState(false);


    const handleLogout = () => {
        Cookies.remove("web23_token");
        window.location.href = "/auth/login";
    }

    /* change password */
    const changePassword = () => {
        setLoader(true);
        let url = 'action/change/login';

        let data = {
            'token': localStorage.getItem("web23_token"),
            'passwordOld': currentPassword,
            'passwordNew': newPassword,
            'cpassword': confirmPassword
        }

        services(true, url, data).then((response) => {
            let res = response.data

            if (res.status === 1) {
                setLoader(false);
                handleLogout();
            } else if (res.status.status === 0 || res.status.status === -1) {
                setError(res.msg);
                setError(res.resData[0]?.msg);
                setLoader(false);
            } else {
                setError(res.msg);
                setError(res.resData[0]?.msg);
                setLoader(false);
            }
        })
    }

    return (
        <div className="w-100">
            <div className="row pb-3">
                <div className="col-lg-6 col-md-8 col-sm-10">
                    <div className="w-100 e-card-wrapper e-px-32 e-py-32">
                        <div className="d-flex align-items-center pb-3">
                            <div className="e-card-icon">
                                <img src={Icon} alt="settings icon" />
                            </div>
                            <h3 className="mb-0 ps-1">Change Password</h3>
                        </div>
                        <div className="w-100 pt-3">
                            <InputBox
                                label={<>Current Password <span className="e-warning-color">*</span></>}
                                value={currentPassword}
                                type="password"
                                valueChange={(value) => {
                                    setCurrentPassword(value);
                                    setError(null);
                                }}
                                placeholder="Current Password"
                                required={true} />
                        </div>
                        <div className="w-100 pt-3">
                            <InputBox
                                label={<>New Password <span className="e-warning-color">*</span></>}
                                value={newPassword}
                                type="password"
                                valueChange={(value) => {
                                    setNewPassword(value);
                                    setError(null);
                                }}
                                placeholder="New Password"
                                required={true} />
                        </div>
                        <div className="w-100 pt-3">
                            <InputBox
                                label={<>Re-enter New Password <span className="e-warning-color">*</span></>}
                                value={confirmPassword}
                                type="password"
                                valueChange={(value) => {
                                    setConfirmPassword(value);
                                    setError(null);
                                }}
                                placeholder="Re-enter New Password"
                                required={true} />
                        </div>
                        <div className="w-100 d-flex pt-3">
                            {
                                currentPassword &&
                                    confirmPassword &&
                                    newPassword ?
                                    <Primary
                                        name="Save"
                                        large={true}
                                        className={loader ? "e-btn-loader" : ""}
                                        handleClick={() => changePassword()}
                                    />
                                    :
                                    <Primary
                                        name="Save"
                                        large={true}
                                        disabled={true}
                                    />
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="pt-4">
                <h3 className="mb-0 pb-3">Other</h3>
                <div className="d-flex align-items-cener pt-3 e-logout-wrapper" onClick={handleLogout}>
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.25 15.75H3.75C2.92157 15.75 2.25 15.0784 2.25 14.25V11.25H3.75V14.25H14.25V3.75H3.75V6.75H2.25V3.75C2.25 2.92157 2.92157 2.25 3.75 2.25H14.25C15.0784 2.25 15.75 2.92157 15.75 3.75V14.25C15.75 15.0784 15.0784 15.75 14.25 15.75ZM8.25 12V9.75H2.25V8.25H8.25V6L12 9L8.25 12Z" fill="#2E3A59" />
                    </svg>
                    <h6> Logout</h6>
                </div>
            </div>
            {
                error !== null ?
                    <Toast data={error} />
                    :
                    null
            }
        </div>
    )
}

export default AccountSettings;