/* ------------------------------------- 
Custom Hook to trigger tooltip on hover

const tooltip = useToolTip();

<Component onMouseEnter={e=>tooltip.showTooltip(e, "Tooltip title")} onMouseLeave={tooltip.hideTooltip}>...</Component>

------------------------------------- */

import { useContext } from 'react';
import { TooltipHandlerContext } from '../../context/tooltipHandlerContext';

export const useToolTip=()=>{  
    const [setShowTooltip, setTitle, setPosition, tooltipLoading] = useContext(TooltipHandlerContext);

    const tooltip = {
        showTooltip: function(e, title){
            if(!tooltipLoading){
                let top; let left; let right;
                let objectBottom = e.target.getBoundingClientRect().bottom;
                let objectTop = e.target.getBoundingClientRect().top;
                if (e.clientX > window.innerWidth / 2) {
                    left = "unset";
                    right = `${window.innerWidth - (e.clientX + 30)}px`;
                }
                else {
                    right = "unset";
                    left = `${e.clientX - 40}px`;
                }
                top = `${objectBottom + 8}px`;
                setPosition({top: top, left: left, right: right, bottom: "unset"});
                setTitle(title);
                setShowTooltip(true);
            }
        },
        hideTooltip: function(){
            setShowTooltip(false);
        }
    }

  return tooltip;
}