import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import CreateAccount from '../../components/auth/createAccount';
import ForgotPassword from '../../components/auth/forgotPassword';
import { AuthIllustrationWrapper } from '../../components/auth/illustrationWrapper';
import Login from '../../components/auth/login';
import CreatePassword from '../../components/auth/setPassword';
import VerifyOtp from '../../components/auth/verifyOtp';
import { AuthHeader } from '../../components/navigation';

const AuthContainer = (props) => {
    const [email, setEmail] = useState(null);
    const [number, setNumber] = useState(null);
    const [password, setPassword] = useState(null);
    const [confirmPassword, setConfirmPassword] = useState(null);
    const [countryCode, setCountryCode] = useState(null);
    const [name, setName] = useState(null);
    const [userId, setUserId] = useState(null);
    const [redirect, setRedirect] = useState("");

    const clearAll = () => {
        setName(null);
        setEmail(null);
        setNumber(null);
        setUserId(null);
        setPassword(null);
        setConfirmPassword(null);
        setCountryCode(null);
    }


    return (
        <div className="e-auth-wrapper d-flex flex-column flex-md-row">
            <AuthHeader />
            <AuthIllustrationWrapper type={props.type} />
            <div className="e-auth-content-wrapper">
                {
                    props.type === "login" ?
                        <Login
                            userId={userId}
                            setUserId={(val) => setUserId(val)}
                            password={password}
                            setPassword={(val) => setPassword(val)}
                            redirect={redirect}
                            setRedirect={(val) => setRedirect(val)}
                            clearAll={() => clearAll()}
                        />
                        :
                        props.type === "create-account" ?
                            <CreateAccount
                                name={name}
                                setName={(val) => setName(val)}
                                password={password}
                                countryCode={countryCode ? countryCode : "91"}
                                setPassword={(val) => setPassword(val)}
                                email={email}
                                setEmail={(val) => setEmail(val)}
                                number={number}
                                setNumber={(val) => setNumber(val)}
                                confirmPassword={confirmPassword}
                                setConfirmPassword={(val) => setConfirmPassword(val)}
                                redirect={redirect}
                                setRedirect={(val) => setRedirect(val)}
                                clearAll={() => clearAll()}
                                setCountryCode={(val) => { setCountryCode(val.split('+')[1]); }} />
                            :
                            props.type === "forgot-password" ?
                                <ForgotPassword
                                    number={number}
                                    setNumber={(val) => setNumber(val)}
                                    countryCode={countryCode ? countryCode : "91"}
                                    redirect={redirect}
                                    setRedirect={(val) => setRedirect(val)}
                                    clearAll={() => clearAll()}
                                    setCountryCode={(val) => { setCountryCode(val.split('+')[1]); }} />
                                :
                                props.type === "verify-otp" ?
                                    <VerifyOtp redirect={redirect} />
                                    :
                                    props.type === "create-password" ?
                                        <CreatePassword
                                            setPassword={(val) => setPassword(val)}
                                            setConfirmPassword={(val) => setConfirmPassword(val)}
                                            password={password}
                                            confirmPassword={confirmPassword} />

                                        :
                                        <Redirect to="/auth/login" />
                }
            </div>
        </div>
    )
}

export default AuthContainer;