import React, { useEffect, useState } from "react";

import { RecordsCard, UpgradePlanCard } from "../../components/cards";
import { TextButton } from "../../components/cta";
import AddW2RecordModal from "../../components/modals/addW2RecordModal";

const WebTwoManagement=(props)=>{
	  useEffect(() => {
		  if(props.domain){
			console.log("Domain Name:-"+props.domain);
		  }
    }, [props.domain]);
    return(
        <>
        <div className="w-100 pb-4">
        <RecordsCard type={1} domain={props.domain} />
        </div>
        <div className="w-100 pb-4 pt-3">
        <RecordsCard type={2} domain={props.domain} />
        </div>
        <div className="w-100 pb-5 pt-3">
            <div className="row gy-4">
                <div className="col-lg-6">
                    <div className="e-card-wrapper w-100 h-100">
                        <div className="w-100 e-py-32 e-px-32 e-border-bottom">
                            <h3 className="mb-0">Forwarding</h3>
                        </div>
                        <div className="e-px-32 e-py-32 w-100">
                            <div className="d-flex align-items-center justify-content-between">
                                <h5 className="mb-0">Domain</h5>
                                <TextButton name="Edit" blue={true}/>
                            </div>
                            <h5 className="mb-0 pt-3 pb-3 fw-normal">
                            https://www.example.com
                            </h5>
                            <div className="d-flex align-items-center justify-content-between pt-3">
                                <h5 className="mb-0">Sub Domain</h5>
                                <TextButton name="Add" blue={true}/>
                            </div>
                            <div className="d-flex align-items-center pt-3">
                            <h5 className="mb-0 pe-3 fw-normal">
                            shop.sarahjames.com
                            </h5>
                            <TextButton name="Edit" blue={true}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <UpgradePlanCard/>
                </div>
            </div>
        </div>
        <AddW2RecordModal type={1} domain={props.domain} />
        </>
    )
}

export default WebTwoManagement;