import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import $ from 'jquery';

//import Logo from '../../assets/images/logo-long-color.svg';
import Logo from '../../assets/logo/logoSky.svg';
import CartIcon from '../../assets/images/icons/cart-24.svg';
import SearchIcon from '../../assets/images/icons/search-24.svg';

import SearchBox from '../serachBox/searchBox';
import ProfileDropdown from '../others/profileDropdown';
import NotificationDropdown from '../others/notificationDropdown';
import CartDropdown from '../others/cartDropdown';
import NavBar from './navBar';
/* services */
import services from '../../services/apis/services';

const Header = (props) => {
    const [query, setQuery] = useState('');
    const [headerHeight, setHeaderHeight] = useState(92);
    const [totalHeaderHeight, setTotalHeaderHeight] = useState(176);
    const [cartPageHeight, setCartPageHeight] = useState(70);
    const router = useHistory();
    const location = useLocation();
    const headerRef = useRef(null);
    const mainHeaderRef = useRef(null);
    const [activePage, setActivePage] = useState("");
    const [subPath, setSubPath] = useState("");
    const [cart, setCart] = useState([]);
    const [error, setError] = useState(null);


    const checkPath=()=>{
        let paths = location.pathname.split('/');
        let activePage= paths[1];
        if(paths[2]) setSubPath(`${paths[2]}`);
        if(activePage != "search" && activePage != "cart"&& activePage != "domain" && activePage != "profile" && activePage != "coupons") $('.e-app-body-wrapper').css("padding-top", `${totalHeaderHeight}px`);
        else if(activePage === "search" || activePage == "cart") $('.e-app-body-wrapper').css("padding-top", `${cartPageHeight}px`);
        else $('.e-app-body-wrapper').css("padding-top", `${headerHeight}px`);
        setActivePage(activePage);
        setTimeout(() => {
            setBodyPadding(activePage === "search" || activePage == "cart"? true : false);
        }, 500);
    }

    useEffect(()=>{
        checkPath();
        setQuery("");
    },[location.pathname]);

    const setBodyPadding=(isCartPage)=>{
        let header = headerRef.current;
        let mainHeader = mainHeaderRef.current;
        let height = header.getBoundingClientRect().height;
        let mainHeight = mainHeader.getBoundingClientRect().height;
        if(isCartPage){
            if(cartPageHeight!=height){
                setCartPageHeight(height-1);
                $('.e-app-body-wrapper').css("padding-top", `${height-1}px`);
            }
        }
        else if(height!=totalHeaderHeight && mainHeight!=height){
            setHeaderHeight(mainHeight-1);
            setTotalHeaderHeight(height-1);
            $('.e-app-body-wrapper').css("padding-top", `${height-1}px`);
        }
        else if(mainHeight===height){
            setHeaderHeight(mainHeight-1);
            $('.e-app-body-wrapper').css("padding-top", `${height-1}px`);
        }
    }

    useEffect(()=>{
        if(!localStorage.getItem("web23_token")) router.push('/auth/login');
    },[]);

    useEffect(() => {
        getCart();
    }, []);

    /* get cart */
    const getCart = () => {
     
        let url = 'action/bill/get';

        let body = {
            token: localStorage.getItem("web23_token")
        }
        services(true, url, body).then((response) => {
            let res = response.data;

            if (res.status === 1) {
                setCart(res.resData);
                props.cart(res.resData);
            } else {
               
                setError(res.msg);
            }
        })
    }

    return (
        <>
        <header className="e-header position-fixed" ref={headerRef}>
            <div className="container py-3" ref={mainHeaderRef}>
                <div className="row gx-lg-3 gx-sm-2 gx-1">
                    <div className="col-sm-3 col-4">
                        <div className="h-100 d-flex align-items-center">
                            <Link to="/">
                                <img src={Logo} alt="Web 23 Logo" className="e-header-logo"  />
                            </Link>
                        </div>
                    </div>
                    <div className="col-lg-6 py-2 col-md-5 d-none d-md-block">
                        {
                            activePage != "search"&& activePage != "cart" ?
                                <SearchBox header={true} onSearch={() => { query !== "" ?  router.push(`/search?query=${query}`) : router.push(`/search?query=`) }} value={query} onChange={(val) => { setQuery(val) }} />
                                :
                                null
                        }
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-8 col-8">
                        <div className="w-100 h-100 d-flex align-items-center justify-content-end">
                            {
                                activePage != "search"&& activePage != "cart" ?
                                    <div className="me-3 d-md-none">
                                        <img src={SearchIcon} alt="Search Icon" className="e-header-icon" />
                                    </div>
                                    :
                                    null
                            }
                            <div className="me-1">
                                <NotificationDropdown />
                            </div>
                            <div className="mx-lg-4 mx-md-3 ms-2 me-3">
                                <ProfileDropdown activePage={activePage} path={subPath} profile={props.profile}/>
                            </div>
                            <CartDropdown cart={cart}/>
                        </div>
                    </div>
                </div>
            </div>
            {
                activePage != "search"&& activePage != "cart" && activePage != "domain" && activePage != "profile" && activePage != "coupons" ?
                <NavBar activePage={activePage}/>
                :
                null
            }
        </header>
        </>
    )
}

export default Header;