// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SocialButton_e_social_button_wrapper__2LOeM .SocialButton_e_social_btn__b6KcD {\n  width: 0px;\n  height: 0px;\n  overflow: hidden;\n  opacity: 0;\n  top: 0;\n  left: 0;\n  z-index: 0; }\n", "",{"version":3,"sources":["webpack://src/styles/components/SocialButton.module.scss"],"names":[],"mappings":"AAAA;EAEQ,UAAU;EACV,WAAW;EACX,gBAAgB;EAChB,UAAU;EACV,MAAM;EACN,OAAO;EACP,UAAU,EAAA","sourcesContent":[".e_social_button_wrapper{\n    .e_social_btn{\n        width: 0px;\n        height: 0px;\n        overflow: hidden;\n        opacity: 0;\n        top: 0;\n        left: 0;\n        z-index: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"e_social_button_wrapper": "SocialButton_e_social_button_wrapper__2LOeM",
	"e_social_btn": "SocialButton_e_social_btn__b6KcD"
};
export default ___CSS_LOADER_EXPORT___;
