import React from "react";
import { TemplateCard } from "../../components/cards";

import Web2Icon from '../../assets/images/icons/globe-blue-30.png';
import EditUrlModal from "../../components/modals/editUrlModal";
import ConfirmDeleteModal from "../../components/modals/confirmDeleteModal";

const sampleData= [
    {domain: "Sarahjames.com", subdomains:[
    {title: "shop.sarahjames.com", redirection_url: "www.example.com"},
    {title: "store.sarahjames.com", redirection_url: "www.example.com"},
    {title: "music.sarahjames.com", redirection_url: "www.example.com"}
    ]},
    {domain: "sarahjames.xyz", subdomains:[
        {title: "shop.sarahjames.xyz", redirection_url: "www.example.com"},
        {title: "store.sarahjames.xyz", redirection_url: "www.example.com"},
        {title: "music.sarahjames.xyz", redirection_url: "www.example.com"}
    ]},
    {domain: "Sarahjames.in", subdomains:[
        {title: "shop.sarahjames.in", redirection_url: "www.example.com"},
        {title: "store.sarahjames.in", redirection_url: "www.example.com"},
        {title: "music.sarahjames.in", redirection_url: "www.example.com"}
    ]},
    {domain: "Sarahjames.co.in", subdomains:[
        {title: "shop.sarahjames.co.in", redirection_url: "www.example.com"},
        {title: "store.sarahjames.co.in", redirection_url: "www.example.com"},
        {title: "music.sarahjames.co.in", redirection_url: "www.example.com"}
    ]}
];

const WebTwoTemplates=()=>{ 

    return(
        <div>
            {
                sampleData.map((item, i)=>{
                    return(
                        <div className={i!=0?"pt-3": ""} key={`web-2-template-${i}`}>
                            <TemplateCard data={item} isExpanded={i===0} key={i} icon={Web2Icon}/>
                        </div>
                    )
                })
            }
            <EditUrlModal/>
            <ConfirmDeleteModal message="Do you want to delete this subdomain and URL?"/>
        </div>
    )
}

export default WebTwoTemplates;