/*--------------
props.type
value       desc
1           user
2           admin
---------------*/
import { Modal } from "bootstrap";
import React, { useEffect, useState } from "react";

/*import style*/
import styles from '../../styles/components/CouponCard.module.scss';

import { Primary } from "../cta";
// import AddCouponModal from "../modals/addCouponModal";
import ShowcouponModal from "../modals/showModal";

const CouponCard = (props) => {

    const [copied, setCopied] = useState(null);
    const [cardData, setCardData] = useState([]);
    const [couponData, setCouponData] = useState({});

    let IMAGE_URL = "http://147.182.216.70:5001"

    useEffect(() => {
        if (props.data) {
            setCardData(props.data);
        }
    }, [props.data]);

    // copy coupon code
    const copyData = (data) => {
        // if (!window.navigator.clipboard) {
        //     // document.execCommand('copy');
        // }
        // else {
        window.navigator.clipboard.writeText(data).then(() => {
            console.log("code::", data)
            setCopied(data);
            setTimeout(() => {
                setCopied(null);
            }, 2000);
        }).catch(err => {
            console.log(err);
        });
        // }
    }

    const openCouponModal = (data) => {
        var modal = new Modal(document.getElementById("showCoupon"), {});
        modal.show();
        setCouponData(data)
    }

    // social share func  
    const shareResource = (id, code, domain) => {
        let share_url = '';
        switch (id) {
            case 1:
                share_url = 'https://www.facebook.com';
                openWindow(share_url);
                break;

            case 2:
                share_url = 'https://twitter.com/intent/tweet?text=' + domain + ",code=" + code;
                openWindow(share_url);
                break;


            default:
                console.log('Un-handled share opation :: ', id);
                break;
        }
    }

    // open popup for social share
    const openWindow = (url) => {
        window.open(url, "", "width=600,height=600");
    }

    return (
        <>
            <div className="row">
                {
                    cardData.map((item, key) => {
                        if( props.tabIndex === 1&& item.isNFT === 0 ){
                            return (

                                <div className="col-lg-4 col-md-6 col-12 mb-3">
                                    <div className={styles.e_card_wrapper}>
                                        <div className="w-100 position-relative">
                                            <div className={`${styles.e_cover_wrapper} w-100`}>
                                                <img src={IMAGE_URL + item.couponImagePath} alt="Cover Image" />
                                            </div>
                                            {
                                                props.type && props.type === 2 ?
                                                    <div className={`${styles.e_actions_wrapper} position-absolute d-flex`}>
                                                        {/* <div className={`${styles.e_edit_icon} d-flex align-items-center justify-content-center`}>
                                                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/6000/svg">
                                                                <path d="M3.315 15.434C3.10461 15.4336 2.90405 15.3449 2.76225 15.1895C2.61783 15.0353 2.54607 14.8269 2.565 14.6165L2.74875 12.596L11.2372 4.1105L13.89 6.7625L5.40375 15.2472L3.38325 15.431C3.36 15.4332 3.33675 15.434 3.315 15.434ZM14.4195 6.23225L11.7675 3.58025L13.3582 1.9895C13.4989 1.84866 13.6898 1.76953 13.8889 1.76953C14.0879 1.76953 14.2788 1.84866 14.4195 1.9895L16.0102 3.58025C16.1511 3.72092 16.2302 3.91181 16.2302 4.11087C16.2302 4.30993 16.1511 4.50082 16.0102 4.6415L14.4202 6.2315L14.4195 6.23225Z" fill="white" />
                                                            </svg>
                                                        </div> */}
                                                        {/* <div className={`${styles.e_delete_icon} ms-3 d-flex align-items-center justify-content-center`}>
                                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M13.8092 17.619H6.1902C5.34862 17.619 4.66639 16.9367 4.66639 16.0951V6.19038H3.14258V4.66657H6.1902V3.90467C6.1902 3.06309 6.87243 2.38086 7.71401 2.38086H12.2854C13.127 2.38086 13.8092 3.06309 13.8092 3.90467V4.66657H16.8569V6.19038H15.3331V16.0951C15.3331 16.9367 14.6508 17.619 13.8092 17.619ZM6.1902 6.19038V16.0951H13.8092V6.19038H6.1902ZM7.71401 3.90467V4.66657H12.2854V3.90467H7.71401Z" fill="white" />
                                                            </svg>
                                                        </div> */}
                                                    </div>
                                                    :
                                                    null
                                            }
                                        </div>
                                        <div className={`${styles.e_card_body} w-100 p-3`}>
                                            <h4>{item.title}</h4>
                                            <p className={`${styles.e_card_description} mb-0`}>{item.description}</p>
                                            <div className="pt-4">
                                                <div className={styles.e_coupon_code} onClick={() => {
                                                    openCouponModal(item);
                                                }
                                                }>
                                                    Code : {item.couponsRecs ?
                                                        <div className="d-flex">
                                                            {item.couponsRecs.newly_added[0].gs1}<span className={`${styles.e_remaining} `}>+{item.couponsRecs.newly_added.length - 1}</span>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </div>
                                            </div>
                                            <div className="pt-4 d-flex align-items-center pb-2 position-relative w-100">
                                                <h5 className="mb-0 e-text-muted">Share:</h5>
                                                <svg className={`${styles.e_share_icon} ms-2`} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => shareResource(1, item.couponsRecs.newly_added[0].gs1, item.description)}>
                                                    <path d="M2.00195 12.0016C2.00312 16.9209 5.58036 21.1096 10.439 21.8806V14.8916H7.90195V12.0016H10.442V9.80156C10.3284 8.7591 10.6845 7.72015 11.4136 6.96649C12.1427 6.21283 13.1693 5.82257 14.215 5.90156C14.9655 5.91368 15.7141 5.98052 16.455 6.10156V8.56056H15.191C14.7558 8.50356 14.3183 8.64728 14.0017 8.95122C13.6851 9.25517 13.5237 9.68644 13.563 10.1236V12.0016H16.334L15.891 14.8926H13.563V21.8806C18.8174 21.0502 22.502 16.2513 21.9475 10.9606C21.3929 5.66993 16.7932 1.73949 11.4808 2.01673C6.16831 2.29398 2.0028 6.68186 2.00195 12.0016Z" fill="#11142F" />
                                                </svg>
                                                <svg className={`${styles.e_share_icon} ms-3`} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => shareResource(2, item.couponsRecs.newly_added[0].gs1, item.description)}>
                                                    <path d="M19.995 6.68799C20.8914 6.15208 21.5622 5.30823 21.882 4.31399C21.0397 4.81379 20.118 5.16587 19.157 5.35499C17.8246 3.94552 15.7135 3.60251 14.0034 4.51764C12.2933 5.43277 11.4075 7.37948 11.841 9.26999C8.39062 9.09676 5.17598 7.4669 2.99702 4.78599C1.85986 6.74741 2.44097 9.25477 4.32502 10.516C3.64373 10.4941 2.97754 10.3096 2.38202 9.97799C2.38202 9.99599 2.38202 10.014 2.38202 10.032C2.38241 12.0751 3.82239 13.8351 5.82502 14.24C5.19308 14.4119 4.53022 14.4372 3.88702 14.314C4.45022 16.0613 6.06057 17.2583 7.89602 17.294C6.37585 18.4871 4.49849 19.1342 2.56602 19.131C2.22349 19.1315 1.88123 19.1118 1.54102 19.072C3.50341 20.333 5.78738 21.0023 8.12002 21C11.3653 21.0223 14.484 19.7429 16.7787 17.448C19.0734 15.1531 20.3526 12.0342 20.33 8.78899C20.33 8.60299 20.3257 8.41799 20.317 8.23399C21.1575 7.62659 21.8828 6.87414 22.459 6.01199C21.676 6.35905 20.8455 6.58691 19.995 6.68799Z" fill="#11142F" />
                                                </svg>
                                                <svg className={`${styles.e_share_icon} ms-3`} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => copyData(item.couponsRecs.newly_added[0].gs1)}>
                                                    <path d="M14.0002 22.0002H4.00019C3.46427 22.0188 2.94467 21.8141 2.56548 21.4349C2.1863 21.0557 1.98157 20.5361 2.00019 20.0002V10.0002C1.98157 9.46427 2.1863 8.94467 2.56548 8.56548C2.94467 8.1863 3.46427 7.98157 4.00019 8.00019H8.00019V4.00019C7.98157 3.46427 8.1863 2.94467 8.56548 2.56548C8.94467 2.1863 9.46427 1.98157 10.0002 2.00019H20.0002C20.5361 1.98157 21.0557 2.1863 21.4349 2.56548C21.8141 2.94467 22.0188 3.46427 22.0002 4.00019V14.0002C22.0185 14.536 21.8137 15.0555 21.4346 15.4346C21.0555 15.8137 20.536 16.0185 20.0002 16.0002H16.0002V20.0002C16.0185 20.536 15.8137 21.0555 15.4346 21.4346C15.0555 21.8137 14.536 22.0185 14.0002 22.0002ZM4.00019 10.0002V20.0002H14.0002V16.0002H10.0002C9.46435 16.0185 8.94493 15.8137 8.56581 15.4346C8.18669 15.0555 7.98187 14.536 8.00019 14.0002V10.0002H4.00019ZM10.0002 4.00019V14.0002H20.0002V4.00019H10.0002Z" fill="#11142F" />
                                                </svg>
                                                <div className={item.couponsRecs?.newly_added[0]?.gs1 === copied ? `${styles.e_copied_toast} ${styles.e_copied_active} position-absolute` : `${styles.e_copied_toast} position-absolute`}>
                                                    Coupon Code Copied
                                                </div>
                                            </div>
                                            {
                                                props.type && props.type === 1 ?
                                                    <div className="w-100 pt-3">
                                                        <Primary
                                                            name="Copy Code"
                                                            full_width={true}
                                                            handleClick={() => copyData(props.data.quantity)}
                                                        />
                                                    </div>
                                                    :
                                                    null
                                            }
                                        </div>
    
                                    </div>
                                </div>
    
                            )
                        
                        }
                        else if( props.tabIndex === 2&& item.isNFT === 1 ){
                                return (
    
                                    <div className="col-lg-4 col-md-6 col-12 mb-3">
                                        <div className={styles.e_card_wrapper}>
                                            <div className="w-100 position-relative">
                                                <div className={`${styles.e_cover_wrapper} w-100`}>
                                                    <img src={IMAGE_URL + item.couponImagePath} alt="Cover Image" />
                                                </div>
                                                {
                                                    props.type && props.type === 2 ?
                                                        <div className={`${styles.e_actions_wrapper} position-absolute d-flex`}>
                                                            {/* <div className={`${styles.e_edit_icon} d-flex align-items-center justify-content-center`}>
                                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/6000/svg">
                                                                    <path d="M3.315 15.434C3.10461 15.4336 2.90405 15.3449 2.76225 15.1895C2.61783 15.0353 2.54607 14.8269 2.565 14.6165L2.74875 12.596L11.2372 4.1105L13.89 6.7625L5.40375 15.2472L3.38325 15.431C3.36 15.4332 3.33675 15.434 3.315 15.434ZM14.4195 6.23225L11.7675 3.58025L13.3582 1.9895C13.4989 1.84866 13.6898 1.76953 13.8889 1.76953C14.0879 1.76953 14.2788 1.84866 14.4195 1.9895L16.0102 3.58025C16.1511 3.72092 16.2302 3.91181 16.2302 4.11087C16.2302 4.30993 16.1511 4.50082 16.0102 4.6415L14.4202 6.2315L14.4195 6.23225Z" fill="white" />
                                                                </svg>
                                                            </div> */}
                                                            {/* <div className={`${styles.e_delete_icon} ms-3 d-flex align-items-center justify-content-center`}>
                                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M13.8092 17.619H6.1902C5.34862 17.619 4.66639 16.9367 4.66639 16.0951V6.19038H3.14258V4.66657H6.1902V3.90467C6.1902 3.06309 6.87243 2.38086 7.71401 2.38086H12.2854C13.127 2.38086 13.8092 3.06309 13.8092 3.90467V4.66657H16.8569V6.19038H15.3331V16.0951C15.3331 16.9367 14.6508 17.619 13.8092 17.619ZM6.1902 6.19038V16.0951H13.8092V6.19038H6.1902ZM7.71401 3.90467V4.66657H12.2854V3.90467H7.71401Z" fill="white" />
                                                                </svg>
                                                            </div> */}
                                                        </div>
                                                        :
                                                        null
                                                }
                                            </div>
                                            <div className={`${styles.e_card_body} w-100 p-3`}>
                                                <h4>{item.title}</h4>
                                                <p className={`${styles.e_card_description} mb-0`}>{item.description}</p>
                                                <div className="pt-4">
                                                    <div className={styles.e_coupon_code} onClick={() => {
                                                        openCouponModal(item);
                                                    }
                                                    }>
                                                        Code : {item.couponsRecs ?
                                                            <div className="d-flex">
                                                                {item.couponsRecs.newly_added[0].gs1}<span className={`${styles.e_remaining} `}>+{item.couponsRecs.newly_added.length - 1}</span>
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                </div>
                                                <div className="pt-4 d-flex align-items-center pb-2 position-relative w-100">
                                                    <h5 className="mb-0 e-text-muted">Share:</h5>
                                                    <svg className={`${styles.e_share_icon} ms-2`} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => shareResource(1, item.couponsRecs.newly_added[0].gs1, item.description)}>
                                                        <path d="M2.00195 12.0016C2.00312 16.9209 5.58036 21.1096 10.439 21.8806V14.8916H7.90195V12.0016H10.442V9.80156C10.3284 8.7591 10.6845 7.72015 11.4136 6.96649C12.1427 6.21283 13.1693 5.82257 14.215 5.90156C14.9655 5.91368 15.7141 5.98052 16.455 6.10156V8.56056H15.191C14.7558 8.50356 14.3183 8.64728 14.0017 8.95122C13.6851 9.25517 13.5237 9.68644 13.563 10.1236V12.0016H16.334L15.891 14.8926H13.563V21.8806C18.8174 21.0502 22.502 16.2513 21.9475 10.9606C21.3929 5.66993 16.7932 1.73949 11.4808 2.01673C6.16831 2.29398 2.0028 6.68186 2.00195 12.0016Z" fill="#11142F" />
                                                    </svg>
                                                    <svg className={`${styles.e_share_icon} ms-3`} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => shareResource(2, item.couponsRecs.newly_added[0].gs1, item.description)}>
                                                        <path d="M19.995 6.68799C20.8914 6.15208 21.5622 5.30823 21.882 4.31399C21.0397 4.81379 20.118 5.16587 19.157 5.35499C17.8246 3.94552 15.7135 3.60251 14.0034 4.51764C12.2933 5.43277 11.4075 7.37948 11.841 9.26999C8.39062 9.09676 5.17598 7.4669 2.99702 4.78599C1.85986 6.74741 2.44097 9.25477 4.32502 10.516C3.64373 10.4941 2.97754 10.3096 2.38202 9.97799C2.38202 9.99599 2.38202 10.014 2.38202 10.032C2.38241 12.0751 3.82239 13.8351 5.82502 14.24C5.19308 14.4119 4.53022 14.4372 3.88702 14.314C4.45022 16.0613 6.06057 17.2583 7.89602 17.294C6.37585 18.4871 4.49849 19.1342 2.56602 19.131C2.22349 19.1315 1.88123 19.1118 1.54102 19.072C3.50341 20.333 5.78738 21.0023 8.12002 21C11.3653 21.0223 14.484 19.7429 16.7787 17.448C19.0734 15.1531 20.3526 12.0342 20.33 8.78899C20.33 8.60299 20.3257 8.41799 20.317 8.23399C21.1575 7.62659 21.8828 6.87414 22.459 6.01199C21.676 6.35905 20.8455 6.58691 19.995 6.68799Z" fill="#11142F" />
                                                    </svg>
                                                    <svg className={`${styles.e_share_icon} ms-3`} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => copyData(item.couponsRecs.newly_added[0].gs1)}>
                                                        <path d="M14.0002 22.0002H4.00019C3.46427 22.0188 2.94467 21.8141 2.56548 21.4349C2.1863 21.0557 1.98157 20.5361 2.00019 20.0002V10.0002C1.98157 9.46427 2.1863 8.94467 2.56548 8.56548C2.94467 8.1863 3.46427 7.98157 4.00019 8.00019H8.00019V4.00019C7.98157 3.46427 8.1863 2.94467 8.56548 2.56548C8.94467 2.1863 9.46427 1.98157 10.0002 2.00019H20.0002C20.5361 1.98157 21.0557 2.1863 21.4349 2.56548C21.8141 2.94467 22.0188 3.46427 22.0002 4.00019V14.0002C22.0185 14.536 21.8137 15.0555 21.4346 15.4346C21.0555 15.8137 20.536 16.0185 20.0002 16.0002H16.0002V20.0002C16.0185 20.536 15.8137 21.0555 15.4346 21.4346C15.0555 21.8137 14.536 22.0185 14.0002 22.0002ZM4.00019 10.0002V20.0002H14.0002V16.0002H10.0002C9.46435 16.0185 8.94493 15.8137 8.56581 15.4346C8.18669 15.0555 7.98187 14.536 8.00019 14.0002V10.0002H4.00019ZM10.0002 4.00019V14.0002H20.0002V4.00019H10.0002Z" fill="#11142F" />
                                                    </svg>
                                                    <div className={item.couponsRecs?.newly_added[0]?.gs1 === copied ? `${styles.e_copied_toast} ${styles.e_copied_active} position-absolute` : `${styles.e_copied_toast} position-absolute`}>
                                                        Coupon Code Copied
                                                    </div>
                                                    {
                                                        item.nftTokenId ?
                                                            <a href={`https://testnet.dragonglass.me/hedera/search?q=${item.nftTokenId}`} className="e-nft-detail-link" target="_blank">VIew token details</a>
                                                        :
                                                            null
                                                    }
                                                </div>
                                                
                                                {
                                                    props.type && props.type === 1 ?
                                                        <div className="w-100 pt-3">
                                                            <Primary
                                                                name="Copy Code"
                                                                full_width={true}
                                                                handleClick={() => copyData(props.data.quantity)}
                                                            />
                                                        </div>
                                                        :
                                                        null
                                                }
                                            </div>
        
                                        </div>
                                    </div>
        
                                )
                            
                        }
                        
                    })
                }
                <ShowcouponModal data={couponData} />
            </div>
        </>
    )
}

export default CouponCard;