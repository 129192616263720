import React, { useEffect, useState } from 'react';

/* asset */
import Check from '../../assets/images/icons/circle_check.svg';
const PaymentModal = (props) => {


    return (
        <div className="modal fade e-payment-modal" id="payment" tabIndex="-1" aria-labelledby="paymentModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content position-relative justify-content-center">
                    {/* <h5 className="modal-title mb-0" id="paymentModalLabel">Confirm Package</h5> */}
                    <div className="e-close-btn-wrapper d-flex align-items-center justify-content-center position-absolute" data-bs-dismiss="modal">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41L17.59 5Z" fill="#11142F" />
                        </svg>
                    </div>

                    <div className="e-resolve-result  d-flex flex-column align-items-center justify-content-end">
                        <div className="e-health-badge-container position-relative my-1">
                            
                            <img src={Check} alt="health badge" className="position-absolute e-health-icon" />
                        </div>
                        Payment Successfull
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaymentModal;