import React, { useContext, useEffect, useState } from 'react';

import { message } from 'antd';

import { CartContext } from '../../context/cartContext';

/*--- import services---*/
import services from '../../services/apis/services';

/*import style*/
import styles from '../../styles/components/SearchTable.module.scss';

import { Primary } from '../cta';
import { Toast } from '../alerts';



const EncroachmentTable = (props) => {
    const [cartData, setCartData] = useContext(CartContext);
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(null);
    const [cartItem,setCartItem] = useState("");


    useEffect(() => {
        setTimeout(() => {
            setError(null);
        },3000);
    },[error]);
   
    

    return (
        <div className='mt-4 py-4'>
			<div className="">
                <div className="">
					<table className="table table-hover table-striped table-bordered table-box-sha">
                    <thead>
                        <tr>
                            <td width='60px'><h6>Sr. No.</h6></td> 
                            <td width='320px'><h6>Domain Name</h6></td>
                            <td><h6>IP(s)</h6></td>
                            <td><h6>Navigate</h6></td>
                        </tr>
                    </thead>
                    <tbody >
                        {
                            props.data ?
                                <>
                                    {
                                        props.data.map((item, i) => {
                                            return (
                                                <tr  key={`search-result-row-${i}`}>
                                                    
                                                        <td>{i + 1}</td>
                                                        <td className="text-break">
                                                            <div className="w-100 d-flex flex-column">
                                                                <div className="fs-5 mb-2 text-lowercase text-break">{item.domain}</div>
                                                                
                                                            </div>
                                                        </td>
                                                        <td className="r899">
                                                            <span className="d-flex h-100">
															{(item.ips!=null && item.ips.length>=1) && 
																item.ips.join(" , ")
															}
															{(item.ips===null || item.ips.length===0) &&
																<span>-</span>
															}
                                                            </span>
                                                        </td>
                                                        <td className="y788">
                                                            <div className="d-flex ">
															{(item.ips!=null && item.ips.length>=1) &&
                                                                <a href={"https://"+item.domain} target='_blank' >Goto site</a>
															}
															{(item.ips===null || item.ips.length===0) &&
																<span className='fs-6 fw-light text-decoration-line-through'>Parked</span>
															}
                                                            </div>
                                                        </td>
                                                    
                                                </tr>
                                            )
                                        })
                                    }
                                </>
                                :
                                null
                        }
                    </tbody>
                </table>
			</div>	
            </div>
            {
                error !== null ?
                    <Toast data={error} type="fail" />
                    :
                    null
            }
        </div>
    )
}

export default EncroachmentTable;