import React, { useEffect, useState } from 'react';
import styles from '../../styles/components/CheckBox.module.scss';

const CheckBox = (props) => {
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        setChecked(props.defaultChecked);
    }, [props.defaultChecked]);

    const changeStatus = () => {
        const newStatus = !checked;
        setChecked(newStatus);
        props.onChecked(newStatus); // Pass only the new checked status
    }

    return (
        <div onClick={() => changeStatus()}>
            <label className={`${styles.e_check_box} d-flex position-relative`}>
                <div>
                    <div className={checked ? `${styles.e_check_mark} ${styles.e_check_mark_checked}` : `${styles.e_check_mark}`}></div>
                </div>
                <span className="ms-2">{props.label}</span>
            </label>
        </div>
    );
}

export default CheckBox;
