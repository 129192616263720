/*--------------
Properties      default    desc

healthy         false      set to true for healthy card
type            null       String. one of ["web2", "web3", "social"]
value           0          value to show on card
full_width      false      set to true to make card full width
---------------*/

/*Package importing*/
import React, { useState } from 'react';

/*import style*/
import styles from '../../styles/components/SecurityIndicatorCard.module.scss';

const SecurityIndicatorCard = (props) => {

    return (
        <div>
            <div className={`${styles.e_indicator_wrapper} position-relative`}>
                <div className={`${styles.e_outer_indicator} ${styles.e_healthy_indicator} position-absolute`}>
                    <div className="w-100 position-relative d-flex h-100">
                        <div className={`${styles.e_left_half} overflow-hidden position-relative`}>
                            <div className={styles.e_progress_bar}></div>
                        </div>
                        <div className={`${styles.e_right_half} overflow-hidden position-relative`}>
                            <div className={styles.e_progress_bar}></div>
                        </div>
                    </div>
                </div>
                <div className={`${styles.e_outer_cover} position-absolute`}>
                </div>
                <div className={`${styles.e_inner_cover} position-absolute`}>
                    <div className="w-100 position-relative d-flex h-100">
                        <div className={`${styles.e_left_half} overflow-hidden position-relative`}>
                            <div className={styles.e_progress_bar}></div>
                        </div>
                        <div className={`${styles.e_right_half} overflow-hidden position-relative`}>
                            <div className={styles.e_progress_bar}></div>
                        </div>
                    </div>
                </div>
                <div className={`${styles.e_inner_wrapper} position-absolute`}>
                    <div className="w-100 h-100 d-flex align-items-center flex-column justify-content-center">
                        <h3>{props.value}</h3>
                        <span>{props.remarks}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SecurityIndicatorCard;