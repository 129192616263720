/*--------------
Properties    default    desc
type          --         possible type: ["web_2", "web_3", "look_alike", "enroachments", "redirections", "resolved", "social", "templates"]
value         0          value to show on card
---------------*/

/*Package importing*/
import React from 'react';
/*import style*/
import styles from '../../styles/components/OverviewCard.module.scss';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const OverviewDomainStatus = (props) => {
   
    const statusColor = props.DomainStatus === "Available" ? "green" : "red";
    // console.log("checkkkkkkkkkk");
    // console.log(props);

    
  return (
    <div
        className={`${styles.e_overview_wrapper} ${styles.e_web_3}`}>
        
        <div className="d-flex" style={{"cursor":"pointer"}}>
            <div className={styles.e_icon}></div>
            <div className="ps-2 d-flex flex-column pt-1">
                <h4 className="mb-2">
                    <div style={{ overflowWrap: 'anywhere' }}><b>{props.domain}</b></div>
                    <div style={{ color: statusColor }}><b>{props.DomainStatus}</b></div>
                </h4>
            </div>
        </div>
        <ToastContainer />
    </div>
  )
}

export default OverviewDomainStatus
