import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

/* components */
import { Primary, TextButton } from '../../components/cta';
import { SingleSelect, ToggleButton } from '../../components/form';
import Loader from '../../components/others/loader'

/*assets*/
import GlobeIcon from '../../assets/images/icons/globe-yellow-30.svg';
import CalanderIcon from '../../assets/images/icons/calendar-30.svg';
import EmptyIllustration from '../../assets/images/empty-cart-illustration.svg';
import GifLoader from '../../assets/images/loader/loader1.gif'

/* services */
import services from '../../services/apis/services';
import { Toast } from '../../components/alerts';


const CartContainer = (props) => {
    // const [cartData, setCartData] = useContext(CartContext);
    const [cart, setCart] = useState([]);
    const [error, setError] = useState(null);
    const [loader, setLoader] = useState(false);
    const [cartLoader, setCartLoader] = useState(false);
    const [deleteLoader, setDeleteLoader] = useState(false);
    const [itemId,setItemId ] = useState("");

    const router = useHistory();

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);

    useEffect(() => {
        setCartLoader(true);
        if (props.cart.length > 0) {
            setCart(props.cart);
            setCartLoader(false)
        }    
        setTimeout(() => {
            if(props.cart.length === 0) {
                setCartLoader(false)
            }
        },2000)    
    }, [props.cart]);
    

    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null);
            }, 3000);
        }
    }, [error]);

    const removeItem = (i, name, id) => {
        setItemId(id)
        setDeleteLoader(true);
        let url = 'action/order/remove/item';

        var params = {
            itemName: name,
            itemId: id,
            token: localStorage.getItem("web23_token")
        };

        services(true, url, params).then((response) => {
            let res = response.data;

            if (res.status === 1) {
                getCart();
                setDeleteLoader(false);
            } else if (res.status === 0 || res.status === -1 || res.status === -9) {
                setCartLoader(false);
                setError(res?.msg);
                setDeleteLoader(false);
            } else {
                setCartLoader(false);
                setDeleteLoader(false);
                setError(res.msg);
                setError(res?.resData[0]?.msg);
            }
        });

    }

    /* get cart */
    const getCart = () => {
        setCartLoader(true);

        let url = 'action/bill/get';

        let body = {
            token: localStorage.getItem("web23_token")
        }
        services(true, url, body).then((response) => {
            let res = response.data;

            if (res.status === 1) {
                setCart(res.resData);
                setCartLoader(false);
            } else if (res.status === 0 || res.status === -1 || res.status === -9) {
                setCartLoader(false);
                setError(res.msg);
            } else {
                setCartLoader(false);
                setError(res.msg);
            }
        })
    }

    return (
        <div className="container-fluid position-relative e-cart-page-container pt-3">
            <div className="e-gradient-bg position-absolute"> </div>
            <div className="container position-relative mt-3 pb-4">
                <h3 className="mb-0 text-center">Your Cart</h3>
                <div className="position-absolute e-back-btn-wrapper">
                    <TextButton
                        name="Back"
                        back={true}
                        handleClick={() => {
                            router.push('/search?query=');
                        }}
                    />
                </div>
            </div>
            <div className="container pt-4 position-relative e-pb-248">
                <div className="row justify-content-center">
                    {
                        cartLoader === true ?
                            <Loader />
                            :
                            <div className="col-lg-7 col-md-10">
                                {
                                    cart && cart.length > 0 ?
                                        <div className="e-cart-wrapper w-100">
                                            {
                                                cart.map((item, i) => {
                                                    return (
                                                        <>
                                                            <div className="container e-px-32 e-py-32">
                                                                <div className="row">
                                                                    <div className="col-6">
                                                                        <div className="d-flex align-items-center h-100">
                                                                            <img src={item.orderType === "PK" ? CalanderIcon : GlobeIcon} alt="" className="e-icon" />
                                                                            <h4 className="mb-0 ps-2">{(item?.itemName).toLowerCase()}</h4>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <div className="d-flex w-100 h-100 align-items-center justify-content-end">
                                                                            {
                                                                                deleteLoader && item.itemId === itemId  ?
                                                                                    <img src={GifLoader} alt="" width="25px" height="25px" />
                                                                                    :
                                                                                    <svg className="e-delete-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => removeItem(i, item.itemName, item.itemId)}>
                                                                                        <path d="M17 22H7C5.89543 22 5 21.1046 5 20V7H3V5H7V4C7 2.89543 7.89543 2 9 2H15C16.1046 2 17 2.89543 17 4V5H21V7H19V20C19 21.1046 18.1046 22 17 22ZM7 7V20H17V7H7ZM9 4V5H15V4H9Z" fill="#11142F" />
                                                                                    </svg>

                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="container e-px-32 e-py-32 e-gradient-faded-bg">
                                                                <div className="row pb-3">
                                                                    <div className="col-lg-8">
                                                                        <div className="d-flex align-items-center h-100">
                                                                            <h5 className="fw-normal mb-0">₹{item.price}/year</h5>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-4">
                                                                        <SingleSelect
                                                                            value={{
                                                                                label: `₹${item.price}`,
                                                                                value: item.price
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-lg-6">
                                                                        {
                                                                            item.orderType === "PK" ?
                                                                                null
                                                                                :
                                                                                <div className="d-flex align-items-center h-100 justify-content-start">
                                                                                    <h5 className="fw-normal mb-0 pe-1">Web protection</h5>
                                                                                    <ToggleButton
                                                                                        defaultActive={true}
                                                                                        onChange={() => { }}
                                                                                    />
                                                                                </div>
                                                                        }
                                                                    </div>
                                                                    <div className="col-lg-6">
                                                                        <div className="d-flex align-items-center h-100 justify-content-end">
                                                                            <h5 className="fw-normal mb-0 pe-1">Auto Renewal</h5>
                                                                            <ToggleButton
                                                                                onChange={() => { }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                })
                                            }

                                            <div className="container e-px-32 e-py-32 e-border-bottom">
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="d-flex justify-content-center flex-column">
                                                            <h4 className="mb-0">Total Amount</h4>
                                                            <span className="e-grey-text">(Taxes will be calculated at checkout)</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="w-100 h-100 d-flex align-items-center justify-content-end">
                                                            <h3 className="mb-0">₹{Math.round((cart?.reduce((a, b) => a + b?.finalPrice, 0) + Number.EPSILON) * 100) / 100}</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="container e-px-32 e-py-32">
                                                <div className="d-flex flex-column flex-md-row align-items-center justify-content-between">
                                                    <div className="d-flex align-items-end pb-3 pb-md-0">
                                                        {/* <h6 className="mb-0 fw-normal">Your Savings</h6> */}
                                                        {/* <h4 className="mb-0 ps-1 e-green-text">₹{cart?.reduce((a, b) => a + b?.discount, 0)}</h4> */}
                                                    </div>
                                                    <Primary
                                                        name="Proceed to Checkout"
                                                        large={true}
                                                        handleClick={() => {
                                                            router.push('/cart/checkout', { cart: cart });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="e-cart-wrapper w-100 e-py-60">
                                            <div className="w-100 e-py-60 d-flex flex-column align-items-center">
                                                <img src={EmptyIllustration} alt="" className="mb-4 e-empty-illustration" />
                                                <h5 className="text-center mb-0 pb-4 pt-2">No items found. Add items to cart to continue.</h5>
                                                <Primary
                                                    name="Search Domains"
                                                    handleClick={() => {
                                                        router.push('/search?query=');
                                                    }}
                                                />
                                            </div>
                                        </div>
                                }
                            </div>
                    }
                </div>
            </div>
            {
                error !== null ?
                    <Toast data={error} />
                    :
                    null
            }
        </div>
    )
}

export default CartContainer;