/*--------------
Properties    default    desc
type          --         possible type: ["web_2", "web_3", "look_alike", "enroachments", "redirections", "resolved", "social", "templates"]
value         0          value to show on card
---------------*/

/*Package importing*/
import React from 'react';
/*import style*/
import styles from '../../styles/components/OverviewCard.module.scss';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const OverviewBookCard = (props) => {
	
    return (
        <div
            className={`${styles.e_overview_wrapper} ${styles.e_web_3}`}>
			
            <div className="d-flex" style={{"cursor":"pointer"}}>
                <div className={styles.e_icon}></div>
                <div className="ps-2 d-flex flex-column pt-1">
                    <h4 className="mb-2">
                        {props.domain}
                    </h4>
                    <div className="d-flex align-items-end">
                        <span className={styles.e_value}>{props.value ? props.value : 0}</span><span className={styles.e_currency}>{props.currency}</span>
                        {
                            props.suffix ?
                                <span className={`${styles.e_suffix} ms-1 mb-1`}>
                                    {props.suffix}
                                </span>
                                :
                                null
                        }
                    </div>
                </div>
            </div>
			<ToastContainer />
        </div>
    )
}

export default OverviewBookCard;