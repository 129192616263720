import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";


/*import style*/
import styles from '../../styles/components/ProfileDetailCard.module.scss';

import { TextButton } from "../cta";

const ManageCouponsCard = (props) => {

    const router = useHistory();
    const [couponsList, setCouponsList] = useState([]);

    useEffect(() => {
        if (props.data.length > 0) {
            setCouponsList(props.data)
        }
    }, [props.data]);

    return (
        <>
            <div className={`${styles.e_card_wrapper}`}>
                <div className="w-100 e-py-32 e-px-32 e-border-bottom">
                    <h3 className="mb-0">Manage Coupons</h3>
                </div>
                <div className="w-100 e-py-32 e-px-32">
                    {
                        couponsList.length > 0 ?
                            <div className={`${styles.e_card_body} p-3 w-100`}>
                                <div className="row gy-3">
                                    {
                                        couponsList.map((item, i) => (
                                            <div className="col-12" key={`card-${i}`}>
                                                <div className={`${styles.e_white_wrapper} p-3`}>
                                                    <div className="w-100 d-flex align-items-center justify-content-between">
                                                        <h5 className="fw-normal mb-0">{item.itemName}</h5>
                                                        <TextButton
                                                            name="Add Coupons"
                                                            blue={true}
                                                            handleClick={() => {
                                                                router.push(`/coupons?domain=${item.itemName}`,{type: props.type})
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            :
                            <div className={`${styles.e_card_body} p-3 w-100`}>No data</div>
                    }
                </div>
            </div>
        </>
    )
}

export default ManageCouponsCard;