import React, { useEffect, useState } from "react";
import { Primary, TextButton } from "../../components/cta";

import sample1 from "../../assets/images/sample-coupon-1.png";
import sample2 from "../../assets/images/sample-coupon-2.png";
import sample3 from "../../assets/images/sample-coupon-3.png";
import { CouponCard } from "../../components/cards";
import AddCouponModal from "../../components/modals/addCouponModal";
import services from "../../services/apis/services";
import Loader from "../../components/others/loader";
import GetURLVar from "../../services/getParams/getUrl";
import { TabHeadingOne } from "../../components/tabHeadings";

const sampleData = [
    { title: "10% off on Lenovo Thinkpads", desc: "Up to 10% Off ThinkBook Laptops. Offer valid till 22nd Oct 2020.", code: "LNV10", image: sample1 },
    { title: "10% off on Lenovo Thinkpads", desc: "Up to 10% Off ThinkBook Laptops. Offer valid till 22nd Oct 2020.", code: "LNV10", image: sample2 },
    { title: "10% off on Lenovo Thinkpads", desc: "Up to 10% Off ThinkBook Laptops. Offer valid till 22nd Oct 2020.", code: "LNV10", image: sample3 },
    { title: "10% off on Lenovo Thinkpads", desc: "Up to 10% Off ThinkBook Laptops. Offer valid till 22nd Oct 2020.", code: "LNV10", image: sample1 },
    { title: "10% off on Lenovo Thinkpads", desc: "Up to 10% Off ThinkBook Laptops. Offer valid till 22nd Oct 2020.", code: "LNV10", image: sample2 },
    { title: "10% off on Lenovo Thinkpads", desc: "Up to 10% Off ThinkBook Laptops. Offer valid till 22nd Oct 2020.", code: "LNV10", image: sample3 },
    { title: "10% off on Lenovo Thinkpads", desc: "Up to 10% Off ThinkBook Laptops. Offer valid till 22nd Oct 2020.", code: "LNV10", image: sample1 },
    { title: "10% off on Lenovo Thinkpads", desc: "Up to 10% Off ThinkBook Laptops. Offer valid till 22nd Oct 2020.", code: "LNV10", image: sample2 },
    { title: "10% off on Lenovo Thinkpads", desc: "Up to 10% Off ThinkBook Laptops. Offer valid till 22nd Oct 2020.", code: "LNV10", image: sample3 }
]

const CouponList = (props) => {
    const [isUserView, setUserView] = useState(false);
    const [couponsList, setCouponsList] = useState([]);
    const [listLoader, setListLoader] = useState(false);
    const [error, setError] = useState(null);
    const [domainName, setDomainName] = useState("");
    const [ activeTab, setActiveTab] = useState(1);
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });


        getQuery();
    }, [isUserView]);

    useEffect(()=>{
        setTimeout(()=>{intiWallet()},500)
    },[])

    useEffect(()=>{
        listenHex();
    },[])

    const listenHex = () =>{
        window.addEventListener("hexProvider", (event) => {
			if(event.detail.message.type==="onAccount" ){
				localStorage.setItem('hedera_address', event.detail.message.accountId);
			}
		  });
    }
    const intiWallet = async () =>{
        var hex = 	await  window.hex.enable();
        console.log('hex', hex);
    }



    const getQuery = async () => {
        let domainName = await GetURLVar();
        setDomainName(domainName.domain)
        getCouponsList(domainName.domain)
    }

    const changeView = () => {
        let currentView = isUserView;
        setUserView(!currentView);
    }

    /* ------- coupons list --------*/
    const getCouponsList = (domainName) => {
        setListLoader(true);
        let url = "action/" + process.env.REACT_APP_GET_COUPON;

        let data = {
            token: localStorage.getItem("web23_token"),
            domain: domainName
        }

        services(true, url, data).then((response) => {
            let res = response.data;

            console.log(res)
            if (res.status === 1) {
                setCouponsList(res.resData);
                setListLoader(false);
            } else if (res.status === 0 || res.status === -1 || res.status === -9) {
                setListLoader(false);
                setError(res?.msg);
            } else {
                setListLoader(false);
                setError(res.msg);
            }
        })
    }

    const handleTabSwitch =  (val) =>{
        setActiveTab(val)
    }

    return (
        <>
            <div className="container-fluid position-relative e-coupon-list-page-container pt-3">
                <div className="e-gradient-bg position-absolute"> </div>
                <div className="container position-relative pt-4 pb-5">
                    <div className="w-100 d-flex align-items-center justify-content-between pb-3">
                        <h3 className="mb-0">Listed Coupons</h3>
                        {
                            !isUserView ?
                                <Primary
                                    name="Add Coupons"
                                    toggle="modal"
                                    target="#addCouponModal"
                                />
                                :
                                null
                        }
                    </div>
                    <div className="col-lg-12 text-end">
                        <a href={`http://${domainName}`} target="_blank">{domainName}</a>
                    </div>
                    <TabHeadingOne
                    className="ps-2"
                    tabs={["Coupons", "NFT Coupons"]}
                    activeTab={activeTab}
                    setActive={handleTabSwitch}
                    activeClass="e-coupons-active-tab"
                />
                    <div className="w-100 py-3">
                        <div className="e-card-wrapper e-px-32 e-py-32">
                            {
                                listLoader ?
                                    <Loader />
                                    :
                                    couponsList.length > 0 ?
                                        <CouponCard
                                            data={couponsList}
                                            type={isUserView ? 1 : 2}
                                            tabIndex={activeTab}
                                        />
                                        :
                                        <p className="text-center">No data found</p>
                            }
                        </div>
                    </div>
                    <div className="pt-3">
                        <TextButton
                            blue={true}
                            name={
                                isUserView ?
                                    "Back to admin view"
                                    :
                                    "Preview user view"
                            }
                            handleClick={changeView}
                        />
                    </div>
                </div>
            </div>
            <AddCouponModal
                type={props.location.state.type}
                added={() => getCouponsList(domainName)}
            />
        </>
    )
}

export default CouponList;