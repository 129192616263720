import React from 'react';

/*import style*/
import styles from '../../styles/components/Toast.module.scss';

function Toast(props){
	return(
    <span className={props.type ==="success" ? styles.e_success : styles.e_fail }>{props.data}</span>
  );
}
export default Toast;