/*--------------
Properties    default    desc

type            --          possible type: ["facebook", "instagram", "linkedin", "twitter", "youtube"]
issues          0           0: healthy, >0: issues present will be displayed
data            []          data populates the dataTable.
not_connected   false       set to true to show disconnected cards
---------------*/

/*Package importing*/
import React, { useState, useEffect } from 'react';

/*import style*/
import styles from '../../styles/components/SocialMediaReport.module.scss';

import NotConnected from '../../assets/images/connect-illustration.svg';

import { HeathIndicatorBadge } from '../badges';
import { Primary, TextButton } from '../cta';
import { SingleSelect } from '../form';
import services from '../../services/apis/services';
import { Toast } from '../alerts';


const SocialMediaReport = (props) => {
    const [showDropdown, setShowDropdown] = useState(null);
    const [details, setDetails] = useState({});
    const [error, setError] = useState(null);

    useEffect(() => {
        loadScript();
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setError(null);
        }, 3000);
    }, [error]);

    /*-------- load fb SDK-------*/
    const loadScript = async () => {

        if (props.type === "Facebook") {
            window.fbAsyncInit = function () {
                window.FB.init({
                    appId: process.env.REACT_APP_FACEBOOK_APP_ID,
                    autoLogAppEvents: true,
                    xfbml: true,
                    version: 'v13.0'
                });
            };
        }
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    }

    /* --- fetching social media details -----*/
    const fetchDetails = (res) => {
        let url = 'action/' + process.env.REACT_APP_SOCIAL_APPS;

        let body = {
            token: localStorage.getItem("web23_token"),
            socialMedia: props.type
        }
        services(true, url, body).then((response) => {
            let res = response.data;

            if (res.status === 1) {

            } else if (res.status === -1 || res.status === 0 || res.status === -8) {
                setError(res.resData[0]?.msg);
            }
            else {
                setError(res.msg);
            }
        })
    }

    const testAPI = () => {
        window.FB.api('/me', function (response) {
            console.log('Successful login for: ' + response.name);
            fetchDetails(response)
        });
    }

    /* ---- status ----*/
    const statusChangeCallback = (response) => {
        if (response.status === 'connected') {
            testAPI();
        } else if (response.status === 'not_authorized') {
            console.log("Please log into this app.");
        } else {
            console.log("Please log into this facebook.");
        }
    }

    /*--- login status ----*/
    const checkLoginState = () => {
        window.FB.getLoginStatus(function (response) {
            statusChangeCallback(response);
        }.bind(this));
    }

    /*---- connnect fb ---*/
    const connectSocialMedia = () => {
        window.FB.login(checkLoginState());
    }

    return (
        <div>
            <div className={`${styles.e_report_wrapper}`}>
                <div className="container-fluid px-0">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="d-flex align-items-start">
                                <div className={
                                    props.type == "Facebook" ?
                                        `${styles.e_fb_icon} ${styles.e_media_icon}`
                                        : props.type == "Instagram" ?
                                            `${styles.e_ig_icon} ${styles.e_media_icon}`
                                            : props.type == "Twitter" ?
                                                `${styles.e_tw_icon} ${styles.e_media_icon}`
                                                : props.type == "LinkedIn" ?
                                                    `${styles.e_ln_icon} ${styles.e_media_icon}`
                                                    : props.type == "Youtube" ?
                                                        `${styles.e_yt_icon} ${styles.e_media_icon}`
                                                        :
                                                        `${styles.e_media_icon}`
                                }>
                                </div>
                                <h3 className="px-2">
                                    {
                                        props.type == "Facebook" ?
                                            `Facebook`
                                            : props.type == "Instagram" ?
                                                `Instagram`
                                                : props.type == "Twitter" ?
                                                    `Twitter`
                                                    : props.type == "LinkedIn" ?
                                                        `LinkedIn`
                                                        : props.type == "Youtube" ?
                                                            `Youtube`
                                                            :
                                                            props.type
                                    }
                                </h3>
                                {
                                    !props.not_connected ?
                                        <HeathIndicatorBadge
                                            healthy={props.issues ? false : true}
                                            value={props.issues}
                                        />
                                        :
                                        null
                                }
                            </div>
                        </div>
                        {
                            !props.not_connected ?
                                <div className="col-lg-6">
                                    <div className="d-flex justify-content-end position-relative">
                                        <div className={`${styles.e_select_wrapper} d-flex align-items-center me-4`}>
                                            <span className={styles.e_select_indicator}>Show:</span>
                                            <div className={`${styles.e_select_container} ms-1`}>
                                                <SingleSelect
                                                    placeholder="This month"
                                                />
                                            </div>
                                        </div>
                                        <div className={styles.e_dotted_burger} onClick={() => setShowDropdown(true)}></div>
                                        <div className={showDropdown ? `${styles.e_dropdown} ${styles.e_dropdown_active} position-absolute py-2` : `${styles.e_dropdown} position-absolute py-2`}>
                                            {
                                                showDropdown ?
                                                    <>
                                                        <div className={`${styles.e_dropdown_option} px-3 py-2 text-end`}>
                                                            <p className="e-warning-color text-end mb-0">Remove Account</p>
                                                        </div>
                                                        <div className={`${styles.e_dropdown_option} px-3 py-2 text-end`}>
                                                            <p className="text-end mb-0">Report Issue</p>
                                                        </div>
                                                    </>
                                                    :
                                                    null
                                            }
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                        }
                    </div>
                    <div className="row mt-4">
                        <div className="col-lg-12">
                            <div className={`${styles.e_list_wrapper} p-3`}>
                                {!props.not_connected ? <h4 className="mb-4">Summary</h4> : null}
                                {
                                    props.issues && !props.not_connected ?
                                        <div className={styles.e_list_container}>
                                            <div className={`${styles.e_table_head} row gx-1 mb-2`}>
                                                <div className="col-3"><h6>Issue</h6></div>
                                                <div className="col-3"><h6>Status</h6></div>
                                                <div className="col-3"><h6>Solved Date</h6></div>
                                                <div className="col-3"><h6>Actions</h6></div>
                                            </div>
                                            <div className={`${styles.e_table_body} row gx-1 gy-4`}>
                                                <div className="col-3">Impersonation</div>
                                                <div className="col-3">
                                                    <div className="d-flex align-items-center">
                                                        <span className={styles.e_status}>Not Resolved</span>
                                                        <div className={`${styles.e_status_icon} ${styles.e_status_not_resolved} ms-1`}></div>
                                                    </div>
                                                </div>
                                                <div className="col-3">--</div>
                                                <div className="col-3">
                                                    <TextButton
                                                        name="Recheck & verify"
                                                        recheck={true}
                                                        small={true} />
                                                </div>
                                                <div className="col-3">Impersonation</div>
                                                <div className="col-3">
                                                    <div className="d-flex align-items-center">
                                                        <span className={styles.e_status}>Auto Resolved</span>
                                                        <div className={`${styles.e_status_icon} ${styles.e_status_resolved} ms-1`}></div>
                                                    </div>
                                                </div>
                                                <div className="col-3">Aug 15 2020</div>
                                                <div className="col-3">
                                                    <TextButton
                                                        name="Recheck"
                                                        recheck={true}
                                                        small={true}
                                                    />
                                                </div>
                                                <div className="col-3">Impersonation</div>
                                                <div className="col-3">
                                                    <div className="d-flex align-items-center">
                                                        <span className={styles.e_status}>Auto Resolved</span>
                                                        <div className={`${styles.e_status_icon} ${styles.e_status_resolved} ms-1`}></div>
                                                    </div>
                                                </div>
                                                <div className="col-3">Aug 15 2020</div>
                                                <div className="col-3">
                                                    <TextButton
                                                        name="Recheck"
                                                        recheck={true}
                                                        small={true} />
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        !props.not_connected ?
                                            <div className="py-4 w-100 d-flex justify-content-center">
                                                <h5 className="mt-3">No Issues Found</h5>
                                            </div>
                                            :

                                            // connect social media
                                            <div className="w-100 d-flex justify-content-center flex-column align-items-center">
                                                {props.type === "Youtube" ? <img src={NotConnected} className={styles.e_not_connected_illustration} alt="Not connected illustration" /> : null}
                                                <h5 className="mt-3">Not Connected Yet</h5>
                                                <Primary
                                                    name={`Connect ${props.type}`}
                                                    handleClick={() => {
                                                        if (props.type === "Facebook") {
                                                            connectSocialMedia()
                                                        } else {
                                                            // fetchDetails();
                                                        }
                                                    }} />
                                            </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                showDropdown ?
                    <div className={`${styles.e_dropdown_backdrop} position-fixed`} onClick={() => setShowDropdown(false)}></div>
                    :
                    null
            }
            {
                error !== null ?
                    <Toast data={error} />
                    :
                    null
            }
        </div>
    )
}

export default SocialMediaReport;