import React, { useEffect, useState } from "react";
import { HeathIndicatorCard, OverviewCard } from ".";

/*import style*/
import styles from '../../styles/components/DomainHealthSummary.module.scss';
import { TextButton } from "../cta";

import { getServices } from "../../services/apis/services";

import { useParams, useLocation } from 'react-router-dom';

const DomainHealthSummary = (props) => {

    const { type } = useParams();
    
    // Extract query parameters
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const urldomain = queryParams.get('domain');

    const [domainsData, setDomainsData] = useState([]);
    const [issuesData, setIssuesData] = useState([]);
    const [matchedDomain, setMatchedDomain] = useState(null);
    const [domainInfo, setDomainInfo] = useState(null)
    const [domainId, setDomainId] = useState(null);

    useEffect(() => {
        const fetchDomains = async () => {
            try {
                const url = "domains/getmaindomain2";
                const token = localStorage.getItem("web23_token");
                const response = await getServices(url, token);

                const domainArray = response?.data?.resData?.data || [];
                
                const matchedDomain = domainArray.find(domain => domain.domain_name === urldomain);
                // console.log("matchhhhhhhhh",matchedDomain)

                if (matchedDomain) {
                    setDomainsData(domainArray); 
                    setMatchedDomain(matchedDomain);
                    
                    setDomainInfo({
                        domain_name: matchedDomain.domain_name,
                        _id: matchedDomain._id
                    });
                    setDomainId(matchedDomain._id);
                    
                } else {
                    console.log("Domain does not match.");
                }

                try {
                    const url = "reports/getIssueDetailsAllDashboardViewReports2";
                    const token = localStorage.getItem("web23_token");
                    const domainId = matchedDomain._id;
                    const domainName = matchedDomain.domain_name;
                
                    const response = await getServices(url, token, { domainId });
                    // console.log("response.data.resData", response.data.resData);
                    const domain_reportById = response.data.resData;
                
                    // Filter issues where the domain_id matches the current domainId
                    // const filteredIssues = domain_reportById.filter(issue => issue._id === domainId);
                    const filteredIssues = domain_reportById.filter(issue => {
                        // console.log("issue._id", issue._id);  
                        return issue.domain_id === domainId;
                    });
                    
                    // console.log("filteredIssues", filteredIssues);
                
                    // Set the filtered issues data
                    setIssuesData(filteredIssues);
                    // console.log("filteredIssues", filteredIssues);
                
                    console.log(`Issues fetched for domainId ${domainId}:`, response);
                } catch (error) {
                    console.error(`Error fetching issues for domainId ${domainId}:`, error);
                }

            } catch (error) {
                console.error("Error fetching domains", error);
            }
        };

        fetchDomains();
    }, []);

    // useEffect(() => {
    //     fetchReportDetails();
    //   }, []);

    //   const fetchReportDetails = async (domainId) => {
    //     try {
    //         const url = "reports/getIssueDetailsAllDashboardViewReports2";
    //         const token = localStorage.getItem("web23_token");
    
    //         const response = await getServices(url, token, { domainId });
    
    //         // Assuming response.data.resData is an array of issues
    //         const filteredIssues = response.data.resData.filter(issue => issue.domain_id === domainId);
    
    //         setIssuesData(filteredIssues);
    
    //         console.log(`Issues fetched for domainId ${domainId}:`, response);
    //     } catch (error) {
    //         console.error(`Error fetching issues for domainId ${domainId}:`, error);
    //     }
    // };


    return (
        <div>
            <div className={`${styles.e_report_wrapper}`}>
                <div className={`${styles.e_report_header}`}>
                    <div className="row">
                        <div className="col-lg-6">
                            <h3 className="mb-0 text-nowrap">Health Summary</h3>
                        </div>
                        <div className="col-lg-6 pt-3 pt-lg-0">
                            <div className="w-100 h-100 d-flex justify-content-end align-items-center">
                                <TextButton name="Recheck" recheck={true} handleClick={props.handleRefresh}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${styles.e_report_body}`}>
                    <div className="row gy-3">
                        <div className="col-lg-2 pe-0">
                            <div className="e-max-content-width">
                                <span className={`${styles.e_indicator_title} pb-3 text-uppercase d-flex justify-content-center`}>Health indicator</span>
                                <span>
                                    <HeathIndicatorCard value={1} />
                                </span>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 ps-0">
                            <div className="w-100 h-100 d-flex align-items-center">
                                <OverviewCard type="resolved" title="Last Check" value="2" suffix="ago" currency="hr"/>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="w-100 h-100 d-flex align-items-center">
                                <OverviewCard type="look_alike" title="Look Alikes" value="0" />
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="w-100 h-100 d-flex align-items-center">
                                <OverviewCard type="enroachments" title="Unresolved Issues" value="0" />
                            </div>
                        </div>
                    </div>
                    <div className="row pt-4">
                        <div className="col-12">

                            <div className={`${styles.e_list_wrapper} py-3`}>
                                <h4 className="mb-4 px-3">Summary</h4>
                                <div className={styles.e_list_container+ " px-3"}>
                                    <div className={`${styles.e_table_head} row gx-1 mb-2`}>
                                        <div className="col-3"><h6>Issue</h6></div>
                                        <div className="col-3"><h6>Status</h6></div>
                                        <div className="col-3"><h6>Solved Date</h6></div>
                                        <div className="col-3"><h6>Actions</h6></div>
                                    </div>
                                    <div className={`${styles.e_table_body} row gx-1 gy-4`}>
                                        <div className="col-3">Impersonation</div>
                                        <div className="col-3">
                                            <div className="d-flex align-items-center">
                                                <span className={styles.e_status}>Not Resolved</span>
                                                <div className={`${styles.e_status_icon} ${styles.e_status_not_resolved} ms-1`}></div>
                                            </div>
                                        </div>
                                        <div className="col-3">--</div>
                                        <div className="col-3"><TextButton name="Recheck & verify" recheck={true} small={true} /></div>
                                        <div className="col-3">Impersonation</div>
                                        <div className="col-3">
                                            <div className="d-flex align-items-center">
                                                <span className={styles.e_status}>Auto Resolved</span>
                                                <div className={`${styles.e_status_icon} ${styles.e_status_resolved} ms-1`}></div>
                                            </div>
                                        </div>
                                        <div className="col-3">Aug 15 2020</div>
                                        <div className="col-3"><TextButton name="Recheck" recheck={true} small={true} /></div>
                                        <div className="col-3">Impersonation</div>
                                        <div className="col-3">
                                            <div className="d-flex align-items-center">
                                                <span className={styles.e_status}>Auto Resolved</span>
                                                <div className={`${styles.e_status_icon} ${styles.e_status_resolved} ms-1`}></div>
                                            </div>
                                        </div>
                                        <div className="col-3">Aug 15 2020</div>
                                        <div className="col-3"><TextButton name="Recheck" recheck={true} small={true} /></div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DomainHealthSummary;