/*--------------
File Uploader

Properties      default                     type            desc

id              --                          string          input id (required).
name            "Upload"                    string/html     upload btn content (required).
accept          --                          string          accept types (required).
isDraggable     false                       bool            set to true to enable drag and drop.
uploadStatus    --                          fn              returns upload status (required).
uploadSucess    --                          fn              returns uploaded file on success (required).
className       "e-file-upload"             string          classnames for upload button styling.

---------------*/

/*Package importing*/
import React from 'react';
import $ from 'jquery';


export default class FileUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            file: "",
            reload: false
        }
    }

    async uploadHandler(file) {
        this.props.uploadStatus(true);
        if (file) {
            let tempFileName = file.name;
            let fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onloadend = function (event) {
                let uploaded = event.target.result;
                let key = this.props.tempKey;
                key = key + "/" + new Date().getTime();
                let data = {
                    'file': uploaded,
                    'fileName': tempFileName,
                    'key': key
                }
                this.props.uploadSucess(file);
            }.bind(this);
        }
        this.props.uploadStatus(false);
    }

    async onChangeHandler(e) {
        if (e.target.files.length) {
            let file = e.target.files[0];
            this.uploadHandler(file);
        }
    }

    fileUploadDrag = (e) => {
        if (this.props.isDraggable) e.preventDefault();
    }

    fileUploadHandler = async (e) => {
        if (this.props.isDraggable) {
            e.preventDefault();
            if (e.dataTransfer.items && e.dataTransfer.items[0].kind === 'file') {
                let file = e.dataTransfer.items[0].getAsFile();
                this.uploadHandler(file);
            }
        }
    }

    render() {
        return (
            <>
                <span
                    className={this.props.className? this.props.className: "e-file-upload"      }
                    onClick={() => { $(`#e-file-uploader-${this.props.id ? this.props.id : "input"}`).trigger('click') }}
                    onDragOver={this.fileUploadDrag.bind(this)} onDrop={this.fileUploadHandler.bind(this)}>{this.props.name? this.props.name : "Upload"}</span>
                {
                    !this.state.reload ?
                        <input
                            id={`e-file-uploader-${this.props.id ? this.props.id : "input"}`}
                            className="e-file-input"
                            type="file"
                            accept={this.props.accept}
                            onChange={this.onChangeHandler.bind(this)}
                        />
                        :
                        null
                }
            </>
        );
    }
}

