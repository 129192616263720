import React from "react";

import { RecordsCard } from "../../components/cards";
import { TextButton } from "../../components/cta";
import AddRecordModal from "../../components/modals/addRecordModal";

const WebThreeManagement=()=>{
    return(
        <>
        <div className="w-100 pb-4">
        <RecordsCard type={3}/>
        </div>
        <div className="w-100 pb-5 pt-3">
            <div className="row gy-4">
                <div className="col-lg-6">
                    <div className="e-card-wrapper w-100 h-100">
                        <div className="w-100 e-py-32 e-px-32 e-border-bottom">
                            <h3 className="mb-0">Sub Domains</h3>
                        </div>
                        <div className="e-px-32 e-py-32 w-100">
                            <div className="d-flex align-items-center justify-content-between">
                                <h5 className="mb-0">Domains</h5>
                                <TextButton name="Add" blue={true}/>
                            </div>
                            <div className="d-flex align-items-center pt-3">
                            <h5 className="mb-0 pe-3 fw-normal">
                            shop.sarahjames.eth
                            </h5>
                            <TextButton name="Edit" blue={true}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <AddRecordModal type={2}/>
        </>
    )
}

export default WebThreeManagement;