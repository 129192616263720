import React, { useState } from 'react';

import { Primary, Secondary } from '../cta';

import DeleteIllustration from '../../assets/images/delete-illustration.png';

const ConfirmDeleteModal = (props) => {
    const [error, setError] = useState(null);

    return (
        <div className="modal fade e-confirm-delete-modal" id="confirmDeleteModal" tabIndex="-1" aria-labelledby="confirmDeleteModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content position-relative">
                    <div className="modal-header">
                        <h5 className="modal-title mb-0" id="confirmDeleteModalLabel">Confirm Delete</h5>
                        <div className="e-close-btn-wrapper d-flex align-items-center justify-content-center position-absolute" data-bs-dismiss="modal">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41L17.59 5Z" fill="#11142F"/>
                            </svg>
                        </div>
                    </div>
                    <div className="modal-body pt-4 pb-2">
                        <div className="d-flex justify-content-center pb-4">
                            <img src={DeleteIllustration} alt="Delete Illustration" className="e-delete-illustration"/>
                        </div>
                        <p className="mb-0">{props.message? props.message: ""}</p>
                    </div>
                    <div className="modal-footer pt-4 justify-content-end align-items-center d-flex">
                        <div className="d-flex align-items-center">
                            <Secondary large={true} className="me-3" name="Yes" red={true}/>
                            <Primary large={true} name="No" cancel="modal"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConfirmDeleteModal;