import React from "react";

/*import style*/
import styles from '../../styles/components/UpgradePlanCard.module.scss';

import UpgradeImg from '../../assets/images/pro-illustration.svg';
import UpgradeImg1 from '../../assets/images/pro-illus-card.svg';
import UpgradeImg2 from '../../assets/images/prop-illus-plant.svg';

import { Secondary } from "../cta";

const specs=["Additional 2 domains", "Own analytics platform", "Chat support", "Optimize hashtags", "Unlimited users"];

const UpgradePlanCard=()=>{
    return(
        <div className={`${styles.e_wrapper} e-py-32 e-px-32 h-100 w-100`}>
            <div className="w-100">
                <h3 className="mb-0">Upgrade To Pro Plan</h3>
                <div className="row">
                    <div className="col">
                        <ul className={`${styles.e_list_wrapper} pt-4`}>
                            {
                                specs.map(item=>{
                                    return(
                                        <li className="d-flex align-items-center" key={`li-${item}`}>
                                            <svg className="me-1" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9 16.5C4.85975 16.4955 1.50455 13.1403 1.5 9.00001V8.85001C1.58245 4.72841 4.97594 1.44598 9.098 1.50067C13.2201 1.55537 16.5253 4.92668 16.4983 9.04902C16.4714 13.1714 13.1224 16.4992 9 16.5ZM5.5575 8.69251L4.5 9.75001L7.5 12.75L13.5 6.75001L12.4425 5.68501L7.5 10.6275L5.5575 8.69251Z" fill="#ffffff"/>
                                            </svg>
                                            {item}
                                        </li>
                                    )
                                })
                            }                            
                        </ul>
                        <div className="pt-3 mt-4">
                        <Secondary name="Upgrade Now" medium={true} white={true}/>
                        </div>
                    </div>
                    <div className="col pt-4 position-relative">
                        <img src={UpgradeImg} className={styles.e_illustration} alt="Upgrade plan illustration" />
                        <img src={UpgradeImg1} alt="" className={styles.e_illustration1} />
                        <img src={UpgradeImg2} alt="" className={styles.e_illustration2} />
                    </div>
                </div>

            </div>
        </div>
    )
}

export default UpgradePlanCard;