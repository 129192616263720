import React, { useState } from 'react';

/*import style*/
import styles from '../../styles/components/TabHeadingTwo.module.scss';

const TabHeadingTwo = (props) => {

    return (
        <div className={`${styles.e_head_overflow_wrapper} w-100 ${props.className}`}>
            {
                props.tabs && props.tabs.length ?
                    <div className={`${styles.e_head_wrapper} d-flex align-items-center`}>
                        {
                            props.tabs.map((tab, i) => {
                                let index = i + 1;
                                return (
                                    <div className={
                                        props.activeTab == index && i > 0 ?
                                            `${styles.e_tab_item} ${styles.e_tab_active} d-flex position-relative ${styles.e_tab_margin}`
                                            : props.activeTab == index && i <= 0 ?
                                                `${styles.e_tab_item} ${styles.e_tab_active} d-flex position-relative`
                                                : i > 0 ?
                                                    `${styles.e_tab_item} d-flex position-relative ${styles.e_tab_margin}`
                                                    : `${styles.e_tab_item} d-flex position-relative`} onClick={() => props.setActive(index)}
                                        key={`tab-heading-${i}`}>
                                        <h5 className={`${styles.e_tab_name} mb-0 pb-2`}>
                                            {tab}
                                        </h5>
                                        <span
                                            className={`${styles.e_active_indicator} position-absolute`}></span>
                                    </div>
                                )
                            })
                        }
                    </div>
                    :
                    null
            }
        </div>
    )
}

export default TabHeadingTwo;