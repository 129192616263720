import React, { useEffect, useState } from 'react';

import HealthIcon from '../../assets/images/icons/health-icon-72.png';
import ProgressBar from '../others/progressBar';

const ScanHealthModal = (props) => {
    const [error, setError] = useState(null);
    const [width, setWidth] = useState(0);
    const [isScanComplete, setScanComplete] = useState(false);

    const handleScanComplete=()=>{
        setWidth(100);
        setTimeout(() => {
            setScanComplete(true);
        }, 500);
    }

    const runScan=()=>{
        setWidth(30);
        setTimeout(() => {
            setWidth(60);
        }, 400);
        setTimeout(() => {
            handleScanComplete()
        }, 1000);
    }

    const setDataToInitial=()=>{
        setWidth(0);
        setScanComplete(false);
    }

    useEffect(()=>{
        let scanHealthModal = document.querySelector('#scanHealthModal');
        scanHealthModal.addEventListener('shown.bs.modal', ()=> runScan());
        scanHealthModal.addEventListener('hidden.bs.modal', ()=> setDataToInitial());
    },[]);


    return (
        <div className="modal fade e-scan-health-modal" id="scanHealthModal" tabIndex="-1" aria-labelledby="scanHealthModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content position-relative justify-content-center">
                    <div className="e-close-btn-wrapper d-flex align-items-center justify-content-center position-absolute" data-bs-dismiss="modal">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41L17.59 5Z" fill="#11142F"/>
                        </svg>
                    </div>
                    <div className={isScanComplete? "e-scan-loader w-100 d-flex flex-column align-items-center e-hide-scan-loader":"e-scan-loader w-100 d-flex flex-column align-items-center"}>
                        <ProgressBar width={width}/>
                        <h4 className="mb-0 pt-3">Scanning<span className="e-append-dots"></span></h4>
                    </div>
                    <div className={isScanComplete?"e-scan-result e-show-result position-absolute d-flex flex-column align-items-center": "e-scan-result position-absolute d-flex flex-column align-items-center"}>
                        {props.title}
                        <div className="e-health-badge-container position-relative my-1">
                            <div className="position-absolute e-indicator-halo-bottom"></div>
                            <div className="position-absolute e-indicator-halo-top"></div>
                            <img src={HealthIcon} alt="health badge" className="position-absolute e-health-icon"/>
                        </div>
                        <h4 className="mb-0 pt-2">100% Safe</h4>
                        <h6 className="e-text-muted fw-normal pt-1 mb-0">No issues found</h6>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ScanHealthModal;