/* Package importing */
import React, { Component } from 'react';
import Chart from 'react-apexcharts';

export default class LineChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            series: this.props.data,
            options: {
                chart: {
                    type: 'line', // Line chart
                    height: this.props.height,
                    toolbar: {
                        show: false
                    },
                    fontFamily: "'Lexend', sans-serif",
                },
                stroke: {
                    curve: 'smooth', // Smooth line
                    width: 2, // Line width
                },
                plotOptions: {
                    line: {
                        dataLabels: {
                            enabled: false
                        },
                        markers: {
                            size: 5, // Size of dots
                            colors: [this.props.color], // Dot color
                            strokeColor: this.props.color,
                            strokeWidth: 2,
                            hover: {
                                size: 8 // Size of dots on hover
                            }
                        }
                    },
                },
                grid: {
                    show: false,
                },
                dataLabels: {
                    enabled: false
                },
                xaxis: {
                    categories: this.props.categories,
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    },
                    labels: {
                        style: {
                            fontWeight: 500,
                            fontSize: "12px",
                            lineHeight: "15px",
                            fontFamily: "'Lexend', sans-serif",
                        }
                    }
                },
                yaxis: {
                    show: true,
                    labels: {
                        style: {
                            fontWeight: 500,
                            fontSize: "12px",
                            lineHeight: "15px",
                            fontFamily: "'Lexend', sans-serif",
                        }
                    }
                },
                fill: {
                    colors: [this.props.color],
                    opacity: 0.3 // Set opacity for the fill under the line
                },
                legend: {
                    fontWeight: 500,
                    fontSize: "12px",
                    lineHeight: "15px",
                    fontFamily: "'Lexend', sans-serif",
                    position: 'bottom'
                },
                tooltip: {
                    shared: true, // Shared tooltips for multiple points
                    intersect: false, // Tooltip shows on hover of points
                    followCursor: true, // Tooltip follows cursor
                    style: {
                        fontFamily: "'Lexend', sans-serif",
                        fontWeight: 700,
                    },
                    y: {
                        formatter: function (value) {
                            return value;
                        }
                    }
                }
            },
        };
    }

    render() {
        return (
            <div className="e-chart-wrapper">
                <Chart options={this.state.options} series={this.state.series} type="line" width={this.props.width} height="150" />
            </div>
        );
    }
}
