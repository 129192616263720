import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { TextButton } from '../../components/cta';

import GetURLVar from '../../services/getParams/getUrl';
import WebThreeManagement from './webThreeManagement';
import WebTwoManagement from './webTwoManagement';

const DomainManagement = (props) => {
    const [domainName, setDomainName] = useState("");

    const router = useHistory();

    const getQuery = async () => {
        let search = await GetURLVar();
        if (search && search.domain) setDomainName(search.domain);
    }

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        getQuery();
    }, []);

    return (
        <div className="container-fluid position-relative e-domain-management-container pt-3">
            <div className="e-gradient-bg position-absolute"></div>
            <div className="container position-relative mt-3 pb-4">
                <h3 className="mb-0 pb-2 text-center">{props.type === "web3" ? "Domain Management" : "DNS Management"}</h3>
                {
                    domainName ?
                        <div className="d-flex align-items-center justify-content-center w-100">
                            <svg className="pe-1" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipeRule="evenodd" d="M1.66602 9.99984C1.66602 14.6022 5.39698 18.3332 9.99935 18.3332C14.5996 18.3281 18.3276 14.6001 18.3327 9.99984C18.3327 5.39746 14.6017 1.6665 9.99935 1.6665C5.39698 1.6665 1.66602 5.39746 1.66602 9.99984ZM3.38426 9.16651C3.64787 7.05252 4.90124 5.24592 6.66602 4.22505V9.16651H3.38426ZM8.33268 9.16651V3.5432C8.86538 3.40609 9.42385 3.33317 9.99935 3.33317C10.5749 3.33382 11.1333 3.4073 11.666 3.5449V9.16651H8.33268ZM11.666 10.8332H8.33268V16.4565C8.86538 16.5936 9.42385 16.6665 9.99935 16.6665C10.5748 16.6665 11.1333 16.5936 11.666 16.4565V10.8332ZM13.3327 15.7746V10.8332H16.6144C16.3508 12.9472 15.0975 14.7538 13.3327 15.7746ZM13.3327 9.16651V4.22823C15.0956 5.24956 16.348 7.05442 16.6136 9.16651H13.3327ZM3.38426 10.8332H6.66602V15.7746C4.90124 14.7538 3.64787 12.9472 3.38426 10.8332ZM16.666 10.0094L16.666 9.99984L16.666 9.99526V10.0094Z" fill="url(#paint0_linear_906:12953)" />
                                <defs>
                                    <linearGradient id="paint0_linear_906:12953" x1="9.99935" y1="1.6665" x2="9.99935" y2="18.3332" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#FFE176" />
                                        <stop offset="1" stopColor="#ECE200" />
                                    </linearGradient>
                                </defs>
                            </svg>
                            <p className="mb-0 e-domain-name-title">{domainName}</p>
                        </div>
                        :
                        null
                }
                <div className="position-absolute e-back-btn-wrapper">
                    <TextButton name="Back" back={true} handleClick={() => router.goBack()} />
                </div>
            </div>
            <div className="container position-relative pt-3 ">
                {
                    props.type==="web2"?
                    <WebTwoManagement domain={domainName}/>
                    :
                    props.type==="web3"?
                    <WebThreeManagement/>
                    :
                    null
                }
            </div>
        </div>
    )
}

export default DomainManagement;