/*--------------
Properties    default    desc
type          --         possible type: ["web_2", "web_3", "look_alike", "enroachments", "redirections", "resolved", "social", "templates"]
value         0          value to show on card
---------------*/

/*Package importing*/
import React, { useEffect, useState } from 'react';
/*import style*/
import styles from '../../styles/components/OverviewCard.module.scss';
import blueCircle from '../../assets/images/loader/blueCircle.gif';
import $ from 'jquery';
import services from '../../services/apis/services';
const OverviewEnroachmentsCard = (props) => {
	let _UI_ID="gsjds3773_";
	let [domainsInfo,setDomainsInfo]=useState([]);
	let [arList,setARList]=useState([]);
	let [pList,setPList]=useState([]);
	let [arCount,setARCount]=useState(0);
	let [pCount,setPCount]=useState(0);
	let [reqStatus,setReqStatus]=useState(false);
	useEffect(() => {
		if(props.domains!=null && props.domains.length>0){
		setTimeout(() => {
			let html="";
			let tmpDomains=[];
			for(let i=0;i<props.domains.length;i++){
				tmpDomains.push(props.domains[i].domain);
			}
			
			domainsARecords(tmpDomains);
			
        }, 1000)	
		}
        
    }, [props.domains]);
	
	
	
	const domainsARecords=(tmpDomains)=>{
		let url = 'dns/ars';	// A records
        let data = {
            domains: tmpDomains.join(",")
        };
		setReqStatus(true);
		
		services(true, url, data).then((response) => {
            let res = response.data
			// console.log(res);
            if (res.status === 1) {
                setPCount(res.resData.PCount);
                setARCount(res.resData.ARCount);
				let IPList=res.resData.ARList;
				props.setBookedIPs(IPList);
				
            } else if (res.status === -1 || res.status === 0 || res.status === -9) {
               
               
            } else {
            }
			
			setReqStatus(false);
			
        });
	}
	return (
        <div className={`${styles.e_overview_wrapper} ${styles.e_web_3}`}>
			{
				/*
				<a  className="d-flex text-decoration-none" style={{"cursor":"pointer"}} role="button" data-bs-toggle="offcanvas" aria-controls={_UI_ID+"offcanvasJList"} href={"#"+_UI_ID+"offcanvasJList"} aria-controls="offcanvasRight" id={_UI_ID+"tooltip"} > 
				*/
			}
			<a  className="d-flex text-decoration-none" style={{"cursor":"pointer"}} role="button" onClick={()=> props.setITab(3)} >
                <div className={styles.e_icon}></div>
                <div className="ps-2 d-flex flex-column pt-1">
                    <h4 className="mb-2 text-decoration-none">
                        {"Web2 Enroachments"}
                    </h4>
                    <div className="d-flex align-items-end text-decoration-none">
                        <span className={styles.e_value}>{props.value ? props.value : 0}</span>
						<span className="badge text-primary">
						Assets:
							{reqStatus && 
									<>
										<img src={blueCircle} style={{width:"32px"}}/>
									</>
							}									
							{!reqStatus && 
								<>
								{arCount}
								</>
							} 
						</span>
                        <span className="badge text-primary">Parked:{pCount}</span>
                    </div>
                </div>
            </a>
			
        </div>
    )
}

export default OverviewEnroachmentsCard;