import React from "react";
import { TemplateCard } from "../../components/cards";

import Web2Icon from '../../assets/images/icons/globe-blue-30.png';

const sampleData= [
    {domain: "Sarahjames.eth", subdomains:[
    {title: "shop.sarahjames.eth", redirection_url: "www.example.com"},
    {title: "store.sarahjames.eth", redirection_url: "www.example.com"},
    {title: "music.sarahjames.eth", redirection_url: "www.example.com"}
    ]},
    {domain: "sarahjames.coin", subdomains:[
        {title: "shop.sarahjames.coin", redirection_url: "www.example.com"},
        {title: "store.sarahjames.coin", redirection_url: "www.example.com"},
        {title: "music.sarahjames.coin", redirection_url: "www.example.com"}
    ]},
    {domain: "Sarahjames.nft", subdomains:[
        {title: "shop.sarahjames.nft", redirection_url: "www.example.com"},
        {title: "store.sarahjames.nft", redirection_url: "www.example.com"},
        {title: "music.sarahjames.nft", redirection_url: "www.example.com"}
    ]},
    {domain: "Sarahjames.x", subdomains:[
        {title: "shop.sarahjames.x", redirection_url: "www.example.com"},
        {title: "store.sarahjames.x", redirection_url: "www.example.com"},
        {title: "music.sarahjames.x", redirection_url: "www.example.com"}
    ]}
];

const WebThreeTemplates=()=>{ 

    return(
        <div>
            {
                sampleData.map((item, i)=>{
                    return(
                        <div className={i!=0?"pt-3": ""} key={`web-2-template-${i}`}>
                            <TemplateCard data={item} isExpanded={i===0} key={i} icon={Web2Icon}/>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default WebThreeTemplates;