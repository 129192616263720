import axios from 'axios';

import Cookies from 'js-cookie';


export default async function services(type, url, data,content) {
  if(url != 'action/check/vlogin') {
    let tokenNew =Cookies.get('web23_token')
    if (!data) {
      data = {}; // Initialize `data` if it's null or undefined
    }
    data.token = tokenNew;
  }
  
  if (type === true) {
    let API_URL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_MODE + url : process.env.REACT_APP_PRO_MODE + url;
    return await axios.post(
      API_URL,
      data,
      {
        'headers': {
          'method': 'post',
          'Accept': 'application/json',
          'Content-Type':  content ? 'multipart/form-data' :  'application/json',
        }
      }

    ).then(function (response) {
      
      if (response.data.msg == 'Session not found, Or Token not found in header') {
        alert("Session not found, Or Token not found in header");
        window.location.href = "/auth/login";
      } 
      return response
    })
      .catch(function (response) {
        if (response.status === 401) {
          localStorage.clear();
          sessionStorage.clear();
          sessionStorage.removeItem("web23_token");
        } else {
          // let res = {
          //   status: "error",
          //   message: "Something went wrong!"
          // };
          return response;
        }
      })
  } else {
    let API_URL = process.env.NODE_ENV === "development" ? process.env.REACT_APP_DEV_MODE + url : process.env.REACT_APP_PRO_MODE + url;
    return await axios.get(
      API_URL,
      data,
      {
        headers: {
          'method': 'get',
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      }
    ).then(function (response) {
          if (response.data.msg == 'Session not found, Or Token not found in header') {
            alert("Session not found, Or Token not found in header");
            window.location.href = "/auth/login";
          }  

          return response
          
        })
      .catch(function (response) {
        if (response.status_code === 401) {
          localStorage.clear();
          sessionStorage.clear();
          sessionStorage.removeItem("web23_token");
        } else {
          // let res = {
          //   status: "error",
          //   message: "Something went wrong!"
          // };
          return response;
        }
      })
  }
} 


export async function getServices(url,token,parameter) {
    let tokenNew = Cookies.get('web23_token')
    token = tokenNew
    let API_URL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_MODE + url : process.env.REACT_APP_PRO_MODE + url;
    return await axios.post(
      API_URL,
      parameter,
      {
        headers: {
          'token': `${token}`,
          'Accept': 'application/x-www-form-urlencoded',
          'Content-Type': 'application/json',
        }
    
      }
    ).then(function (response) {
      if (response.data.msg == 'Session not found, Or Token not found in header') {
        alert("Session not found, Or Token not found in header");
        window.location.href = "/auth/login";
      }

      return response
    })
      .catch(function (response) {
        if (response.status === 401) {
          localStorage.clear();
          sessionStorage.clear();
          sessionStorage.removeItem("web23_token");
        } else {
          // let res = {
          //   status: "error",
          //   message: "Something went wrong!"
          // };
          return response;
        }
      })
  } 

