import React from "react";
import { BillingCard, ProfileDetailCard } from "../../components/cards";

const BillingContainer=(props)=>{

    return(
        <>
        <div className="w-100 pb-3">
            <BillingCard/>
        </div>
        <div className="w-100 pt-4">
            <ProfileDetailCard update={(data) => props.update(data)} billing={true}/>
            {/* <ProfileDetailCard update={(data) => props.update(data)} billing={true}/> */}
        </div>
        </>
    )
}

export default BillingContainer;