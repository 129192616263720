
/* packages */
import React, { useEffect, useState } from 'react';
import $ from 'jquery';

/* components */
import { Primary, Secondary } from '../cta';
import { FileUpload, InputBox, SingleSelect } from '../form';


/* services */
import services from '../../services/apis/services';
import axios from 'axios';
import { Toast } from '../alerts';
const AddKycModal = (props) => {

    const [error, setError] = useState(null);
    const [documentType, setDocumentType] = useState({});
    const [documentId, setDocumentId] = useState("");
    const [fileName, setFileName] = useState("");
    const [uploadName, setUploadName] = useState(false);
    const [uploadStatus, setUploadStatus] = useState(false);
    const [loader, setLoader] = useState(false);
    const [options, setOptions] = useState([]);
    const [picture, setPicture] = useState("");

    useEffect(() => {
        if (props.options.length > 0) {
            var temp_options = [];
            props.options.map((item) => {
                return (
                    temp_options.push({
                        label: item,
                        value: item
                    })

                );
            });
            setOptions(temp_options);
        }
    }, [props.options]);

    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null)
            }, 4000);
        }
    }, [error]);

    useEffect(() => {
        getUploadedDocument();
    }, []);

    /*upload document */
    const handleDocumentUpload = (file) => {
        console.log(file)
        if (file.size <= 100000) {
            setUploadStatus(true)
            setUploadName(false);
            const formdata = new FormData();
            formdata.append("image", file, file.name);


            axios.post("http://147.182.216.70:5001/image/kyc/document/set", formdata, {
                headers: {
                    token: `${localStorage.getItem("web23_token")}`
                }
            }).then((response) => {
                console.log(response)
                if (response.data.status === 1) {
                    setUploadStatus(false);
                    setFileName(file.name);
                    setUploadName(true)
                } else if (response.data.status === 0 || response.data.status === -1 || response.data.status === -9) {
                    setError(response.data.msg);
                    setUploadStatus(false);
                } else {
                    setError(response.data.msg);
                    setUploadStatus(false);
                }

            });
        } else {
            setError("File size must be lest than 100kb")
        }
    }


    /* get uploaded document */
    const getUploadedDocument = () => {

        let data = {
            token: localStorage.getItem("web23_token")
        }

        axios.post("http://147.182.216.70:5001/action/image/kyc/document/get", data).then((response) => {
            let res = response.data;
            if (res.status === 1) {
                setUploadStatus(false);
                setUploadName(true);
                setFileName(res.resData.url.split("get/")[1])
            } else if (res.status === 0 || res.status === -1 || res.status === -9) {
                setError(res.msg);
                setUploadStatus(false);
            } else {
                setError(res.msg);
                setUploadStatus(false);
            }

        });
    }

    /* submit kyc */
    const submitKyc = () => {
        setLoader(true);
        let url = "action/kyc/add";

        let data = {
            token: localStorage.getItem("web23_token"),
            docType: documentType?.label,
            docId: documentId
        }

        services(true, url, data).then((response) => {
            let res = response.data;

            if (res.status === 1) {
                setLoader(false);
                $("#addKycModal").trigger("click");
                props.updated(true);
                setDocumentId("");
                setDocumentType({});
            } else if (res.status === 0 || res.status === -1 || res.status === -9) {
                setError(res.msg);
                setLoader(false);
                setError(res?.resData[0]?.msg)
            } else {
                setError(res.msg);
                setLoader(false);
            }

        })
    }

    const validate = () => {

        if (documentType.label === "Adhaar Card") {
            if (documentId.length === 12) {
                submitKyc()
            } else {
                setError("Enter valid Aadhar number");
                return false
            }
            console.log(documentType)
        } else if (documentType.label === "Pancard") {
            var pattern = new RegExp(/^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/);
            if (pattern.test(documentId)) {
                submitKyc()
            } else {
                setError("Enter valid PAN number , starting with 5 capital letters following with 4 digits and ending with 1 capital letter");
            }
        } else if (documentType.label === "Driving Licence") {
            var pattern = new RegExp(/^([A-Z]{2})(\d{2}|\d{3})[a-zA-Z]{0,1}(\d{4})(\d{7})$/gm);
            if (pattern.test(documentId)){
                submitKyc();
            } else { 
                setError("Please enter valid Driving Licence.(e.g. MH4320120014306), with 2 capital Letters following with 13 or 14 digits")
            }
        }

    }
    const handlePhotoUpload = (file) => {
        console.log(file)
        setPicture(file.name)
    }
    return (
        <div className="modal fade e-add-kyc-modal" id="addKycModal" tabIndex="-1" aria-labelledby="addKycModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content position-relative">
                    <div className="modal-header">
                        <h5 className="modal-title mb-0" id="addKycModalLabel">Add KYC</h5>
                        <div className="e-close-btn-wrapper d-flex align-items-center justify-content-center position-absolute" data-bs-dismiss="modal" onClick={() => { setDocumentId(""); setDocumentType({}); }}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41L17.59 5Z" fill="#11142F" />
                            </svg>
                        </div>
                    </div>
                    <div className="modal-body pt-4 pb-2">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="e-input-wrap">
                                    <label>Document Type <span className="e-warning-color">*</span></label>
                                    {
                                        options?.length > 0 ?
                                            <SingleSelect
                                                options={options}
                                                placeholder="Select Type"
                                                selectchange={val => setDocumentType(val)}
                                            />
                                            :
                                            null
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <InputBox
                                    label={<>{documentType?.label ? documentType?.label : "Document"} ID <span className="e-warning-color">*</span></>}
                                    value={documentId}
                                    type="text"
                                    valueChange={(value) => {
                                        setDocumentId(value);
                                        setError(null);
                                    }}
                                    placeholder="ID"
                                    required={true} />
                            </div>
                            <div className="col-md-6">
                                <div className="e-input-wrap">
                                    <label>Upload Document <span className="e-warning-color">*</span></label>
                                    <div className="pt-2">
                                        <FileUpload
                                            name={uploadName === true ? fileName : "Add Document"}
                                            tempKey="/kyc/document"
                                            id="kyc"
                                            className={uploadStatus ? "e-file-upload w-100 d-flex justify-content-center e-upload-loader" : "e-file-upload w-100 d-flex justify-content-center"}
                                            uploadStatus={() => { }}
                                            uploadSucess={handleDocumentUpload} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="e-input-wrap">
                                    <label>Upload Latest Photo <span className="e-warning-color">*</span></label>
                                    <div className="pt-2">
                                        <FileUpload
                                            name={picture ? picture : "Add photo"}
                                            tempKey="/kyc/photo"
                                            id="kyc-image"
                                            className="e-file-upload w-100 d-flex justify-content-center"
                                            uploadStatus={() => { }}
                                            uploadSucess={handlePhotoUpload} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer pt-3 justify-content-end align-items-center d-flex">
                        <div className="d-flex align-items-center">
                            <Secondary
                                large={true}
                                className="me-3"
                                name="Cancel"
                                cancel="modal"
                                handleClick={() => {
                                    setDocumentId("");
                                    setDocumentType({});
                                }} />
                            {
                                documentId && documentType?.value ?
                                    <Primary
                                        large={true}
                                        name="Save"
                                        className={loader ? "e-btn-loader" : ""}
                                        handleClick={() => { validate(); }} />
                                    :
                                    <Primary large={true} name="Save" disabled={true} />
                            }
                        </div>
                    </div>
                </div>
            </div>
            {
                error !== null ?
                    <Toast data={error} />
                    :
                    null
            }
        </div>
    )
}

export default AddKycModal;