import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import CartIcon from '../../assets/images/icons/cart-24.svg';
import CartIconWhite from '../../assets/images/icons/shopping-cart-white-32.svg'

import { CartContext } from '../../context/cartContext';

/*import style*/
import styles from '../../styles/components/CartDropdown.module.scss';

const CartDropdown = (props) => {
    const [isActive, setmenuActive] = useState(false);
    const [inCart, setInCart] = useState(false);
    const [cartData] = useContext(CartContext);
    const router = useHistory();

    const listenToRouterChange = () => {
        router.listen((loc) => {
            if (loc && loc.pathname && loc.pathname === "/cart") setmenuActive(false);
        })
    }

    useEffect(() => {
        listenToRouterChange();
    }, [])

    const closeMenu = () => {
        setmenuActive(false);
    }

    const handleCartDataChange = () => {
        if (cartData && cartData.length && router.location.pathname != "/cart") {
            setInCart(true);
            setmenuActive(true);
        }
        else if (!cartData || !cartData.length) {
            setInCart(false);
            setmenuActive(false);
        }
    }

    useEffect(() => {
        handleCartDataChange();
    }, [cartData]);

    return (
        <div>
            <div className={`${styles.e_dropdown_wrapper} position-relative`}>
                <div className={`${styles.e_icon_container} position-relative`}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => window.location.href = '/cart'}>
                        <path fillRule="evenodd" clipRule="evenodd" d="M5.792 2H1V4H4.218L6.988 16.678H7V17H20V16.752L22.193 7.091L22.531 6H6.655L6.085 3.389L5.792 2ZM19.987 8H7.092L8.621 15H18.398L19.987 8Z" fill="#11142F" />
                        <path d="M10 22C10.5304 22 11.0391 21.7893 11.4142 21.4142C11.7893 21.0391 12 20.5304 12 20C12 19.4696 11.7893 18.9609 11.4142 18.5858C11.0391 18.2107 10.5304 18 10 18C9.46957 18 8.96086 18.2107 8.58579 18.5858C8.21071 18.9609 8 19.4696 8 20C8 20.5304 8.21071 21.0391 8.58579 21.4142C8.96086 21.7893 9.46957 22 10 22Z" fill="#11142F" />
                        <path d="M19 20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22C16.4696 22 15.9609 21.7893 15.5858 21.4142C15.2107 21.0391 15 20.5304 15 20C15 19.4696 15.2107 18.9609 15.5858 18.5858C15.9609 18.2107 16.4696 18 17 18C17.5304 18 18.0391 18.2107 18.4142 18.5858C18.7893 18.9609 19 19.4696 19 20Z" fill="#11142F" />
                    </svg>
                    <span className={props.cart.length > 0 ? 'd-flex e-icon-indicator-bubble e-indicator-bubble-active position-absolute' : 'd-flex  e-icon-indicator-bubble position-absolute'}></span>
                </div>
                <div className={`${isActive ? `${styles.e_menu_wrapper} ${styles.e_menu_active}` : styles.e_menu_wrapper} position-absolute`}>
                    <div className={`${styles.e_menu_container} mt-1`}>
                        <div className="w-100 h-100 d-flex justify-content-between">
                            <p className="mb-0 pe-2">
                                Products has been added to cart.
                                You can view the cart and checkout from here
                            </p>
                            <div className={`${styles.e_side_container} d-flex h-100 align-items-end flex-column justify-content-between`}>
                                <img src={CartIconWhite} alt="Cart Icon White" className="e-img-32" />
                                <span className={styles.e_close_link} onClick={closeMenu}>Close</span>
                            </div>
                        </div>
                    </div>
                    <svg width="255" height="121" viewBox="0 0 255 121" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <defs>
                            <clipPath id="svgFrame">
                                <path fillRule="evenodd" clipRule="evenodd" d="M247.319 17.0062L237.5 0L227.685 17H8C3.5817 17 0 20.5817 0 25V113C0 117.418 3.5817 121 8 121H247C251.418 121 255 117.418 255 113V25C255 20.6884 251.589 17.1735 247.319 17.0062Z" fill="#C4C4C4" />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
            </div>
        </div>
    )
}

export default CartDropdown;