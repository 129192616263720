import React, { useEffect, useState } from "react";
import { ReportCards } from "../../components/cards";
import Web3Icon from '../../assets/images/icons/globe-purple-30.png';
import { getServices } from "../../services/apis/services";

const WebThreeReport = () => {
  const [domainsData, setDomainsData] = useState([]); // To store domains data
  const [issuesData, setIssuesData] = useState([]); // To store issues data
  const [expandedDomainId, setExpandedDomainId] = useState(null); // To track the expanded domain

  // Fetch all domains on mount
  useEffect(() => {
    const fetchDomains = async () => {
      try {
        const url = "domains/getmaindomain3";
        const token = localStorage.getItem("web23_token");
        const response = await getServices(url, token);
        setDomainsData(response.data.resData.data);
        // console.log("Domains fetched:", response.data);
      } catch (error) {
        console.error("Error fetching domains", error);
      }
    };

    fetchDomains();
  }, []);

  // Fetch issues based on domain_id
  const fetchReportDetails = async (domain_name) => {
    try {
      const url = "reports/getIssueDetailsAllDashboardViewReports3";
      const token = localStorage.getItem("web23_token");

      const response = await getServices(url, token, { domain_name });

        setIssuesData( response.data.resData);
      
      console.log(`Issues fetched for domain_name ${domain_name}:`, response);
    } catch (error) {
      console.error(`Error fetching issues for domain_name ${domain_name}:`, error);
    }
  };

  // Handle domain expand/collapse
  const handleExpand = (domain_name) => {
    setExpandedDomainId((prevDomainId) =>
      prevDomainId === domain_name ? null : domain_name
    );
    // if (!issuesData[domain_name]) {
      fetchReportDetails(domain_name); // Fetch issues only if not already fetched
    // }
  };

  return (
    <div className="w-100 e-web-2-report pt-3 pb-5">
      <h3 className="mb-0 pb-3">Web 3.0 Domain Reports</h3>
      {domainsData.map((domain, i) => {
        const isExpanded = expandedDomainId === domain.domain_name;
        // const domainIssues = issuesData[domain.domain_id] || [];

        return (
          <div className="pt-3" key={`web-2-report-${i}`}>
            <ReportCards
              data={{ ...domain, issues: issuesData }} // Pass issues for this domain
              isExpanded={isExpanded}
              icon={Web3Icon}
              onExpand={() => handleExpand(domain.domain_name)} // Handle expand click
            />
          </div>
        );
      })}
    </div>
  );
};



export default WebThreeReport;
