import React, { useState , useEffect} from 'react';
import services from '../../services/apis/services';
/*import style*/
import styles from '../../styles/components/ContactCard.module.scss';

const ContactCard = (props) => {
	const [contactRec,setContactRec]=useState({});
	useEffect(() => {
		getContactDetails();
	  }, []);
	const getContactDetails = () => {
		setContactRec({});	
		let SESSION_TOKEN=localStorage.getItem("web23_token");
		let url = 'action/bill/getcontact';
		let data = {
		  token:SESSION_TOKEN ,
		};
		
		services(true, url, data).then((response) => {
		  let res = response.data
		  if (res.status === 1) {
			setContactRec(res.resData);
		  } 
		});
  }
    return (
        <div className={`${styles.e_wrapper} w-100 e-px-32 e-py-32 h-100`}>
            <h3 className="mb-0 pb-4">
                Contact Information
            </h3>
            {
                contactRec ?
                    <div className="row pt-1 gy-3">
                        <div className="col-lg-10 col-md-12 col-sm-10">
                            <p className="mb-0 pb-4">{contactRec.fullName ? contactRec.fullName : "--"}</p>
                            <p className="mb-0 pb-2">Address:</p>
                            <p className="mb-0 pb-4">
                                {contactRec.adrLine1 ? contactRec.adrLine1 : "--"}, {contactRec.adrLine2 ? contactRec.adrLine2 : "--"}
                                <p className="mb-0">
                                    {contactRec.city ? contactRec.city : "--"}, {contactRec.state ? contactRec.state : "--"}
                                </p>
                                <p>
                                    {contactRec.pincode ? contactRec.pincode : "--"}, {contactRec.country ? contactRec.country : "--"}
                                </p>
                            </p>
                            <p className="mb-0 pb-2">Email:</p>
                            <p className="mb-0 pb-4">{contactRec.contactEmail ? contactRec.contactEmail : "--"}</p>
                            <p className="mb-0 pb-2">Phone:</p>
                            <p className="mb-0 pb-4">
                                {contactRec.countryCode ? "+" + contactRec.countryCode : "--"} {contactRec.mobileNo ? contactRec.mobileNo : "--"}
                            </p>
                        </div>
                    </div>
                    :
                    <div className="row pt-1 gy-3">
                        <div className="col-lg-10 col-md-12 col-sm-10">
                            <p className="mb-0 pb-4">--</p>
                            <p className="mb-0 pb-2">Address:</p>
                            <p className="mb-0 pb-4">
                                --
                            </p>
                            <p className="mb-0 pb-2">Email:</p>
                            <p className="mb-0 pb-4">--</p>
                            <p className="mb-0 pb-2">Phone:</p>
                            <p className="mb-0 pb-4">
                                --
                            </p>
                        </div>
                    </div>
            }
        </div>
    )
}

export default ContactCard;