/*--------------
Properties    default    desc

---------------*/

/*Package importing*/
import React, { useEffect, useState } from 'react';

/*import style*/
import styles from '../../styles/components/TemplateCard.module.scss';
import { TextButton } from '../cta';

const TemplateCard = (props) => {
    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        if (props.isExpanded) setExpanded(true);
    }, [props.isExpanded]);

    const collapseAccordian = () => {
        let currentState = expanded;
        setExpanded(!currentState);
    }

    return (
        <div>
            {
                props.data?
                <div className={`${styles.e_reports_wrapper} d-flex w-100 flex-column`}>
                <div className={expanded ?
                    `${styles.e_reports_header} ${styles.e_report_expanded} position-relative w-100`
                    :
                    `${styles.e_reports_header} position-relative w-100`}
                    onClick={() => collapseAccordian()}>
                    <div className="row">
                        <div className="col-6">
                            <div className="d-flex align-items-center">
                                <div className={styles.e_head_icon}>
                                    {
                                        props.icon?
                                        <img src={props.icon} alt="card icon"/>
                                        :
                                        null
                                    }
                                </div>
                                <h3 className={props.socialMedia?"mb-0 ms-2 text-capitalize": "mb-0 ms-2 text-lowercase"}>{props.data.domain}</h3>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="d-flex w-100 justify-content-end">
                                <div className={styles.e_expand_icon}></div>
                            </div>
                        </div>
                    </div>
                    <div className={`${styles.e_header_border} position-absolute`}></div>
                </div>
                <div className={expanded ? `${styles.e_reports_body_wrapper} ${styles.e_expanded} w-100` : `${styles.e_reports_body_wrapper} w-100`}>
                    <div className={styles.e_reports_body}>
                        <div className={`${styles.e_table_wrapper}`}>
                            <div className={`${styles.e_table_container}`}>
                                <div className={`${styles.e_table_head} w-100 py-3`}>
                                    <div className="w-100 px-3">
                                        <div className="row">
                                            <div className="col-4">
                                                <h6 className="mb-0">Subdomain Name</h6>
                                            </div>
                                            <div className="col-4">
                                                <h6 className="mb-0">Redirection URL</h6>
                                            </div>
                                            <div className="col-4">
                                                <h6 className="mb-0">Action</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`${styles.e_table_body} w-100`}>
                                    {
                                        props.data.subdomains.map((subdomain, i)=>{
                                            return(
                                                <div className={i<props.data.subdomains.length-1? "w-100 px-3 e-border-bottom": "w-100 px-3"} key={`subdomain-${i}-${props.key}`}>
                                                    <div className="row py-3">
                                                        <div className="col-4">{subdomain.title}</div>
                                                        <div className="col-4">{subdomain.redirection_url}</div>
                                                        <div className="col-4">
                                                            <div className="d-flex align-items-center">
                                                                <TextButton blue={true} name="Edit" toggle="modal" target="#editUrlModal"/>
                                                                <TextButton red={true} name="Delete" className="ms-3" toggle="modal" target="#confirmDeleteModal"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            :
            null
            }
        </div>
    )
}

export default TemplateCard;