import React, { useContext, useEffect, useState } from 'react';

import { message } from 'antd';

import { CartContext } from '../../context/cartContext';

/*--- import services---*/
import services from '../../services/apis/services';

/*import style*/
import styles from '../../styles/components/SearchTable.module.scss';

import { Primary } from '../cta';
import { Toast } from '../alerts';



const SearchW3Table = (props) => {
    const [cartData, setCartData] = useContext(CartContext);
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(null);
    const [cartItem,setCartItem] = useState("");


    useEffect(() => {
        setTimeout(() => {
            setError(null);
        },3000);
    },[error]);
   
    const addItemToCart = (item) => {
        setCartItem(item.domain);
        setLoader(true);

        let url = 'action/order/add/item';

        var params = {
            itemName: item.domain,
            orderType: props.type === "web2" ? "Web2" : "Web3",
            token: localStorage.getItem("web23_token")
        };

        services(true,url, params).then((response) => {
            let res = response.data;

            if (res.status === 1) {
                let items = [];
                items.push(res.resData);
                setCartData(items);
                setLoader(false);
            } else {
                setError(res.resData[0].msg);
                setLoader(false);
            }
        });
    }

    return (
        <div className='mt-4 py-4'>
            <div className="">
                <div className="">
					<table className="table table-borderless">
                    <thead>
                        <tr>
                            <td><h6 className="mb-0">Web3 Domain Name</h6></td>
                            <td><h6 className="mb-0">Price (In HBAR)</h6></td>
                            <td><h6 className="mb-0">Action</h6></td>
                        </tr>
                    </thead>
                    <tbody >
                        {
                            props.data ?
                                <>
                                    {
                                        props.data.map((item, i) => {
                                            return (
                                                <tr  key={`search-result-row-${i}`}>
                                                    
                                                    
                                                        <td className="text-break">
                                                            <div className="w-100 d-flex flex-column">
                                                                <div className="fs-4 mb-2 text-lowercase text-break">{item.name}</div>
                                                                {
                                                                    item.badge ?
                                                                        <span className={`${styles.e_recommented_badge} text-capitalize`}>
                                                                             {item.badge}
                                                                        </span>
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                        </td>
                                                        <td className="r899">
                                                            <span className="d-flex h-100">
                                                                ℏ&nbsp;&nbsp;{item.price}
                                                            </span>
                                                        </td>
                                                        <td className="y788">
                                                            <div className="d-flex ">
                                                               <Primary name="Purchase" small={true}   handleClick={() => alert("Hello")} /> 
                                                            </div>
                                                        </td>
                                                    
                                                </tr>
                                            )
                                        })
                                    }
                                </>
                                :
                                null
                        }
                    </tbody>
                </table>
			</div>	
            </div>
            {
                error !== null ?
                    <Toast data={error} type="fail" />
                    :
                    null
            }
        </div>
    )
}

export default SearchW3Table;