/*--------------
props

prop.type 
value           desc.          
1               No KYC details found             
2               KYC details present

---------------*/

import React, { useState } from "react";

/*import style*/
import styles from '../../styles/components/ProfileDetailCard.module.scss';

import Icon from '../../assets/images/profile/user-card-icon.png';

import { Primary, TextButton } from "../cta";

const KycCard = (props) => {
    const [showDropdown, setDropdown] = useState(false);

    return (
        <>
            <div className={`${styles.e_card_wrapper} ${styles.e_kyc_card} e-py-32 e-px-32`}>
                <div className="w-100 d-flex align-items-center justify-content-between pb-3">
                    <div className="d-flex align-items-center">
                        <div className={styles.e_card_icon}>
                            <img src={Icon} alt="orders icon" />
                        </div>
                        <h3 className="mb-0 ps-1">KYC Details</h3>
                    </div>
                    {
                        props.type && props.type !== 1 ?
                            <div className="position-relative d-flex">
                                <div className={styles.e_dotted_burger} onClick={() => setDropdown(true)}></div>
                                <div className={showDropdown ? `${styles.e_dropdown} ${styles.e_dropdown_active} position-absolute py-2` : `${styles.e_dropdown} position-absolute py-2`}>
                                    <div className="px-3 py-2 text-end">
                                        <TextButton name={
                                            <div className="d-flex align-items-center">
                                                <svg className="me-1" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M3.68333 17.1492C3.44956 17.1488 3.22672 17.0502 3.06916 16.8775C2.9087 16.7062 2.82896 16.4746 2.84999 16.2408L3.05416 13.9958L12.4858 4.5675L15.4333 7.51417L6.00416 16.9417L3.75916 17.1458C3.73333 17.1483 3.70749 17.1492 3.68333 17.1492ZM16.0217 6.925L13.075 3.97834L14.8425 2.21084C14.9988 2.05436 15.2109 1.96643 15.4321 1.96643C15.6533 1.96643 15.8654 2.05436 16.0217 2.21084L17.7892 3.97834C17.9456 4.13464 18.0336 4.34675 18.0336 4.56792C18.0336 4.78909 17.9456 5.0012 17.7892 5.1575L16.0225 6.92417L16.0217 6.925Z" fill="#4660F1" />
                                                </svg>
                                                Edit Details
                                            </div>
                                        } toggle="modal" target="#addKycModal" handleClick={()=>setDropdown(false)}/>
                                    </div>
                                </div>
                            </div>
                            :
                            null
                    }
                </div>
                <div className="w-100 pt-3">
                    {
                        props.type && props.type === 1 ?
                            <div className={`${styles.e_card_body} ${styles.e_empty_kyc} p-3 w-100 d-flex align-items-center justify-content-center`}>
                                <Primary name="Add KYC Details" toggle="modal" target="#addKycModal"/>
                            </div>
                            :
                            props.type && props.type === 2 ?
                                <div className={`${styles.e_card_body} p-3 w-100`}>
                                    <div className="row gx-1">
                                        <div className="col">
                                            <h4>Document</h4>
                                            <h5 className="mb-0 fw-normal pt-1">Passport</h5>
                                        </div>
                                        <div className="col">
                                            <h4>Status</h4>
                                            {
                                                1 == 1 ?
                                                    <span className={`${styles.e_badge} ${styles.e_verified}`}>Verified</span>
                                                    :
                                                    <span className={`${styles.e_badge}`}>In Progress</span>
                                            }
                                        </div>
                                    </div>
                                    <div className="row gx-1 pt-4">
                                        <div className="col">
                                            <h4>Unique ID</h4>
                                            <h5 className="mb-0 fw-normal pt-1">J25XXXXX</h5>
                                        </div>
                                        <div className="col">
                                            <h4>Status</h4>
                                            {
                                                1 == 1 ?
                                                    <span className={`${styles.e_badge} ${styles.e_verified}`}>Verified</span>
                                                    :
                                                    <span className={`${styles.e_badge}`}>In Progress</span>
                                            }
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className={`${styles.e_card_body} p-3 w-100`}></div>
                    }
                </div>
            </div>
            {
                showDropdown ?
                    <div className={`${styles.e_backdrop} position-fixed`} onClick={() => setDropdown(false)}></div>
                    :
                    null
            }
        </>
    )

}

export default KycCard;