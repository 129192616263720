import React, { useState } from "react";
import styles from '../../styles/components/ExpiringSoon.module.scss';
import { Primary } from "../cta";

const ExpiringSoon = (props) => {
    const [currentPage, setCurrentPage] = useState(1); // State to track current page
    const itemsPerPage = 5; // Number of items per page

    // Calculate the total number of pages
    const totalPages = Math.ceil(props.data.length / itemsPerPage);

    // Get the current items based on pagination
    const startIndex = (currentPage - 1) * itemsPerPage;
    const currentItems = props.data.slice(startIndex, startIndex + itemsPerPage);

    // Handle page change
    const goToPage = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <div className={styles.e_card_wrapper}>
            <h3 className="mb-0">Expiring Soon</h3>
            <div className={`${styles.e_table_wrapper} w-100 pt-3`}>
                <div className={`${styles.e_table_container} py-3`}>
                    <div className={`${styles.e_table_head} w-100`}>
                        <div className="w-100 px-3">
                            <div className="row pb-4">
                                <div className="col-4">
                                    <h5 className="mb-0">Domain Name</h5>
                                </div>
                                <div className="col-3">
                                    <h5 className="mb-0">Expiry on</h5>
                                </div>
                                <div className="col-3">
                                    <h5 className="mb-0">Auto Renewal</h5>
                                </div>
                                <div className="col-3"></div>
                            </div>
                        </div>
                    </div>
                    <div className={`${styles.e_table_body} w-100`}>
                        {currentItems && currentItems.length ? (
                            <div className="w-100 px-3">
                                <div className="row gy-4">
                                    {currentItems.map((item, i) => (
                                        <div className="col-12" key={i}>
                                            <div className="row">
                                                <div className="col-4">
                                                    <div className="h-100 d-flex align-items-center">
                                                        <h3 className="mb-0 text-lowercase">{item.name}</h3>
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <div className="h-100 d-flex align-items-center">
                                                        <p className="mb-0">{item.expiry}</p>
                                                    </div>
                                                </div>
                                                <div className="col-2">
                                                    <div className="h-100 d-flex align-items-center">
                                                        <p className={item.auto_renewal ? "mb-0 e-green-text fw-bold" : "mb-0 e-warning-color fw-bold"}>
                                                            {item.auto_renewal ? "On" : "Off"}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    {item.auto_renewal ? (
                                                        <Primary
                                                            grey={true}
                                                            disabled={true}
                                                            name={
                                                                <span className={styles.e_auto_renew_btn_content + " d-flex align-items-center flex-column"}>
                                                                    <span className="fw-normal">Will renew on</span>
                                                                    <span className="fw-bold">{item.expiry}</span>
                                                                </span>
                                                            }
                                                        />
                                                    ) : (
                                                        <Primary name="Renew" />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ) : 
                            <center><p>No Data Available</p></center>
                        }
                    </div>
                </div>

                {/* Pagination Controls */}
                <div className="pagination-controls d-flex justify-content-center mt-4">
                    <button
                        className="btn btn-secondary mx-2"
                        onClick={() => goToPage(currentPage - 1)}
                        disabled={currentPage === 1}
                    >
                        Previous
                    </button>
                    {Array.from({ length: totalPages }, (_, i) => (
                        <button
                            key={i}
                            className={`btn mx-1 ${currentPage === i + 1 ? 'btn-primary' : 'btn-outline-primary'}`}
                            onClick={() => goToPage(i + 1)}
                        >
                            {i + 1}
                        </button>
                    ))}
                    <button
                        className="btn btn-secondary mx-2"
                        onClick={() => goToPage(currentPage + 1)}
                        disabled={currentPage === totalPages}
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ExpiringSoon;
