/* Packages */

import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";

/* Services */
// import devconfig from '../../config/config';
// import APIGet from '../../services/apis/services';
// import APIPost from '../../services/apis/postApi';

/* Components */
import { Primary, TextButton } from '../cta';
import { InputBox } from '../form';
import services from '../../services/apis/services';
import { message } from 'antd';

const VerifyOtp = (props) => {
    const router = useHistory();
    const [otp, setOtp] = useState(null);
    const [remaining, setRemaining] = useState(null);
    const [error, setError] = useState(null);
    const [loader, setLoader] = useState(false);
    const history = useHistory();

    useEffect(() => {
        if (remaining) {
            let count = 30;
            setTimeout(() => {
                setRemaining(count - 1);
            }, 1000);
        }
    }, [remaining]);

    useEffect(() => {
        setTimeout(() => {
            setError(null);
        }, 3000);
    }, [error]);

    /*--- verifying otp api integration ---*/
    const enterOtp = () => {
        setLoader(true);

        let url = 'action/verifyotp/signup';

        let data = {
            otp: otp,
            otpToken: history.location?.state?.token,
        };

        services(true, url, data).then((response) => {
            let res = response.data;
            if (res.status === 1) {
                window.location.href = "/auth/login";
                setLoader(false);
            } else {
                setError(res.msg);
                setLoader(false);
            }
        });
    }


    /*---forgot paswd verifying otp api integration ---*/
    const enterOtpForgotPwd = () => {
        setLoader(true);

        let url = 'action/verifyotp/forgotpwd';

        let data = {
            otp: otp,
            otpToken: history.location?.state?.token,
        };

        services(true, url, data).then((response) => {
            let res = response.data;
            if (res.status === 1) {
                router.push('/auth/create-password',{token : res.resData.otpToken});
                setLoader(false);
            } else {
                setError(res.msg);
                setLoader(false);
            }
        });
    }

    /*-------otp validation 123456 ---------*/
    const verifyOtp = async (e) => {
        e.preventDefault();
        if (otp > 0 && otp.length === 6) {
            if (props.redirect === "forgot-password") {
                enterOtpForgotPwd();
            }
            else if (props.redirect === "login" || props.redirect === "create-account") {
                enterOtp();
            }
        }
        else {
            setError('Enter a valid OTP');
        }
    }

    /*------------resend otp api--------*/
    const resendOtp = () => {
        setRemaining(60);

        let url = 'action/get/reotp/signup';

        let data = {
            email: history.location?.state?.email,
            otpToken: history.location?.state?.token,
        };

        services(true, url, data).then((response) => {
            let res = response.data
            if (response.status === 1) {
                message.success("OTP sent successfully")
            } else {
                setError(res.resData[0].msg);
            }
        });
    }

    return (
        <div className="container-fluid p-0">
            <div className="e-auth-container w-100">
                <div className="e-auth-max-width-container mx-auto position-relative">
                    <h3 className="text-center mb-3">Verify OTP</h3>
                    <span className="position-absolute e-fp-back-btn">
                        <TextButton name="Back" back={true} small={true} handleClick={() => router.push(`/auth/${props.redirect ? props.redirect : ""}`)} />
                    </span>
                </div>
                <div className="w-100 d-flex justify-content-center pt-3">
                    <div className="e-auth-form-wrapper">
                        <form>
                            <div className="row">
                                <div className="col-lg-12 pb-2">
                                    <InputBox
                                        label="One Time Password"
                                        value={otp}
                                        type="password"
                                        valueChange={(value) => {
                                            setOtp(value);
                                            setError(null);
                                        }}
                                        placeholder="One Time Password"
                                        tip="Please enter the OTP recieved in your email"
                                        required={true}
                                        error={error} />
                                </div>
                                <div className="pt-4 col-lg-12 pb-3">
                                    {
                                        otp ?
                                            <Primary
                                                className={loader ? "e-btn-loader" : ""}
                                                name="Verify OTP"
                                                full_width={true}
                                                handleClick={(e) => {
                                                    verifyOtp(e)
                                                }}
                                            />
                                            :
                                            <Primary
                                                name="Verify OTP"
                                                full_width={true}
                                                disabled={true}
                                            />
                                    }
                                </div>
                                <div className="col-lg-12 pt-3">
                                    <div className="d-flex align-items-center">
                                        <TextButton
                                            name="Resend"
                                            blue={true}
                                            small={true}
                                            disabled={remaining}
                                            handleClick={() => resendOtp()}
                                        />
                                        {
                                            remaining ?
                                                <p className="mb-0 me-1">{`in ${remaining} sec`}</p>
                                                :
                                                null
                                        }
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VerifyOtp;