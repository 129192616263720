/*--------------
prop-type        card-type
1                Web2 Records Modal
2                Web3 Records Modal
---------------*/

import React, { useState } from 'react';

import { Primary, Secondary } from '../cta';

import { InputBox, SingleSelect } from '../form';

const w2TypeOptions = [
    {value: 1, label: "Nameserver"},
    {value: 2, label: "A"},
    {value: 3, label: "CNAME"},
    {value: 4, label: "MX"},
    {value: 5, label: "TXT"},
    {value: 6, label: "SRV"},
    {value: 7, label: "AAAA"},   
    {value: 8, label: "CAA"},   
]

const w3TypeOptions = [
    {value: 1, label: "Address"},
    {value: 2, label: "Other Addresses"},
    {value: 3, label: "Content"},
    {value: 4, label: "Content"},
]

const AddRecordModal = (props) => {
    const [error, setError] = useState(null);

    const [type, setType] = useState(null);
    const [service, setService] = useState("");
    const [protocol, setProtocol] = useState("");
    const [name, setName] = useState("");
    const [target, setTarget] = useState("");
    const [priority, setPriority] = useState("");
    const [weight, setWeight] = useState("");
    const [port, setPort] = useState("");
    const [ttl, setTtl] = useState(null);

    const [address, setAddress] = useState("");
    const [currency, setCurrency] = useState(null);

    

    return (
        <div className="modal fade e-add-record-modal" id="addRecordModal" tabIndex="-1" aria-labelledby="addRecordModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content position-relative">
                    <div className="modal-header">
                        <h5 className="modal-title mb-0" id="addRecordModalLabel">Add Record</h5>
                        <div className="e-close-btn-wrapper d-flex align-items-center justify-content-center position-absolute" data-bs-dismiss="modal">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41L17.59 5Z" fill="#11142F"/>
                            </svg>
                        </div>
                    </div>
                    <div className="modal-body pt-4 pb-2">
                        {
                            props.type===1?
                            <>
                            <div className="row">
                                <div className="col-lg-4 col-md-6">
                                    <div className="e-input-wrap">
                                        <label>Type <span className="e-warning-color">*</span></label>
                                        <SingleSelect
                                            placeholder="Select Type"
                                            value={type}
                                            selectchange={val => setType(val)}
                                            options={w2TypeOptions}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <InputBox
                                        label={<>Service <span className="e-warning-color">*</span></>}
                                        value={service}
                                        type="text"
                                        valueChange={(value) => {
                                            setService(value);
                                            setError(null);
                                        }}
                                        placeholder="Service"
                                        required={true} />
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <InputBox
                                        label={<>Protocol <span className="e-warning-color">*</span></>}
                                        value={protocol}
                                        type="text"
                                        valueChange={(value) => {
                                            setProtocol(value);
                                            setError(null);
                                        }}
                                        placeholder="Protocol"
                                        required={true} />
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <InputBox
                                        label={<>Name <span className="e-warning-color">*</span></>}
                                        value={name}
                                        type="text"
                                        valueChange={(value) => {
                                            setName(value);
                                            setError(null);
                                        }}
                                        placeholder="Name"
                                        required={true} />
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <InputBox
                                        label={<>Target <span className="e-warning-color">*</span></>}
                                        value={target}
                                        type="text"
                                        valueChange={(value) => {
                                            setTarget(value);
                                            setError(null);
                                        }}
                                        placeholder="Target"
                                        required={true} />
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <InputBox
                                        label={<>Priority <span className="e-warning-color">*</span></>}
                                        value={priority}
                                        type="text"
                                        valueChange={(value) => {
                                            setPriority(value);
                                            setError(null);
                                        }}
                                        placeholder="Priority"
                                        required={true} />
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <InputBox
                                        label={<>Weight <span className="e-warning-color">*</span></>}
                                        value={weight}
                                        type="text"
                                        valueChange={(value) => {
                                            setWeight(value);
                                            setError(null);
                                        }}
                                        placeholder="Weight"
                                        required={true} />
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <InputBox
                                        label={<>Port <span className="e-warning-color">*</span></>}
                                        value={port}
                                        type="text"
                                        valueChange={(value) => {
                                            setPort(value);
                                            setError(null);
                                        }}
                                        placeholder="Port"
                                        required={true} />
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="e-input-wrap">
                                        <label>TTL <span className="e-warning-color">*</span></label>
                                        <SingleSelect
                                            placeholder="Select TTL"
                                            value={ttl}
                                            selectchange={val => setTtl(val)}
                                        />
                                    </div>
                                </div>
                            </div>
                            </>
                            :
                            props.type===2?
                            <>
                            <div className="row">
                                <div className="col-lg-4 col-md-6">
                                    <div className="e-input-wrap">
                                        <label>Type <span className="e-warning-color">*</span></label>
                                        <SingleSelect
                                            placeholder="Select Type"
                                            value={type}
                                            selectchange={val => setType(val)}
                                            options={w3TypeOptions}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="e-input-wrap">
                                        <label>Currency <span className="e-warning-color">*</span></label>
                                        <SingleSelect
                                            placeholder="Select Currency"
                                            value={currency}
                                            selectchange={val => setCurrency(val)}
                                        />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <InputBox
                                        label={<>Address <span className="e-warning-color">*</span></>}
                                        value={address}
                                        type="text"
                                        valueChange={(value) => {
                                            setAddress(value);
                                            setError(null);
                                        }}
                                        placeholder="Address"
                                        required={true} />
                                </div>
                            </div>
                            </>
                            :
                            null
                        }
                    </div>
                    <div className="modal-footer pt-4 justify-content-end align-items-center d-flex">
                        <div className="d-flex align-items-center">
                            <Secondary large={true} className="me-3" name="Cancel" cancel="modal"/>
                            <Primary large={true} name="Save" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddRecordModal;