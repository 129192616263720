/*--------------
Properties    default         desc

name           --             Button Name
type           button         Button type
disabled       false          set to true to disable button
forward		     false		      set to true to show next icon
back		       false	        set to true to show back icon
download	     false          set to true to show download icon
recheck		     false		      set to true for recheck icon
refresh        false          set to true for recheck icon on left
white          false          set to true for white btn
blue           false          set to true for blue btn
red            false          set to true for red btn
small		       false 		      set to true to reduce button font size
className      --             classname for button wrapper
cancel         --             data-bs-dismiss attribute for bootstrap
toggle         --             data-bs-toggle attribute for bootstrap
target         --             data-bs-target attribute for bootstrap
handleClick    --             onClick function
handleSubmit   --             onKeyPress function
---------------*/

/*Package importing*/
import React from 'react';

/*import style*/
import styles from '../../styles/components/TextButton.module.scss';

export default function TextButton(props) {
  return (
    <div className={
      props.disabled && props.white ?
        `${styles.e_btn_wrapper} ${styles.e_btn_disabled} ${styles.e_btn_white} ${props.className}`
        : props.white ?
          `${styles.e_btn_wrapper} ${styles.e_btn_white} ${props.className}`
          :props.disabled && props.red ?
          `${styles.e_btn_wrapper} ${styles.e_btn_disabled} ${styles.e_btn_red} ${props.className}`
          : props.red ?
            `${styles.e_btn_wrapper} ${styles.e_btn_red} ${props.className}`
          : props.disabled ?
            `${styles.e_btn_wrapper} ${styles.e_btn_disabled} ${props.className}`
            : `${styles.e_btn_wrapper} ${props.className}`}>
      <button
        id={props.id}
        className={props.recheck ? `${styles.e_t_btn} ${styles.e_t_btn_recheck}` : `${styles.e_t_btn}`}
        data-bs-dismiss={props.cancel}
        data-bs-toggle={props.toggle}
        data-bs-target={props.target}
        onClick={props.handleClick}
        onKeyPress={props.handleSubmit}
        type={props.type ? props.type : "button"} >
        <span className={'d-flex align-items-center'}>
          {
            props.back && props.small ?
              <>
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9C16.4955 13.1403 13.1403 16.4955 9 16.5ZM9 3C5.70405 3.00083 3.02585 5.66015 3.00168 8.95601C2.97751 12.2519 5.61644 14.9502 8.91202 14.9993C12.2076 15.0485 14.9258 12.4301 15 9.135V10.4722V9C14.9963 5.68783 12.3122 3.00372 9 3ZM9 12.75L5.25 9L9 5.25L10.0575 6.3075L8.1225 8.25H12.75V9.75H8.1225L10.0575 11.6925L9 12.75Z" fill="#4660F1" />
                </svg>
                <span className={`${styles.e_t_btn_small} ms-2`}>
                  {props.name}
                </span>
              </>
              : props.back ?
                <>
                  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.9998 29.3337C8.63604 29.3337 2.6665 23.3641 2.6665 16.0003C2.6665 8.63653 8.63604 2.66699 15.9998 2.66699C23.3636 2.66699 29.3332 8.63653 29.3332 16.0003C29.3251 23.3608 23.3603 29.3256 15.9998 29.3337ZM15.9998 5.33366C10.1404 5.33514 5.37912 10.0628 5.33616 15.9221C5.2932 21.7814 9.98462 26.5784 15.8434 26.6658C21.7022 26.7532 26.5347 22.0983 26.6665 16.2403V18.6177V16.0003C26.6599 10.112 21.8881 5.34027 15.9998 5.33366ZM15.9998 22.667L9.33317 16.0003L15.9998 9.33366L17.8798 11.2137L14.4398 14.667H22.6665V17.3337H14.4398L17.8798 20.787L15.9998 22.667Z" fill="#4660F1" />
                  </svg>
                  <span className="ms-2">
                    {props.name}
                  </span>
                </>
                : props.forward ?
                  <>
                    <span className="me-1">
                      {props.name}
                    </span>
                    <svg className={props.name && props.name.length ? "" : `${styles.e_svg_hover}`} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M15.0006 4.50002H15C9.20101 4.50002 4.5 9.20103 4.5 15C4.5 20.799 9.20101 25.5 15 25.5C20.799 25.5 25.5 20.799 25.5 15V14.9995C25.4935 9.20348 20.7965 4.50653 15.0006 4.50002ZM16.4625 15.75H9.25V14.25H16.4625H17.6663L16.8167 13.3972L13.9439 10.5132L15 9.45713L20.5429 15L15 20.5429L13.9439 19.4868L16.8167 16.6029L17.6663 15.75H16.4625ZM15.0005 27C8.37588 26.9927 3.00742 21.6243 3 14.9997V14.7551C3.13453 8.16283 8.56305 2.91359 15.1567 3.00108C21.752 3.08859 27.0403 8.48269 26.9972 15.0784C26.9542 21.674 21.5961 26.9984 15.0005 27Z" fill="#4660F1" stroke="#4660F1" />
                    </svg>
                  </>
                  : props.download && props.blue ?
                  <>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C21.9939 17.5203 17.5203 21.9939 12 22ZM12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C19.995 7.58378 16.4162 4.00496 12 4ZM12 17L7 12L8.41 10.59L11 13.17V7H13V13.17L15.59 10.59L17 12L12 17Z" fill="#4660F1" />
                    </svg>
                    <span className={`${styles.e_blue_btn} ${styles.newdownloadbtn_class} ms-1`}>
                      {props.name}
                    </span>
                  </>
                  : props.download ?
                    <>
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C21.9939 17.5203 17.5203 21.9939 12 22ZM12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C19.995 7.58378 16.4162 4.00496 12 4ZM12 17L7 12L8.41 10.59L11 13.17V7H13V13.17L15.59 10.59L17 12L12 17Z" fill="#fff" />
                      </svg>
                      <span className="ms-1">
                        {props.name}
                      </span>
                    </>
                    : props.recheck && props.small ?
                      <>
                        <span className={`${styles.e_t_btn_small} me-1`}>
                          {props.name}
                        </span>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M3.3335 16.6669V10.8336H9.16683L6.486 13.5169C7.41096 14.4627 8.67722 14.9972 10.0002 15.0002C12.1164 14.9971 14.0014 13.6621 14.7068 11.6669H14.7218C14.8173 11.3957 14.8895 11.117 14.9377 10.8336H16.6143C16.1944 14.1668 13.3598 16.6668 10.0002 16.6669H9.99183C8.22413 16.6722 6.5278 15.97 5.281 14.7169L3.3335 16.6669ZM5.06183 9.1669H3.38516C3.80495 5.8349 6.63766 3.33535 9.996 3.33353H10.0002C11.7682 3.32787 13.4649 4.0301 14.7118 5.28356L16.6668 3.33353V9.1669H10.8335L13.5185 6.48356C12.5926 5.5366 11.3246 5.00201 10.0002 5.00023C7.88396 5.00335 5.9989 6.33838 5.2935 8.33356H5.2785C5.18229 8.60449 5.11007 8.88334 5.06266 9.1669H5.06183Z" fill="#9EA3AA" />
                        </svg>
                      </>
                      : props.recheck ?
                        <>
                          <span className="me-2">
                            {props.name}
                          </span>
                          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.3335 16.6669V10.8336H9.16683L6.486 13.5169C7.41096 14.4627 8.67722 14.9972 10.0002 15.0002C12.1164 14.9971 14.0014 13.6621 14.7068 11.6669H14.7218C14.8173 11.3957 14.8895 11.117 14.9377 10.8336H16.6143C16.1944 14.1668 13.3598 16.6668 10.0002 16.6669H9.99183C8.22413 16.6722 6.5278 15.97 5.281 14.7169L3.3335 16.6669ZM5.06183 9.1669H3.38516C3.80495 5.8349 6.63766 3.33535 9.996 3.33353H10.0002C11.7682 3.32787 13.4649 4.0301 14.7118 5.28356L16.6668 3.33353V9.1669H10.8335L13.5185 6.48356C12.5926 5.5366 11.3246 5.00201 10.0002 5.00023C7.88396 5.00335 5.9989 6.33838 5.2935 8.33356H5.2785C5.18229 8.60449 5.11007 8.88334 5.06266 9.1669H5.06183Z" fill="#4660F1" />
                          </svg>
                        </>
                        : props.refresh ?
                          <>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M3.3335 16.6669V10.8336H9.16683L6.486 13.5169C7.41096 14.4627 8.67722 14.9972 10.0002 15.0002C12.1164 14.9971 14.0014 13.6621 14.7068 11.6669H14.7218C14.8173 11.3957 14.8895 11.117 14.9377 10.8336H16.6143C16.1944 14.1668 13.3598 16.6668 10.0002 16.6669H9.99183C8.22413 16.6722 6.5278 15.97 5.281 14.7169L3.3335 16.6669ZM5.06183 9.1669H3.38516C3.80495 5.8349 6.63766 3.33535 9.996 3.33353H10.0002C11.7682 3.32787 13.4649 4.0301 14.7118 5.28356L16.6668 3.33353V9.1669H10.8335L13.5185 6.48356C12.5926 5.5366 11.3246 5.00201 10.0002 5.00023C7.88396 5.00335 5.9989 6.33838 5.2935 8.33356H5.2785C5.18229 8.60449 5.11007 8.88334 5.06266 9.1669H5.06183Z" fill="#4660F1" />
                            </svg>
                            <span className="ms-2">
                              {props.name}
                            </span>
                          </>
                          : props.blue && props.small ?
                            <span className={`${styles.e_blue_btn} ${styles.e_t_btn_small}`}>{props.name}</span>
                            : props.blue ?
                              <span className={`${styles.e_blue_btn}`}>{props.name}</span>
                              :
                              <span>{props.name}</span>
          }
        </span>
      </button>
    </div>
  );
}