

var MY_NAME="MOHAMMAD JILANI";
var PROGRAMID_METADATA = "AAjtZv8F6ftKmyF885kLJAVnaqKCtTdcaGWuM4LVppJt";
var BRAND_METADATA = "AAjtZv8F6ftKmyF885kLJAVnaqKCtTdcaGWuM4LVppJt";
var CUSTOMER_METADATA : "28mL7fJa7TZRuaHUT1969jFUcSsAX4N8UdRiJSHSLgc";

var  jconstant ={
	PROGRAMID_METADATA: PROGRAMID_METADATA,
	BRAND_METADATA: BRAND_METADATA,
	CUSTOMER_METADATA:CUSTOMER_METADATA,
	MY_NAME:MY_NAME,
	}
module.exports = jconstant;
//9bjjez4b8sq81qAtKmFiKfzQM85fKbcRVh7N5eak2b9N
