import React, { useState } from 'react';

import { Primary, Secondary } from '../cta';

import { FileUpload, InputBox, SingleSelect } from '../form';


const EditUrlModal = (props) => {
    const [error, setError] = useState(null);

    const [subdomainName, setSubdomain] = useState("");
    const [redirectionUrl, setUrl] = useState("");    

    return (
        <div className="modal fade e-edit-url-modal" id="editUrlModal" tabIndex="-1" aria-labelledby="editUrlModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content position-relative">
                    <div className="modal-header">
                        <h5 className="modal-title mb-0" id="editUrlModalLabel">Edit URL</h5>
                        <div className="e-close-btn-wrapper d-flex align-items-center justify-content-center position-absolute" data-bs-dismiss="modal">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41L17.59 5Z" fill="#11142F"/>
                            </svg>
                        </div>
                    </div>
                    <div className="modal-body pt-4">
                        <div className="row">
                            <div className="col-12">
                                <InputBox
                                    label={<>Subdomain Name <span className="e-warning-color">*</span></>}
                                    value={subdomainName}
                                    type="text"
                                    valueChange={(value) => {
                                        setSubdomain(value);
                                        setError(null);
                                    }}
                                    placeholder="example.domainname.com"
                                    required={true} />
                            </div>
                            <div className="col-12">
                                <InputBox
                                    label={<>Redirection Link <span className="e-warning-color">*</span></>}
                                    value={redirectionUrl}
                                    type="text"
                                    valueChange={(value) => {
                                        setUrl(value);
                                        setError(null);
                                    }}
                                    placeholder="www.example.com"
                                    required={true} />
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer pt-2 justify-content-end align-items-center d-flex">
                        <Primary large={true} name="Save" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditUrlModal;