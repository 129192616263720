import React from "react";
import { Link } from "react-router-dom";

/*import style*/
import styles from '../../styles/components/NavBar.module.scss';

import Dashboard from '../../assets/images/navBarIcons/home.svg'
import Domains from '../../assets/images/navBarIcons/domains.svg';
import Reports from '../../assets/images/navBarIcons/reports.svg';
import Accounts from '../../assets/images/navBarIcons/socialMedia.svg';
import Template from '../../assets/images/navBarIcons/templates.svg';

const sidebarItems = [
    {
        name: "Dashboard", link: "dashboard",
        image: <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.8327 18.3333H4.16602C3.70578 18.3333 3.33268 17.9602 3.33268 17.5V10.8333H1.66602L9.40935 3.08916C9.56566 2.93268 9.77776 2.84476 9.99893 2.84476C10.2201 2.84476 10.4322 2.93268 10.5885 3.08916L18.3327 10.8333H16.666V17.5C16.666 17.9602 16.2929 18.3333 15.8327 18.3333ZM8.33268 12.5H11.666V16.6667H14.9993V9.85666L9.99935 4.85666L4.99935 9.85666V16.6667H8.33268V12.5Z" fill="white" />
        </svg>
        , class: "dashboard"
    },
    {
        name: "Manage Domains", link: "domains",
        image: <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M1.66602 10C1.66602 14.6024 5.39698 18.3333 9.99935 18.3333C14.5996 18.3283 18.3276 14.6003 18.3327 10C18.3327 5.39763 14.6017 1.66667 9.99935 1.66667C5.39698 1.66667 1.66602 5.39763 1.66602 10ZM3.38426 9.16667C3.64787 7.05269 4.90124 5.24609 6.66602 4.22522V9.16667H3.38426ZM8.33268 9.16667V3.54337C8.86538 3.40626 9.42385 3.33334 9.99935 3.33334C10.5749 3.33398 11.1333 3.40747 11.666 3.54507V9.16667H8.33268ZM11.666 10.8333H8.33268V16.4566C8.86538 16.5938 9.42385 16.6667 9.99935 16.6667C10.5748 16.6667 11.1333 16.5938 11.666 16.4566V10.8333ZM13.3327 15.7748V10.8333H16.6144C16.3508 12.9473 15.0975 14.7539 13.3327 15.7748ZM13.3327 9.16667V4.22839C15.0956 5.24972 16.348 7.05458 16.6135 9.16667H13.3327ZM3.38426 10.8333H6.66602V15.7748C4.90124 14.7539 3.64787 12.9473 3.38426 10.8333ZM16.666 10.0062L16.666 10L16.666 9.99778V10.0062Z" fill="white" />
        </svg>
        , class: "domain"
    },
    {
        name: "Reports", link: "reports",
        image: <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.3327 17.5H1.66602V9.16667C1.66602 8.2462 2.41221 7.5 3.33268 7.5H6.66602V3.33334C6.66602 2.41286 7.41221 1.66667 8.33268 1.66667H11.666C12.5865 1.66667 13.3327 2.41286 13.3327 3.33334V5.83334H16.666C17.5865 5.83334 18.3327 6.57953 18.3327 7.5V17.5ZM13.3327 7.5V15.8333H16.666V7.5H13.3327ZM8.33268 3.33334V15.8333H11.666V3.33334H8.33268ZM3.33268 9.16667V15.8333H6.66602V9.16667H3.33268Z" fill="white" />
        </svg>
        , class: "report"
    },
    {
        name: "Social Media Accounts", link: "social-media",
        image: <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.49935 2.5C5.19816 2.5 3.33268 4.36548 3.33268 6.66667C3.33268 8.96785 5.19816 10.8333 7.49935 10.8333C9.80054 10.8333 11.666 8.96785 11.666 6.66667C11.666 4.36548 9.80054 2.5 7.49935 2.5ZM4.99935 6.66667C4.99935 5.28595 6.11864 4.16667 7.49935 4.16667C8.88006 4.16667 9.99935 5.28595 9.99935 6.66667C9.99935 8.04738 8.88006 9.16667 7.49935 9.16667C6.11864 9.16667 4.99935 8.04738 4.99935 6.66667Z" fill="white" />
            <path d="M14.0897 6.84857C13.8553 6.72903 13.5958 6.66672 13.3327 6.66672V5.00005C13.859 5.00005 14.3779 5.12464 14.8468 5.36371C14.8984 5.39004 14.9493 5.41768 14.9993 5.44658C15.4038 5.68011 15.7553 5.99641 16.0304 6.37546C16.3396 6.80143 16.5435 7.29456 16.6254 7.81447C16.7073 8.33439 16.665 8.86631 16.5018 9.36671C16.3386 9.86711 16.0593 10.3218 15.6866 10.6934C15.314 11.0651 14.8586 11.3433 14.3578 11.5051C13.9121 11.6492 13.4417 11.6975 12.9773 11.6477C12.9198 11.6415 12.8624 11.6338 12.8052 11.6247C12.2859 11.5414 11.7936 11.3365 11.3686 11.0266L11.3678 11.0259L12.3502 9.67964C12.5628 9.83476 12.8091 9.93731 13.0689 9.97895C13.3288 10.0206 13.5948 10.0001 13.8452 9.91919C14.0956 9.83826 14.3233 9.69919 14.5096 9.51335C14.6959 9.32752 14.8356 9.1002 14.9172 8.85001C14.9988 8.59981 15.0199 8.33386 14.979 8.07391C14.938 7.81396 14.8361 7.56741 14.6815 7.35443C14.5269 7.14145 14.3241 6.9681 14.0897 6.84857Z" fill="white" />
            <path d="M16.6644 17.5C16.6644 17.0625 16.5783 16.6292 16.4108 16.225C16.2434 15.8208 15.998 15.4535 15.6886 15.1441C15.3792 14.8347 15.0119 14.5893 14.6077 14.4219C14.2035 14.2544 13.7702 14.1682 13.3327 14.1682V12.5C13.9011 12.5 14.4647 12.5969 14.9993 12.786C15.0823 12.8153 15.1646 12.8468 15.2461 12.8806C15.8527 13.1319 16.4039 13.5002 16.8682 13.9645C17.3325 14.4288 17.7008 14.98 17.9521 15.5866C17.9858 15.6681 18.0174 15.7504 18.0467 15.8333C18.2358 16.368 18.3327 16.9316 18.3327 17.5H16.6644Z" fill="white" />
            <path d="M13.3327 17.5H11.666C11.666 15.1988 9.80054 13.3333 7.49935 13.3333C5.19816 13.3333 3.33268 15.1988 3.33268 17.5H1.66602C1.66602 14.2783 4.27769 11.6667 7.49935 11.6667C10.721 11.6667 13.3327 14.2783 13.3327 17.5Z" fill="white" />
        </svg>
        , class: "account"
    }
    
]

const NavBar = (props) => {

    return (
        <div className={`container-fluid ${styles.e_nav_wrapper} px-0`}>
            <div className="container">
                <div className={styles.e_nav_container}>
                    <div className={styles.e_nav_content + " d-flex align-items-center py-1"}>
                        {
                            sidebarItems && sidebarItems.map((item, i) => {
                                return (
                                    <Link to={`/${item.link}`} key={`nav-item-${i}`}>
                                        <div className={props.activePage === item.link ? `${styles.e_nav_item} ${styles.e_nav_item_active} d-flex align-items-center` : `${styles.e_nav_item} d-flex align-items-center`}>
                                            <div className={`${styles.e_nav_icon} me-2 d-flex align-item-center justify-content-center`}>
                                                {item.image}
                                            </div>
                                            {item.name}
                                        </div>
                                    </Link>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NavBar;