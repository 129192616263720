/*
 *   File : index.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : index file for card components 
 *   Integrations : null
 *   Version : 1.0
 *   Created : 24-06-2021
 */
import OverviewCard from './overview';
import HeathIndicatorCard from './healthIndicator';
import SecurityIndicatorCard from './securityIndicator';
import SocialMediaIndicator from './socialMediaIndicator';
import SocialMediaReport from './socialMediaReport';
import DashboardReportCard from './dashboardReport';
import ReportCards from './reportCards';
import HealthSummaryCard from './healthSummary';
import DomainCard from './domainCard';
import RecomendedDomains from './recomendedDomains';
import ExpiringSoon from './expiringSoon';
import DomainHealthSummary from './domainHealthSummary';
import ManageDomains from './manageDomains';
import ContactCard from './contactCard';
import UpgradePlanCard from './upgradePlanCard';
import DomainDetailCard from './domainDetail';
import RecordsCard from './recordsCard';
import ReportOverviewCard from './ReportOverviewCard';
import SocialMediaOverview from './socialMediaOverviewCard';
import OrdersCard from './ordersCard';
import ProfileDetailCard from './profileDetailsCard';
import BillingCard from './billingCard';
import KycCard from './kycCard';
import ManageCouponsCard from './manageCouponsCard';
import CouponCard from './couponCard';
import TemplateCard from './templateCard';

export {
    OverviewCard,
    HeathIndicatorCard,
    HealthSummaryCard,
    SocialMediaIndicator,
    SocialMediaReport,
    ReportCards,
    SecurityIndicatorCard,
    DashboardReportCard,
    DomainCard,
    RecomendedDomains,
    ExpiringSoon,
    DomainHealthSummary,
    ManageDomains,
    ContactCard,
    UpgradePlanCard,
    DomainDetailCard,
    RecordsCard,
    ReportOverviewCard,
    SocialMediaOverview,
    OrdersCard,
    ProfileDetailCard,
    BillingCard,
    KycCard,
    ManageCouponsCard,
    CouponCard,
    TemplateCard
};