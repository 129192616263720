
/* packages */
import React, { useState } from 'react';
import $ from 'jquery';
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

/* components */
import { Primary } from '../cta';

const ImagePreview = (props) => {

	const { imageToCrop, onImageCropped } = props;
	const [image, setImage] = useState("");
	const [imageRef, setImageRef] = useState();
	const [cropConfig, setCropConfig] = useState(
		// default crop config
		{
			unit: "px",
			width:120,
			height:120,
			minWidth: 120,
			minHeight: 120,
			maxWidth: 120,
			maxHeight: 120,
			aspect: 1,
			disabled:true,
			locked:true,
			keepSelection:false
		}
	);

	async function cropImage(crop) {
		if (imageRef && crop.width && crop.height) {
			const croppedImage = await getCroppedImage(
				imageRef,
				crop,
			);
			setImage(croppedImage);
		}
	}

	const getCroppedImage = (sourceImage, cropConfig, fileName) => {
		// creating the cropped image from the source image
		const canvas = document.createElement("canvas");
		const scaleX = sourceImage.naturalWidth / sourceImage.width;
		const scaleY = sourceImage.naturalHeight / sourceImage.height;
		const pixelRatio = window.devicePixelRatio || 1
		canvas.width = Math.floor(cropConfig.width * pixelRatio * scaleX)
		canvas.height = Math.floor(cropConfig.height * pixelRatio * scaleY)
		const ctx = canvas.getContext("2d");

		ctx.scale(pixelRatio, pixelRatio)
		ctx.imageSmoothingQuality = 'high'

		const cropX = cropConfig.x * scaleX
		const cropY = cropConfig.y * scaleY
		const cropWidth = cropConfig.width * scaleX
		const cropHeight = cropConfig.height * scaleY

		ctx.drawImage(
			sourceImage,
			cropX,
			cropY,
			cropWidth,
			cropHeight,
			0,
			0,
			cropWidth,
			cropHeight,
		);

		return new Promise((resolve, reject) => {
			canvas.toBlob((blob) => {
				// returning an error
				if (!blob) {
					reject(new Error("Canvas is empty"));
					return;
				}

				blob.name = fileName;
				// creating a Object URL representing the Blob object given
				const croppedImageUrl = window.URL.createObjectURL(blob);

				resolve(croppedImageUrl);
			}, "image/jpeg");
		});
	}

	const onImageLoad = (e) => {
		let img = document.getElementById("img")
		setImageRef(img);
	}

	const getImage = () => {
		onImageCropped(image);
		$("#imagePreview").trigger("click")
	}

	return (
		<div className="modal fade e-image-preview-modal" id="imagePreview" tabIndex="-1" aria-labelledby="addKycModalLabel" aria-hidden="true">
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content position-relative">
					<div className="modal-header">
						<div className="e-close-btn-wrapper d-flex align-items-center justify-content-center position-absolute" data-bs-dismiss="modal">
							<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41L17.59 5Z" fill="#11142F" />
							</svg>
						</div>
					</div>
					<div className="modal-body pt-4 pb-2 mx-auto">
						<ReactCrop
							crop={cropConfig}
							onComplete={(cropConfig) => { cropImage(cropConfig); }}
							onChange={cropConfig => { setCropConfig(cropConfig); }}>
							<img id="img" src={imageToCrop} onLoad={onImageLoad} />
						</ReactCrop>
					</div>
					<div className="modal-footer pt-3 justify-content-end align-items-center d-flex">
						<div className="d-flex align-items-center">
							{/* <Secondary large={true} className="me-3" name="Cancel" cancel="modal" /> */}

							<Primary
								large={true}
								name="Ok"
								handleClick={() => {
									getImage()
								}}
							/>
						</div>
					</div>
				</div>
			</div>

		</div>
	)
}

export default ImagePreview;