import React from 'react';

/* Images */
import SignupIllustration from '../../assets/images/authIllustrations/signup.svg';
import OtpIllustration from '../../assets/images/authIllustrations//enterOtp.svg';
import PasswordIllustration from '../../assets/images/authIllustrations/enterPassword.svg';

export const AuthIllustrationWrapper=(props)=>{
    return(
        <div>
            <div className="e-auth-gradient">
                <div className="position-fixed e-illustration-wrapper d-flex align-items-center justify-content-center">
                    <div className="container">
                    <div className="e-auth-illustration">
                        <img src={
                            props.type==="login"||props.type==="create-account"?
                            SignupIllustration
                            :props.type==="forgot-password"||props.type==="verify-otp"?
                            OtpIllustration
                            :props.type==="create-password"?
                            PasswordIllustration
                            :
                            SignupIllustration
                        } alt=""/>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
