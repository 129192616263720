import React, { useEffect, useRef, useState } from 'react';

/*import style*/
import styles from '../../styles/components/TabHeadingOne.module.scss';

const TabHeadingOne=(props)=>{
    const [indicatorWidth, setIndicatorWidth] = useState();
    const indicatorRef = useRef(null);

    useEffect(()=>{
        let indicator = indicatorRef.current;
        let width = indicator.getBoundingClientRect().width;
        setIndicatorWidth(width);
    },[]);

    return(
        <div className={`w-100 d-flex justify-content-center ${props.className}`}>
        {
            props.tabs&&props.tabs.length?
            <div className={`${styles.e_head_wrapper} d-flex position-relative align-items-center`}>
            {
                props.tabs.map((tab, i)=>{
                    let index=i+1;
                    return(
                        <h5 className={
                            props.activeTab==index? 
                            `${styles.e_tab_head} ${styles.e_tab_active} text-center`
                            : styles.e_tab_head
                        }
                        onClick={()=>props.setActive(index)}
                        key={`tab-heading-${i}`}>
                            {tab}
                        </h5>
                    )
                })
            }
            <span 
            ref={indicatorRef}
            className={`${styles.e_active_indicator} position-absolute ${props.activeClass}`} 
            style={{transform: `translate(${
                props.activeTab>1?
                (((props.activeTab-1)*indicatorWidth)+2)
                :
                0}px, -50%)`}}></span>
        </div>
        :
        null
        }
        </div>
    )
}

export default TabHeadingOne;