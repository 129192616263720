import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { Primary, TextButton } from '../cta';
import { CheckBox, InputBox } from '../form';
import { SocialButton } from '../socialLogin';
import { message } from 'antd';
import axios from 'axios';
import services from '../../services/apis/services';
// import { GoogleLogin } from '@react-oauth/google';
import { useGoogleLogin } from '@react-oauth/google';
import { LoginSocialFacebook} from "reactjs-social-login";
import Cookies from 'js-cookie';

import styles from '../../styles/components/SocialButton.module.scss';

const Login = (props) => {

    const facebookAppId = process.env.REACT_APP_FACEBOOK_APP_ID;

    const loginwithgoogle = useGoogleLogin({
        onSuccess: (codeResponse) => setUser(codeResponse),
        onError: (error) => console.log('Login Failed:', error)
    });

    const [user, setUser] = useState([]);
    const router = useHistory();
    const [error, setError] = useState(null);
    const [userNamePlaceholder, setUserNamePlaceholder] = useState("Email or Phone Number");
    const [userNameType, setUserNameType] = useState("text");
    const [loader, setLoader] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);
    const [profileFacebookAPIData, setProfileFacebookAPIData] = useState();

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        // Load credentials if "Remember me" was checked previously
        const storedEmail = localStorage.getItem('emailPh');
        const storedPassword = localStorage.getItem('password');
        if (storedEmail && storedPassword) {
            props.setUserId(storedEmail);
            props.setPassword(storedPassword);
            setRememberMe(true);
        }
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setError(null);
        }, 3000);
    }, [error]);

    useEffect(() => {
        if (user) {
            axios
                .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                    headers: {
                        Authorization: `Bearer ${user.access_token}`,
                        Accept: 'application/json'
                    }
                })
                .then((res) => {
                    res.data.social='google'
                    if (res.data.verified_email === true) {
                        loginwithgoogleNew(res);
                    } else {
                        signUpGoogle(res);
                    }
                })
                .catch((err) => console.log(err));
        }
    }, [user]);


    useEffect(() => {
        if (profileFacebookAPIData) {
            
            if(profileFacebookAPIData.data.accessToken) {
                let res = {}
                res.data= { "email":profileFacebookAPIData.data.email,
                    "id":profileFacebookAPIData.data.userID,
                    "name":profileFacebookAPIData.data.name,
                    "social":'facebook'
                    
                }
                if (profileFacebookAPIData.data.accessToken) {
                    loginwithgoogleNew(res);
                } else {
                    signUpGoogle(res);
                }
            }
                   
                
        }
    }, [profileFacebookAPIData]);

    async function signUpGoogle(gogoleData) {
        let url = 'action/add/signupgoogle';
        let data = {
            fullName: gogoleData.data.name,
            email: gogoleData.data.email,
            isGoogleAccountId: gogoleData.data.id,
            social: gogoleData.data.social
        };

        try {
            let response = await services(true, url, data);
            if (response.data.status === 1) {
                await loginwithgoogleNew(gogoleData);
            }
        } catch (error) {
            console.error("Error during signup:", error);
        }
    }

    async function loginwithgoogleNew(gogoleData) {
        let url_login = 'action/check/vlogin';
        let data_login = {
            emailPh: gogoleData.data.email,
            password: '1234567890'
        };

        try {
            let response = await services(true, url_login, data_login);
            if (response.data.status === 1) {
                message.success("Login successful");
                Cookies.set('web23_token', response.data.resData.token, { expires: 1 });
                localStorage.setItem("name", response.data.resData.fullName);
                localStorage.setItem("email", response.data.resData.email);
                window.location.href = "/dashboard";
            } else if (response.data.status !== 1) {
                await signUpGoogle(gogoleData);
            }
        } catch (error) {
            console.error("Error during login:", error);
        }
    }

    const login = () => {
        setLoader(true);
        let url = 'action/check/vlogin';
        let data = {
            emailPh: props.userId,
            password: props.password
        };

        services(true, url, data).then((response) => {
            let res = response.data;
            if (res.status === 1) {
                message.success("Login successful");
                Cookies.set('web23_token', response.data.resData.token, { expires: 1 });
                localStorage.setItem("name", response.data.resData.fullName);
                localStorage.setItem("email", response.data.resData.email);

                if (rememberMe) {
                    localStorage.setItem('emailPh', props.userId);
                    localStorage.setItem('password', props.password);
                } else {
                    localStorage.removeItem('emailPh');
                    localStorage.removeItem('password');
                }

                window.location.href = "/dashboard";
                setLoader(false);
            } else {
                setError(res.msg);
                setLoader(false);
            }
        });
    }

    const userNameChange = (value) => {
        props.setUserId(value);
        let isNum = /^\d+$/.test(value);
        if (isNum) {
            setUserNamePlaceholder("Phone Number");
            setUserNameType("number");
        } else {
            setUserNamePlaceholder("Email or Phone Number");
            setUserNameType("email");
        }
    }

    const handleLogin = async (e) => {
        e.preventDefault();
        login();
    }

    const handleRememberMeChange = (checked) => {
        setRememberMe(checked);
    }

    return (
        <div className="container-fluid p-0">
            <div className="e-auth-container w-100">
                <h3 className="text-center mb-3">Login</h3>
                <div className="w-100 d-flex justify-content-center pt-3">
                    <div className="e-auth-form-wrapper">
                        <div className="row">
                            <form>
                                <div className="col-lg-12">
                                    <InputBox
                                        label="Email / Phone"
                                        value={props.userId}
                                        type={userNameType}
                                        valueChange={(value) => userNameChange(value)}
                                        placeholder={userNamePlaceholder}
                                        required={true} />
                                </div>
                                <div className="col-lg-12 pt-2">
                                    <InputBox
                                        label="Password"
                                        value={props.password}
                                        type="password"
                                        valueChange={(value) => {
                                            props.setPassword(value);
                                            setError(null);
                                        }}
                                        placeholder="Password"
                                        required={true}
                                        error={error} />
                                </div>
                                <div className="col-lg-12 pt-2 pb-3">
                                    <div className="w-100 d-flex align-items-center justify-content-between">
                                        <CheckBox
                                            label="Remember me"
                                            onChecked={handleRememberMeChange}
                                            defaultChecked={rememberMe}
                                        />
                                        <TextButton
                                            name="Forgot Password?"
                                            blue={true}
                                            small={true}
                                            handleClick={() => {
                                                router.push('/auth/forgot-password')
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12 pt-3">
                                    {
                                        props.userId && props.password ?
                                            <Primary
                                                className={loader ? "e-btn-loader" : ""}
                                                name="Sign In"
                                                full_width={true}
                                                handleClick={(e) => {
                                                    handleLogin(e)
                                                }}
                                            />
                                            :
                                            <Primary
                                                name="Sign In"
                                                full_width={true}
                                                disabled={true}
                                            />
                                    }
                                </div>
                            </form>
                            <div className="col-lg-12 py-3 text-center">
                                <p className="mb-0">Or sign in with</p>
                            </div>
                            <div className="col-lg-12 pt-2 pb-3">
                                <div className="row gx-3 gy-3">
                                    <div className="col-sm-6" onClick={() => loginwithgoogle()}>
                                        <SocialButton
                                            provider="google"
                                            name={
                                                <div className="d-flex align-items-center">
                                                    <span className="e-google-signup me-2"></span>
                                                    Google
                                                </div>
                                            }
                                        />
                                    </div>
                                    <div className="col-sm-6">
                                    <LoginSocialFacebook
                                        appId={facebookAppId}
                                        onResolve={(response) => {
                                            setProfileFacebookAPIData(response);
                                        }}
                                        onReject={(error) => {
                                            // console.log(error);
                                        }}
                                    >
                                    
                                      <div className={`w-100 position-relative ${styles.e_social_button_wrapper}`}>
                                        <Primary 
                                         name={
                                            <div className="d-flex align-items-center">
                                                <span className="e-facebook-signup me-2"></span>
                                                Facebook
                                            </div>
                                        }
                                         grey={true} type="button" full_width={true}/>
                                        
                                    </div>
                                    </LoginSocialFacebook>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 pt-3">
                                <div className="d-flex align-items-center">
                                    <p className="mb-0 me-1">New User?</p>
                                    <TextButton
                                        name="Create New Account"
                                        blue={true}
                                        small={true}
                                        handleClick={() => {
                                            props.clearAll();
                                            router.push('/auth/create-account');
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login;
