/*
 *   File : index.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : index file for button components 
 *   Integrations : null
 *   Version : 1.0
 *   Created : 24-06-2021
 */
import Primary from './primary';
import Secondary from './secondary';
import TextButton from './textBtn';

export { TextButton, Primary, Secondary,};