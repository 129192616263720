import React, { useState } from "react";

/* Import styles */
import styles from '../../styles/components/SecondaryDomainTable.module.scss';

import Success from '../../assets/images/icons/tick-icon-18.svg';
import Warning from '../../assets/images/icons/warning-icon-18.svg';
import { TextButton } from "../cta";

const SecondaryDomainTable = (props) => {
    // Pagination state
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5; // Change this to the desired number of items per page

    // Calculate total pages
    const totalPages = Math.ceil(props.data.length / itemsPerPage);

    // Slice the data based on the current page
    const paginatedData = props.data.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    // Handle page navigation
    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    return (
        <div className={`${styles.e_table_wrapper} w-100`}>
            <div className={`${styles.e_table_container} py-3`}>
                <div className={`${styles.e_table_head} w-100`}>
                    <div className="w-100 px-3">
                        <div className="row pb-4">
                            <div className="col-4">
                                <h5 className="mb-0">Domain Name</h5>
                            </div>
                            <div className="col-2">
                                <h5 className="mb-0">Status</h5>
                            </div>
                            <div className="col-2">
                                <h5 className="mb-0">Expiry</h5>
                            </div>
                            <div className="col-2">
                                <h5 className="mb-0">Redirections</h5>
                            </div>
                            <div className="col-2">
                                <h5 className="mb-0">More</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${styles.e_table_body} w-100`}>
                    {
                        paginatedData && paginatedData.length ?
                            <div className="w-100 px-3">
                                <div className="row gy-4">
                                    {
                                        paginatedData.map((item, i) => (
                                            <div className="col-12" key={i}>
                                                <div className="row">
                                                    <div className="col-4">
                                                        <p className="mb-0 text-lowercase">{item.name}</p>
                                                    </div>
                                                    <div className="col-2">
                                                        <div className="d-flex align-items-center">
                                                            {
                                                                new Date(item.expiry)> new Date() ?
                                                                <img src={Warning} alt="Warning Icon" className="me-1" />
                                                                    :
                                                                    
                                                                    <img src={Success} alt="Success Icon" className="me-1" />
                                                            }
                                                            <p className="mb-0">{new Date(item.expiry)> new Date() ? "Active" : "Inactive"}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-2">
                                                        <p className="mb-0">{item.expiry}</p>
                                                    </div>
                                                    <div className="col-2">
                                                        <p className="mb-0">{item.redirections}</p>
                                                    </div>
                                                    <div className="col-2">
                                                        <TextButton name="Manage" small={true} blue={true} />
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            :
                            <p className="text-center">No data available</p>
                    }
                </div>

                {/* Pagination Controls */}
                <div className="d-flex justify-content-between px-3 pt-4">
                    <button
                        className="btn btn-secondary"
                        onClick={handlePreviousPage}
                        disabled={currentPage === 1}
                    >
                        Previous
                    </button>
                    <p className="mb-0">Page {currentPage} of {totalPages}</p>
                    <button
                        className="btn btn-secondary"
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SecondaryDomainTable;
