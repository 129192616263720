/*--------------
Properties    default         desc

name           --             Button Name
type           button         Button type
disabled       false          set to true to disable button
large          false          set to true for large button
medium         false          set to true for medium button
small          false          set to true for small button
blue           false          set to true for blue button
white          false          set to true for white button
red            false          set to true for red button
full_width     false          button takes full width when set to true
globe_icon     false          set to true to show globe icon
className      --             classname for button wrapper
cancel         --             data-bs-dismiss attribute for bootstrap
toggle         --             data-bs-toggle attribute for bootstrap
target         --             data-bs-target attribute for bootstrap
handleClick    --             onClick function
handleSubmit   --             onKeyPress function
---------------*/


/*Package importing*/
import React from 'react';

/*import styles*/
import styles from '../../styles/components/Secondary.module.scss';

export default function Secondary(props) {
  return (
    <>
    {
      !props.full_width?
      <div className={
        props.disabled &&props.large? 
        `${styles.e_btn_wrapper} ${styles.e_btn_disabled} ${styles.e_s_btn_large} ${props.className}`
        :props.disabled &&props.medium? 
        `${styles.e_btn_wrapper} ${styles.e_btn_disabled} ${styles.e_s_btn_medium} ${props.className}`
        :props.disabled &&props.small?
        `${styles.e_btn_wrapper} ${styles.e_btn_disabled} ${styles.e_s_btn_small} ${props.className}`
        :props.large? 
        `${styles.e_btn_wrapper} ${styles.e_s_btn_large} ${props.className}`
        :props.medium? 
        `${styles.e_btn_wrapper} ${styles.e_s_btn_medium} ${props.className}`
        :props.small?
        `${styles.e_btn_wrapper} ${styles.e_s_btn_small} ${props.className}`
        :
        `${styles.e_btn_wrapper} ${props.className}`}>
        <button
          id={props.id}
          className={
            props.blue ||props.globe_icon?
              `${styles.e_s_btn} ${styles.e_s_btn_blue}`
              :props.white?
              `${styles.e_s_btn} ${styles.e_s_btn_white}`
              :props.red?
              `${styles.e_s_btn} ${styles.e_s_btn_red}`
              : `${styles.e_s_btn}`} 
          data-bs-dismiss={props.cancel}
          data-bs-toggle={props.toggle}
          data-bs-target={props.target}
          onClick={props.handleClick}
          onKeyPress={props.handleSubmit}
          type={props.type ? props.type : "button"} >
          <span className={props.globe_icon? "d-flex align-items-center" : ""}>
            {
              props.globe_icon?
              <>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M1.6665 10.0003C1.6665 14.6027 5.39746 18.3337 9.99984 18.3337C14.6001 18.3286 18.3281 14.6006 18.3332 10.0003C18.3332 5.39795 14.6022 1.66699 9.99984 1.66699C5.39746 1.66699 1.6665 5.39795 1.6665 10.0003ZM3.38475 9.16699C3.64836 7.05301 4.90173 5.24641 6.6665 4.22554V9.16699H3.38475ZM8.33317 9.16699V3.54369C8.86587 3.40658 9.42434 3.33366 9.99984 3.33366C10.5754 3.33431 11.1338 3.40779 11.6665 3.54539V9.16699H8.33317ZM11.6665 10.8337H8.33317V16.457C8.86587 16.5941 9.42434 16.667 9.99984 16.667C10.5753 16.667 11.1338 16.5941 11.6665 16.457V10.8337ZM13.3332 15.7751V10.8337H16.6149C16.3513 12.9476 15.0979 14.7542 13.3332 15.7751ZM13.3332 9.16699V4.22871C15.0961 5.25004 16.3485 7.05491 16.614 9.16699H13.3332ZM3.38475 10.8337H6.6665V15.7751C4.90173 14.7542 3.64836 12.9476 3.38475 10.8337ZM16.6665 10.0066L16.6665 10.0003L16.6665 9.9981V10.0066Z" fill="#4660F1"/>
              </svg>
              <span className="ps-1">{props.name}</span>
              </>
              :
              <>{props.name}</>
            }
          </span>
        </button>
      </div>
      :
      <div className={
        props.disabled &&props.large? 
        `${styles.e_btn_wrapper} ${styles.e_btn_disabled} ${styles.e_s_btn_large} ${styles.e_s_btn_full_width} ${props.className}`
        :props.disabled &&props.small?
        `${styles.e_btn_wrapper} ${styles.e_btn_disabled} ${styles.e_s_btn_small} ${styles.e_s_btn_full_width}  ${props.className}`
        :props.large? 
        `${styles.e_btn_wrapper} ${styles.e_s_btn_large} ${styles.e_s_btn_full_width}  ${props.className}`
        :props.small?
        `${styles.e_btn_wrapper} ${styles.e_s_btn_small} ${styles.e_s_btn_full_width}  ${props.className}`
        :
        `${styles.e_btn_wrapper} ${styles.e_s_btn_full_width}  ${props.className}`}>
        <button
          id={props.id}
          className={
            props.blue ||props.globe_icon?
              `${styles.e_s_btn} ${styles.e_s_btn_blue} d-flex justify-content-center`
              :props.white?
              `${styles.e_s_btn} ${styles.e_s_btn_white} d-flex justify-content-center`
              :props.red?
              `${styles.e_s_btn} ${styles.e_s_btn_red} d-flex justify-content-center`
              : `${styles.e_s_btn} d-flex justify-content-center`} 
          data-bs-dismiss={props.cancel}
          data-bs-toggle={props.toggle}
          data-bs-target={props.target}
          onClick={props.handleClick}
          onKeyPress={props.handleSubmit}
          type={props.type ? props.type : "button"} >
          <span className={props.globe_icon? "d-flex align-items-center" : ""}>
            {
              props.globe_icon?
              <>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M1.6665 10.0003C1.6665 14.6027 5.39746 18.3337 9.99984 18.3337C14.6001 18.3286 18.3281 14.6006 18.3332 10.0003C18.3332 5.39795 14.6022 1.66699 9.99984 1.66699C5.39746 1.66699 1.6665 5.39795 1.6665 10.0003ZM3.38475 9.16699C3.64836 7.05301 4.90173 5.24641 6.6665 4.22554V9.16699H3.38475ZM8.33317 9.16699V3.54369C8.86587 3.40658 9.42434 3.33366 9.99984 3.33366C10.5754 3.33431 11.1338 3.40779 11.6665 3.54539V9.16699H8.33317ZM11.6665 10.8337H8.33317V16.457C8.86587 16.5941 9.42434 16.667 9.99984 16.667C10.5753 16.667 11.1338 16.5941 11.6665 16.457V10.8337ZM13.3332 15.7751V10.8337H16.6149C16.3513 12.9476 15.0979 14.7542 13.3332 15.7751ZM13.3332 9.16699V4.22871C15.0961 5.25004 16.3485 7.05491 16.614 9.16699H13.3332ZM3.38475 10.8337H6.6665V15.7751C4.90173 14.7542 3.64836 12.9476 3.38475 10.8337ZM16.6665 10.0066L16.6665 10.0003L16.6665 9.9981V10.0066Z" fill="#4660F1"/>
              </svg>
              <span className="ps-1">{props.name}</span>
              </>
              :
              <>{props.name}</>
            }
          </span>
        </button>
      </div>
    }
    </>
  );
}