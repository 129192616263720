import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

/* assets */
import ContactIcon from '../../assets/images/icons/contact-card-grey-30.svg';
import CreditCardIcon from '../../assets/images/icons/credit-card-grey-30.svg';

/* components */
import { CheckBox, InputBox, NumberInput, SingleSelect } from '../../components/form';
import { Toast } from '../../components/alerts';
import Loader from '../../components/others/loader';
import PaymentModal from '../../components/modals/payment';
import { Primary, TextButton } from '../../components/cta';

/* services */
import services from '../../services/apis/services';
import { Modal } from 'bootstrap';

const CheckoutContainer = (props) => {

    const [name, setName] = useState("");
    const [location, setLocation] = useState(null);
    const [addressOne, setAddressOne] = useState("");
    const [addressTwo, setAddressTwo] = useState("");
    const [pin, setPin] = useState(null);
    const [city, setCity] = useState("");
    const [state, setState] = useState(null);
    const [number, setNumber] = useState(null);
    const [email, setEmail] = useState("");
    const [receiveMail, setReceiveMail] = useState(false);
    const [saved, setSaved] = useState(false);
    const [error, setError] = useState(null);
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [loader, setLoader] = useState(false);
    const [countryCode, setCountryCode] = useState("91");
    const [cart, setCart] = useState([]);
    const [billingLoader, setBillingLoader] = useState(false);
    const [paymentData, setPaymentData] = useState({});
    const [paymentLoader,setPaymentLoader] = useState()
    const router = useHistory();

    useEffect(() => {
        let cartData = props.location.state.cart;
        setCart(cartData)
    }, [props.location.state.cart])
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

        getCountryList();
        getBillingAddress();
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setError(null);
        }, 3000)
    }, [error])

    useEffect(() => {
        if (location !== null) {
            getStateList();
        }
    }, [location]);

    /*-------country list api ----*/
    const getCountryList = () => {
        let temp_country_list = [];
        let url = 'action/get/countrylist';

        services(true, url).then((response) => {
            let res = response.data;

            if (res.status === 1) {
                let countryObj = res.resData

                Object.values(countryObj).map((item) => (
                    temp_country_list.push({
                        value: item.cd,
                        label: item.name
                    })
                ));
                setCountryList(temp_country_list);
                var data = Object.values(countryObj).filter((item) => item.cd === "91")
                data.map((value) => (
                    setLocation({
                        value: value.cd,
                        label: value.name
                    })
                ))
            } else {
                setError(res.msg);
            }
        })
    }

    /*-------country list api ----*/
    const getStateList = () => {
        let temp_state_list = [];
        let url = 'action/get/statelist';

        let data = {
            countryName: location?.label
        }

        services(true, url, data).then((response) => {
            let res = response.data;


            if (res.status === 1) {
                let countryObj = res.resData

                Object.values(countryObj).map((item) => (
                    temp_state_list.push({
                        value: item,
                        label: item
                    })
                ));
                setStateList(temp_state_list);
            } else {
                setError(res.msg);
            }
        });
    }

    const addBillingAddress = (e) => {
        e.preventDefault();
        setLoader(true);

        let url = 'action/bill/setcontact';

        let data = {
            token: localStorage.getItem("web23_token"),
            fullName: name,
            country: location?.label,
            adrLine1: addressOne,
            adrLine2: addressTwo,
            pincode: pin,
            city: city,
            state: state?.value,
            countryCode: countryCode,
            phoneNo: number,
            contactEmail: email,
            receiveMsg: receiveMail === true ? 1 : 0
        }

        services(true, url, data).then((response) => {
            let res = response.data;

            if (res.status === 1) {
                setLoader(false);
                setSaved(true);
                const pos = window.pageYOffset;
                const newPos = pos + Number(620);
                window.scrollTo(0, parseInt(newPos));
            } else {
                setError(res.msg);
                setLoader(false);
            }
        });
    }

    /* ------ get address-----*/
    const getBillingAddress = () => {

        setBillingLoader(true);
        let url = 'action/bill/getcontact';

        let data = {
            token: localStorage.getItem("web23_token")
        }

        services(true, url, data).then((response) => {
            let res = response.data;

            if (res.status === 1) {
                setBillingLoader(false)
                setName(res.resData.fullName);
                setLocation({ value: res.resData.country, label: res.resData.country });
                setAddressOne(res.resData.adrLine1);
                setAddressTwo(res.resData.adrLine2);
                setCity(res.resData.city);
                setCountryCode(res.resData.countryCode);
                setEmail(res.resData.contactEmail);
                setNumber(res.resData.mobileNo);
                setState({ value: res.resData.state, label: res.resData.state });
                if (res.resData.receiveMsg === 1) {
                    setReceiveMail(true);
                } else {
                    setReceiveMail(false);
                }
                setPin(res.resData.pincode);
            } else if (res.status === 0 || res.status === -1) {
                setError(res?.msg);
                setBillingLoader(false);
            } else {
                setError(res?.msg);
                setBillingLoader(false);
            }
        });
        // setBillingLoader(false);
    }

    /*--- loading script * -----*/
    const loadScript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }
    /* ------ razor pay ----*/
    const payment = async () => {
        setPaymentLoader(true);
        let url = 'action/' + process.env.REACT_APP_PAYMENT_URL;

        const src = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!src) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
        }

        let data = {
            token: localStorage.getItem("web23_token"),
            key: process.env.REACT_APP_KEY,
            amount: cart?.reduce((prev, last) => prev + last.finalPrice, 0),
            name: name,
        }

        services(true, url, data).then((response) => {
            let res = response.data;

            if (res.status === 1) {
                setPaymentLoader(false);
                setPaymentData(res.resData);
                let options = res.resData;
                options.handler = function (response) {
                    confirmPayment(response.razorpay_payment_id);
                }
                const paymentObject = new window.Razorpay(options);
                paymentObject.open();
                paymentObject.on('payment.failed', function (response) {
                    setError(response.error.description);
                });

            } else {
                setError(res.msg);
                setPaymentLoader(false);
                // setError(res.resData[0]?.msg);
            }
        });
    }

    /*------- confirmation payment api success -----*/
	// 5267 3181 8797 5449
    const confirmPayment = async (paymentId) => {
        let url = "action/bill/paymentSuccess";

        let data = {
            token: localStorage.getItem("web23_token"),
            amount: cart?.reduce((prev, last) => prev + last.finalPrice, 0),
            txn: paymentId
        }

        services(true, url, data).then((response) => {
            let res = response.data;

            if (res.status === 1) {
                let modal = new Modal(document.getElementById("payment"), {});
                modal.show();
                setTimeout(() => {
                    modal.hide();
                    getCart();
                    window.location.href = '/domains/web2'
                }, 3000)
            } else {
                setError(res.msg);
                setLoader(false);
                setError(res.resData[0]?.msg)
            }
        });
    }

    /* get cart */
    const getCart = () => {

        let url = 'action/bill/get';

        let body = {
            token: localStorage.getItem("web23_token")
        }
        services(true, url, body).then((response) => {
            let res = response.data;

            if (res.status === 1) {
                setCart(res.resData);
            } else {
                setError(res.msg);
            }
        })
    }

    return (
        <div className="container-fluid position-relative e-cart-page-container e-checkout-page pt-3">
            <div className="e-gradient-bg position-absolute"> </div>
            <div className="container position-relative mt-3 pb-4">
                <h3 className="mb-0 text-center">Checkout</h3>
                <div className="position-absolute e-back-btn-wrapper">
                    <TextButton name="Back to Cart" back={true} handleClick={() => router.goBack()} />
                </div>
            </div>
            <div className="container pt-4 position-relative e-pb-248">
                <div className="row justify-content-center">
                    <div className="col-lg-7 col-md-10">
                        <div className="e-cart-wrapper w-100 overflow-hidden">
                            <div className="w-100 d-flex align-items-center e-px-32 e-py-32 e-grey-bg">
                                <img src={ContactIcon} alt="" className="e-checkout-form-icon me-2" />
                                <h4 className="mb-0 pe-3">Contact information</h4>
                                <span className="e-checkout-info-muted">* Required Information by ICANN</span>
                            </div>

                            <div className="w-100 d-flex align-items-center e-px-32 e-py-32">
                                {
                                    billingLoader === true ?
                                        <Loader />
                                        :
                                        <form>
                                            <div className="row gy-2">
                                                <div className="col-lg-6">
                                                    <InputBox
                                                        label="Full Name*"
                                                        value={name}
                                                        type="text"
                                                        valueChange={(value) => {
                                                            setName(value);
                                                            setError(null);
                                                        }}
                                                        placeholder="Full Name"
                                                        required={true} />
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="e-input-wrap">
                                                        <label>Country</label>
                                                        <SingleSelect
                                                            placeholder="Choose Country"
                                                            isSearchable={true}
                                                            options={countryList}
                                                            value={location}
                                                            selectchange={val => {
                                                                setLocation(val);
                                                                setState(null);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <InputBox
                                                        label="Address Line 1*"
                                                        value={addressOne}
                                                        type="text"
                                                        valueChange={(value) => {
                                                            setAddressOne(value);
                                                            setError(null);
                                                        }}
                                                        placeholder="Your Address Line 1"
                                                        required={true} />
                                                </div>
                                                <div className="col-lg-6">
                                                    <InputBox
                                                        label="Address Line 2*"
                                                        value={addressTwo}
                                                        type="text"
                                                        valueChange={(value) => {
                                                            setAddressTwo(value);
                                                            setError(null);
                                                        }}
                                                        placeholder="Your Address Line 2"
                                                        required={true} />
                                                </div>
                                                <div className="col-lg-4">
                                                    <InputBox
                                                        label="Pincode*"
                                                        value={pin}
                                                        type="text"
                                                        valueChange={(value) => {
                                                            setPin(value);
                                                            setError(null);
                                                        }}
                                                        placeholder="Your Pincode"
                                                        required={true} />
                                                </div>
                                                <div className="col-lg-4">
                                                    <InputBox
                                                        label="City*"
                                                        value={city}
                                                        type="text"
                                                        valueChange={(value) => {
                                                            setCity(value);
                                                            setError(null);
                                                        }}
                                                        placeholder="Your City"
                                                        required={true} />
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="e-input-wrap">
                                                        <label>State</label>
                                                        <SingleSelect
                                                            isSearchable={true}
                                                            placeholder="Choose State"
                                                            value={state}
                                                            options={stateList}
                                                            selectchange={val => setState(val)} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <NumberInput
                                                        label="Phone Number*"
                                                        value={number}
                                                        type="number"
                                                        valueChange={(value) => {
                                                            setNumber(value);
                                                            setError(null);
                                                        }}
                                                        placeholder="00000 00000"
                                                        defaultCode={countryCode ? "+" + countryCode : "+91"}
                                                        onCodeChange={(val) => setCountryCode(val.split("+")[1])}
                                                        required={true} />
                                                </div>
                                                <div className="col-lg-6">
                                                    <InputBox
                                                        label="Email Address*"
                                                        value={email}
                                                        type="email"
                                                        valueChange={(value) => {
                                                            setEmail(value);
                                                            setError(null);
                                                        }}
                                                        placeholder="Your Email Id"
                                                        required={true} />
                                                </div>
                                                <div className="col-lg-8 pt-3">
                                                    <div className="w-100">
                                                        <CheckBox label={<>I would like to receive promotional messages (Dont’ worry, we won’t spam you)</>} onChecked={(e) => setReceiveMail(e)} defaultChecked={receiveMail} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 pt-3">
                                                    {
                                                        name && location &&
                                                            addressOne && addressTwo &&
                                                            pin && city && state &&
                                                            number && email ?
                                                            <Primary className={loader ? "e-btn-loader" : ""} name="Save & Continue" full_width={true} large={true} handleClick={(e) => { addBillingAddress(e) }} />
                                                            :
                                                            <Primary name="Save & Continue" full_width={true} large={true} disabled={true} />
                                                    }
                                                </div>
                                            </div>
                                        </form>
                                }
                            </div>
                            {
                                saved ?
                                    <>
                                        {
                                            cart?.map((item) => (
                                                <>
                                                    <div className="w-100 d-flex align-items-center e-px-32 e-py-32 e-grey-bg">
                                                        <img src={CreditCardIcon} alt="" className="e-checkout-form-icon me-2" />
                                                        <h4 className="mb-0">Purchase Details</h4>
                                                    </div>
                                                    <div className="w-100 e-px-32 e-py-32">
                                                        <div className="d-flex w-100 align-items-center justify-content-between">
                                                            {
                                                                item.orderType === "W2" ?
                                                                    <div>
                                                                        <h5 className="mb-2 fw-normal">Package subscription</h5>
                                                                        <div className="d-flex align-items-center">
                                                                            <h4 className="mb-0 pe-1">{item?.itemName}</h4>
                                                                            <span className="e-checkout-info-muted">(1 Year)</span>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div>
                                                                        <h5 className="mb-2 fw-normal">Domain Registration</h5>
                                                                        <div className="d-flex align-items-center">
                                                                            <h4 className="mb-0 pe-1">{item?.itemName}</h4>
                                                                            <span className="e-checkout-info-muted">(1 Year)</span>
                                                                        </div>
                                                                    </div>
                                                            }
                                                            <h5 className="mb-0 fw-normal">₹{item.price}</h5>
                                                        </div>
                                                        <div className="d-flex w-100 align-items-center justify-content-between pt-4">
                                                            <h5 className="mb-0 fw-normal">GST</h5>
                                                            <h5 className="mb-0 fw-normal">₹{item?.tax}</h5>
                                                        </div>



                                                    </div>
                                                </>
                                            ))
                                        }
                                    </>
                                    :
                                    null
                            }
                            <div className="w-100 e-px-32 e-py-32 e-grey-bg">

                                {
                                    saved ?
                                        <>
                                            <div>
                                                <div className="d-flex w-100 align-items-center justify-content-between pt-3">
                                                    <div>
                                                        <h3 className="mb-2">Total Amount</h3>
                                                        <span className="e-checkout-info-muted">(Inclusive of all taxes)</span>
                                                    </div>
                                                    <h3 className="mb-0">₹{cart?.reduce((a, b) => a + b.finalPrice, 0)}</h3>
                                                </div>
                                            </div>
                                            <div className='d-flex justify-content-end w-100 e-py-32 ms-auto'>
                                                <Primary
                                                    name={
                                                        <span className={paymentLoader ? "e-payment-loader d-flex align-items-center" : "d-flex align-items-center"}>
                                                            Proceed to Payment
                                                            <svg className="ps-1" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M14.1673 16.6663H5.00065C4.08018 16.6663 3.33398 15.9201 3.33398 14.9997V5.83301C3.33398 4.91253 4.08018 4.16634 5.00065 4.16634H8.33398V5.83301H5.00065V14.9997H14.1673V11.6663H15.834V14.9997C15.834 15.9201 15.0878 16.6663 14.1673 16.6663ZM9.75065 11.4222L8.57565 10.2438L13.8198 4.99967H10.834V3.33301H16.6673V9.16634H15.0007V6.17884L9.75065 11.4222Z" fill="white" />
                                                            </svg>
                                                        </span>
                                                    }

                                                    large={true} handleClick={() => {
                                                        payment();
                                                    }} />
                                            </div>
                                        </>
                                        :
                                        null
                                }


                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PaymentModal />
            {
                error !== null ?
                    <Toast
                        data={error}
                    />
                    :
                    null
            }
        </div>
    )
}

export default CheckoutContainer;