const request=require("request");
class JRequest
{
	
	getIP(request) {
    var ip = request.connection.remoteAddress ||
        request.socket.remoteAddress ||
        request.connection.socket.remoteAddress || request.headers['x-forwarded-for'] ;
    ip = ip.split(',')[0];
    //ip = ip.split(':').slice(-1); //in case the ip returned in a format: "::ffff:146.xxx.xxx.xxx"
    return ip;
	}
	checkIP(request,response) {
    var ip =
        request.connection.remoteAddress ||
        request.socket.remoteAddress ||
        request.connection.socket.remoteAddress ||request.headers['x-forwarded-for'];
    ip = ip.split(',')[0];
    //ip = ip.split(':').slice(-1); //in case the ip returned in a format: "::ffff:146.xxx.xxx.xxx"
    return ip;
	}
	
	async getGetService(_url,_params=null){
	return (
			 new Promise(function(resolve, reject){
					var options = { method: 'GET',
					  url: _url+(_params===null?"":("?"+_params)),
					};
					console.log(options.url);
					request(options, function (error, response, body) {
					try{
						console.log(body);
						if (!error && response.statusCode === 200){
							var _json=JSON.parse(body);
							resolve(_json);
							}
						else resolve({status:-1});
					}catch(e){ console.log(e); resolve({status:-9}); }
					})
			 })
	);
	}

	async getPostService(_url,_formData){
	return (
			 new Promise(function(resolve, reject){
					var options = { method: 'POST',
					  url: _url,
					  headers: { 'cache-control': 'no-cache',
								 'content-type': 'application/x-www-form-urlencoded' },
					  form:    _formData
					};
					//console.log(_url)
					//console.log(_formData)
					request(options, function (error, response, body) {
					try{
						console.log(body);
						if (!error && response.statusCode === 200){
							var _json=JSON.parse(body);
							resolve(_json);
							}
						else resolve({status:-1});
					}catch(e){ console.log(e); resolve({status:-9}); }
					})
			 })
	);
	}
	
}
var jreq=new JRequest();
export default jreq;