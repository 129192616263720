/* packages */
import React, { useState, useEffect , useRef} from 'react';
import $ from 'jquery';
import * as solanaWeb3 from '@solana/web3.js';

/* components */
import { Primary, Secondary } from '../cta';
import { FileUpload, InputBox , CheckBox} from '../form';
import { Toast } from '../alerts';
import jconstant from '../../config/xutils/constant';
import {metadataInput,getMetadata} from "../../config/xutils/customermetadata.js"
import * as jtoken from '../../config/xclient/token';
/* assets */
import PlusIcon from '../../assets/images/icons/coolicon.svg';
import Loader from '../../assets/images/loader.gif';

/*services*/
import axios from 'axios';
import services from '../../services/apis/services';
import jhex from '../../services/hedera_services/utils/hex-provider';

const AddCouponModal = (props) => {
    const [error, setError] = useState(null);
    const [title, setTitle] = useState("");
    const [quantity, setQuantity] = useState("");
    const [description, setDescription] = useState("");
    const [cover, setCover] = useState("");
    const [coverLoading, setCoverLoading] = useState(false);
    const [couponImageId, setCouponImageId] = useState("");
    const [createCouponLoader, setCreateCouponLoader] = useState(false);
    const [hexData, setHexData] = useState();
    const [nft, setNFT] = useState(false);
    const uploaderRef = useRef(null);
    const [ nftFile, setFile] = useState();
    const [ fileUrl, setFileUrl] = useState();
    const [ token_id, set_token_id] = useState();

    useEffect(() => {
        listernWindow();
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setError(null)
        }, 3000)
    }, [error]);

    /* ----------upload cover image api--------*/
    const handleFileUpload = file => {
        console.log(file)
        setCoverLoading(true);
        setCover("");
        let IMAGE_URL = "http://147.182.216.70:5001"
        const formdata = new FormData();
        formdata.append("image", file, file.name);


        axios.post("http://147.182.216.70:5001/image/kpn/set", formdata, {
            headers: {
                token: `${localStorage.getItem("web23_token")}`
            }
        }).then((response) => {
            if (response.data.status === 1) {
                setCoverLoading(false);
                setCover(IMAGE_URL + response.data.resData.url);
                setCouponImageId(response.data.resData.imageId);
            } else if (response.data.status === 0 || response.data.status === -1) {
                setError(response.data.msg);
                setCoverLoading(false);
            } else {
                setError(response.data.msg);
                setCoverLoading(false);
            }
        });
    }

    /*------------- create coupon api---------------*/
    const createCoupon = () => {
        console.log('descrip', description);
        console.log('nft', nft);
        console.log('title', title);
        console.log('qty', quantity);

        setCreateCouponLoader(true);
        let url = "action/" + process.env.REACT_APP_CREATE_COUPON_URL;
        let header = window.location.href;
        let domain = header.substring(header.indexOf('=') + 1);
        let data = {
            token: localStorage.getItem("web23_token"),
            couponImageId: couponImageId,
            description: description,
            title: title,
            qty: quantity,
            domain: domain,
            isNFT:  nft ? 1 : 0,           
            nftTokenId : nft ? token_id : null
        }
       

        services(true, url, data).then((response) => {
            let res = response.data;
            console.log('Response :: ', response)
            if (res.status === 1) {
                setCreateCouponLoader(false);
                props.added(true);
                $("#addCouponModal").trigger("click");
            } else if (res.status === 0 || res.status === -1 || res.status === -9) {
                setError(res.msg);
                setCreateCouponLoader(false);
            } else {
                setError(res.msg);
                setCreateCouponLoader(false);
            }
        });
    }

    const resetData = () => {
        setCover("");
        setQuantity("");
        setTitle("");
        setDescription("");
        setCoverLoading(false);
    }


   const  listernWindow = () =>{
        window.addEventListener("hexProvider", (event) => {
			if(event.detail.message.type==="onTransaction" ){
                if( event.detail.message.data.status === 'SUCCESS'){
                    set_token_id(event.detail.message.data.tokenId);
                }
				console.log(event.detail.message)
			}
		  });
	}

    useEffect(()=>{
        if (token_id) createCoupon();
    },[token_id])

    const _mintNFT = async(hash) => {
        console.log('after---->')
        console.log('descrip', description);
        console.log('nft', nft);
        console.log('title', title);
        console.log('qty', quantity);
        const myAccountId = window.hex.activeAddress;

        var metaData = [
            { "ipfs_hash": hash},
        ];
        var options = {
            admin: true,
            changeSupply: true,
            wipe: false
        };
        var token_result = jhex.createNonFungibleToken({
            tokenName: 'testing',
            'accountId': myAccountId,
            templateName: "Testing",
            properties: metaData,
            options: options
        });
        console.log('result');   
        console.log('after---->')
        console.log('descrip', description);
        console.log('nft', nft);
        console.log('title', title);
        console.log('qty', quantity);
   }

//    ipfs hash
const ipfs_upload = async () =>{
    let data = new FormData();
    let image = nftFile;
    let fileName = image.name;
    data.append("ipfs", image, fileName);
    data.append("title", title);
    data.append("file_type", image.type);
    data.append("description", description );
    console.log('data-->', data);
    // let url = "http://139.59.59.31:3006/upload/ipfs";
    let url = process.env.REACT_APP_DEV_MODE+'upload/ipfs'
    if (nftFile != null) {
      let requestOptions = {
        method: 'POST',
        body: data,
      };
      await fetch(url, requestOptions)    //file and data upload
        .then(response => response.text())
        .then(async result => {
          if(result){
              let temp = JSON.parse(result);
          if (temp.body && temp.body.IpfsHash) {  //Loader Modal text
            const hash = temp.body.IpfsHash;
            _mintNFT(hash);
            console.log("Hash--> : ", hash)
            console.log('after---->')
            console.log('descrip', description);
            console.log('nft', nft);
            console.log('title', title);
            console.log('qty', quantity);
           }
         }
            
        }).catch(error=>{
            console.log('err',error);
        })
     }
}
const uploadDocument = (e)=>{
    console.log(e.target.files[0]);
    handleFileUpload(e.target.files[0])
    setFile(e.target.files[0]);
    setFileUrl(URL.createObjectURL(e.target.files[0]));
}


      

    return (
        <div className="modal fade e-add-coupon-modal"
            id="addCouponModal" tabIndex="-1"
            aria-labelledby="addCouponModalLabel"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content position-relative">
                    <div className="modal-header">
                        <h5 className="modal-title mb-0" id="addCouponModalLabel">Add Coupon</h5>
                        <div className="e-close-btn-wrapper d-flex align-items-center justify-content-center position-absolute" data-bs-dismiss="modal" onClick={resetData}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41L17.59 5Z" fill="#11142F" />
                            </svg>
                        </div>
                    </div>
                    <div className="modal-body pt-4 pb-2">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-12">
                                        <InputBox
                                            label={<>Title <span className="e-warning-color">*</span></>}
                                            value={title}
                                            type="text"
                                            valueChange={(value) => {
                                                setTitle(value);
                                                setError(null);
                                            }}
                                            placeholder="Title"
                                            required={true} />
                                    </div>
                                    <div className="col-12">
                                        <InputBox
                                            label={<>Quantity <span className="e-warning-color">*</span></>}
                                            value={quantity}
                                            type="number"
                                            valueChange={(value) => {
                                                setQuantity(value);
                                                setError(null);
                                            }}
                                            className={
                                                nft ?
                                                    'e-disabled-input'
                                                :
                                                    null
                                            }
                                            isDisabled={nft}
                                            placeholder="Total number of coupons"
                                            required={true} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="w-100 h-100 d-flex flex-column">
                                    <div className="e-input-wrap flex-fill w-100 e-coupon-upload d-flex flex-column">
                                        <label>Cover Image <span className="e-warning-color">*</span></label>
                                        <>
                                        {
                                            nft ?
                                            <>
                                            {
                                                fileUrl ?
                                                    <div className="e-uploaded-wrapper flex-fill position-relative">
                                                        <img src={fileUrl} alt="cover image" className="e-cover-uploaded position-absolute" />
                                                        <div className="e-upload-new-wrapper w-100 h-100 justify-content-center d-flex align-items-center position-absolute">
                                                            <span onClick={()=>{ setFile(null); setFileUrl(null) }}>Change</span>
                                                        </div>
                                                    </div>
                                                :
                                                    <>
                                                        <div className="w-100 flex-fill h-100 text-center" ref={uploaderRef} onClick={()=> $('#e-nft-file').trigger('click')}>
                                                            <div className='e-file-upload h-100 '>
                                                                <div className="e-plus-icon-wrapper mt-4 pt-1">
                                                                    <img src={PlusIcon} alt="+" />
                                                                </div>
                                                                <h5 className="mb-0 pt-3 text-center">
                                                                    Add Cover
                                                                </h5>
                                                            </div>
                                                        </div>
                                                        <input type="file" id="e-nft-file" name="file" style={{display: 'none'}} multiple onChange={(e)=> uploadDocument(e)}/>
                                                    </>
                                            }
                                             </>
                                            :
                                                <div className="w-100 flex-fill">
                                                    <FileUpload
                                                        name={
                                                            coverLoading ?
                                                                <>
                                                                    <img src={Loader} className="e-img-loading" alt="loading" />
                                                                </>
                                                                :
                                                                cover !== "" ?
                                                                    <div className="e-uploaded-wrapper flex-fill position-relative">
                                                                        <img src={cover} alt="cover image" className="e-cover-uploaded position-absolute" />
                                                                        <div className="e-upload-new-wrapper w-100 h-100 justify-content-center d-flex align-items-center position-absolute">
                                                                            <span>Change</span>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <>
                                                                        <div className="e-plus-icon-wrapper">
                                                                            <img src={PlusIcon} alt="+" />
                                                                        </div>
                                                                        <h5 className="mb-0 pt-3 text-center">
                                                                            Add Cover
                                                                        </h5>
                                                                    </>
                                                        }
                                                        uploadStatus={setCoverLoading}
                                                        uploadSucess={handleFileUpload}
                                                        tempKey="/kyc/document"
                                                        id="kyc"
                                                        className="e-file-upload p-0 w-100 h-100 d-flex flex-column align-items-center justify-content-center" 
                                                    />
                                                </div>
                                        }                                           
                                        </>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <InputBox
                                    label={<>Description <span className="e-warning-color">*</span></>}
                                    value={description}
                                    type="text"
                                    valueChange={(value) => {
                                        setDescription(value);
                                        setError(null);
                                    }}
                                    placeholder="Description of the offer"
                                    required={true} />
                            </div>
                            <div className='col-lg-12 mt-2 mb-3'>
                                <CheckBox label="NFT" onChecked={(status)=>{  setNFT(status); if(status) setQuantity(1); else setQuantity('');}}/>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer pt-3 justify-content-end align-items-center d-flex">
                        <div className="d-flex align-items-center">
                            {/* {
                                title && quantity && description && cover ? */}
                                    <Primary large={true}
                                        name={ nft ?"Mint NFT" : "Add"}
                                        className={createCouponLoader ? "e-btn-loader" : ""}
                                        handleClick={() => {
                                            if( nft )  _mintNFT(null); //ipfs_upload(); 
                                            else createCoupon();
                                            
                                        }} />
                                    {/* :
                                    <Primary large={true}
                                        name="Add"
                                        disabled={true} />
                            } */}
                        </div>
                    </div>
                </div>
            </div>
            {
                error !== null ?
                    <Toast data={error} />
                    :
                    null
            }
        </div>
    )
}

export default AddCouponModal;