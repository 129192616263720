
/* packages */
import React, { useEffect, useState, useRef } from 'react';
import $ from 'jquery';
import Web23Logo from '../../assets/images/logo-long-color.svg'
import jsPDF from 'jspdf';
import ReactToPrint from 'react-to-print';
import moment from 'moment';

/* components */
import { Primary } from '../cta';


const InvoiceModal = (props) => {
  const componentRef = useRef(null);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    if (loader === false) {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  }, [loader]);
  // const savePdf = () => {
  //   setLoader(true)
  //   // let elem = document.getElementById("pdf");
  //   // // window.print(elem)
  //   // elem.scrollIntoView();
  //   // html2canvas(elem).then(canvas => {
  //   //   setLoader(false)
  //   //   const img = canvas.toDataURL("image/png", 1);
  //   //   var imgWidth = 150;
  //   //   var pageHeight = 200;
  //   //   var imgHeight = (canvas.height * 130) / canvas.width;
  //   //   var heightLeft = imgHeight;

  //   //   const pdf = new jsPDF("l", "mm");
  //   //   var position = 10;

  //   //   pdf.addImage(img, "PNG", 0, position, imgWidth, imgHeight, 100);
  //   //   heightLeft -= pageHeight;

  //   //   while (heightLeft >= 0) {
  //   //     position = heightLeft - imgHeight;
  //   //     pdf.addPage();
  //   //     pdf.addImage(img, "PNG", 0, position, imgWidth, imgHeight);
  //   //     heightLeft -= pageHeight;
  //   //   }
  //   //   setLoader(false);
  //   //   pdf.save("invoice.pdf");
  //   // });
  //   let specific_element = document.getElementById("pdf").innerHTML;
  //   let original_elements = document.body.innerHTML;

  //   document.body.innerHTML = specific_element;
  //   window.print();
  //   setLoader(false);
  //   document.body.innerHTML = original_elements;
  // }

  // console.log(componentRef)

  return (
    <div className="modal fade e-invoice-modal" id="invoice" tabIndex="-1" aria-labelledby="addKycModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-xl">
        <div className="modal-content position-relative p-3">
          <div className="modal-header mb-3 pb-4">
            <div className="e-close-btn-wrapper d-flex align-items-center justify-content-center position-absolute" data-bs-dismiss="modal">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41L17.59 5Z" fill="#11142F" />
              </svg>
            </div>
          </div>
          <div className="modal-body pt-4 pb-2 mx-auto" id="pdf" ref={componentRef}>
            <div className="page print-page">
              <div className="subpage">
                <table className="e-container-table " align="center" border="0" cellpadding="0" cellspacing="0" width="100%"
                  style={{ padding: "0px", overflowY: "scroll" }}>
                  <tr>
                    <td>
                      <table className="e-sub1" align="center" border="0" cellpadding="0" cellspacing="0" width="800"
                        style={{ borderCollapse: "collapse", backgroundColor: "#FFFFFF", }}>
                        <tr>
                          <td style={{ display: "flex", margin: "40px 30px 30px 30px" }}>
                            <div style={{ color: "#FFFFFF", marginTop: "25px" }}>
                              <img src={Web23Logo} />
                            </div>
                            <div style={{ color: " #FFFFFF", marginLeft: "auto", paddingRight: "5px" }} >
                              <h2 style={{ color: "black", fontSize: "28px", margin: 0, textAlign: "right", marginBottom: "8px" }}>Tax Invoice</h2>
                              <p style={{ color: "grey", fontSize: "10px", fontWeight: "400", marginBottom: "10px", fontWeight: "500", textAlign: "right" }}>Bill Id:
                                {props?.billId}
                              </p>
                              <p style={{ color: "grey", fontSize: "10px", fontWeight: "400", margin: 0, fontWeight: "500", textAlign: "right" }}>Bill Date : {(moment(props?.invoice[0]?.purchaseDate?.split('T')[0], "YYYY-MM-DD").format("DD MMM YYYY"))}</p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <table border="0" cellpadding="0" cellspacing="0">
                              <tr>
                                <td
                                  style={{ display: "flex", border: "solid 1px 6e6e6e", backgroundColor: "#f5f5f5", border: "2px solid #dbd7d2", margin: "0px 30px", }} >
                                  <table>
                                    <tr>
                                      <td style={{ display: "flex" }}>
                                        <div style={{ borderRight: "2px solid #dbd7d2", padding: "30px", width: "365px" }}>
                                          <h3 style={{ color: "rgb(87, 87, 87)", fontSize: "18px" }}>Seller:</h3>
                                          <p style={{ fontSize: "12px", margin: 0, lineHeight: "24px", color: "#6d6d6d" }}> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
                                            has
                                          </p>
                                          <p style={{ fontSize: "12px", margin: 0, lineHeight: "24px", color: "#6d6d6d" }}>Email : xyz@gmail.com</p>
                                          <p style={{ fontSize: "12px", margin: 0, lineHeight: "24px", color: "#6d6d6d" }}>Contact no: +917897898989</p>
                                        </div>
                                        <div style={{ padding: "30px", width: "365px" }}>
                                          <h3 style={{ color: "rgb(87, 87, 87)", fontSize: "18px" }}>Purchaser:</h3>
                                          <p style={{ fontSize: "12px", margin: "0", lineHeight: "24px", color: "#6d6d6d" }}>
                                            {props.billingAddress.fullName} <br />
                                            Address : {
                                              props.billingAddress.adrLine1, props.billingAddress.adrLine2
                                            }
                                            <br />
                                            {props.billingAddress.city}, {props.billingAddress.state}, {props.billingAddress.pincode},
                                            <br />
                                            {props.billingAddress.country}
                                          </p>
                                          <p style={{ fontSize: "12px", margin: "0", lineHeight: "24px", color: "#6d6d6d" }}>Email : {props.billingAddress.contactEmail}</p>
                                          <p style={{ fontSize: "12px", margin: "0", lineHeight: "24px", color: "#6d6d6d" }}>Contact no: +{props.billingAddress.countryCode}{props.billingAddress.mobileNo}</p>
                                        </div>
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ display: "flex", margin: "30px 30px 10px 30px", backgroundColor: "#f5f5f5" }}>
                            <div style={{ color: "#FFFFFF", padding: "10px" }}>
                              <h3 style={{ color: "rgb(87, 87, 87)", fontSize: "18px", margin: "0" }}>Products</h3>
                            </div>
                            <div style={{ color: "#FFFFFF", marginLeft: "auto", padding: "10px" }}>
                              <h3 style={{ color: "rgb(87, 87, 87)", fontSize: "18px", margin: "0" }}>Amount</h3>
                            </div>
                          </td>
                        </tr>
                        {
                          props.invoice.map((item) => (
                            <>
                              <tr>
                                <td style={{ display: "flex", margin: "0px 30px 10px 30px", borderBottom: "1px solid #dbd7d2", }}>
                                  <div style={{ color: "#FFFFFF", padding: "10px" }}>
                                    <p style={{ fontSize: " 16px", fontWeight: "500", margin: "0", lineHeight: "24px", color: "#6d6d6d" }}>{item.itemName}</p>
                                  </div>
                                  <div style={{ color: "#FFFFFF", marginLeft: "auto", padding: "10px" }}>
                                    <p style={{ fontSize: " 16px", fontWeight: "500", margin: "0", lineHeight: "24px", color: "#6d6d6d" }}>₹ {item.finalPrice}
                                    </p>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td style={{ display: "flex", margin: "0px 30px 10px 30px", borderBottom: "1px solid #dbd7d2" }}>
                                  <div style={{ color: "#FFFFFF", padding: "10px" }}>
                                    <p style={{ fontSize: "16px", fontWeight: "500", margin: " 0", lineHeight: "24px", color: "#6d6d6d" }}>CGST 9%</p>
                                  </div>
                                  <div style={{ color: "#FFFFFF", marginLeft: "auto", padding: "10px" }}>
                                    <p style={{ fontSize: "16px", fontWeight: "500", margin: "0", lineHeight: "24px", color: "#6d6d6d" }}>₹ 0.0
                                    </p>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td style={{ display: "flex", margin: "0px 30px 10px 30px", borderBottom: "1px solid #dbd7d2", marginBottom: "40px" }}>
                                  <div style={{ color: "#FFFFFF", padding: "10px" }}>
                                    <p style={{ fontSize: "16px", fontWeight: "500", margin: "0", lineHeight: "24px", color: "#6d6d6d" }}>SGST 9%</p>
                                  </div>
                                  <div style={{ color: "#FFFFFF", marginLeft: "auto", padding: "10px" }}>
                                    <p style={{ fontSize: "16px", fontWeight: "500", margin: "0", lineHeight: "24px", color: " #6d6d6d" }}>₹ 0.0
                                    </p>
                                  </div>
                                </td>
                              </tr>
                            </>
                          ))
                        }
                        <tr>
                          <td style={{ display: "flex", margin: "0px 30px 20px 30px", borderBottom: " 2px solid black" }}>
                            <div style={{ color: "#FFFFFF", padding: "10px" }}>
                              <h3 style={{ color: "black", fontSize: "18px", margin: " 0" }}>Total INR</h3>
                            </div>
                            <div style={{ color: "#FFFFFF", marginLeft: "auto", padding: "10px" }}>
                              <h3 style={{ color: 'black', fontSize: "18px", margin: "0" }}>₹{props.invoice?.reduce((prev, last) => (prev + last.finalPrice), 0)}</h3>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td >
                            <p style={{ color: "grey", fontSize: "12px", margin: "0px 30px 15px 30px", fontWeight: "400", fontWeight: 500 }}>
                              Notes/Terms
                            </p>
                            <p style={{ color: "grey", fontSize: "10px", margin: "0px 30px 20px 30px", fontWeight: 300 }}>
                              Thank you for the purchase. This is a computer-generated document. No signature is required</p>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </div>

            </div>
          </div>
          <div className="modal-footer pt-3 justify-content-end align-items-center d-flex">
            <div className="d-flex align-items-center">
              {/* <Secondary large={true} className="me-3" name="Cancel" cancel="modal" /> */}
              <ReactToPrint
                trigger={() => <button className="e-print-btn">Save pdf</button>}
                content={() => componentRef.current}
              />
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default InvoiceModal;