/* packages */
import React, { useState, useEffect } from 'react';
import $ from 'jquery'

/* components */
import { Toast } from '../alerts';

import styles from '../../styles/components/CouponCard.module.scss';


const ShowcouponModal = (props) => {
    const [error, setError] = useState(null);
    const [copied, setCopied] = useState(false);
    const [coupon, setCoupon] = useState("");

    useEffect(() => {
        setCoupon(props.data);
    }, [props.data]);

    useEffect(() => {
        setTimeout(() => {
            setError(null)
        }, 3000)
    }, [error]);

    /* copy coupon code func */
    const copyData = (data) => {
        // if (!window.navigator.clipboard) {
        //     // document.execCommand('copy');
        // }
        // else {
        window.navigator.clipboard.writeText(data).then(() => {
            setCopied(data);
            setTimeout(() => {
                setCopied(null);
            }, 2000);
        }).catch(err => {
            console.log(err);
        });
        // }
    }

    return (
        <div className={`${styles.e_modal_wraper} modal fade e-add-coupon-modal`}
            id="showCoupon" tabIndex="-1"
            aria-labelledby="showCouponLabel"
            aria-hidden="true" >
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content position-relative">
                    <div className="modal-header mb-4">
                        <h5 className="modal-title mb-0" id="showCouponLabel">Coupons - {coupon.domain}</h5>
                        <div className="e-close-btn-wrapper d-flex align-items-center justify-content-center position-absolute" data-bs-dismiss="modal">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41L17.59 5Z" fill="#11142F" />
                            </svg>
                        </div>
                    </div>
                    <div className="modal-body pt-4 pb-2">
                        <div className="row">
                            {
                                coupon.couponsRecs?.newly_added.length > 0 ?
                                    coupon.couponsRecs.newly_added.map((item) => {
                                        return (
                                            <div className="col-lg-12 d-flex mb-4 position-relative">
                                                <p className="mb-0" >{item.gs1}</p >
                                                <svg className={`${styles.e_share_icon} ms-auto position-relative`} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => copyData(item.gs1)}>
                                                    <path d="M14.0002 22.0002H4.00019C3.46427 22.0188 2.94467 21.8141 2.56548 21.4349C2.1863 21.0557 1.98157 20.5361 2.00019 20.0002V10.0002C1.98157 9.46427 2.1863 8.94467 2.56548 8.56548C2.94467 8.1863 3.46427 7.98157 4.00019 8.00019H8.00019V4.00019C7.98157 3.46427 8.1863 2.94467 8.56548 2.56548C8.94467 2.1863 9.46427 1.98157 10.0002 2.00019H20.0002C20.5361 1.98157 21.0557 2.1863 21.4349 2.56548C21.8141 2.94467 22.0188 3.46427 22.0002 4.00019V14.0002C22.0185 14.536 21.8137 15.0555 21.4346 15.4346C21.0555 15.8137 20.536 16.0185 20.0002 16.0002H16.0002V20.0002C16.0185 20.536 15.8137 21.0555 15.4346 21.4346C15.0555 21.8137 14.536 22.0185 14.0002 22.0002ZM4.00019 10.0002V20.0002H14.0002V16.0002H10.0002C9.46435 16.0185 8.94493 15.8137 8.56581 15.4346C8.18669 15.0555 7.98187 14.536 8.00019 14.0002V10.0002H4.00019ZM10.0002 4.00019V14.0002H20.0002V4.00019H10.0002Z" fill="#11142F" />

                                                </svg>
                                                <div className={copied === item.gs1 ? `${styles.e_copied_toast} ${styles.e_copied_active} position-absolute` : `${styles.e_copied_toast} position-absolute`}>
                                                    Coupon Code Copied
                                                </div>
                                            </div>
                                        )
                                    })
                                    :
                                    null
                            }
                        </div>
                    </div>
                </div>
            </div>
            {
                error !== null ?
                    <Toast data={error} />
                    :
                    null
            }
        </div >
    )
}

export default ShowcouponModal;