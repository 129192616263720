import React from 'react';
import { Link } from 'react-router-dom';

const AuthHeader=()=>{
    return(
        <div className="position-fixed py-3 e-auth-header-wrapper">
            <div className="container py-3">
                <Link to="/">
                <svg width="142" height="30" viewBox="0 0 142 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M48.8929 20H50.9762L52.8966 13.4619H52.9756L54.9009 20H56.9842L59.8771 10H57.542L55.8685 16.9629H55.7796L53.9382 10H51.9389L50.0925 16.9482H50.0086L48.3351 10H46L48.8929 20Z" fill="white"/>
                    <path d="M61.0212 20H67.8536V18.2568H63.1588V15.8691H67.4834V14.126H63.1588V11.7432H67.8339V10H61.0212V20Z" fill="white"/>
                    <path d="M69.5333 20H73.8529C76.1238 20 77.3333 18.8281 77.3333 17.2363C77.3333 15.7568 76.2621 14.8633 75.1118 14.8096V14.7119C76.1633 14.4775 76.9384 13.7402 76.9384 12.5635C76.9384 11.0645 75.8128 10 73.5814 10H69.5333V20ZM71.6709 18.2715V15.6152H73.4629C74.4898 15.6152 75.1266 16.2012 75.1266 17.0264C75.1266 17.7783 74.6083 18.2715 73.4136 18.2715H71.6709ZM71.6709 14.1846V11.709H73.2951C74.243 11.709 74.7811 12.1924 74.7811 12.915C74.7811 13.7061 74.1294 14.1846 73.2556 14.1846H71.6709Z" fill="white"/>
                    <g opacity="0.5">
                    <path d="M80.0959 19.8669H87.0925V18.1844H82.9712V18.1179L84.404 16.7253C86.4216 14.9002 86.9631 13.9876 86.9631 12.8802C86.9631 11.193 85.5733 10 83.4696 10C81.4089 10 79.9952 11.2215 80 13.1321H81.9696C81.9648 12.2006 82.559 11.6302 83.4552 11.6302C84.3178 11.6302 84.9599 12.1625 84.9599 13.0181C84.9599 13.7928 84.4807 14.3251 83.5894 15.1759L80.0959 18.384V19.8669Z" fill="white"/>
                    <path d="M92.2237 20C94.4281 20 96.0048 18.7975 96 17.134C96.0048 15.9221 95.2284 15.057 93.786 14.8764V14.8004C94.8834 14.6055 95.6406 13.8356 95.6358 12.7424C95.6406 11.2072 94.2844 10 92.2525 10C90.2398 10 88.7254 11.1692 88.6967 12.8517H90.6854C90.7094 12.1103 91.3947 11.6302 92.2429 11.6302C93.0815 11.6302 93.6422 12.134 93.6374 12.866C93.6422 13.6312 92.9857 14.1492 92.0416 14.1492H91.1263V15.6606H92.0416C93.1534 15.6606 93.8483 16.212 93.8435 16.9962C93.8483 17.7709 93.1774 18.3032 92.2285 18.3032C91.3132 18.3032 90.6279 17.8279 90.5896 17.115H88.5002C88.5337 18.8165 90.0672 20 92.2237 20Z" fill="white"/>
                    </g>
                    <path d="M28.1953 22.0373L27.8214 22.1911C27.5683 22.1911 27.3768 22.1235 27.2514 21.9837C27.126 21.8415 27.0098 21.5455 26.8867 21.0886L23.2867 7H16.251L12.952 21.0886C12.838 21.5851 12.7081 21.9114 12.5576 22.0583L12.0264 22.2867C11.5727 22.2867 11.2307 21.8881 11.0141 21.0886L6.44751 7H0L6.04625 25.3823C6.41331 26.4709 6.81229 27.3427 7.23635 27.9883C7.66269 28.634 8.24406 29.1282 8.98046 29.4802C9.7237 29.8252 10.6562 30 11.7961 30C14.7622 30 16.6545 28.4196 17.4616 25.2541L19.9034 15.6946L22.4637 25.1002C22.7441 26.0862 23.1294 26.9441 23.6105 27.669C24.0915 28.3916 24.6957 28.965 25.4207 29.38C26.1525 29.7925 27.0098 30 27.9878 30C28.6558 30 29.3581 29.8042 30.0967 29.4149C30.8331 29.028 31.5194 28.4545 32.1532 27.69C32.7847 26.9277 33.2726 26.0443 33.61 25.035L42 0H35.6961L28.551 21.3054C28.421 21.6993 28.2865 21.9347 28.1953 22.0373Z" fill="white"/>
                    </svg>
                </Link>
            </div>
        </div>
    )
}

export default AuthHeader;