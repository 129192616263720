/* packages */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

/* components */
import { ManageCouponsCard } from "../../components/cards";
import Loader from "../../components/others/loader";
import { TabHeadingOne } from "../../components/tabHeadings";

/* services */
import services from "../../services/apis/services";

const sampleWeb2 = ["coupons.sarahjames.com", "coupons.sarah.in", "coupons.sarahjames.page", "coupons.sarahjames.xyz", "coupons.sarahjames.co.in"];
const sampleWeb3 = [{ itemName: "coupons.sarahjames.eth" }, { itemName: "coupons.sarah.coin" }, { itemName: "coupons.sarahjames.x" }, { itemName: "coupons.sarahjames.wallet" }]

const ManageCoupons = (props) => {
    const [activeTab, setActiveTab] = useState(1);
    const [domainsList, setDomainsList] = useState([]);
    const [listLoader, setListLoader] = useState(false);
    const [error, setError] = useState(null);

    const router = useHistory()

    const handleRouteChange = () => {
        if (props.type === "web2") {
            setActiveTab(1);
        }
        else if (props.type === "web3") {
            setActiveTab(2);
        }
        else {
            router.replace('/manage-coupons/web2');
        }
    }

    const handleTabSwitch = (val) => {
        if (val === 1) {
            router.push('/manage-coupons/web2');
        }
        else if (val === 2) {
            router.push('/manage-coupons/web3');
        }
    }

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);

    useEffect(() => {
        if (props.type) {
            handleRouteChange();
        }
    }, [props.type]);

    useEffect(() => {
        if (activeTab === 1 && props.type === "web2") {
            getWeb2DomainsList();
        }
        else {
            setDomainsList(sampleWeb3);
        }
    }, [activeTab, props.type]);

    const getWeb2DomainsList = () => {
        setListLoader(true);
        let url = "action/my/w2domains";

        let data = {
            token: localStorage.getItem("web23_token")
        }

        services(true, url, data).then((response) => {
            let res = response.data;

            console.log(res)
            if (res.status === 1) {
                setDomainsList(res.resData);
                setListLoader(false);
            } else if (res.status === 0 || res.status === -1 || res.status === -9) {
                setListLoader(false);
                setError(res?.msg);
            } else {
                setListLoader(false);
                setError(res.msg);
            }
        });
    }

    return (
        <div className="container-fluid position-relative e-manage-coupons-page-container pt-3">
            <div className="e-gradient-bg position-absolute"> </div>
            <div className="container position-relative pt-3">
                <TabHeadingOne
                    tabs={["Web 2.0", "Web 3.0"]}
                    activeTab={activeTab}
                    setActive={handleTabSwitch}
                />
                <div className="pt-5">
                    {
                        listLoader ?
                            <Loader />
                            :
                            domainsList.length > 0 ?
                                <ManageCouponsCard
                                    data={domainsList}
                                    type={props.type}
                                />
                                :
                                null
                    }
                </div>
            </div>
        </div>
    )
}

export default ManageCoupons;