import React, { useContext, useEffect, useState } from 'react';
import { message } from 'antd';
import $ from 'jquery';

/* components */
import { Primary } from '../cta';
import SubscriptionTable from '../dataTable/subscriptionTable';
import { CartContext } from '../../context/cartContext';
import { Toast } from '../alerts';

/* Services */
import services from '../../services/apis/services';



// const sampleData = [
//     { "name": "Starter", "badge": null, "single_price": 200,"isSubscription": true, "specs": ["Additional 2 domains", "Own analytics platform", "Chat support", "Optimize hashtags", "Unlimited users"] },
//     { "name": "Medium", "badge": "popular", "single_price": 499, "isSubscription": true, "specs": ["Additional 2 domains", "Own analytics platform", "Chat support", "Optimize hashtags", "Unlimited users"] },
//     { "name": "Pro", "badge": null, "single_price": 999, "isSubscription": true, "specs": ["Additional 2 domains", "Own analytics platform", "Chat support", "Optimize hashtags", "Unlimited users"] },
// ]

const SubscriptionModal = (props) => {
    const [packageId, setPackageId] = useState(null);
    const [packageName, setPackageName] = useState(null);
    const [data, setData] = useState([]);
    const [cartData, setCartData] = useContext(CartContext);
    const [error, setError] = useState(null);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        getSubscription();
    }, []);

    // console.log(props)

    useEffect(() => {
        setTimeout(() => {
            setError(null);
        }, 3000);
    }, [error]);

    /*------- add to cart api----*/
    const handleAddPackageToCart = () => {
        setLoader(true);

        let url = 'action/order/add/package';

        let data = {
            itemName: packageName,
            packageId: Number(packageId),
            token: localStorage.getItem('web23_token')
        }

        services(true, url, data).then((response) => {
            let res = response.data;
			props.updatePackage({packageId:packageId,packageName:packageName});
            
                $('#subscriptionModal').trigger('click');
                setLoader(false);
            
        });

    }

    /*--- get subscription list api ---*/
    const getSubscription = () => {
        let url = 'action/get/subscriptionlist';

        services(true, url).then((response) => {
            let res = response.data
            if (res.status === 1) {
                setData([...res.resData]);
                setPackageId(res.resData[0].PackageId.value);
                setPackageName(res.resData[0].PackageName.value);
				
				props.updatePackage({packageId:res.resData[0].PackageId.value,packageName:res.resData[0].PackageName.value});
            } else {
                setError(res.msg);
            }
        });
    }

    return (
        <div className="modal fade e-subscription-modal" id="subscriptionModal" tabIndex="-1" aria-labelledby="subscriptionModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content position-relative">
                    <div className="modal-header">
                        <h5 className="modal-title mb-0" id="subscriptionModalLabel">Confirm Package</h5>
                        <div className="e-close-btn-wrapper d-flex align-items-center justify-content-center position-absolute" data-bs-dismiss="modal">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41L17.59 5Z" fill="#11142F" />
                            </svg>
                        </div>
                    </div>
                    <div className="modal-body pt-4 pb-2">
                        <SubscriptionTable
                            data={data}
                            selected={packageId}
                            setSelected={(id) => setPackageId(id)}
                            selectedPackageName={(name) => setPackageName(name)}
                        />
                    </div>
                    <div className="modal-footer pt-4 justify-content-between align-items-center d-flex">
                        <div className="d-flex align-items-end">
                            {/* <h6 className="mb-0 fw-normal">Your Savings</h6>
                            <h4 className="mb-0 ps-1 e-green-text">₹1500</h4> */}
                        </div>
                        <div className="d-flex align-items-center">
                            <Primary
                                className={loader ? "e-btn-loader" : ""}
                                medium={true}
                                name="Add to Cart"
                                handleClick={handleAddPackageToCart}
                                disabled={packageId ? false : true}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {
                error !== null ?
                    <Toast data={error} type="fail" />
                    :
                    null
            }
        </div>
    )
}

export default SubscriptionModal;