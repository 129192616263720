import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router';
import styles from '../../styles/components/SecondaryDomainTable.module.scss';
import Success from '../../assets/images/icons/tick-icon-18.svg';
import Warning from '../../assets/images/icons/warning-icon-18.svg';
import { TextButton } from "../cta";

const SecondaryDomainTable = (props) => {
	 const router =useHistory();
	 const [initiated,setInitiated]=useState(false);
	useEffect(() => {
        if (props.data!=undefined && initiated===false) {
			setInitiated(true);
			setTimeout(()=>{
			window["initTable"]("w3DListTable");
			},500);
        }
    }, [props.data])
    return (
		<>
		<div className='col-md-12'>
		<div className='row'>
		{
        props.data && props.data.length ?
			<table id="w3DListTable" className="xtable table-striped" border='0' width='100%'>
			<thead>
				<tr>
					<th></th>
					<th><h6>Domain / Subscription</h6></th>
					<th><h6>Purchase Date</h6></th>
					<th><h6>Expires On</h6></th>
					<th><h6>Redirection</h6></th>
					<th><h6>Action</h6></th>
				   
				</tr>
			</thead>
			<tbody>
			{props.data.map((item,i)=>{
				return(
				<tr>
					<td></td>
					<td><p className="mb-0 text-lowercase">{item.itemName}</p></td>
					<td><p className="mb-0">{item.purchaseDate}</p></td>
					<td>
						<p className="mb-0">{item.expireOn}</p>
					</td>
					<td>
						<p className="mb-0">{item.redirections}</p>
					</td>
					 
					<td align='left'>
						<button className='btn btn-primary btn-sm' onClick={(e)=> router.push('/domain/web2/detail?domain='+item.itemName) }>Manage</button>
					</td>
					
				</tr>
				)
			})
			}
			</tbody>
			<tfoot>
				<tr>
					
				</tr>
			</tfoot>
			</table>
			:null
		}
		
		</div>
		</div>
        </>
		
        
    )
}

export default SecondaryDomainTable;