import React, { useEffect, useState } from 'react';

import HealthIcon from '../../assets/images/icons/health-icon-72.png';
import ResolveLoader from '../../assets/images/resolving-loader.gif';

import { HeathIndicatorBadge } from '../badges';

const ResolveModal = (props) => {
    const [error, setError] = useState(null);
    const [isScanComplete, setScanComplete] = useState(false);

    const handleScanComplete=()=>{
        setScanComplete(true);
    }

    const runScan=()=>{
        setTimeout(() => {
            handleScanComplete()
        }, 1000);
    }

    const setDataToInitial=()=>{
        setScanComplete(false);
    }

    useEffect(()=>{
        let resolveModal = document.querySelector('#resolveModal');
        resolveModal.addEventListener('shown.bs.modal', ()=> runScan());
        resolveModal.addEventListener('hidden.bs.modal', ()=> setDataToInitial());
    },[]);


    return (
        <div className="modal fade e-resolve-modal" id="resolveModal" tabIndex="-1" aria-labelledby="resolveModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content position-relative justify-content-center">
                    <div className="e-close-btn-wrapper d-flex align-items-center justify-content-center position-absolute" data-bs-dismiss="modal">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41L17.59 5Z" fill="#11142F"/>
                        </svg>
                    </div>
                    <div className={isScanComplete? "e-resolve-loader w-100 d-flex flex-column align-items-center e-hide-resolve-loader":"e-resolve-loader w-100 d-flex flex-column align-items-center"}>
                        <img src={ResolveLoader} alt="resolving gif" className="e-resolving-gif"/>
                        <h4 className="mb-0 pt-3">Resolving<span className="e-append-dots"></span></h4>
                    </div>
                    <div className={isScanComplete?"e-resolve-result e-show-result position-absolute d-flex flex-column align-items-center justify-content-center": "e-resolve-result position-absolute d-flex flex-column align-items-center justify-content-end"}>
                        {props.title}
                        <div className="e-health-badge-container position-relative my-1">
                            <div className="position-absolute e-indicator-halo-bottom"></div>
                            <div className="position-absolute e-indicator-halo-top"></div>
                            <img src={HealthIcon} alt="health badge" className="position-absolute e-health-icon"/>
                        </div>
                        <div className="pt-2">
                            <HeathIndicatorBadge healthy={true}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResolveModal;